import { Space } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormattedMessage, PageContainer, type PageContainerProps as PageContainerPropsType } from '@/components';
import { useActiveCompany, useCompanyPermissions } from '@/features/company/hooks';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withSuppressPromise } from '@/infrastructure/utils/functions';
import {
  settingsBrandingSummaryLink,
  settingsCompanySummaryLink,
  settingsComplianceLink,
  settingsIntegrationLink,
  settingsPermissionsLink,
  settingsProfileLink,
} from '@/pages/settings/routes';

import SettingsTabIcon from '../SettingsTabIcon';

import { type SettingsPageLayoutProps, SettingsTabType } from './types';

const dataTest: string | undefined = 'settings-tab';

const modeLinks: Record<SettingsTabType, () => string> = {
  [SettingsTabType.company]: () => settingsCompanySummaryLink(),
  [SettingsTabType.compliance]: () => settingsComplianceLink(),
  [SettingsTabType.branding]: () => settingsBrandingSummaryLink(),
  [SettingsTabType.integration]: () => settingsIntegrationLink(),
  [SettingsTabType.permissions]: () => settingsPermissionsLink(),
  [SettingsTabType.profile]: () => settingsProfileLink(),
};

const SettingsPageLayout: React.FC<SettingsPageLayoutProps> = ({ selected, PageContainerProps, ...props }) => {
  const navigate = useNavigate();
  const {
    data: { data: featureFlags },
  } = useFeatureToggle();
  const {
    data: { data: permissions },
  } = useCompanyPermissions();
  const doChangeTab = useCallback((newMode: string) => navigate(modeLinks[newMode as SettingsTabType]()), [navigate]);
  const { data: company } = useActiveCompany();
  const tabList = useMemo(
    () => [
      {
        key: SettingsTabType.company,
        tab: (
          <Space size={2}>
            <SettingsTabIcon value={SettingsTabType.company} />
            <FormattedMessage
              id={I18nPageSettings.TABS_SETTINGS}
              data-test={dataTest && `${dataTest}-${SettingsTabType.company}`}
              tagName="span"
            />
          </Space>
        ),
      },
      {
        key: SettingsTabType.compliance,
        tab: (
          <Space size={2}>
            <SettingsTabIcon value={SettingsTabType.compliance} />
            <FormattedMessage
              id={I18nPageSettings.TABS_COMPLIANCE}
              data-test={dataTest && `${dataTest}-${SettingsTabType.compliance}`}
              tagName="span"
            />
          </Space>
        ),
      },
      ...(permissions?.integrationManagement
        ? [
            {
              key: SettingsTabType.integration,
              tab: (
                <Space size={2}>
                  <SettingsTabIcon value={SettingsTabType.integration} />
                  <FormattedMessage
                    id={I18nPageSettings.TABS_INTEGRATION}
                    data-test={dataTest && `${dataTest}-${SettingsTabType.integration}`}
                    tagName="span"
                  />
                </Space>
              ),
            },
          ]
        : []),
      ...((permissions?.brandingConfiguration || permissions?.termsOfServiceSet) && featureFlags?.enableBranding
        ? [
            {
              key: SettingsTabType.branding,
              tab: (
                <Space size={2}>
                  <SettingsTabIcon value={SettingsTabType.branding} />
                  <FormattedMessage
                    id={I18nPageSettings.TABS_BRANDING}
                    data-test={dataTest && `${dataTest}-${SettingsTabType.branding}`}
                    tagName="span"
                  />
                </Space>
              ),
            },
          ]
        : []),
      {
        key: SettingsTabType.profile,
        tab: (
          <Space size={2}>
            <SettingsTabIcon value={SettingsTabType.profile} />
            <FormattedMessage
              id={I18nPageSettings.TABS_PROFILE}
              data-test={dataTest && `${dataTest}-${SettingsTabType.profile}`}
              tagName="span"
            />
          </Space>
        ),
      },
      ...(company.data?.isOwner && permissions?.usersManagement
        ? [
            {
              key: SettingsTabType.permissions,
              tab: (
                <Space size={2}>
                  <SettingsTabIcon value={SettingsTabType.permissions} />
                  <FormattedMessage
                    id={I18nPageSettings.TABS_PERMISSIONS}
                    data-test={dataTest && `${dataTest}-${SettingsTabType.permissions}`}
                    tagName="span"
                  />
                </Space>
              ),
            },
          ]
        : []),
    ],
    [
      company.data?.isOwner,
      featureFlags?.enableBranding,
      permissions?.brandingConfiguration,
      permissions?.integrationManagement,
      permissions?.termsOfServiceSet,
      permissions?.usersManagement,
    ],
  );

  const mergedContainerProps = useMemo<PageContainerPropsType['PageContainerProps']>(
    () => ({
      ...PageContainerProps,
      tabList,
      onTabChange: withSuppressPromise(doChangeTab),
      tabActiveKey: selected,
    }),
    [PageContainerProps, tabList, doChangeTab, selected],
  );
  return <PageContainer {...props} PageContainerProps={mergedContainerProps} />;
};

const SettingsPageLayoutMemo = React.memo(SettingsPageLayout);

export default SettingsPageLayoutMemo;
