import { useCallback } from 'react';

import { useEmailAuth as useEmailAuthBase } from '@/features/auth/hooks';
import type { EmailAuthOptions } from '@/features/auth/hooks';
import { withFinally } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import { cancellable } from '@/infrastructure/utils/ui';

const useEmailAuth = ({
  onSelectProvider,
  withAuth,
}: {
  onSelectProvider: (selected?: { id: string; onCancel?: () => void }) => void;
  withAuth: <V extends unknown[] = unknown[], R = unknown>(
    func: (options: Omit<EmailAuthOptions, 'cancellation'>) => Func<V, R>,
  ) => Func<V>;
}) => {
  const { loginByEmail } = useEmailAuthBase();

  const select = useCallback(
    () =>
      cancellable((reject) => {
        onSelectProvider({
          id: 'email',
          onCancel: () => {
            reject();
            onSelectProvider();
          },
        });
      }),
    [onSelectProvider],
  );
  const auth = useCallback(
    (request: Parameters<typeof loginByEmail.act>[0]) =>
      withFinally(
        withAuth((options) => () => loginByEmail.act(request, options)),
        onSelectProvider,
      )(),
    [loginByEmail, onSelectProvider, withAuth],
  );
  return { auth, select, isAvailable: loginByEmail.available };
};

export default useEmailAuth;
