import { StopOutlined } from '@ant-design/icons';
import { css, cx } from '@emotion/css';
import { useConnect } from '@wallet-standard/react';
import { Alert, theme } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import Web3ProviderIcon from '@/features/web3/components/Web3ProviderIcon';
import Web3WalletInProgress from '@/features/web3/components/Web3WalletInProgress';
import Web3WalletLink from '@/features/web3/components/Web3WalletLink';
import type { Web3WalletType } from '@/features/web3/components/Web3WalletLink/types';
import { walletTypes } from '@/features/web3/components/Web3WalletLink/types';
import { withWeb3SolanaWallet } from '@/features/web3/hocs';
import { useWeb3SolanaWallet } from '@/features/web3/hooks';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';
import { useDefaultNotification, useStateMountSafe, useSubmitting } from '@/hooks';
import { emptyWith, noop, withFinally, withLogError } from '@/infrastructure/utils/functions';
import { cancellable, runCancellable } from '@/infrastructure/utils/ui';

import type { Web3SolanaWalletConnectProps } from './types';
import type { UiWalletAccount } from '@wallet-standard/ui';

const Web3SolanaWalletConnect: React.FC<Web3SolanaWalletConnectProps> = ({
  'data-test': dataTest,
  onlyLocal,
  disabled,
  onConnect = noop,
  cancellation: supportCancellation,
  requiredAccount,
  withConnect,
  inProgressMode = 'button',
}) => {
  const { token } = theme.useToken();
  const styles = useMemo(
    () => ({
      container: css`
        width: 100%;
        display: flex;
      `,
      operation: css`
        display: flex;
        flex: 1;
        align-items: center;
        margin-left: auto;
        width: 100%;
      `,
      cancellable: css`
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      `,
      cancel: css`
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;

        > button {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      `,
      icon: css`
        display: flex;
      `,
      description: css`
        text-align: center;
        padding-top: ${token.paddingLG};
      `,
    }),
    [token.paddingLG],
  );

  const { id, wallet, account, isLocal, isReady, isConnecting: baseIsConnecting } = useWeb3SolanaWallet();
  const [, connect] = useConnect(wallet);
  const [onCancel, setOnCancel] = useStateMountSafe<(() => void) | undefined>();
  const { withDefaultError } = useDefaultNotification();
  const [localIsConnecting, withConnecting] = useSubmitting(false);
  const isConnecting = localIsConnecting || baseIsConnecting;

  const doConnect = useMemo(
    () =>
      withLogError(
        withDefaultError(
          (withConnect ?? emptyWith)(
            withConnecting(
              withFinally(
                async () => {
                  const cancellation =
                    // eslint-disable-next-line no-nested-ternary
                    (supportCancellation
                      ? typeof supportCancellation === 'function'
                        ? supportCancellation()
                        : typeof supportCancellation === 'object'
                          && (supportCancellation.create?.() ?? cancellable((cancel) => setOnCancel(() => cancel)))
                      : undefined) || undefined;
                  const newAccount: UiWalletAccount | undefined = (
                    await (cancellation ? runCancellable(connect(), cancellation) : connect())
                  )[0];
                  if (!requiredAccount || requiredAccount === newAccount.address) await onConnect(newAccount);
                },
                () => setOnCancel(undefined),
              ),
            ),
          ),
        ),
      ),
    [
      withDefaultError,
      withConnect,
      withConnecting,
      supportCancellation,
      connect,
      requiredAccount,
      onConnect,
      setOnCancel,
    ],
  );

  if (!isConnecting && (!isReady || (onlyLocal && !isLocal))) {
    return (
      <Alert
        data-test={dataTest && `${dataTest}-notSupported`}
        showIcon
        message={
          <FormattedMessage
            id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_NOT_SUPPORTED}
            values={{
              provider: walletTypes.find((v) => v === id) ? (
                <Web3WalletLink type={id as Web3WalletType} />
              ) : (
                <b>{wallet.name}</b>
              ),
            }}
            tagName="span"
          />
        }
      />
    );
  }

  if (account) {
    return null;
  }

  if (isConnecting && inProgressMode === 'view') {
    return (
      <Web3WalletInProgress
        data-test={dataTest}
        wallet={{ id: wallet.name, name: wallet.name }}
        // Metamask fails to restore if the connection has been cancelled
        // cancel={
        //   onCancel && {
        //     onAction: onCancel,
        //     title: <FormattedMessage id="auth.components.wallet-connect.cancel-connect.title" />,
        //   }
        // }
        message={
          <FormattedMessage
            id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_DESCRIPTION}
            values={{ provider: <b>{wallet.name}</b> }}
          />
        }
      />
    );
  }

  return (
    <div className={styles.container}>
      <Operation
        ButtonProps={{ size: 'large' }}
        title={
          <FormattedMessage
            id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_CONNECT_TITLE}
            values={{ provider: wallet.name }}
          />
        }
        className={cx(styles.operation, onCancel && styles.cancellable)}
        data-test={dataTest && `${dataTest}-submit`}
        inProgress={isConnecting}
        disabled={disabled}
        icon={<Web3ProviderIcon value={wallet.name} className={styles.icon} />}
        onClick={doConnect}
        mode="button"
      />
      {onCancel && (
        <Operation
          ButtonProps={{ size: 'large' }}
          className={styles.cancel}
          title={<FormattedMessage id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_CANCEL_CONNECT_TITLE} />}
          data-test={dataTest && `${dataTest}-cancel`}
          inProgress={isConnecting}
          icon={<StopOutlined />}
          onClick={onCancel}
          mode="inline"
        />
      )}
    </div>
  );
};

const Web3SolanaWalletConnectWithConnector =
  withWeb3SolanaWallet<Web3SolanaWalletConnectProps>(Web3SolanaWalletConnect);

const Web3SolanaWalletConnectMemo = React.memo(
  Web3SolanaWalletConnectWithConnector,
) as typeof Web3SolanaWalletConnectWithConnector;

export default Web3SolanaWalletConnectMemo;
