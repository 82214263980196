import { EditOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useUserActions, useUserAddress } from '@/features/user/hooks';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureUser } from '@/generated/i18n/i18n';
import { useFormVisible } from '@/hooks';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import OperationRefreshUser from '../OperationRefreshUser';

import { AddUserAddressModal } from './components';

import type { OperationAddUserAddressProps } from './types';

const OperationAddUserAddress: React.FC<OperationAddUserAddressProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  api,
}) => {
  const formControl = useFormVisible(false);
  const { data: address, loading } = useUserAddress(api);
  const { addAddress: addAddressAction } = useUserActions();

  const disabledMessage = useMemo(() => {
    if (loading || address.isDirty) {
      return (
        <FormattedMessage
          id={I18nFeatureUser.COMPONENTS_OPERATION_ADD_USER_ADDRESS_CONNECTOR_ERROR_NO_BLOCKCHAIN_DATA}
          values={{
            op: (text: React.ReactNode) => (
              <OperationRefreshUser data-test={dataTest && `${dataTest}-opRefresh`} mode="link" title={text} />
            ),
          }}
        />
      );
    }
    switch (addAddressAction.unavailabilityReason) {
      case 'no-data':
        return (
          <FormattedMessage
            id={I18nFeatureUser.COMPONENTS_OPERATION_ADD_USER_ADDRESS_CONNECTOR_ERROR_NO_BLOCKCHAIN_DATA}
          />
        );
      case undefined:
        return undefined;
    }
  }, [dataTest, addAddressAction.unavailabilityReason, loading, address.isDirty]);

  const doShow = useCallback(() => {
    formControl.show();
    goalReached(YMGoals.USER_ADD_ADDRESS_SHOWN);
  }, [formControl]);
  const doOnSuccess = useCallback(() => {
    formControl.hide();
    goalReached(
      api === BlockchainAPITypeAPIModel.EVM
        ? YMGoals.USER_ADD_EVM_ADDRESS_FINISHED
        : YMGoals.USER_ADD_SOLANA_ADDRESS_FINISHED,
    );
  }, [api, formControl]);

  return (
    <>
      {formControl.visible && (
        <AddUserAddressModal
          data-test={dataTest && `${dataTest}-dialog`}
          api={api}
          onSuccess={doOnSuccess}
          onClose={formControl.hide}
        />
      )}
      <Operation
        title={title ?? <FormattedMessage id={I18nFeatureUser.COMPONENTS_OPERATION_ADD_USER_ADDRESS_TITLE} />}
        tooltip={<FormattedMessage id={I18nFeatureUser.COMPONENTS_OPERATION_ADD_USER_ADDRESS_TOOLTIP} />}
        disabled={!!disabledMessage}
        disabledMessage={disabledMessage}
        icon={<EditOutlined />}
        inProgress={address.isDirty || loading}
        onClick={doShow}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationAddUserAddressMemo = React.memo(OperationAddUserAddress);

export default OperationAddUserAddressMemo;
