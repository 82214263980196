import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { ProCard } from '@ant-design/pro-components';
import { css, cx } from '@emotion/css';
import { Space, theme } from 'antd';
import React from 'react';

import { ErrorFormMessage, Operation } from '@/components';
import { ReCaptchaLicense } from '@/features/recaptcha/components';
import { useSharedStyles } from '@/hooks';

import type { AuthFormContainerProps } from './types';

const AuthFormContainer: React.FC<AuthFormContainerProps> = ({
  'data-test': dataTest,
  className,
  loading,
  title,
  error,
  children,
  onCancel,
  onBack,
}) => {
  const { token } = theme.useToken();
  const { modalCard } = useSharedStyles();
  return (
    <ProCard
      data-test={dataTest && `${dataTest}-card`}
      boxShadow
      loading={loading}
      title={
        <Space>
          {onBack && (
            <Operation
              title={null}
              data-test={dataTest && `${dataTest}-back`}
              icon={<ArrowLeftOutlined />}
              onClick={onBack}
              mode="icon"
            />
          )}
          {title}
        </Space>
      }
      className={cx(
        className,
        modalCard,
        css`
          div > button:first-child {
            justify-content: start;
          }
        `,
      )}
      extra={
        onCancel ? (
          <Operation
            title={null}
            data-test={dataTest && `${dataTest}-cancel`}
            icon={<CloseOutlined />}
            onClick={onCancel}
            mode="icon"
          />
        ) : null
      }
    >
      {error && <ErrorFormMessage content={error} data-test={dataTest && `${dataTest}-error`} />}
      {children}
      <div
        className={css`
          margin-top: ${token.marginSM}px;
        `}
      >
        <ReCaptchaLicense data-test={dataTest && `${dataTest}-recaptchaLicense`} type="secondary" />
      </div>
    </ProCard>
  );
};

const AuthFormContainerMemo = React.memo(AuthFormContainer);

export default AuthFormContainerMemo;
