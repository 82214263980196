import Icon from '@ant-design/icons';
import React, { forwardRef } from 'react';

import BTCIcon from '@/assets/icons/bitcoin.svg?react';
import EthereumIcon from '@/assets/icons/ethereum.svg?react';
import LogoSmallLight from '@/assets/icons/smartypay/logo-small-light';
import SolanaIcon from '@/assets/icons/sol.svg?react';
import TronIcon from '@/assets/icons/tron-trx.svg?react';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import type { BlockchainAPIIconProps } from './types';

const mapping: Record<BlockchainAPITypeAPIModel, typeof EthereumIcon> = {
  [BlockchainAPITypeAPIModel.EVM]: EthereumIcon,
  [BlockchainAPITypeAPIModel.Solana]: SolanaIcon,
  [BlockchainAPITypeAPIModel.BTC]: TronIcon,
  [BlockchainAPITypeAPIModel.Tron]: BTCIcon,
};

const defaultIcon = LogoSmallLight;

const BlockchainAPIIcon = forwardRef<HTMLSpanElement, BlockchainAPIIconProps>(function BlockchainAPIIcon(
  { 'data-test': dataTest, api, className, style },
  ref,
) {
  return (
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    <Icon component={mapping[api] ?? defaultIcon} className={className} style={style} data-test={dataTest} ref={ref} />
  );
});

const BlockchainAPIIconMemo = React.memo(BlockchainAPIIcon) as typeof BlockchainAPIIcon;

export default BlockchainAPIIconMemo;
