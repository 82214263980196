import { Form, Space } from 'antd';
import xor from 'lodash-es/xor';
import React, { useCallback, useMemo } from 'react';

import {
  defaultPageFormTailLayout,
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
} from '@/components';
import type { QRSupport } from '@/features/company-settings/types';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop, withVoidOrThrow, wrap } from '@/infrastructure/utils/functions';
import type { SomeNullable } from '@/infrastructure/utils/ts';
import { nameof } from '@/infrastructure/utils/ts';

import { QRSupportItem } from './components';

import type { UpdateQRSupportFormProps } from './types';
import type { FormInstance } from 'antd/es/form';


type QRSupportFormValues = Partial<{
  settings: SomeNullable<QRSupport, 'isDeployed'>[];
}>;

const fields = [nameof<QRSupportFormValues>('settings')];

const UpdateQRSupportForm: React.FC<UpdateQRSupportFormProps> = ({
  'data-test': dataTest,
  onSubmit,
  data,
  className,
  messages,
  style,
  onReset = noop,
  submitCallback,
  onSubmitting,
  layout = {},
  tailLayout = defaultPageFormTailLayout,
}) => {
  const { form, withResetForm } = useForm<QRSupportFormValues>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling, reset: resetSubmit } = useErrorSafeSubmitting();
  const doReset = useMemo(() => withResetForm(resetSubmit), [resetSubmit, withResetForm]);
  const doSubmit = useCallback(
    (value: QRSupportFormValues) => withSubmitting(onSubmit)(value.settings ?? []),
    [onSubmit, withSubmitting],
  );
  const doCancel = useMemo(
    () => withErrorHandling(withResetForm(onReset)),
    [onReset, withErrorHandling, withResetForm],
  );

  const updateComplete = useCallback(
    (complete: boolean) => {
      const value = form.getFieldsValue();
      const isCompleteAndUpdated = complete;
      if (isFormComplete !== isCompleteAndUpdated) {
        setFormComplete(isCompleteAndUpdated);
      }
      const doSubmitOnCallback = isCompleteAndUpdated
        ? withSubmitting(
            withVoidOrThrow(
              wrap(
                async () => {
                  await doSubmit(value);
                  setTimeout(doReset, 0);
                },
                () => onSubmitting?.(true),
                () => onSubmitting?.(false),
              ),
            ),
          )
        : undefined;
      submitCallback?.(doSubmitOnCallback);
    },
    [form, isFormComplete, setFormComplete, withSubmitting, submitCallback, doSubmit, doReset, onSubmitting],
  );

  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    return <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_UPDATE_QR_SUPPORT_FORM_ERROR_COMMON} />;
  }, [error]);
  const initialSelected = useMemo(
    () =>
      data.selected
        .filter(({ isEnabled }) => isEnabled)
        .map(({ blockchain }) => blockchain)
        .sort(),
    [data.selected],
  );
  const checkIsFormComplete = useCallback(
    ({ getFieldsValue }: FormInstance<QRSupportFormValues>) => {
      const value = getFieldsValue();
      const newCodes = value.settings
        ?.filter(({ isEnabled }) => isEnabled)
        .map(({ blockchain }) => blockchain)
        .sort();
      return !!xor(newCodes, initialSelected).length;
    },
    [initialSelected],
  );

  const initialValues = useMemo<Partial<QRSupportFormValues>>(() => ({ settings: data.selected }), [data]);

  const footerMessages = useMemo(
    () => ({
      ...messages,
      submit: messages?.submit ?? (
        <FormattedMessage
          id={I18nFeatureCompanySettings.COMPONENTS_UPDATE_QR_SUPPORT_FORM_SUBMIT_TITLE}
          tagName="span"
        />
      ),
    }),
    [messages],
  );

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={2}>
      <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_UPDATE_QR_SUPPORT_FORM_DESCRIPTION} />
      <Form<QRSupportFormValues>
        onValuesChange={resetSubmit}
        onResetCapture={doReset}
        className={className}
        style={style}
        autoComplete="off"
        form={form}
        onFinish={doSubmit}
        onReset={doCancel}
        initialValues={initialValues}
        {...layout}
      >
        {errorMessage && <ErrorFormMessage content={errorMessage} />}
        <QRSupportItem<QRSupportFormValues>
          data-test={dataTest && `${dataTest}-settings`}
          name={nameof<QRSupportFormValues>('settings')}
          value={data.selected}
          assets={data.assets}
        />
        <FormCompletenessItem<QRSupportFormValues>
          requiredFields={fields}
          onChange={updateComplete}
          checkIsComplete={checkIsFormComplete}
        />
        <FormFooter
          data-test={dataTest && `${dataTest}-footer`}
          noStyle={!!submitCallback}
          style={submitCallback ? { display: 'none' } : undefined}
          tailLayout={tailLayout}
          submitDisabled={!isFormComplete}
          submitting={submitting}
          messages={footerMessages}
        />
      </Form>
    </Space>
  );
};

const UpdateQRSupportFormMemo = React.memo(UpdateQRSupportForm);

export default UpdateQRSupportFormMemo;
