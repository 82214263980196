import { Alert } from 'antd';
import React from 'react';

import { FormattedMessageWithMarkup } from '@/components';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';

import type { KYTStatusBannerProps } from './types';

const KYTStatusBanner: React.FC<KYTStatusBannerProps> = (props) => (
  <Alert
    {...props}
    type="info"
    showIcon
    message={<FormattedMessageWithMarkup id={I18nFeatureCompanySettings.COMPONENTS_KYT_STATUS_BANNER_MESSAGE} />}
  />
);

const KYTStatusBannerMemo = React.memo(KYTStatusBanner) as typeof KYTStatusBanner;

export default KYTStatusBannerMemo;
