import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useAuthWeb3EVMActions } from '@/features/auth/hooks';
import type { Web3EVMWalletOperationClientAction } from '@/features/web3/components';
import { Web3EVMWalletOperation } from '@/features/web3/components';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { emptyWith, noop, withOnSuccess } from '@/infrastructure/utils/functions';
import { type Cancellable, isCancelled } from '@/infrastructure/utils/ui';

import type { OperationDisconnectWeb3AuthWithConnectorProps } from './types';

const notificationOptions = {
  error: (e: unknown) =>
    !isCancelled(e)
      ? {
          message: (
            <FormattedMessage id={I18nFeatureAuth.COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_CONNECTOR_FAILED_MESSAGE} />
          ),
          description: (
            <FormattedMessage
              id={I18nFeatureAuth.COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_CONNECTOR_FAILED_DESCRIPTION}
            />
          ),
        }
      : undefined,
  success: () => ({
    message: (
      <FormattedMessage id={I18nFeatureAuth.COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_CONNECTOR_SUCCESS_MESSAGE} />
    ),
    description: (
      <FormattedMessage id={I18nFeatureAuth.COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_CONNECTOR_SUCCESS_DESCRIPTION} />
    ),
  }),
};

const OperationDisconnectWeb3AuthWithConnector: React.FC<OperationDisconnectWeb3AuthWithConnectorProps> = ({
  'data-test': dataTest,
  connector,
  extra,
  onSuccess,
  withInProgress,
}) => {
  const { disconnectWeb3Auth } = useAuthWeb3EVMActions();
  const { withNotification } = useNotification();

  const doDisconnect = useMemo<Web3EVMWalletOperationClientAction>(
    () =>
      withNotification(
        withOnSuccess(
          (withInProgress ?? emptyWith)(({ client }, cancellation?: Cancellable) =>
            disconnectWeb3Auth.act({ client }, { cancellation }),
          ),
          onSuccess ?? noop,
        ),
        notificationOptions,
      ),
    [withNotification, withInProgress, onSuccess, disconnectWeb3Auth],
  );

  return (
    <Web3EVMWalletOperation
      data-test={dataTest}
      connector={connector}
      requiredAccount={extra.address}
      showAccount
      disabled={!disconnectWeb3Auth.available}
      inProgress={disconnectWeb3Auth.inAction}
      mainAction={useMemo(
        () => ({
          runOnConnect: true,
          withClient: true,
          onAction: doDisconnect,
          title: (
            <FormattedMessage
              id={I18nFeatureAuth.COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_CONNECTOR_TITLE}
              values={{ connector: connector.name }}
            />
          ),
        }),
        [connector.name, doDisconnect],
      )}
    />
  );
};

const OperationDisconnectWeb3AuthWithConnectorMemo = React.memo(
  OperationDisconnectWeb3AuthWithConnector,
) as typeof OperationDisconnectWeb3AuthWithConnector;

export default OperationDisconnectWeb3AuthWithConnectorMemo;
