import { useCallback, useMemo } from 'react';

import { FormattedMessage, PageCard, PageContainer } from '@/components';
import { ReportScheduleForm } from '@/features/report-schedules/components';
import { useReportSchedulesActions } from '@/features/report-schedules/hooks';
import { I18nPageReports } from '@/generated/i18n/i18n';
import {
  useDefaultNotification,
  useFirstRenderEffect,
  useFormPageAction,
  useNotification,
  useStateMountSafe,
} from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import type { TestableProps } from '@/infrastructure/utils/react';
import type { Func } from '@/infrastructure/utils/ts';
import { goalReached, YMGoals } from '@/infrastructure/ym';
import { reportSchedulesLink } from '@/pages/reports/routes';

import { ReportScheduleAddBreadcrumb } from './components';

import type React from 'react';

type ReportScheduleAddPageProps = TestableProps;

const ReportScheduleAddPage: React.FC<ReportScheduleAddPageProps> = ({ 'data-test': dataTest = 'profile-add' }) => {
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const { create: createAction } = useReportSchedulesActions();
  const { redirectOnSubmit } = useFormPageAction();
  const doRedirect = useMemo<Func>(() => redirectOnSubmit(reportSchedulesLink()), [redirectOnSubmit]);
  const [onSubmit, setOnSubmit] = useStateMountSafe<Func | undefined>();
  const updateOnSubmit = useCallback((func?: Func) => setOnSubmit(() => func), [setOnSubmit]);

  useFirstRenderEffect(() => {
    goalReached(YMGoals.REPORT_SCHEDULE_CREATE_INIT);
  });

  const doCreate = useMemo(
    () =>
      withOnSuccess(
        withDefaultError(
          withSuccess(createAction.act, () => (
            <FormattedMessage id={I18nPageReports.REPORT_SCHEDULE_ADD_MESSAGES_SUCCESS} />
          )),
          () => <FormattedMessage id={I18nPageReports.REPORT_SCHEDULE_ADD_MESSAGES_ERROR} />,
        ),
        () => {
          goalReached(YMGoals.REPORT_SCHEDULE_CREATED);
          doRedirect();
        },
      ),
    [withDefaultError, withSuccess, doRedirect, createAction],
  );

  return (
    <PageContainer
      data-test={dataTest}
      breadcrumb={<ReportScheduleAddBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
      submit={useMemo(() => ({ onClick: onSubmit ?? noop, disabled: !onSubmit }), [onSubmit])}
      cancel={doRedirect}
    >
      <PageCard>
        <ReportScheduleForm
          data-test={dataTest && `${dataTest}-form`}
          isNew
          onSubmit={doCreate}
          onReset={doRedirect}
          submitCallback={updateOnSubmit}
        />
      </PageCard>
    </PageContainer>
  );
};

export default ReportScheduleAddPage;
