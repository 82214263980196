import React, { useCallback, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useAddressSignedUp } from '@/features/auth/hooks';
import { Web3SolanaWalletOperation } from '@/features/web3/components';
import type {
  Web3SolanaWalletOperationAccountAction,
  Web3SolanaWalletOperationProps,
} from '@/features/web3/components';
import { withWeb3SolanaWallet } from '@/features/web3/hocs';
import { useWeb3SolanaWallet, useWeb3WalletExtensionInstallMetrics } from '@/features/web3/hooks';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { emptyWith } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';

import type { Web3WalletSolanaLoginProps } from './types';

const InProgressDescription: React.FC<{ account?: string; provider: string }> = ({ provider, account }) => (
  <FormattedMessage
    id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_DESCRIPTION}
    values={{ provider: <b>{provider}</b>, account: account && <b>{account}</b> }}
  />
);
const inProgressMessages = { Description: InProgressDescription };

const notificationOptions = {
  error: (error: unknown) =>
    (error as Partial<Error | undefined>)?.message?.includes('wallet_requestPermissions')
      ? {
          message: <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_ERROR_CONNECT_IN_PROGRESS_TITLE} />,
          description: (
            <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_ERROR_CONNECT_IN_PROGRESS_DESCRIPTION} />
          ),
        }
      : undefined,
};

const showAccount = { tooltip: <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_ACCOUNT_TOOLTIP} /> };

const Web3WalletSolanaLogin: React.FC<Web3WalletSolanaLoginProps> = ({
  'data-test': dataTest,
  onSuccess,
  extra: { login },
  inProgress: baseInProgress,
  inProgressMode,
  withInProgress,
  cancellation,
}) => {
  const { id, account, wallet } = useWeb3SolanaWallet();
  useWeb3WalletExtensionInstallMetrics(id);
  const { data: isNewAccount, loading: accountLoading } = useAddressSignedUp(
    BlockchainAPITypeAPIModel.Solana,
    account?.address,
  );
  const { withNotification } = useNotification();

  const doLogin = useCallback<Web3SolanaWalletOperationAccountAction>(
    async (loginAccount, cancellable) =>
      (withInProgress ?? emptyWith)(login)({ walletId: id, account: loginAccount }, cancellable),
    [id, login, withInProgress],
  );
  const withConnect = useMemo(
    () =>
      <V extends unknown[], R>(func: Func<V, R>): Func<V> =>
        (withInProgress ?? emptyWith)(withNotification(func, notificationOptions)),
    [withInProgress, withNotification],
  );

  return (
    <Web3SolanaWalletOperation
      data-test={dataTest}
      onSuccess={onSuccess}
      wallet={wallet}
      showAccount={showAccount}
      withConnect={withConnect}
      inProgress={accountLoading || isNewAccount.isDirty || baseInProgress}
      inProgressMode={inProgressMode === 'view' ? inProgressMessages : inProgressMode}
      cancellation={cancellation}
      mainAction={useMemo<Web3SolanaWalletOperationProps['mainAction']>(
        () => ({
          withAccount: true,
          onAction: doLogin,
          runOnConnect: true,
          title: !isNewAccount.data ? (
            <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_SIGNUP_TITLE} />
          ) : (
            <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_LOGIN_TITLE} />
          ),
          'data-test': dataTest && `${dataTest}-${!isNewAccount.data ? 'signup' : 'login'}`,
        }),
        [dataTest, doLogin, isNewAccount.data],
      )}
    />
  );
};

const Web3WalletSolanaLoginHOC = withWeb3SolanaWallet(Web3WalletSolanaLogin);

const Web3WalletSolanaLoginMemo = React.memo(Web3WalletSolanaLoginHOC) as typeof Web3WalletSolanaLogin;

export default Web3WalletSolanaLoginMemo;
