import React from 'react';

import type { Web3Auth } from '@/infrastructure/security/web3-auth/types';

export interface Web3AuthContextType {
  web3Auth?: Web3Auth | undefined;
  initialized: boolean;
  state?: string;
}

export const defaultWeb3AuthContext = { initialized: false };
const Web3AuthContext = React.createContext<Web3AuthContextType>(defaultWeb3AuthContext);

export default Web3AuthContext;
