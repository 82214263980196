import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import listenerMiddleware from '@/app/listenerMiddleware';
import { channelLoginTrigger, logoutActionTrigger } from '@/features/auth/shared-actions';
import type { AuthState } from '@/features/auth/types';
import { AuthStatus } from '@/features/auth/types';
import { InitStatus, storedDirtyData } from '@/infrastructure/model';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';
import { suppressPromise } from '@/infrastructure/utils/functions';

import {
  channelLogin,
  logout,
  markSignupStatusDirty,
  notifyAuthTokenUpdated,
  storeAuth,
  storeAuthStatus,
  storeLogout,
  storeSignupStatus,
  storeTermsOfServiceAcceptance,
} from './actions';
import { makeSelectAuthTokenData } from './selectors';

import type { Draft } from 'immer';

const initialState: AuthState = {
  status: AuthStatus.NOT_INITIALIZED,
  token: storedDirtyData,

  addressSignupStatus: {},
  emailResetAllowance: {},

  termsOfService: { status: InitStatus.NOT_INITIALIZED, value: false },
};

const { storeSignupStatusReducer, markSignupStatusDirtyReducer } = createSingleReducers(
  'SignupStatus' as const,
  (state: Draft<AuthState>) => state.addressSignupStatus,
  (state, addressSignupStatus) => ({ ...state, addressSignupStatus }),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(storeAuthStatus, (state, { payload: { newState, expected } }) =>
      !expected || expected === state.status ? { ...state, status: newState } : state,
    )

    .addCase(storeAuth, (state, { payload: { token } }) => ({
      ...state,
      token: { ...token, isDirty: false },
      status: token.data?.info.address ? AuthStatus.AUTHORIZED : AuthStatus.UNAUTHORIZED,
      addressSignupStatus: !token.data?.info.address
        ? state.addressSignupStatus
        : { ...state.addressSignupStatus, [token.data.info.address]: { data: true, isDirty: false } },
    }))

    .addCase(storeTermsOfServiceAcceptance, (state, { payload: { accepted } }) => ({
      ...state,
      termsOfService: { value: accepted, status: InitStatus.FINISHED },
    }))

    .addCase(storeSignupStatus, storeSignupStatusReducer)
    .addCase(markSignupStatusDirty, markSignupStatusDirtyReducer)

    .addCase(storeLogout, ({ addressSignupStatus }) => ({ ...initialState, addressSignupStatus }));

  listenerMiddleware.startListening({
    matcher: isAnyOf(storeLogout, storeAuth),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    effect: (_, { dispatch, getOriginalState, getState }) => {
      const previous = makeSelectAuthTokenData()(getOriginalState());
      const current = makeSelectAuthTokenData()(getState());

      if (current?.token !== previous?.token || current?.info.activeCompanyId !== previous?.info.activeCompanyId) {
        dispatch(notifyAuthTokenUpdated({ previous: previous?.info, current: current?.info }));
      }
    },
  });

  listenerMiddleware.startListening({
    matcher: isAnyOf(logoutActionTrigger, channelLoginTrigger),
    effect: (action, { dispatch }) => {
      if (logoutActionTrigger.match(action)) {
        suppressPromise(dispatch(logout(action.payload)).unwrap());
      }
      if (channelLoginTrigger.match(action)) {
        suppressPromise(dispatch(channelLogin(action.payload)).unwrap());
      }
    },
  });
});

export default reducer;
