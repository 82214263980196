import { SwapOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useSelectedNetworkActions } from '@/features/dictionary/blockchain/hooks';
import { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { withOnSuccess } from '@/infrastructure/utils/functions';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import { useStyles } from './hooks';

import type { OperationSelectNetworkProps } from './types';

const options = {
  error: () => <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_OPERATION_SELECT_NETWORK_MESSAGE_ERROR} />,
  success: () => <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_OPERATION_SELECT_NETWORK_MESSAGE_SUCCESS} />,
};

const OperationSelectNetwork: React.FC<OperationSelectNetworkProps> = ({
  'data-test': dataTest,
  title,
  disabled,
  inProgress,
  mode = 'inline',
}) => {
  const { switchStyles } = useStyles();
  const { network, update: updateAction } = useSelectedNetworkActions();
  const { withNotification } = useNotification();

  const newNetwork = useMemo(
    () =>
      network === BlockchainNetworkTypeAPIModel.TestNet
        ? BlockchainNetworkTypeAPIModel.MainNet
        : BlockchainNetworkTypeAPIModel.TestNet,
    [network],
  );

  const updateNetwork = useMemo(
    () =>
      withOnSuccess(
        withNotification(() => updateAction.act(newNetwork), options),
        () => goalReached(YMGoals.BLOCKCHAIN_NETWORK_UPDATED),
      ),
    [withNotification, updateAction, newNetwork],
  );

  return mode === 'switch' ? (
    <Switch
      value={network === BlockchainNetworkTypeAPIModel.MainNet}
      checkedChildren={network}
      unCheckedChildren={network}
      className={switchStyles}
      disabled={disabled || !updateAction.available}
      loading={inProgress || updateAction.inAction}
      onClick={updateNetwork}
    />
  ) : (
    <Operation
      title={
        title ?? (
          <FormattedMessage
            id={I18nFeatureDictionary.COMPONENTS_OPERATION_SELECT_NETWORK_LABEL}
            values={{ network: newNetwork }}
          />
        )
      }
      tooltip={
        <FormattedMessage
          id={I18nFeatureDictionary.COMPONENTS_OPERATION_SELECT_NETWORK_LABEL}
          values={{ network: newNetwork }}
        />
      }
      icon={<SwapOutlined />}
      onClick={updateNetwork}
      disabled={disabled || !updateAction.available}
      inProgress={inProgress || updateAction.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationSelectNetworkMemo = React.memo(OperationSelectNetwork);

export default OperationSelectNetworkMemo;
