import { css } from '@emotion/css';
import { useContext } from 'react';

import { PageLoading } from '@/components';
import Web3EVMInitContext from '@/features/web3/hocs/withWeb3Support/hocs/withEVMConnectorsSupport/context';
import { useFirstRenderEffect } from '@/hooks';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import type React from 'react';

const Web3ContextLoading = () => (
  <PageLoading
    type="withWeb3EVMContext"
    className={css`
      height: auto;
    `}
  />
);

const withWeb3Context = <T extends EmptyObject>(
  Component: React.ComponentType<T>,
  LoadingComponent: React.ComponentType = Web3ContextLoading,
): React.FC<T> =>
  namedHOC(
    Component,
    'WithWeb3Context',
  )((props) => {
    const ctx = useContext(Web3EVMInitContext);
    useFirstRenderEffect(ctx.initialize);
    return ctx.initialized ? <Component {...props} /> : <LoadingComponent />;
  });

export default withWeb3Context;
