import { theme } from 'antd';


import type { ProTokenType } from '@ant-design/pro-provider';

export const layoutTheme = theme.getDesignToken({ algorithm: theme.darkAlgorithm });

export const layoutToken: NonNullable<ProTokenType['layout']> = {
  header: {
    colorBgHeader: 'rgb(28, 32, 44)',
    colorBgMenuItemHover: layoutTheme.controlItemBgHover,
    colorBgMenuElevated: 'rgb(28, 32, 44)',
    colorTextMenu: layoutTheme.colorText,
    colorTextMenuSecondary: layoutTheme.colorText,
    colorTextMenuSelected: layoutTheme.colorPrimaryText,
    colorBgMenuItemSelected: layoutTheme.controlItemBgActive,
    colorTextMenuActive: layoutTheme.colorPrimaryTextActive,
    colorHeaderTitle: layoutTheme.colorTextHeading,
    colorTextRightActionsItem: layoutTheme.colorTextSecondary,
    colorBgRightActionsItemHover: layoutTheme.colorBgTextHover,
  },
  sider: {
    colorMenuBackground: 'rgb(28, 32, 44)',
    colorTextMenuTitle: layoutTheme.colorPrimaryTextActive,
    colorMenuItemDivider: layoutTheme.colorSplit,
    colorTextMenu: layoutTheme.colorTextSecondary,
    colorTextMenuSecondary: layoutTheme.colorText,
    colorTextMenuSelected: layoutTheme.colorText,
    colorTextMenuActive: layoutTheme.colorPrimaryTextActive,
    colorTextMenuItemHover: layoutTheme.colorPrimaryTextHover,
    colorBgMenuItemActive: layoutTheme.colorBgTextActive,
    colorBgMenuItemHover: layoutTheme.colorBgTextHover,
    colorBgMenuItemSelected: layoutTheme.colorPrimaryActive,
    colorBgCollapsedButton: 'rgb(28, 32, 44)',
    colorTextCollapsedButton: layoutTheme.colorText,
    colorTextCollapsedButtonHover: layoutTheme.colorPrimaryTextHover,
    colorBgMenuItemCollapsedElevated: layoutTheme.colorBgElevated,
  },
};

export const layoutBgToken: NonNullable<ProTokenType['layout']> = {
  ...layoutToken,
  bgLayout: "center / auto 100% url('https://static.wixstatic.com/media/555773_975998588c11495192eda08fef840020~mv2.png/v1/fill/w_1684,h_794,al_c,q_90,usm_0.66_1.00_0.01,enc_avif,quality_auto/555773_975998588c11495192eda08fef840020~mv2.png')",
};
