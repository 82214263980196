/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SubscriptionChargeAPIModel } from './SubscriptionChargeAPIModel';
import {
    SubscriptionChargeAPIModelFromJSON,
    SubscriptionChargeAPIModelFromJSONTyped,
    SubscriptionChargeAPIModelToJSON,
} from './SubscriptionChargeAPIModel';

/**
 * 
 * @export
 * @interface SubscriptionChargesListAPIModel
 */
export interface SubscriptionChargesListAPIModel {
    /**
     * 
     * @type {Array<SubscriptionChargeAPIModel>}
     * @memberof SubscriptionChargesListAPIModel
     */
    charges?: Array<SubscriptionChargeAPIModel>;
}

/**
 * Check if a given object implements the SubscriptionChargesListAPIModel interface.
 */
export function instanceOfSubscriptionChargesListAPIModel(value: object): boolean {
    return true;
}

export function SubscriptionChargesListAPIModelFromJSON(json: any): SubscriptionChargesListAPIModel {
    return SubscriptionChargesListAPIModelFromJSONTyped(json, false);
}

export function SubscriptionChargesListAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionChargesListAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'charges': json['charges'] == null ? undefined : ((json['charges'] as Array<any>).map(SubscriptionChargeAPIModelFromJSON)),
    };
}

export function SubscriptionChargesListAPIModelToJSON(value?: SubscriptionChargesListAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'charges': value['charges'] == null ? undefined : ((value['charges'] as Array<any>).map(SubscriptionChargeAPIModelToJSON)),
    };
}

