import Icon, { MailOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import React from 'react';

import { FormattedMessage, Operation, type OperationProps } from '@/components';
import { withFeatureToggleGuard } from '@/features/feature-toggle/hocs';
import { I18nPageAuth } from '@/generated/i18n/i18n';

import type { EmailLoginButtonProps } from './types';

const buttonStyles = css`
  flex: 1;
  align-items: center;
  margin-left: auto;
  display: flex;
  width: 100%;
  border-radius: 30px;
`;

const buttonProps: OperationProps['ButtonProps'] = { size: 'large' };

const EmailLoginButton: React.FC<EmailLoginButtonProps> = ({
  'data-test': dataTest,
  onSelect,
  disabled,
  inProgress,
}) => (
  <Operation
    ButtonProps={buttonProps}
    title={<FormattedMessage id={I18nPageAuth.COMPONENTS_EMAIL_BLOCK_EMAIL_LABEL} />}
    className={buttonStyles}
    data-test={dataTest}
    disabled={disabled}
    inProgress={inProgress}
    icon={<Icon style={{ color: 'blue' }} component={MailOutlined as React.ForwardRefExoticComponent<unknown>} />}
    onClick={onSelect}
    mode="button"
  />
);

const EmailLoginButtonMemo: typeof EmailLoginButton = React.memo(
  withFeatureToggleGuard('disableEmailAuth', false)(EmailLoginButton),
);

export default EmailLoginButtonMemo;
