/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { ForwarderDelegateAPIModel } from './ForwarderDelegateAPIModel';
import {
    ForwarderDelegateAPIModelFromJSON,
    ForwarderDelegateAPIModelFromJSONTyped,
    ForwarderDelegateAPIModelToJSON,
} from './ForwarderDelegateAPIModel';

/**
 * 
 * @export
 * @interface ForwarderDelegatesAPIModel
 */
export interface ForwarderDelegatesAPIModel {
    /**
     * 
     * @type {Array<ForwarderDelegateAPIModel>}
     * @memberof ForwarderDelegatesAPIModel
     */
    delegates?: Array<ForwarderDelegateAPIModel>;
}

/**
 * Check if a given object implements the ForwarderDelegatesAPIModel interface.
 */
export function instanceOfForwarderDelegatesAPIModel(value: object): boolean {
    return true;
}

export function ForwarderDelegatesAPIModelFromJSON(json: any): ForwarderDelegatesAPIModel {
    return ForwarderDelegatesAPIModelFromJSONTyped(json, false);
}

export function ForwarderDelegatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForwarderDelegatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'delegates': json['delegates'] == null ? undefined : ((json['delegates'] as Array<any>).map(ForwarderDelegateAPIModelFromJSON)),
    };
}

export function ForwarderDelegatesAPIModelToJSON(value?: ForwarderDelegatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'delegates': value['delegates'] == null ? undefined : ((value['delegates'] as Array<any>).map(ForwarderDelegateAPIModelToJSON)),
    };
}

