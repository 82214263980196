import { walletActions } from 'viem';

import { withCancelledByUser } from '@/infrastructure/utils/ui';

import type { SignMessageFn } from './types';
import type { Account, Chain, Client, Transport } from 'viem';

export const createSignMessage = (client: Client<Transport, Chain, Account>): SignMessageFn =>
  withCancelledByUser(async (message: string) => ({
    message,
    signature: await client.extend(walletActions).signMessage({ message }),
  }));
