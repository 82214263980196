import { Form } from 'antd';
import React, { useCallback, useMemo } from 'react';

import {
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
} from '@/components';
import KYTStatusBanner from '@/features/company-settings/components/KYTStatusBanner';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import { KYTLevelSelectionItem } from './components';

import type { UpdateKYTLevelFormProps, UpdateKYTLevelValues } from './types';
import type { FormInstance } from 'antd/es/form';

const fields = [nameof<UpdateKYTLevelValues>('level')];

const footerMessages = {
  submit: (
    <FormattedMessage
      id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_KYT_LEVEL_FORM_SUBMIT}
      tagName="span"
    />
  ),
};

const UpdateKYTLevelForm: React.FC<UpdateKYTLevelFormProps> = ({
  'data-test': dataTest,
  values,
  onSubmit,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const oldValue = values?.level;
  const { form, withResetForm } = useForm<UpdateKYTLevelValues>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const submit = useMemo(
    () => withSubmitting((val: UpdateKYTLevelValues): unknown => onSubmit(val.level)),
    [onSubmit, withSubmitting],
  );
  const reset = useMemo(() => withErrorHandling(withResetForm(onReset)), [onReset, withErrorHandling, withResetForm]);
  const errorMessage = useMemo(() => {
    if (!error) return undefined;
    return <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_KYT_LEVEL_FORM_ERROR_COMMON} />;
  }, [error]);

  const checkIsComplete = useCallback(
    ({ getFieldValue }: FormInstance<UpdateKYTLevelValues>) =>
      getFieldValue(nameof<UpdateKYTLevelValues>('level')) !== oldValue,
    [oldValue],
  );

  return (
    <Form<UpdateKYTLevelValues>
      {...layout}
      autoComplete="off"
      form={form}
      initialValues={values}
      size="middle"
      onFinish={submit}
      onReset={reset}
    >
      {errorMessage && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={errorMessage} />}
      <KYTStatusBanner />
      <KYTLevelSelectionItem<UpdateKYTLevelValues>
        name={nameof<UpdateKYTLevelValues>('level')}
        data-test={dataTest && `${dataTest}-level`}
      />
      <FormCompletenessItem<UpdateKYTLevelValues>
        requiredFields={fields}
        onChange={setFormComplete}
        checkIsComplete={checkIsComplete}
      />
      <FormFooter
        data-test={dataTest && `${dataTest}-submit`}
        tailLayout={tailLayout}
        submitting={submitting}
        submitDisabled={!isFormComplete}
        messages={footerMessages}
      />
    </Form>
  );
};

const UpdateKYTLevelFormMemo = React.memo(UpdateKYTLevelForm);

export default UpdateKYTLevelFormMemo;
