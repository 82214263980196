import { getED25519Key } from '@web3auth/auth-adapter';
import { CHAIN_NAMESPACES } from '@web3auth/base';
import { SolanaPrivateKeyProvider, SolanaWallet } from '@web3auth/solana-provider';
import { createWalletClient, custom, toHex } from 'viem';
import { mainnet } from 'viem/chains';

import { someOrFail } from '@/infrastructure/utils/functions';

import type { Web3Auth } from '@web3auth/single-factor-auth';
import type { Address } from 'viem';

export const signEVMMessageByWeb3Auth = async (web3Auth: Web3Auth, address: Address, message: string) => {
  const provider = someOrFail(web3Auth.provider);

  const walletClient = createWalletClient({
    account: address,
    chain: mainnet,
    transport: custom(provider),
  });
  const signature = await walletClient.signMessage({ message });
  return { signature, message };
};

const prepareSolanaWallet = async (web3Auth: Web3Auth) => {
  const chainConfig = {
    chainId: '0x2',
    chainNamespace: CHAIN_NAMESPACES.SOLANA,
    rpcTarget: 'https://api.devnet.solana.com',
    tickerName: 'SOLANA',
    ticker: 'SOL',
    decimals: 9,
    blockExplorerUrl: 'https://explorer.solana.com/?cluster=devnet',
    logo: 'https://images.toruswallet.io/sol.svg',
  };
  const privateKeyProvider = new SolanaPrivateKeyProvider({ config: { chainConfig } });
  // it looks weird, but just following the guide provided by web3auth:
  // https://github.com/Web3Auth/web3auth-pnp-examples/blob/main/web-no-modal-sdk/blockchain-connection-examples/multi-chain-no-modal-example/src/RPC/solanaRPC.ts
  await privateKeyProvider.setupProvider(getED25519Key(someOrFail(web3Auth.state.privKey)).sk.toString('hex'));
  return new SolanaWallet(privateKeyProvider);
};

export const signSolanaMessageByWeb3Auth = async (web3Auth: Web3Auth, message: string) => {
  const solanaWallet = await prepareSolanaWallet(web3Auth);
  const signature = await solanaWallet.signMessage(Buffer.from(message));

  return { signature: toHex(signature), message };
};

export const getSolanaPublicKeyByWeb3Auth = async (web3Auth: Web3Auth) => {
  const solanaWallet = await prepareSolanaWallet(web3Auth);
  const [address] = await solanaWallet.requestAccounts();

  return address;
};
