import { QuestionCircleOutlined } from '@ant-design/icons';
import { cx } from '@emotion/css';
import { Form, Input, Space, Tooltip } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { BlockchainLabelList } from '@/features/dictionary/blockchain/components';
import { useSelectedNetwork } from '@/features/dictionary/blockchain/hooks';
import { OperationAddUserAddress as BaseOperationAddUserAddress } from '@/features/user/components';
import { withUserAddressAvailability } from '@/features/user/hocs';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageSettings } from '@/generated/i18n/i18n';

import type { ProfileEVMAddressItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const OperationAddUserAddress = withUserAddressAvailability(
  BlockchainAPITypeAPIModel.EVM,
  false,
)(BaseOperationAddUserAddress);

const ProfileEVMAddressItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  style,
  className,
}: ProfileEVMAddressItemProps<Values>) => {
  const { network } = useSelectedNetwork();
  return (
    <Form.Item<Values>
      name={name}
      label={
        <Space>
          <FormattedMessage
            id={I18nPageSettings.PROFILE_COMPONENTS_PROFILE_CARD_EVM_ADDRESS_ITEM_LABEL}
            values={{
              blockchains: <BlockchainLabelList api={BlockchainAPITypeAPIModel.EVM} network={network} mode="icon" />,
            }}
          />
          <Tooltip
            title={
              <FormattedMessage
                id={I18nPageSettings.PROFILE_COMPONENTS_PROFILE_CARD_EVM_ADDRESS_ITEM_HELP}
                values={{
                  blockchains: (
                    <BlockchainLabelList api={BlockchainAPITypeAPIModel.EVM} network={network} mode="short" />
                  ),
                }}
              />
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      }
    >
      <Input
        data-test={dataTest && `${dataTest}-value`}
        disabled
        className={cx('ym-hide-content', className)}
        style={style}
        suffix={
          <OperationAddUserAddress
            data-test={dataTest && `${dataTest}-opAdd`}
            api={BlockchainAPITypeAPIModel.EVM}
            mode="icon"
          />
        }
      />
    </Form.Item>
  );
};

const ProfileEVMAddressItemMemo = React.memo(ProfileEVMAddressItem) as typeof ProfileEVMAddressItem;

export default ProfileEVMAddressItemMemo;
