import React, { useMemo } from 'react';
import { getAddress } from 'viem';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import { useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import { usePayout } from '@/features/payouts/hooks';
import usePayoutWeb3Actions from '@/features/payouts/hooks/usePayoutWeb3Actions';
import type { PayoutSummary } from '@/features/payouts/types';
import { OperationRefreshBalances } from '@/features/statistics/components';
import { useUserAddress } from '@/features/user/hooks';
import type { Web3EVMWalletOperationProps } from '@/features/web3/components';
import { Web3EVMWalletOperation } from '@/features/web3/components';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nCommon, I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { mapStoredState } from '@/infrastructure/model';
import { emptyWith, noop, withLogError, withOnSuccess } from '@/infrastructure/utils/functions';
import { isCancelled, withCancelledByUser } from '@/infrastructure/utils/ui';
import PayoutViewLink from '@/pages/payouts/payout-view/components/PayoutViewLink';

import type { OperationStartPayoutWithConnectorProps } from './types';

const options = {
  error: (error?: unknown) =>
    !isCancelled(error)
      ? {
          message: (
            <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_FAILED_MESSAGE} />
          ),
        }
      : undefined,
  success: (payout: PayoutSummary) => ({
    message: <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_SUCCESS_MESSAGE} />,
    description: (
      <FormattedMessageWithMarkup
        id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_SUCCESS_DESCRIPTION}
        values={{
          link: (title: React.ReactNode) => <PayoutViewLink value={payout.id} title={title} mode="text" />,
        }}
      />
    ),
  }),
};

const OperationStartPayoutWithConnector: React.FC<OperationStartPayoutWithConnectorProps> = ({
  'data-test': dataTest,
  connector,
  extra: payoutId,
  onSuccess,
  withInProgress,
}) => {
  const {
    data: { data: payout },
  } = usePayout(payoutId);
  const { start: startAction } = usePayoutWeb3Actions(payoutId);
  const {
    data: { data: address },
  } = useUserAddress(BlockchainAPITypeAPIModel.EVM);
  const blockchainState = useBlockchainSystemInfo(payout?.blockchain);
  const blockchain = useMemo(
    () =>
      mapStoredState(blockchainState.data, (state) => (state.apiType === BlockchainAPITypeAPIModel.EVM ? state : null)),
    [blockchainState.data],
  );
  const { withNotification } = useNotification();

  const doStart = useMemo(
    () =>
      withLogError(
        (withInProgress ?? emptyWith)(
          withNotification(withOnSuccess(withCancelledByUser(startAction.act), onSuccess ?? noop), options),
        ),
      ),
    [withNotification, withInProgress, startAction.act, onSuccess],
  );
  const disabledMessage = useMemo(() => {
    switch (startAction.unavailabilityReason) {
      case 'no-data':
        return <FormattedMessage id={I18nCommon.MESSAGES_LOADING} />;
      case 'invalid-status':
        return (
          <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INVALID_STATUS} />
        );
      case 'invalid-account':
        return (
          <FormattedMessage
            id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INVALID_ACCOUNT}
            values={{ account: address?.value }}
          />
        );
      case 'invalid-chain-id':
        return (
          <FormattedMessageWithMarkup
            id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INVALID_CHAIN_ID}
            values={{ bt: payout?.blockchain }}
          />
        );
      case 'unsupported-chain':
        return (
          <FormattedMessageWithMarkup
            id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_UNSUPPORTED}
            values={{ bt: payout?.blockchain }}
          />
        );
      case 'insufficient-balance':
        return (
          <FormattedMessageWithMarkup
            id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INSUFFICIENT_BALANCE}
            values={{ ln: (title: React.ReactNode) => <OperationRefreshBalances title={title} mode="link" /> }}
          />
        );
      case undefined:
        return undefined;
    }
  }, [address?.value, payout?.blockchain, startAction.unavailabilityReason]);

  const requiredChain = useMemo(
    () => (blockchain.data?.chainId ? { id: blockchain.data.chainId, network: blockchain.data.bt } : undefined),
    [blockchain.data?.bt, blockchain.data?.chainId],
  );

  const action: Web3EVMWalletOperationProps['mainAction'] = useMemo(
    () => ({
      withClient: true,
      onAction: doStart,
      title: (
        <FormattedMessage
          id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_TITLE}
          values={{ connector: connector.name }}
        />
      ),
    }),
    [connector.name, doStart],
  );

  return (
    <Web3EVMWalletOperation
      data-test={dataTest}
      connector={connector}
      requiredChain={requiredChain}
      requiredAccount={address?.value ? getAddress(address.value) : undefined}
      disabled={!startAction.available}
      disabledMessage={disabledMessage}
      inProgress={startAction.inAction}
      mainAction={action}
    />
  );
};

const OperationStartPayoutWithConnectorMemo = React.memo(
  OperationStartPayoutWithConnector,
) as typeof OperationStartPayoutWithConnector;

export default OperationStartPayoutWithConnectorMemo;
