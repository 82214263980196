import { validateSolanaSignature } from '@/features/auth/temp';
import type { ReCaptchaParams } from '@/features/recaptcha/types';
import { parseAuthUser } from '@/features/user/api';
import type { OpenIdProviderTypeAPIModel, TokenWithUserAPIModel } from '@/generated/api/ncps-api';
import { AuthSolanaXRecaptchaTypeEnum, AuthEVMXRecaptchaTypeEnum, MerchantAuthApi } from '@/generated/api/ncps-api';
import type {
  BlockchainAPITypeAPIModel,
  EVMCredentialsAuthRequestAPIModel,
  SolanaCredentialsAuthRequestAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { apiConfigurationFactory } from '@/infrastructure/api.provider';

import type { JWTTokenWithUser } from './types';

const authAPINoAuth = new MerchantAuthApi(apiConfigurationFactory('public'));
const authAPIWithAuth = new MerchantAuthApi(apiConfigurationFactory('no-relogin'));

const parseJWTTokenWithUser = ({ user, token }: TokenWithUserAPIModel): JWTTokenWithUser => ({
  user: parseAuthUser(user),
  token,
});

export const queryAuthParameters = async (
  addressType: BlockchainAPITypeAPIModel,
  address: string,
  initOverrides?: RequestInit,
) => await authAPIWithAuth.requestAuthWeb3Params({ address, addressType }, initOverrides);

export const requestAuthEVM = async (
  credentials: EVMCredentialsAuthRequestAPIModel,
  { recaptchaToken, recaptchaType }: ReCaptchaParams,
  initOverrides?: RequestInit,
) =>
  parseJWTTokenWithUser(
    await authAPINoAuth.authEVM(recaptchaToken, credentials, AuthEVMXRecaptchaTypeEnum[recaptchaType], initOverrides),
  );

export const requestAuthSolana = async (
  credentials: SolanaCredentialsAuthRequestAPIModel,
  { recaptchaToken, recaptchaType }: ReCaptchaParams,
  initOverrides?: RequestInit,
) => {
  validateSolanaSignature(credentials.address, credentials.signature, credentials.message);
  return parseJWTTokenWithUser(
    await authAPINoAuth.authSolana(
      recaptchaToken,
      credentials,
      AuthSolanaXRecaptchaTypeEnum[recaptchaType],
      initOverrides,
    ),
  );
};

export const requestAuthEmail = async (
  evm: EVMCredentialsAuthRequestAPIModel | undefined,
  solana: SolanaCredentialsAuthRequestAPIModel | undefined,
  emailToken: string,
  emailProvider: OpenIdProviderTypeAPIModel,
  initOverrides?: RequestInit,
): Promise<JWTTokenWithUser> => {
  if (solana) validateSolanaSignature(solana.address, solana.signature, solana.message);
  return parseJWTTokenWithUser(
    await authAPINoAuth.authEmail({ evm, solana, emailToken, emailTokenProvider: emailProvider }, initOverrides),
  );
};

/** Authenticates user for new company */
export const requestRelogin = async (companyId: number, initOverrides?: RequestInit): Promise<string> =>
  (await authAPIWithAuth.authCompany(companyId, initOverrides)).token;

// the token is in headers means no logout is required
// @ts-expect-error the token is in headers means no logout is required
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const requestLogout = async (initOverrides?: RequestInit): Promise<void> => Promise.resolve();
