import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { SettingsTabIcon, SettingsTabType } from '@/pages/settings/components';
import { settingsComplianceLink } from '@/pages/settings/routes';

import type { SettingsComplianceLinkProps } from './types';

const SettingsComplianceLink: React.FC<SettingsComplianceLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    mode={mode}
    to={settingsComplianceLink()}
    icon={icon ?? <SettingsTabIcon value={SettingsTabType.compliance} />}
    title={title ?? <FormattedMessage id={I18nPageSettings.COMPLIANCE_COMPONENTS_LINK_TITLE} />}
  />
);

const SettingsComplianceLinkMemo = React.memo(SettingsComplianceLink);

export default SettingsComplianceLinkMemo;
