import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Space, Switch } from 'antd';
import isNil from 'lodash-es/isNil';
import React, { useMemo } from 'react';

import type { BooleanSwitchProps } from '@/components/BooleanItem/types';
import WarningTooltip from '@/components/WarningTooltip';

const BooleanSwitch = React.memo(
  React.forwardRef<HTMLButtonElement, BooleanSwitchProps>(
    (
      { 'data-test': dataTest, readonly, value, onChange, SwitchProps, prefix, suffix, disabledMessage, disabled },
      ref,
    ) => {
      const component = useMemo(
        () => (
          <Space>
            {prefix}
            <Switch
              ref={ref}
              onChange={onChange}
              {...(!isNil(value) ? { checked: value } : {})}
              data-test={dataTest}
              disabled={readonly || disabled}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              {...SwitchProps}
            />
            {suffix}
          </Space>
        ),
        [SwitchProps, dataTest, disabled, onChange, prefix, readonly, ref, suffix, value],
      );
      return disabledMessage ? <WarningTooltip title={disabledMessage}>{component}</WarningTooltip> : component;
    },
  ),
);

export default BooleanSwitch;
