import React from 'react';

import { noop } from '@/infrastructure/utils/functions';

const Web3EVMInitContext = React.createContext<{ initialized: boolean; initialize: () => void }>({
  initialized: false,
  initialize: noop,
});

export default Web3EVMInitContext;
