import React from 'react';

import { FormattedMessage } from '@/components';
import OperationRefreshMerchantWallet from '@/features/merchant-wallets/components/OperationRefreshMerchantWallet';
import { useActiveMerchantWallet } from '@/features/merchant-wallets/hooks';
import { Web3WalletModalContainer } from '@/features/web3/components';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';

import OperationDeployMerchantWalletWithConnector from '../OperationDeployMerchantWalletWithConnector';

import type { DeployMerchantWalletModalProps } from './types';

const evm = { Operation: OperationDeployMerchantWalletWithConnector, extra: undefined };

const DeployMerchantWalletModal: React.FC<DeployMerchantWalletModalProps> = ({
  'data-test': dataTest,
  onSuccess,
  onCancel,
  bt,
}) => {
  const { loading } = useActiveMerchantWallet(bt);

  return (
    <Web3WalletModalContainer
      data-test={dataTest}
      onSuccess={onSuccess}
      onClose={onCancel}
      title={<FormattedMessage id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_DIALOG_TITLE} />}
      description={
        <FormattedMessage
          id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_DIALOG_DESCRIPTION}
          tagName="span"
          values={{
            refresh: (title: React.ReactNode) => <OperationRefreshMerchantWallet bt={bt} title={title} mode="link" />,
          }}
        />
      }
      api={undefined}
      bt={bt}
      loading={loading}
      evm={evm}
    />
  );
};

const DeployMerchantWalletModalMemo = React.memo(DeployMerchantWalletModal);

export default DeployMerchantWalletModalMemo;
