import { css } from '@emotion/css';
import React, { useCallback, useMemo, useRef } from 'react';

import { FormattedMessage } from '@/components';
import { withWeb3EVMConnector } from '@/features/web3/hocs';
import { useWeb3EVMConnector } from '@/features/web3/hooks';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import { noop, timeout, withLogError, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';
import { cancellable } from '@/infrastructure/utils/ui';

import Web3WalletInProgress from '../Web3WalletInProgress';
import Web3WalletOperationAccount from '../Web3WalletOperationAccount';
import Web3WalletOperationButtons from '../Web3WalletOperationButtons';

import { Web3EVMWalletConnect, Web3EVMWalletDisconnect } from './components';

import type { Web3EVMWalletConnectProps } from './components';
import type { Web3EVMWalletOperationProps } from './types';
import type { Address } from 'viem';

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
};

const Web3EVMWalletOperation = <Result = unknown,>({
  'data-test': dataTest,
  mainAction,
  onSuccess,
  onlyLocal,
  cancellation: supportCancellation,
  showAccount,
  disabled,
  disabledMessage,
  requiredChain,
  requiredAccount: baseRequiredAccount,
  inProgress,
  inProgressMode,
  withConnect,
}: Web3EVMWalletOperationProps<Result>) => {
  const requiredAccountValue =
    baseRequiredAccount && typeof baseRequiredAccount === 'object' && 'title' in baseRequiredAccount
      ? baseRequiredAccount.value
      : baseRequiredAccount;

  const { connector, account, client, chainId, disconnectable, isConnecting, refresh } = useWeb3EVMConnector();
  const clientRef = useRef(client);
  clientRef.current = client;

  const [onCancel, setOnCancel] = useStateMountSafe<(() => void) | undefined>();

  const doAct = useCallback(
    async (params: { account?: Address; chainId?: number }) => {
      const cancellation =
        // eslint-disable-next-line no-nested-ternary
        (supportCancellation
          ? typeof supportCancellation === 'function'
            ? supportCancellation()
            : typeof supportCancellation === 'object'
              && (supportCancellation.create?.() ?? cancellable((cancel) => setOnCancel(() => cancel)))
          : undefined) || undefined;

      const localClient = clientRef.current;
      if (mainAction.withClient && !localClient) await timeout(500);

      const client = clientRef.current;
      if (!mainAction.withClient) {
        return withOnSuccess(mainAction.onAction, onSuccess ?? noop)(params, cancellation).finally(() =>
          setOnCancel(undefined),
        );
      } else if (client)
        return withOnSuccess(mainAction.onAction, onSuccess ?? noop)({ ...params, client }, cancellation).finally(() =>
          setOnCancel(undefined),
        );
    },
    [mainAction.onAction, mainAction.withClient, onSuccess, setOnCancel, supportCancellation],
  );

  const onAction = useMemo(() => withLogError(async () => doAct({ account, chainId })), [account, chainId, doAct]);

  const onConnect = useMemo(
    () =>
      withSuppressError(async () => {
        const acc = await refresh();
        if (mainAction.runOnConnect) await doAct(acc ?? {});
      }),
    [doAct, mainAction.runOnConnect, refresh],
  );

  const doSwitchNetwork = useCallback(async () => {
    if (requiredChain && connector.switchChain) await connector.switchChain({ chainId: requiredChain.id });
  }, [connector, requiredChain]);

  if (!account) {
    const connectProgressMode: Web3EVMWalletConnectProps['inProgressMode'] =
      inProgressMode === 'view' || typeof inProgressMode === 'object' ? 'view' : inProgressMode;
    return (
      <Web3EVMWalletConnect
        data-test={dataTest && `${dataTest}-connect`}
        disabled={disabled}
        onlyLocal={onlyLocal}
        connector={connector}
        onConnect={onConnect}
        requiredAccount={requiredAccountValue}
        withConnect={withConnect}
        inProgressMode={connectProgressMode}
        cancellation={supportCancellation}
      />
    );
  }

  if (requiredChain && chainId !== requiredChain.id) {
    if (connector.switchChain) {
      return (
        <Web3EVMWalletOperation
          data-test={dataTest}
          showAccount={showAccount}
          mainAction={{
            onAction: doSwitchNetwork,
            title: (
              <FormattedMessage
                id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_SWITCH_NETWORK_TITLE}
                values={{ network: requiredChain.network }}
              />
            ),
          }}
          inProgressMode={inProgressMode}
        />
      );
    }
    return (
      <Web3EVMWalletOperation
        data-test={dataTest}
        mainAction={mainAction}
        disabled
        showAccount={showAccount}
        disabledMessage={
          <FormattedMessage
            id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_SWITCH_NETWORK_MANUAL_TITLE}
            values={{ network: requiredChain.network }}
          />
        }
        inProgressMode={inProgressMode}
      />
    );
  }

  if (requiredAccountValue && account.toLowerCase() !== requiredAccountValue.toLowerCase()) {
    return (
      <Web3EVMWalletOperation
        data-test={dataTest}
        mainAction={mainAction}
        disabled
        showAccount={showAccount}
        disabledMessage={
          (baseRequiredAccount && typeof baseRequiredAccount === 'object' && baseRequiredAccount.title) || (
            <FormattedMessage
              id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_SWITCH_ACCOUNT_MANUAL_TITLE}
              values={{ account: requiredAccountValue }}
            />
          )
        }
        inProgressMode={inProgressMode}
      />
    );
  }

  if (inProgress && (inProgressMode === 'view' || typeof inProgressMode === 'object')) {
    const Description =
      typeof inProgressMode === 'object' && inProgressMode.Description ? (
        <inProgressMode.Description provider={connector.id} account={account} />
      ) : (
        <FormattedMessage
          id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_DESCRIPTION}
          values={{ provider: connector.name }}
        />
      );
    const cancelTitle = typeof inProgressMode === 'object' && inProgressMode.cancel;
    return (
      <Web3WalletInProgress
        data-test={dataTest}
        wallet={connector}
        cancel={onCancel && { onAction: onCancel, title: cancelTitle }}
        message={Description}
      />
    );
  }

  return (
    <div className={styles.container}>
      <Web3WalletOperationButtons
        data-test={dataTest}
        walletId={connector.id}
        cancellationTitle={
          typeof supportCancellation === 'object' && 'title' in supportCancellation && supportCancellation.title
        }
        disconnectable={disconnectable}
        disabled={disabled}
        disabledMessage={disabledMessage}
        inProgress={inProgress || isConnecting}
        title={mainAction.title}
        onAction={onAction}
        onCancel={onCancel}
        Disconnect={Web3EVMWalletDisconnect}
      />
      {showAccount && (
        <Web3WalletOperationAccount
          data-test={dataTest && `${dataTest}-account`}
          account={account}
          tooltip={typeof showAccount === 'object' && 'tooltip' in showAccount && showAccount.tooltip}
        />
      )}
    </div>
  );
};

const Web3EVMWalletOperationWithConnector = withWeb3EVMConnector(Web3EVMWalletOperation);

const Web3EVMWalletOperationMemo = React.memo(
  Web3EVMWalletOperationWithConnector,
) as typeof Web3EVMWalletOperationWithConnector;

export default Web3EVMWalletOperationMemo;
