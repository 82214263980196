import { css } from '@emotion/css';
import { theme } from 'antd';
import { useMemo } from 'react';

const useStyles = () => {
  const { token } = theme.useToken();
  return useMemo(
    () => ({
      container: css`
        border: 2px solid ${token.colorBorderSecondary};
        border-radius: ${token.borderRadius}px;
      `,
      segmented: css`
        padding: 0;
        border-radius: 0;
      `,
      segmentedItem: css`
        &.ant-segmented-item {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          box-shadow: none;
          -moz-box-shadow: none;
        }

        &.ant-segmented-item-selected {
          border-bottom: 1px dotted ${token.colorBorderSecondary};
        }
      `,
      wallets: css`
        padding: ${token.paddingLG}px ${token.paddingSM}px ${token.paddingSM}px ${token.paddingSM}px;
        background-color: ${token.colorBgContainer};
      `,
    }),
    [token.borderRadius, token.colorBgContainer, token.colorBorderSecondary, token.paddingLG, token.paddingSM],
  );
};

export default useStyles;
