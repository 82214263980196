import dayjs from 'dayjs';

import { ConnectorId } from '@/features/web3/utils';
import { useFirstRenderEffect } from '@/hooks';
import createCache from '@/infrastructure/utils/cache';
import { goalReached, YMGoals } from '@/infrastructure/ym';

export default function useWeb3WalletExtensionInstallMetrics(connectorId: string) {
  useFirstRenderEffect(() => {
    if (connectorId !== ConnectorId.metaMaskSDK.toString()) return;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (window.ethereum?.isMetaMask) {
      const cache = createCache(`ncps.wallet.${connectorId}.status`, 'temporal');
      const unavailableAtString = cache.safeRead()?.toString();
      const unavailableAt = unavailableAtString ? dayjs(unavailableAtString) : undefined;
      cache.safeClear();
      if (unavailableAt?.add(30, 'minutes').isAfter()) goalReached(YMGoals.WALLET_MM_INSTALLED);
    } else {
      createCache<Date>(`ncps.wallet.${connectorId}.status`, 'temporal').safeSave(new Date());
    }
  });
}
