/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PagePaymentSortByAPIModel } from './PagePaymentSortByAPIModel';
import {
    PagePaymentSortByAPIModelFromJSON,
    PagePaymentSortByAPIModelFromJSONTyped,
    PagePaymentSortByAPIModelToJSON,
} from './PagePaymentSortByAPIModel';
import type { PaymentPredicatesAPIModel } from './PaymentPredicatesAPIModel';
import {
    PaymentPredicatesAPIModelFromJSON,
    PaymentPredicatesAPIModelFromJSONTyped,
    PaymentPredicatesAPIModelToJSON,
} from './PaymentPredicatesAPIModel';

/**
 * 
 * @export
 * @interface PaymentsFilterAPIModel
 */
export interface PaymentsFilterAPIModel {
    /**
     * 
     * @type {PaymentPredicatesAPIModel}
     * @memberof PaymentsFilterAPIModel
     */
    predicates: PaymentPredicatesAPIModel;
    /**
     * 
     * @type {PagePaymentSortByAPIModel}
     * @memberof PaymentsFilterAPIModel
     */
    page: PagePaymentSortByAPIModel;
}

/**
 * Check if a given object implements the PaymentsFilterAPIModel interface.
 */
export function instanceOfPaymentsFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function PaymentsFilterAPIModelFromJSON(json: any): PaymentsFilterAPIModel {
    return PaymentsFilterAPIModelFromJSONTyped(json, false);
}

export function PaymentsFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentsFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': PaymentPredicatesAPIModelFromJSON(json['predicates']),
        'page': PagePaymentSortByAPIModelFromJSON(json['page']),
    };
}

export function PaymentsFilterAPIModelToJSON(value?: PaymentsFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': PaymentPredicatesAPIModelToJSON(value['predicates']),
        'page': PagePaymentSortByAPIModelToJSON(value['page']),
    };
}

