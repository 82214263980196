import { EditOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useMemo } from 'react';

import { defaultDialogFormLayout, defaultDialogFormTailLayout, FormattedMessage, Operation } from '@/components';
import { useCompanySettings, useCompanySettingsActions } from '@/features/company-settings/hooks';
import type { KYTToleranceLevelAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useFormVisible, useNotification } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import { UpdateKYTLevelForm } from './components';

import type { OperationUpdateKYTLevelProps } from './types';

const notificationOptions = {
  error: () => <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_KYT_LEVEL_MESSAGE_ERROR} />,
  success: () => ({
    message: <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_KYT_LEVEL_MESSAGE_SUCCESS} />,
  }),
};

const OperationUpdateKYTLevel: React.FC<OperationUpdateKYTLevelProps> = ({
  'data-test': dataTest,
  disabled,
  inProgress,
  onSuccess,
  mode = 'inline',
}) => {
  const { updateKYTLevel: updateKYTLevelAction } = useCompanySettingsActions();
  const { data: settings } = useCompanySettings();
  const formControl = useFormVisible(false);
  const { withNotification } = useNotification();

  const updateKYTLevel = useMemo(
    () =>
      withNotification(
        formControl.withFormHide(
          withOnSuccess(async (level: KYTToleranceLevelAPIModel) => {
            const result = await updateKYTLevelAction.act(level);
            goalReached(YMGoals.COMPANY_KYT_LEVEL_UPDATED);
            return result;
          }, onSuccess ?? noop),
        ),
        notificationOptions,
      ),
    [withNotification, formControl, onSuccess, updateKYTLevelAction],
  );

  const showForm = useMemo(
    () => withOnSuccess(formControl.show, () => goalReached(YMGoals.COMPANY_KYT_LEVEL_UPDATE_INIT)),
    [formControl.show],
  );

  const initialValues = useMemo(() => ({ level: settings.data?.kytLevel }), [settings.data?.kytLevel]);

  return (
    <>
      {formControl.visible && (
        <Modal
          open
          maskClosable={false}
          footer={null}
          width={800}
          title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_KYT_LEVEL_FORM_TITLE} />}
          closable
          onCancel={formControl.hide}
        >
          <UpdateKYTLevelForm
            values={initialValues}
            layout={defaultDialogFormLayout}
            tailLayout={defaultDialogFormTailLayout}
            data-test={dataTest && `${dataTest}-form`}
            onReset={formControl.hide}
            onSubmit={updateKYTLevel}
          />
        </Modal>
      )}
      <Operation
        title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_KYT_LEVEL_TITLE} />}
        tooltip={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_KYT_LEVEL_TOOLTIP} />}
        icon={<EditOutlined />}
        onClick={showForm}
        disabled={disabled || !updateKYTLevelAction.available}
        inProgress={inProgress || formControl.visible || updateKYTLevelAction.inAction}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationUpdateKYTLevelMemo = React.memo(OperationUpdateKYTLevel);

export default OperationUpdateKYTLevelMemo;
