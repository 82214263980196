import { Modal } from 'antd';
import React, { useCallback } from 'react';

import { useBlockchain } from '@/features/dictionary/blockchain/hooks';
import Web3WalletContainer from '@/features/web3/components/Web3WalletContainer';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { useStateMountSafe } from '@/hooks';
import { withFinally } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';

import type { Web3WalletModalContainerProps } from './types';

const Web3WalletModalContainer = <
  Success = unknown,
  Account = string,
  BT extends BlockchainTypeAPIModel | undefined = undefined,
  EVMExtraProps = undefined,
  SolanaExtraProps = undefined,
>({
  onClose,
  loading,
  className,
  style,
  title,
  ...props
}: Web3WalletModalContainerProps<Success, Account, BT, EVMExtraProps, SolanaExtraProps>) => {
  const { 'data-test': dataTest, api, bt } = props;
  const [inProgress, setInProgress] = useStateMountSafe<string>();
  const withInProgress = useCallback(
    (selected: string) =>
      <V extends unknown[] = unknown[]>(func: Func<V, Success>): Func<V, Success> =>
      async (...args: V) => {
        setInProgress(selected);
        return withFinally(func, () => setInProgress(undefined))(...args);
      },
    [setInProgress],
  );
  const bcState = useBlockchain(!api ? bt : undefined);

  return (
    <Modal
      data-test={dataTest && `${dataTest}-modal`}
      open
      maskClosable={false}
      title={title}
      onOk={!inProgress ? onClose : undefined}
      onCancel={!inProgress ? onClose : undefined}
      closable={!inProgress}
      footer=""
      loading={loading || bcState.loading}
      className={className}
      style={style}
    >
      <Web3WalletContainer<Success, Account, BT, EVMExtraProps, SolanaExtraProps>
        {...props}
        withInProgress={withInProgress}
      />
    </Modal>
  );
};

const Web3WalletModalContainerMemo = React.memo(Web3WalletModalContainer) as typeof Web3WalletModalContainer;

export default Web3WalletModalContainerMemo;
