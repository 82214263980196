import { type AuthenticatedUserAPIModel, MerchantAuthApi, MerchantUserApi } from '@/generated/api/ncps-api';
import type { SignatureComponentsAPIModel, UserAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { UserApi } from '@/generated/api/ncps-core/merchant-bo';
import { apiConfigurationFactory, coreConfigurationFactory } from '@/infrastructure/api.provider';

import type { User, UserPreferences } from './types';
import type { Address } from 'viem';

const merchantUserSecuredApi = new MerchantUserApi(apiConfigurationFactory('auth'));
const authAPIWithAuth = new MerchantAuthApi(apiConfigurationFactory('no-relogin'));
const userApi = new UserApi(coreConfigurationFactory('auth'));

export const parseAuthUser = ({
  address,
  addressType,
  tokenExpiresAt,
  activeCompany,
  roles,
  ...rest
}: AuthenticatedUserAPIModel): User => ({ ...rest, isActive: true });

const parseUser = ({ roles, addresses, ...rest }: UserAPIModel): User => ({
  ...rest,
  addresses: addresses ?? [],
});

export const queryCurrentUser = async (initOverrides?: RequestInit) =>
  parseAuthUser(await authAPIWithAuth.getAuthenticatedUser(initOverrides));

export const queryUserPreferences = (initOverrides?: RequestInit): Promise<UserPreferences> =>
  merchantUserSecuredApi.getOwnPreferences(initOverrides);
export const requestUpdateUserPreferences = (
  preferences: UserPreferences,
  initOverrides?: RequestInit,
): Promise<UserPreferences> => merchantUserSecuredApi.saveOwnPreferences(preferences, initOverrides);

export const requestAddEVMUserAddress = async (
  address: Address,
  signature: SignatureComponentsAPIModel,
  message: string,
  initOverrides?: RequestInit,
) => parseUser(await userApi.addEVMAddress({ address, signature, message }, initOverrides));

export const requestAddSolanaUserAddress = async (
  address: string,
  signature: string,
  message: string,
  initOverrides?: RequestInit,
) => parseUser(await userApi.addSolanaAddress({ address, signature, message }, initOverrides));
