import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import listenerMiddleware from '@/app/listenerMiddleware';
import { notifyAuthTokenUpdated, storeAuth } from '@/features/auth/actions';
import { makeSelectAuthTokenData } from '@/features/auth/selectors';
import { storeSelectedNetwork } from '@/features/dictionary/blockchain/actions';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { storeCookiesAcceptance } from '@/features/global/actions';
import { makeSelectCookiesAcceptance } from '@/features/global/selectors';
import { storeLocale } from '@/features/i18n/actions';
import { makeSelectLocale } from '@/features/i18n/selectors';
import { makeSelectPreferences } from '@/features/user/selectors';
import { storedDataLoaded, storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';
import { ymInitUser } from '@/infrastructure/ym';

import { markPreferencesDirty, markUserDirty, persistPreferences, storePreferences, storeUser } from './actions';

import type { UserState } from './types';
import type { Draft } from 'immer';

const initialState: UserState = {
  user: storedDirtyData,
  preferences: storedDirtyData,
};

const { storeUserReducer, markUserDirtyReducer } = createLoadingDataReducers(
  'User' as const,
  (state: Draft<UserState>) => state.user,
  (state, user) => ({ ...state, user }),
);

const { storePreferencesReducer, markPreferencesDirtyReducer } = createLoadingDataReducers(
  'Preferences' as const,
  (state: Draft<UserState>) => state.preferences,
  (state, preferences) => ({ ...state, preferences }),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(storeUser, storeUserReducer)
    .addCase(markUserDirty, markUserDirtyReducer)

    .addCase(storePreferences, storePreferencesReducer)
    .addCase(markPreferencesDirty, markPreferencesDirtyReducer)

    .addCase(storeAuth, (state, { payload: { user } }) => (user ? { ...state, user: storedDataLoaded(user) } : state))

    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address || previous?.activeCompanyId !== current?.activeCompanyId
        ? initialState
        : state,
    );

  listenerMiddleware.startListening({
    matcher: isAnyOf(storeSelectedNetwork, storeCookiesAcceptance, storeLocale),
    effect: async (_, listenerApi) => {
      const saved = makeSelectPreferences()(listenerApi.getState());
      if (!saved.data) {
        return;
      }

      const lang = makeSelectLocale()(listenerApi.getState());
      const cookiesAccepted = makeSelectCookiesAcceptance()(listenerApi.getState()).value;
      const network = makeSelectSelectedNetwork()(listenerApi.getState());

      if (
        lang === saved.data.lang
        && cookiesAccepted === saved.data.cookiesAccepted
        && network === saved.data.network
      ) {
        return;
      }
      await listenerApi.dispatch(persistPreferences({ lang, cookiesAccepted, network }));
    },
  });

  listenerMiddleware.startListening({
    matcher: isAnyOf(storeUser, storeAuth),
    effect: (_, listenerApi) => {
      const previous = makeSelectAuthTokenData()(listenerApi.getOriginalState());
      const current = makeSelectAuthTokenData()(listenerApi.getState());
      if (current?.info.address && current.info.address !== previous?.info.address) {
        ymInitUser(current.info.address);
      }
    },
  });
});

export default reducer;
