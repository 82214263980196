import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useConnectorClient } from 'wagmi';

import { useWeb3EVMConnectors } from '@/features/web3/hooks';
import { useFirstRenderEffect, useIsMounted, usePrevious, useStateMountSafe } from '@/hooks';
import { noop, timeout, withSuppressPromise } from '@/infrastructure/utils/functions';
import { namedHOC } from '@/infrastructure/utils/react';
import { asType } from '@/infrastructure/utils/ts';

import Web3ConnectorContext from './context';

import type { Web3EVMConnectorContextType } from './context';
import type React from 'react';
import type { Address } from 'viem';
import type { Connector } from 'wagmi';

type Web3EVMConnectorProps = { connectorId: string } | { connector: Connector };

const withWeb3ConnectorSelection = <
  Original extends NonNullable<unknown> = NonNullable<unknown>,
  Wrapper extends Original & Web3EVMConnectorProps = Original & Web3EVMConnectorProps,
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithWeb3ConnectorSelection',
  )((props) => {
    const connectorId = 'connectorId' in props ? props.connectorId : props.connector.id;
    const isMounted = useIsMounted();
    const [account, setAccount] = useStateMountSafe<Address | undefined>();
    const [chainId, setChainId] = useStateMountSafe<number | undefined>();

    const { connectors: all } = useWeb3EVMConnectors();
    const {
      connector,
      available,
      isLocal = true,
      disconnectable = false,
    } = useMemo(
      () => all.find((v) => v.id === connectorId) ?? asType<Partial<(typeof all)[0]>>({}),
      [all, connectorId],
    );
    const prevAccount = usePrevious(account);
    const {
      data: client,
      isFetching,
      isLoading,
      isRefetching,
      refetch,
    } = useConnectorClient({ connector, account, chainId });
    useEffect(() => {
      if (!prevAccount && account) withSuppressPromise(refetch)();
    }, [account, prevAccount, refetch]);
    const isConnecting = isFetching || isLoading || isRefetching;

    const refreshChainId = useCallback(async () => {
      if (connector && (await connector.isAuthorized())) {
        if (!isMounted()) {
          return undefined;
        }
        const val = await connector.getChainId();
        setChainId(val);
        return val;
      }
      setChainId(undefined);
      return undefined;
    }, [connector, isMounted, setChainId]);

    const refreshAccount = useCallback(async () => {
      if (connector && (await connector.isAuthorized())) {
        if (!isMounted()) return undefined;

        const val = (await connector.getAccounts())[0];
        setAccount(val);
        return val;
      }
      setAccount(undefined);
      return undefined;
    }, [connector, isMounted, setAccount]);

    const refreshConnection = useCallback(async () => {
      if (!isMounted()) return;

      if (connector && (await connector.isAuthorized()) && !(await connector.getProvider())) {
        await timeout(200);
        if ((await connector.isAuthorized()) && !(await connector.getProvider())) {
          // FIXME: in case metamask connector can be authorized and ready but not connected...
          await connector.connect();
        }
      }
    }, [isMounted, connector]);

    const refresh = useCallback(async () => {
      await refreshConnection();
      if (!isMounted()) return undefined;

      const acc = await refreshAccount();
      const id = await refreshChainId();
      return { chainId: id, account: acc };
    }, [isMounted, refreshAccount, refreshChainId, refreshConnection]);
    useEffect(() => {
      if (connector) {
        const listener = () => refresh();
        connector.emitter.on('change', listener);
        connector.emitter.on('connect', listener);
        connector.emitter.on('disconnect', listener);
        return () => {
          connector.emitter.off('change', listener);
          connector.emitter.off('connect', listener);
          connector.emitter.off('disconnect', listener);
        };
      }
      return noop;
    }, [setAccount, connector, refreshConnection, isMounted, refreshAccount, refreshChainId, refresh]);
    useFirstRenderEffect(refresh);
    const context = useMemo<Web3EVMConnectorContextType | undefined>(
      () =>
        connector
          ? {
              connector,
              client,
              account,
              chainId,
              isLocal,
              isConnecting,
              isReady: !!available,
              disconnectable,
              refreshChainId,
              refreshAccount,
              refresh,
            }
          : undefined,
      [
        connector,
        client,
        account,
        chainId,
        isLocal,
        isConnecting,
        available,
        disconnectable,
        refreshChainId,
        refreshAccount,
        refresh,
      ],
    );

    return (
      <Web3ConnectorContext.Provider value={context}>
        <Component {...props} />
      </Web3ConnectorContext.Provider>
    );
  });

const withWeb3Connector = <
  Original extends NonNullable<unknown> = NonNullable<unknown>,
  Wrapper extends Original & Web3EVMConnectorProps = Original & Web3EVMConnectorProps,
>(
  Component: React.ComponentType<Original>,
) => {
  const CreationComponent = withWeb3ConnectorSelection(Component);
  return namedHOC<Original, Wrapper>(
    Component,
    'WithWeb3Connector',
  )((props) => {
    const connectorId = 'connectorId' in props ? props.connectorId : props.connector.id;
    const ctx = useContext(Web3ConnectorContext);
    const isCtxDefined = ctx?.connector.id === connectorId;
    return isCtxDefined ? <Component {...props} /> : <CreationComponent {...props} connectorId={connectorId} />;
  });
};

export default withWeb3Connector;
