import { useContext } from 'react';

import type { Web3EVMConnectorContextType } from '@/features/web3/hocs/withWeb3EVMConnector/context';
import Web3EVMConnectorContext from '@/features/web3/hocs/withWeb3EVMConnector/context';
import { someOrFail } from '@/infrastructure/utils/functions';

export default function useWeb3EVMConnector(): Web3EVMConnectorContextType {
  return someOrFail(
    useContext(Web3EVMConnectorContext),
    () =>
      new Error('No web3 connector is available. Please make sure you wrapped the Component with withWeb3EVMConnector'),
  );
}
