import { css, cx } from '@emotion/css';
import React, { useEffect } from 'react';

import { PageLoadingIcon } from './components';

import type { PageLoadingProps } from './types';

const PageLoading: React.FC<PageLoadingProps> = ({ type, className, ...props }) => {
  useEffect(() => {
    const timeout = setTimeout(() => console.warn(`Loading is stale for "${type}"`), 1000);
    return () => clearTimeout(timeout);
  }, [type]);
  return (
    <PageLoadingIcon
      className={cx(
        css`
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          min-height: 420px;
          height: 100%;
          margin-top: -28px;
        `,
        className,
      )}
      {...props}
    />
  );
};

const PageLoadingMemo = React.memo(PageLoading);

export default PageLoadingMemo;
