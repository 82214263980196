import { useMemo } from 'react';

import type { UseAppLoadableData } from '@/app/hooks';
import { useAsset, useBlockchain } from '@/features/dictionary/blockchain/hooks';
import { BlockchainAPITypeAPIModel, BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { mapStoredState } from '@/infrastructure/model';
import { withVoidOrThrow } from '@/infrastructure/utils/functions';
import { enumByKey } from '@/infrastructure/utils/ts';

import useUser from './useUser';

export default function useUserAddress(
  btOrAPIOrAssetId: BlockchainAPITypeAPIModel | BlockchainTypeAPIModel | string | undefined,
): UseAppLoadableData<{ value?: string }> {
  const maybeAPI = enumByKey(BlockchainAPITypeAPIModel, btOrAPIOrAssetId);
  const maybeBt = !maybeAPI ? enumByKey(BlockchainTypeAPIModel, btOrAPIOrAssetId) : undefined;
  const assets = useAsset(!maybeBt ? btOrAPIOrAssetId : undefined);
  const bt = maybeBt ?? assets.data.data?.blockchain;
  const bc = useBlockchain(bt);
  const bcApi = maybeAPI ?? bc.data.data?.apiType;
  const user = useUser();
  const data = useMemo(
    () =>
      mapStoredState(user.data, (userData) => ({
        value: userData.addresses.find(({ addressType }) => addressType === bcApi)?.address,
      })),
    [bcApi, user.data],
  );
  const loading = user.loading || bc.loading || assets.loading;
  const forceRefresh = useMemo(
    () =>
      withVoidOrThrow(() =>
        Promise.all([
          user.forceRefresh(),
          ...[!maybeBt ? [assets.forceRefresh()] : []],
          ...[user.data.data ? [bc.forceRefresh] : []],
        ]),
      ),
    [assets, bc.forceRefresh, maybeBt, user],
  );
  return { data, loading, forceRefresh };
}
