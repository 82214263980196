import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchMerchantWalletOwnership } from '@/features/merchant-wallets/actions';
import { makeSelectMerchantWalletOwnership } from '@/features/merchant-wallets/selectors';
import type { MerchantWalletSignature } from '@/features/merchant-wallets/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const dataSelector = makeSelectMerchantWalletOwnership();
const dataFetchingSelector = makeSelectPending(fetchMerchantWalletOwnership);
const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<MerchantWalletSignature>,
  Parameters<typeof fetchMerchantWalletOwnership>[0]
> => fetchMerchantWalletOwnership({ force });

export default function useMerchantWalletOwnership() {
  return useAppLoadableData(fetchFactory, dataSelector, dataFetchingSelector);
}
