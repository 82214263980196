import { Form } from 'antd';
import React, { useEffect, useMemo } from 'react';

import { CompanyNameItem, OperationUpdateCompanyName } from '@/features/company/components';
import { CompanyWebsiteItem, OperationUpdateCompanyWebsite } from '@/features/company-settings/components';
import { useForm, useSharedStyles } from '@/hooks';

import {
  CompanyEmailItem,
  SettlementScheduleItem,
  CollectScheduleItem,
  Web3AuthPrivateKeySecureItem,
} from './components';
import { withGeneralSettingsDataLoading } from './hocs';

import type { GeneralSettingsCardProps } from './types';

const GeneralSettingsCard: React.FC<GeneralSettingsCardProps> = ({
  data: { company, settings },
  'data-test': dataTest,
}) => {
  const { form } = useForm();
  useEffect(() => {
    setTimeout(() => form.resetFields(), 0);
    // to refresh form values on settings or company update
  }, [form, settings, company]);
  const initialValues = useMemo(
    () => ({ name: company.name, website: settings.website, asset: settings.statisticsAsset }),
    [company.name, settings.statisticsAsset, settings.website],
  );
  const { disabledTextInput } = useSharedStyles();
  return (
    <Form
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      initialValues={initialValues}
    >
      <CompanyNameItem
        name="name"
        data-test={dataTest && `${dataTest}-name`}
        readonly
        InputProps={{
          suffix: <OperationUpdateCompanyName data-test={dataTest && `${dataTest}-nameUpdate`} mode="icon" />,
          className: disabledTextInput,
        }}
      />
      <CompanyWebsiteItem
        name="website"
        data-test={dataTest && `${dataTest}-website`}
        readonly
        InputProps={{
          suffix: <OperationUpdateCompanyWebsite data-test={dataTest && `${dataTest}-websiteUpdate`} mode="icon" />,
          className: disabledTextInput,
        }}
      />
      <CompanyEmailItem data-test={dataTest && `${dataTest}-email`} className={disabledTextInput} />
      <CollectScheduleItem data-test={dataTest && `${dataTest}-collect`} className={disabledTextInput} />
      <SettlementScheduleItem data-test={dataTest && `${dataTest}-settlements`} className={disabledTextInput} />
      <Web3AuthPrivateKeySecureItem data-test={dataTest && `${dataTest}-privateKey`} className={disabledTextInput} />
    </Form>
  );
};

const GeneralSettingsCardLoaded = withGeneralSettingsDataLoading(GeneralSettingsCard);

const GeneralSettingsCardMemo = React.memo(GeneralSettingsCardLoaded) as typeof GeneralSettingsCardLoaded;

export default GeneralSettingsCardMemo;
