import { WalletOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, Operation } from '@/components';
import { useMerchantWallet, useMerchantWalletActions } from '@/features/merchant-wallets/hooks';
import { UserAddressIssueBanner } from '@/features/user/components';
import { I18nCommon, I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';
import { useFormVisible } from '@/hooks';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import OperationRefreshMerchantWalletState from '../OperationRefreshMerchantWalletState';

import { DeployMerchantWalletModal } from './components';

import type { OperationDeployMerchantWalletProps } from './types';

const OperationDeployMerchantWallet: React.FC<OperationDeployMerchantWalletProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  bt,
}) => {
  const formControl = useFormVisible(false);
  const { data: wallet, loading } = useMerchantWallet(bt);
  const { deploy: deployAction } = useMerchantWalletActions(bt, undefined);

  const disabledMessage = useMemo(() => {
    if (loading || wallet.isDirty) {
      return (
        <FormattedMessage
          id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_ERROR_UNKNOWN_STATUS}
          values={{
            op: (text: React.ReactNode) => (
              <OperationRefreshMerchantWalletState
                data-test={dataTest && `${dataTest}-opRefresh`}
                bt={bt}
                mode="link"
                title={text}
              />
            ),
          }}
        />
      );
    }
    switch (deployAction.unavailabilityReason) {
      case 'already-deployed':
        return (
          <FormattedMessage
            id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_ALREADY_DEPLOYED}
          />
        );
      case 'no-blockchain-data':
        return (
          <FormattedMessage
            id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_NO_BLOCKCHAIN_DATA}
          />
        );
      case 'no-wallet-signature':
        return (
          <FormattedMessage
            id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_NO_WALLET_SIGNATURE}
          />
        );
      case 'loading':
        return <FormattedMessage id={I18nCommon.MESSAGES_LOADING} />;
      case 'no-user-address':
        return <UserAddressIssueBanner bt={bt} mode="alert" type="link" />;
      case 'invalid-chain-id':
        return (
          <FormattedMessage
            id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_INVALID_CHAIN_ID}
          />
        );
      case 'unsupported-chain':
        return (
          <FormattedMessageWithMarkup
            id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_UNSUPPORTED}
            values={{ bt }}
          />
        );
      case undefined:
        return undefined;
    }
  }, [bt, dataTest, deployAction.unavailabilityReason, loading, wallet.isDirty]);

  const doShow = useCallback(() => {
    formControl.show();
    goalReached(YMGoals.MERCHANT_WALLET_MANUAL_DEPLOY_SHOWN);
  }, [formControl]);
  const doOnSuccess = useCallback(() => {
    formControl.hide();
    goalReached(YMGoals.MERCHANT_WALLET_MANUAL_DEPLOY_FINISHED);
  }, [formControl]);

  return (
    <>
      {formControl.visible && (
        <DeployMerchantWalletModal
          data-test={dataTest && `${dataTest}-dialog`}
          bt={bt}
          onSuccess={doOnSuccess}
          onCancel={formControl.hide}
        />
      )}
      <Operation
        title={title ?? <FormattedMessage id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_TITLE} />}
        tooltip={
          <FormattedMessage
            id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_TOOLTIP}
            values={{ bt }}
          />
        }
        disabled={!!disabledMessage}
        disabledMessage={disabledMessage}
        icon={<WalletOutlined />}
        inProgress={wallet.isDirty || loading}
        onClick={doShow}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationDeployMerchantWalletMemo = React.memo(OperationDeployMerchantWallet);

export default OperationDeployMerchantWalletMemo;
