import { Form, Input, Space } from 'antd';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage } from '@/components';
import { OperationDisconnectWeb3AuthWallet } from '@/features/auth/components';
import OperationSecureWeb3AuthPrivateKey from '@/features/auth/components/OperationSecureWeb3AuthPrivateKey';
import { withWeb3AuthState } from '@/features/auth/hocs';
import { withFeatureToggleGuard } from '@/features/feature-toggle/hocs';
import { usePreferences, usePreferencesActions } from '@/features/user/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';

import type { Web3AuthPrivateKeySecureItemProps } from './types';

const Web3AuthPrivateKeySecureItem: React.FC<Web3AuthPrivateKeySecureItemProps> = ({
  'data-test': dataTest,
  style,
  className,
}) => {
  const { data: preferences } = usePreferences();
  const { persist } = usePreferencesActions();
  const markExported = useCallback(() => persist.act({ privateKeySecured: true }), [persist]);
  const { formatMessage } = useIntl();
  return (
    <Form.Item
      data-test={dataTest}
      label={
        <FormattedMessage
          id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_PRIVATE_KEY_SECURE_ITEM_LABEL}
          tagName="span"
        />
      }
    >
      <Input
        style={style}
        className={className}
        disabled
        data-test={dataTest && `${dataTest}-input`}
        value={
          preferences.data?.privateKeySecured
            ? formatMessage({
                id: I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_PRIVATE_KEY_SECURE_ITEM_VALUE_SECURED,
              })
            : formatMessage({
                id: I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_PRIVATE_KEY_SECURE_ITEM_VALUE_UNSECURED,
              })
        }
        suffix={
          <Space>
            <OperationSecureWeb3AuthPrivateKey
              data-test={dataTest && `${dataTest}-opExport`}
              mode="icon"
              onSuccess={markExported}
            />
            <OperationDisconnectWeb3AuthWallet data-test={dataTest && `${dataTest}-opDisconnect`} mode="icon" />
          </Space>
        }
      />
    </Form.Item>
  );
};

const Web3AuthPrivateKeySecureItemGuarded = withFeatureToggleGuard('enableWeb3AuthKeyExport')(
  withWeb3AuthState((web3Auth) => web3Auth.isAuthorized())(Web3AuthPrivateKeySecureItem),
);

const Web3AuthPrivateKeySecureItemMemo = React.memo(Web3AuthPrivateKeySecureItemGuarded);

export default Web3AuthPrivateKeySecureItemMemo;
