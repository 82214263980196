/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SettlementIntentSortByAPIModel } from './SettlementIntentSortByAPIModel';
import {
    SettlementIntentSortByAPIModelFromJSON,
    SettlementIntentSortByAPIModelFromJSONTyped,
    SettlementIntentSortByAPIModelToJSON,
} from './SettlementIntentSortByAPIModel';

/**
 * 
 * @export
 * @interface SortAttributeSettlementIntentSortByAPIModel
 */
export interface SortAttributeSettlementIntentSortByAPIModel {
    /**
     * 
     * @type {SettlementIntentSortByAPIModel}
     * @memberof SortAttributeSettlementIntentSortByAPIModel
     */
    attr: SettlementIntentSortByAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof SortAttributeSettlementIntentSortByAPIModel
     */
    asc: boolean;
}

/**
 * Check if a given object implements the SortAttributeSettlementIntentSortByAPIModel interface.
 */
export function instanceOfSortAttributeSettlementIntentSortByAPIModel(value: object): boolean {
    if (!('attr' in value)) return false;
    if (!('asc' in value)) return false;
    return true;
}

export function SortAttributeSettlementIntentSortByAPIModelFromJSON(json: any): SortAttributeSettlementIntentSortByAPIModel {
    return SortAttributeSettlementIntentSortByAPIModelFromJSONTyped(json, false);
}

export function SortAttributeSettlementIntentSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortAttributeSettlementIntentSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'attr': SettlementIntentSortByAPIModelFromJSON(json['attr']),
        'asc': json['asc'],
    };
}

export function SortAttributeSettlementIntentSortByAPIModelToJSON(value?: SortAttributeSettlementIntentSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attr': SettlementIntentSortByAPIModelToJSON(value['attr']),
        'asc': value['asc'],
    };
}

