import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { deleteAPIKey, updateAPIKeyState } from '@/features/company-settings/actions';
import type { APIKey, APIKeyId } from '@/features/company-settings/types';
import { useActionPending } from '@/features/global/hooks';
import type { HookAction, LoadingStateWithDirty } from '@/infrastructure/model';

import useAPIKey from '../useAPIKey';

export type APIKeyActionUnavailability = 'no-data';

export interface UseAPIKeyActions {
  activateAPIKey: HookAction<[], APIKey, APIKeyActionUnavailability>;
  deactivateAPIKey: HookAction<[], APIKey, APIKeyActionUnavailability>;
  deleteAPIKey: HookAction<[], void, APIKeyActionUnavailability>;
}

export default function useAPIKeyActions(key: APIKeyId): UseAPIKeyActions {
  const { withExtractDataDispatch, withVoidDispatch } = useAppDispatch();
  const { data } = useAPIKey(key);

  const unavailabilityReason: APIKeyActionUnavailability | undefined = !data.data ? 'no-data' : undefined;

  const updateAPIKeyStatePending = useActionPending(updateAPIKeyState);

  const activateAPIKeyAction = useCallback(
    () => withExtractDataDispatch(updateAPIKeyState)({ key, active: true }),
    [key, withExtractDataDispatch],
  );
  const activateAPIKey = useMemo(
    () => ({
      act: activateAPIKeyAction,
      available: !unavailabilityReason,
      inAction: updateAPIKeyStatePending,
      unavailabilityReason,
    }),
    [activateAPIKeyAction, unavailabilityReason, updateAPIKeyStatePending],
  );
  const deactivateAPIKeyAction = useCallback(
    () => withExtractDataDispatch(updateAPIKeyState)({ key, active: false }),
    [key, withExtractDataDispatch],
  );
  const deactivateAPIKey = useMemo(
    () => ({
      act: deactivateAPIKeyAction,
      available: !unavailabilityReason,
      inAction: updateAPIKeyStatePending,
      unavailabilityReason,
    }),
    [deactivateAPIKeyAction, unavailabilityReason, updateAPIKeyStatePending],
  );

  const deleteAPIKeyPending = useActionPending(deleteAPIKey);
  const deleteAPIKeyAction = useCallback(
    () => withVoidDispatch<APIKeyId, LoadingStateWithDirty<APIKey[]>>(deleteAPIKey)(key),
    [key, withVoidDispatch],
  );
  const deleteAPIKeyHook = useMemo(
    () => ({
      act: deleteAPIKeyAction,
      available: !unavailabilityReason,
      inAction: deleteAPIKeyPending,
      unavailabilityReason,
    }),
    [deleteAPIKeyAction, deleteAPIKeyPending, unavailabilityReason],
  );

  return {
    activateAPIKey,
    deactivateAPIKey,
    deleteAPIKey: deleteAPIKeyHook,
  };
}
