import { MailOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Input, Space, Tooltip } from 'antd';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage, KYBMailTo } from '@/components';
import { KYBRequiredBanner } from '@/features/company/components';
import type { KYTToleranceLevelAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageSettings } from '@/generated/i18n/i18n';

import type { CompanyKYBStateItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const CompanyKYBStateItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  style,
  className,
  companyId,
}: CompanyKYBStateItemProps<Values>) => {
  const isKYBFinished = Form.useWatch<boolean | undefined>(name);
  const { formatMessage } = useIntl();
  return (
    <Form.Item<Values>
      name={name}
      label={
        <Space>
          <FormattedMessage
            id={I18nPageSettings.COMPLIANCE_COMPONENTS_GENERAL_CARD_KYB_STATE_ITEM_LABEL}
            tagName="span"
          />
          {!isKYBFinished && (
            <Tooltip title={<KYBRequiredBanner hideable={false} />} showArrow={false} styles={{ body: { width: 600 } }}>
              <QuestionCircleOutlined />
            </Tooltip>
          )}
        </Space>
      }
      getValueProps={useCallback(
        (isKYBFinished?: KYTToleranceLevelAPIModel) => ({
          value: formatMessage({
            id: isKYBFinished
              ? I18nPageSettings.COMPLIANCE_COMPONENTS_GENERAL_CARD_KYB_STATE_ITEM_VALUE_FINISHED
              : I18nPageSettings.COMPLIANCE_COMPONENTS_GENERAL_CARD_KYB_STATE_ITEM_VALUE_UNFINISHED,
          }),
        }),
        [formatMessage],
      )}
    >
      <Input
        data-test={dataTest}
        style={style}
        className={className}
        disabled
        suffix={!isKYBFinished ? <KYBMailTo companyId={companyId} title={<MailOutlined />} /> : undefined}
      />
    </Form.Item>
  );
};

const CompanyKYBStateItemMemo = React.memo(CompanyKYBStateItem) as typeof CompanyKYBStateItem;

export default CompanyKYBStateItemMemo;
