import React, { useMemo } from 'react';

import { withWeb3EVMConnector } from '@/features/web3/hocs';
import { useWeb3EVMConnector } from '@/features/web3/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import type { EVMOperationContextedProps } from './types';

const EVMOperationContexted = <
  Success = unknown,
  Account = string,
  BT extends BlockchainTypeAPIModel | undefined = undefined,
  ExtraProps = undefined,
>({
  Operation,
  withInProgress: baseWithInProgress,
  cancellation: baseCancellation,
  connectorId,
  ...props
}: EVMOperationContextedProps<Success, Account, BT, ExtraProps>) => {
  const withInProgress = useMemo(() => baseWithInProgress?.(connectorId), [baseWithInProgress, connectorId]);
  const cancellation = useMemo(() => baseCancellation?.(connectorId), [baseCancellation, connectorId]);
  const { connector } = useWeb3EVMConnector();

  return <Operation {...props} withInProgress={withInProgress} cancellation={cancellation} connector={connector} />;
};

const EVMOperationContextedHOC = withWeb3EVMConnector(EVMOperationContexted);

const EVMOperationContextedMemo = React.memo(EVMOperationContextedHOC) as typeof EVMOperationContexted;

export default EVMOperationContextedMemo;
