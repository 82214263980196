import React, { useCallback, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useAddressSignedUp } from '@/features/auth/hooks';
import type { Web3EVMWalletOperationClientAction, Web3EVMWalletOperationProps } from '@/features/web3/components';
import { Web3EVMWalletOperation } from '@/features/web3/components';
import { withWeb3EVMConnector } from '@/features/web3/hocs';
import { useWeb3EVMConnector, useWeb3WalletExtensionInstallMetrics } from '@/features/web3/hooks';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { emptyWith } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';

import type { Web3WalletEVMLoginProps } from './types';

const InProgressDescription: React.FC<{ account?: string; provider: string }> = ({ provider, account }) => (
  <FormattedMessage
    id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_DESCRIPTION}
    values={{ provider: <b>{provider}</b>, account: account && <b>{account}</b> }}
  />
);
const inProgressMessages = { Description: InProgressDescription };

const notificationOptions = {
  error: (error: unknown) =>
    (error as Partial<Error | undefined>)?.message?.includes('wallet_requestPermissions')
      ? {
          message: <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_ERROR_CONNECT_IN_PROGRESS_TITLE} />,
          description: (
            <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_ERROR_CONNECT_IN_PROGRESS_DESCRIPTION} />
          ),
        }
      : undefined,
};

const showAccount = { tooltip: <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_ACCOUNT_TOOLTIP} /> };

const Web3WalletEVMLogin: React.FC<Web3WalletEVMLoginProps> = ({
  'data-test': dataTest,
  onSuccess,
  extra: { login },
  inProgress: baseInProgress,
  inProgressMode,
  withInProgress,
  cancellation,
}) => {
  const { account, connector } = useWeb3EVMConnector();
  useWeb3WalletExtensionInstallMetrics(connector.id);
  const { data: isNewAccount, loading: accountLoading } = useAddressSignedUp(BlockchainAPITypeAPIModel.EVM, account);
  const { withNotification } = useNotification();

  const doLogin = useCallback<Web3EVMWalletOperationClientAction<void>>(
    async ({ account, client }, cancellation) => {
      if (account)
        await (withInProgress ?? emptyWith)(login)(
          { connectorId: connector.id, address: account, client },
          cancellation,
        );
    },
    [connector.id, login, withInProgress],
  );
  const withConnect = useMemo(
    () =>
      <V extends unknown[], R>(func: Func<V, R>): Func<V> =>
        (withInProgress ?? emptyWith)(withNotification(func, notificationOptions)),
    [withInProgress, withNotification],
  );

  return (
    <Web3EVMWalletOperation
      data-test={dataTest}
      onSuccess={onSuccess}
      connector={connector}
      showAccount={showAccount}
      withConnect={withConnect}
      inProgress={accountLoading || isNewAccount.isDirty || baseInProgress}
      inProgressMode={inProgressMode === 'view' ? inProgressMessages : inProgressMode}
      cancellation={cancellation}
      mainAction={useMemo<Web3EVMWalletOperationProps['mainAction']>(
        () => ({
          withClient: true,
          onAction: doLogin,
          runOnConnect: true,
          title: !isNewAccount.data ? (
            <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_SIGNUP_TITLE} />
          ) : (
            <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_LOGIN_TITLE} />
          ),
          'data-test': dataTest && `${dataTest}-${!isNewAccount.data ? 'signup' : 'login'}`,
        }),
        [dataTest, doLogin, isNewAccount.data],
      )}
    />
  );
};

const Web3WalletEVMLoginHOC = withWeb3EVMConnector(Web3WalletEVMLogin);

const Web3WalletEVMLoginMemo = React.memo(Web3WalletEVMLoginHOC) as typeof Web3WalletEVMLoginHOC;

export default Web3WalletEVMLoginMemo;
