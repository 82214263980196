import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getAddress } from 'viem';

import { useAppDispatch } from '@/app/hooks';
import { useOwnedCompany } from '@/features/company/hooks';
import { parseEVMSignature } from '@/features/dictionary/blockchain/utils';
import { addUserAddress } from '@/features/user/actions';
import type { User } from '@/features/user/types';
import type { SignMessageFn } from '@/features/web3/types';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';
import { useSubmitting } from '@/hooks';
import type { HookAction } from '@/infrastructure/model';
import { someOrFail } from '@/infrastructure/utils/functions';
import type { Cancellable } from '@/infrastructure/utils/ui';
import { runCancellable } from '@/infrastructure/utils/ui';

import useUser from './useUser';

type AddUserAddressUnavailabilityReason = 'no-data';

export interface UseUserActions {
  addAddress: HookAction<
    [
      { signMessage: SignMessageFn; address: string; addressType: BlockchainAPITypeAPIModel },
      { cancellation?: Cancellable },
    ],
    User,
    AddUserAddressUnavailabilityReason
  >;
}

export default function useUserActions(): UseUserActions {
  const { formatMessage } = useIntl();
  const { withExtractDataDispatch } = useAppDispatch();
  const { data } = useUser();
  const ownedCompany = useOwnedCompany();

  const addAddressUnavailabilityReason = !data.data ? 'no-data' : undefined;
  const [isAdding, withAdding] = useSubmitting(false);
  const addAddressAction: UseUserActions['addAddress']['act'] = useMemo(
    () =>
      withAdding(async ({ signMessage, address, addressType }, { cancellation } = {}) => {
        const partnerAddress = someOrFail(ownedCompany.data.data, () => new Error('no-owned-company')).partnerAddress;
        switch (addressType) {
          case BlockchainAPITypeAPIModel.Tron:
          case BlockchainAPITypeAPIModel.BTC:
            throw new Error('FeatureNotSupported');
          case BlockchainAPITypeAPIModel.EVM:
          case BlockchainAPITypeAPIModel.Solana:
            break;
        }
        const messageToSign = partnerAddress
          ? formatMessage(
              { id: I18nFeatureMerchantWallets.HOOKS_ACTIONS_CONFIRM_OWNERSHIP_MESSAGE_WITH_BROKER },
              {
                partner: addressType === BlockchainAPITypeAPIModel.EVM ? partnerAddress.toLowerCase() : partnerAddress,
              },
            )
          : formatMessage(
              { id: I18nFeatureMerchantWallets.HOOKS_ACTIONS_CONFIRM_OWNERSHIP_MESSAGE_NO_BROKER },
              { address: addressType === BlockchainAPITypeAPIModel.EVM ? address.toLowerCase() : address },
            );
        const { signature, message } = await (cancellation
          ? runCancellable(signMessage(messageToSign), cancellation)
          : signMessage(messageToSign));

        return withExtractDataDispatch(addUserAddress)(
          addressType === BlockchainAPITypeAPIModel.EVM
            ? {
                address: getAddress(address),
                addressType: BlockchainAPITypeAPIModel.EVM,
                message,
                signature: parseEVMSignature(signature),
                partner: partnerAddress,
              }
            : { address, addressType: BlockchainAPITypeAPIModel.Solana, message, signature, partner: partnerAddress },
        );
      }),
    [formatMessage, ownedCompany.data.data, withAdding, withExtractDataDispatch],
  );

  const addAddressHook: UseUserActions['addAddress'] = {
    act: addAddressAction,
    inAction: isAdding,
    unavailabilityReason: addAddressUnavailabilityReason,
    available: !addAddressUnavailabilityReason,
  };

  return { addAddress: addAddressHook };
}
