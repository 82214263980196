/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { ContractDeployStatusAPIModel } from './ContractDeployStatusAPIModel';
import {
    ContractDeployStatusAPIModelFromJSON,
    ContractDeployStatusAPIModelFromJSONTyped,
    ContractDeployStatusAPIModelToJSON,
} from './ContractDeployStatusAPIModel';

/**
 * 
 * @export
 * @interface MerchantWalletDeployTransactionAPIModel
 */
export interface MerchantWalletDeployTransactionAPIModel {
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletDeployTransactionAPIModel
     */
    address: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof MerchantWalletDeployTransactionAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletDeployTransactionAPIModel
     */
    owner: string;
    /**
     * 
     * @type {ContractDeployStatusAPIModel}
     * @memberof MerchantWalletDeployTransactionAPIModel
     */
    status: ContractDeployStatusAPIModel;
    /**
     * 
     * @type {Date}
     * @memberof MerchantWalletDeployTransactionAPIModel
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletDeployTransactionAPIModel
     */
    transactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletDeployTransactionAPIModel
     */
    hash?: string;
}

/**
 * Check if a given object implements the MerchantWalletDeployTransactionAPIModel interface.
 */
export function instanceOfMerchantWalletDeployTransactionAPIModel(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('owner' in value)) return false;
    if (!('status' in value)) return false;
    if (!('updatedAt' in value)) return false;
    return true;
}

export function MerchantWalletDeployTransactionAPIModelFromJSON(json: any): MerchantWalletDeployTransactionAPIModel {
    return MerchantWalletDeployTransactionAPIModelFromJSONTyped(json, false);
}

export function MerchantWalletDeployTransactionAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantWalletDeployTransactionAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'owner': json['owner'],
        'status': ContractDeployStatusAPIModelFromJSON(json['status']),
        'updatedAt': (new Date(json['updatedAt'])),
        'transactionId': json['transactionId'] == null ? undefined : json['transactionId'],
        'hash': json['hash'] == null ? undefined : json['hash'],
    };
}

export function MerchantWalletDeployTransactionAPIModelToJSON(value?: MerchantWalletDeployTransactionAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'owner': value['owner'],
        'status': ContractDeployStatusAPIModelToJSON(value['status']),
        'updatedAt': ((value['updatedAt']).toISOString()),
        'transactionId': value['transactionId'],
        'hash': value['hash'],
    };
}

