/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { ReportScheduleStatusAPIModel } from './ReportScheduleStatusAPIModel';
import {
    ReportScheduleStatusAPIModelFromJSON,
    ReportScheduleStatusAPIModelFromJSONTyped,
    ReportScheduleStatusAPIModelToJSON,
} from './ReportScheduleStatusAPIModel';

/**
 * 
 * @export
 * @interface ReportScheduleAPIModel
 */
export interface ReportScheduleAPIModel {
    /**
     * 
     * @type {string}
     * @memberof ReportScheduleAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReportScheduleAPIModel
     */
    template: string;
    /**
     * 
     * @type {string}
     * @memberof ReportScheduleAPIModel
     */
    cron: string;
    /**
     * 
     * @type {ReportScheduleStatusAPIModel}
     * @memberof ReportScheduleAPIModel
     */
    status: ReportScheduleStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof ReportScheduleAPIModel
     */
    disableReason?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReportScheduleAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ReportScheduleAPIModel
     */
    createdBy?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportScheduleAPIModel
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportScheduleAPIModel
     */
    partnerId?: string;
    /**
     * 
     * @type {any}
     * @memberof ReportScheduleAPIModel
     */
    parameters?: any;
    /**
     * 
     * @type {Date}
     * @memberof ReportScheduleAPIModel
     */
    nextGenerationAt?: Date;
}

/**
 * Check if a given object implements the ReportScheduleAPIModel interface.
 */
export function instanceOfReportScheduleAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('template' in value)) return false;
    if (!('cron' in value)) return false;
    if (!('status' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function ReportScheduleAPIModelFromJSON(json: any): ReportScheduleAPIModel {
    return ReportScheduleAPIModelFromJSONTyped(json, false);
}

export function ReportScheduleAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportScheduleAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'template': json['template'],
        'cron': json['cron'],
        'status': ReportScheduleStatusAPIModelFromJSON(json['status']),
        'disableReason': json['disableReason'] == null ? undefined : json['disableReason'],
        'createdAt': (new Date(json['createdAt'])),
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'partnerId': json['partnerId'] == null ? undefined : json['partnerId'],
        'parameters': json['parameters'] == null ? undefined : json['parameters'],
        'nextGenerationAt': json['nextGenerationAt'] == null ? undefined : (new Date(json['nextGenerationAt'])),
    };
}

export function ReportScheduleAPIModelToJSON(value?: ReportScheduleAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'template': value['template'],
        'cron': value['cron'],
        'status': ReportScheduleStatusAPIModelToJSON(value['status']),
        'disableReason': value['disableReason'],
        'createdAt': ((value['createdAt']).toISOString()),
        'createdBy': value['createdBy'],
        'companyId': value['companyId'],
        'partnerId': value['partnerId'],
        'parameters': value['parameters'],
        'nextGenerationAt': value['nextGenerationAt'] == null ? undefined : ((value['nextGenerationAt']).toISOString()),
    };
}

