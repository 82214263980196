/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum SettlementIntentStatusAPIModel {
    Scheduled = 'Scheduled',
    Pending = 'Pending',
    PartlySucceeded = 'PartlySucceeded',
    Succeeded = 'Succeeded',
    Failed = 'Failed'
}


export function SettlementIntentStatusAPIModelFromJSON(json: any): SettlementIntentStatusAPIModel {
    return SettlementIntentStatusAPIModelFromJSONTyped(json, false);
}

export function SettlementIntentStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementIntentStatusAPIModel {
    return json as SettlementIntentStatusAPIModel;
}

export function SettlementIntentStatusAPIModelToJSON(value?: SettlementIntentStatusAPIModel | null): any {
    return value as any;
}

