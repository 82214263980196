/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  CompanyUserAPIModel,
  CompanyUserFilterAPIModel,
  NewCompanyUserAPIModel,
  SliceCompanyUserCompanyUserSortByAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    CompanyUserAPIModelFromJSON,
    CompanyUserAPIModelToJSON,
    CompanyUserFilterAPIModelFromJSON,
    CompanyUserFilterAPIModelToJSON,
    NewCompanyUserAPIModelFromJSON,
    NewCompanyUserAPIModelToJSON,
    SliceCompanyUserCompanyUserSortByAPIModelFromJSON,
    SliceCompanyUserCompanyUserSortByAPIModelToJSON,
} from '../models/index';

export interface CreateCompanyUserRequest {
    newCompanyUserAPIModel: NewCompanyUserAPIModel;
}

export interface GetCompanyUsersRequest {
    companyUserFilterAPIModel: CompanyUserFilterAPIModel;
}

export interface RemoveCompanyUserRequest {
    id: string;
}

/**
 * CompanyUserApi - interface
 * 
 * @export
 * @interface CompanyUserApiInterface
 */
export interface CompanyUserApiInterface {
    /**
     * Creates new user and/or links it to the company
     * @param {NewCompanyUserAPIModel} newCompanyUserAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyUserApiInterface
     */
    createCompanyUserRaw(requestParameters: CreateCompanyUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyUserAPIModel>>;

    /**
     * Creates new user and/or links it to the company
     */
    createCompanyUser(newCompanyUserAPIModel: NewCompanyUserAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyUserAPIModel>;

    /**
     * Returns all company users
     * @param {CompanyUserFilterAPIModel} companyUserFilterAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyUserApiInterface
     */
    getCompanyUsersRaw(requestParameters: GetCompanyUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceCompanyUserCompanyUserSortByAPIModel>>;

    /**
     * Returns all company users
     */
    getCompanyUsers(companyUserFilterAPIModel: CompanyUserFilterAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceCompanyUserCompanyUserSortByAPIModel>;

    /**
     * Removes user from the company
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyUserApiInterface
     */
    removeCompanyUserRaw(requestParameters: RemoveCompanyUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Removes user from the company
     */
    removeCompanyUser(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class CompanyUserApi extends runtime.BaseAPI implements CompanyUserApiInterface {

    /**
     * Creates new user and/or links it to the company
     */
    async createCompanyUserRaw(requestParameters: CreateCompanyUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyUserAPIModel>> {
        if (requestParameters['newCompanyUserAPIModel'] == null) {
            throw new runtime.RequiredError(
                'newCompanyUserAPIModel',
                'Required parameter "newCompanyUserAPIModel" was null or undefined when calling createCompanyUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewCompanyUserAPIModelToJSON(requestParameters['newCompanyUserAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUserAPIModelFromJSON(jsonValue));
    }

    /**
     * Creates new user and/or links it to the company
     */
    async createCompanyUser(newCompanyUserAPIModel: NewCompanyUserAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyUserAPIModel> {
        const response = await this.createCompanyUserRaw({ newCompanyUserAPIModel: newCompanyUserAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Returns all company users
     */
    async getCompanyUsersRaw(requestParameters: GetCompanyUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceCompanyUserCompanyUserSortByAPIModel>> {
        if (requestParameters['companyUserFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'companyUserFilterAPIModel',
                'Required parameter "companyUserFilterAPIModel" was null or undefined when calling getCompanyUsers().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/users/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyUserFilterAPIModelToJSON(requestParameters['companyUserFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceCompanyUserCompanyUserSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns all company users
     */
    async getCompanyUsers(companyUserFilterAPIModel: CompanyUserFilterAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceCompanyUserCompanyUserSortByAPIModel> {
        const response = await this.getCompanyUsersRaw({ companyUserFilterAPIModel: companyUserFilterAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Removes user from the company
     */
    async removeCompanyUserRaw(requestParameters: RemoveCompanyUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling removeCompanyUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes user from the company
     */
    async removeCompanyUser(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeCompanyUserRaw({ id: id }, initOverrides);
    }

}
