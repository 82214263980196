/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { SettlementIntentTransactionStatusAPIModel } from './SettlementIntentTransactionStatusAPIModel';
import {
    SettlementIntentTransactionStatusAPIModelFromJSON,
    SettlementIntentTransactionStatusAPIModelFromJSONTyped,
    SettlementIntentTransactionStatusAPIModelToJSON,
} from './SettlementIntentTransactionStatusAPIModel';

/**
 * 
 * @export
 * @interface SettlementIntentTransactionPredicatesAPIModel
 */
export interface SettlementIntentTransactionPredicatesAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof SettlementIntentTransactionPredicatesAPIModel
     */
    idIn?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettlementIntentTransactionPredicatesAPIModel
     */
    intentIdIn?: Array<string>;
    /**
     * 
     * @type {Array<BlockchainTypeAPIModel>}
     * @memberof SettlementIntentTransactionPredicatesAPIModel
     */
    btIn?: Array<BlockchainTypeAPIModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettlementIntentTransactionPredicatesAPIModel
     */
    assetIn?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettlementIntentTransactionPredicatesAPIModel
     */
    settlementIdIn?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionPredicatesAPIModel
     */
    walletLike?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionPredicatesAPIModel
     */
    destinationLike?: string;
    /**
     * 
     * @type {Array<SettlementIntentTransactionStatusAPIModel>}
     * @memberof SettlementIntentTransactionPredicatesAPIModel
     */
    statusIn?: Array<SettlementIntentTransactionStatusAPIModel>;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof SettlementIntentTransactionPredicatesAPIModel
     */
    networkEq?: BlockchainNetworkTypeAPIModel;
}

/**
 * Check if a given object implements the SettlementIntentTransactionPredicatesAPIModel interface.
 */
export function instanceOfSettlementIntentTransactionPredicatesAPIModel(value: object): boolean {
    return true;
}

export function SettlementIntentTransactionPredicatesAPIModelFromJSON(json: any): SettlementIntentTransactionPredicatesAPIModel {
    return SettlementIntentTransactionPredicatesAPIModelFromJSONTyped(json, false);
}

export function SettlementIntentTransactionPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementIntentTransactionPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'idIn': json['idIn'] == null ? undefined : json['idIn'],
        'intentIdIn': json['intentIdIn'] == null ? undefined : json['intentIdIn'],
        'btIn': json['btIn'] == null ? undefined : ((json['btIn'] as Array<any>).map(BlockchainTypeAPIModelFromJSON)),
        'assetIn': json['assetIn'] == null ? undefined : json['assetIn'],
        'settlementIdIn': json['settlementIdIn'] == null ? undefined : json['settlementIdIn'],
        'walletLike': json['walletLike'] == null ? undefined : json['walletLike'],
        'destinationLike': json['destinationLike'] == null ? undefined : json['destinationLike'],
        'statusIn': json['statusIn'] == null ? undefined : ((json['statusIn'] as Array<any>).map(SettlementIntentTransactionStatusAPIModelFromJSON)),
        'networkEq': json['networkEq'] == null ? undefined : BlockchainNetworkTypeAPIModelFromJSON(json['networkEq']),
    };
}

export function SettlementIntentTransactionPredicatesAPIModelToJSON(value?: SettlementIntentTransactionPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idIn': value['idIn'],
        'intentIdIn': value['intentIdIn'],
        'btIn': value['btIn'] == null ? undefined : ((value['btIn'] as Array<any>).map(BlockchainTypeAPIModelToJSON)),
        'assetIn': value['assetIn'],
        'settlementIdIn': value['settlementIdIn'],
        'walletLike': value['walletLike'],
        'destinationLike': value['destinationLike'],
        'statusIn': value['statusIn'] == null ? undefined : ((value['statusIn'] as Array<any>).map(SettlementIntentTransactionStatusAPIModelToJSON)),
        'networkEq': BlockchainNetworkTypeAPIModelToJSON(value['networkEq']),
    };
}

