import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import listenerMiddleware from '@/app/listenerMiddleware';
import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { createNotification } from '@/features/global/actions';
import type { CollectableAddressLinkAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { CollectTaskStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';
import { createNestedNormalizedListReducers, createNormalizedListReducers } from '@/infrastructure/model/list/reducers';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';
import { onlyUnique, suppressPromise } from '@/infrastructure/utils/functions';
import { notEmpty } from '@/infrastructure/utils/ts';
import { collectTaskViewLink } from '@/pages/collectable/routes';

import {
  markCollectTaskDirty,
  markCollectScheduleDirty,
  storeMultipleCollectTaskSummary,
  storeCollectTask,
  storeCollectSchedule,
  markCollectTaskSummaryDirty,
  storeCollectTaskSummary,
  storeCollectTaskSummaryListData,
  markCollectTaskSummaryListDirty,
  markCollectableTransactionDirty,
  storeCollectableTransaction,
  storeCollectEntityProcessTransaction,
  markCollectEntityProcessTransactionDirty,
  storeMultipleCollectableTransaction,
  storeCollectTaskSummaryListParameters,
  storeCollectTasksForAddressesListData,
  markCollectTasksForAddressesListDirty,
  storeCollectTasksForAddressesListParameters,
  markCollectThresholdDirty,
  storeCollectThreshold,
  storeCollectLockedBalanceListData,
  markCollectLockedBalanceListDirty,
  storeCollectLockedBalanceListParameters,
  storeCollectAvailableBalanceListParameters,
  markCollectAvailableBalanceListDirty,
  storeCollectAvailableBalanceListData,
  markCollectAvailableBalanceDirty,
  storeCollectAvailableBalance,
  storeMultipleCollectAvailableBalance,
  markCollectLockedBalanceDirty,
  storeCollectLockedBalance,
  storeMultipleCollectLockedBalance,
  storeCollectableBalance,
  markCollectableBalanceDirty,
  storePendingCollectTasksRefreshableAfter,
  markPendingCollectTasksInitialized,
  markMultipleCollectTaskDirty,
  markCollectTasksDependentDataDirty,
  markMultipleCollectTaskSummaryDirty,
} from './actions';
import { makeSelectMultipleCollectTaskSummary, makeSelectPendingCollectTaskSummaries } from './selectors';
import {
  type CollectableState,
  defaultBalanceListState,
  defaultCollectTaskForAddressListState,
  defaultCollectTaskListState,
} from './types';
import {
  collectableTaskLinksToId,
  createCollectableBalanceKey,
  extractCollectableBalanceId,
  extractCollectTaskId,
} from './utils';

import type { Draft } from 'immer';

const initialState: CollectableState = {
  schedule: storedDirtyData,
  gwtTransactions: {},
  transactions: {},
  threshold: storedDirtyData,
  balances: {
    entities: {},
    available: defaultBalanceListState,
    locked: defaultBalanceListState,
  },
  tasks: {
    columnState: {},
    list: defaultCollectTaskListState,
    byAddresses: {},
    summaries: {},
    pendingRefreshableAfter: new Date(),
    initialized: false,
    entities: {},
  },
};

const {
  storeCollectableTransactionReducer,
  markCollectableTransactionDirtyReducer,
  storeMultipleCollectableTransactionReducer,
} = createSingleReducers(
  'CollectableTransaction',
  (state: Draft<CollectableState>) => state.transactions,
  (global, transactions) => ({ ...global, transactions }),
);

const { storeCollectEntityProcessTransactionReducer, markCollectEntityProcessTransactionDirtyReducer } =
  createSingleReducers(
    'CollectEntityProcessTransaction',
    (state: Draft<CollectableState>) => state.gwtTransactions,
    (global, gwtTransactions) => ({ ...global, gwtTransactions }),
  );

const { storeCollectThresholdReducer, markCollectThresholdDirtyReducer } = createLoadingDataReducers(
  'CollectThreshold',
  (state: Draft<CollectableState>) => state.threshold,
  (state, threshold) => ({ ...state, threshold }),
);

const { storeCollectTaskReducer, markCollectTaskDirtyReducer, markMultipleCollectTaskDirtyReducer } =
  createSingleReducers(
    'CollectTask',
    (state: Draft<CollectableState>) => state.tasks.entities,
    (global, entities) => ({ ...global, tasks: { ...global.tasks, entities } }),
  );

const {
  storeCollectTaskSummaryReducer,
  storeMultipleCollectTaskSummaryReducer,
  storeCollectTaskSummaryListParametersReducer,
  markCollectTaskSummaryDirtyReducer,
  markMultipleCollectTaskSummaryDirtyReducer,
  storeCollectTaskSummaryListDataReducer,
  markCollectTaskSummaryListDirtyReducer,
} = createNormalizedListReducers(
  'CollectTaskSummary',
  (state: Draft<CollectableState>) => ({ ...state.tasks.list, columnState: state.tasks.columnState }),
  (global, list) => ({ ...global, tasks: { ...global.tasks, list } }),
  defaultCollectTaskListState,
  (state: Draft<CollectableState>) => state.tasks.summaries,
  (global, summaries) => ({ ...global, tasks: { ...global.tasks, summaries } }),
  extractCollectTaskId,
);

const {
  storeCollectTasksForAddressesListDataReducer,
  markCollectTasksForAddressesListDirtyReducer,
  storeCollectTasksForAddressesListParametersReducer,
} = createNestedNormalizedListReducers(
  'CollectTasksForAddresses',
  (state: Draft<CollectableState>, addresses: CollectableAddressLinkAPIModel[] | undefined) =>
    addresses ? state.tasks.byAddresses[collectableTaskLinksToId(addresses)] : undefined,
  (state, addresses, newListState) => ({
    ...state,
    tasks: {
      ...state.tasks,
      byAddresses: { ...state.tasks.byAddresses, [collectableTaskLinksToId(addresses)]: newListState },
    },
  }),
  (state) => state.tasks.columnState,
  (state, columnState) => ({ ...state, tasks: { ...state.tasks, columnState } }),
  defaultCollectTaskForAddressListState,
  extractCollectTaskId,
);

const { storeCollectScheduleReducer, markCollectScheduleDirtyReducer } = createLoadingDataReducers(
  'CollectSchedule',
  (state: Draft<CollectableState>) => state.schedule,
  (global, schedule) => ({ ...global, schedule }),
);

const {
  storeCollectAvailableBalanceReducer,
  storeMultipleCollectAvailableBalanceReducer,
  storeCollectAvailableBalanceListParametersReducer,
  markCollectAvailableBalanceDirtyReducer,
  storeCollectAvailableBalanceListDataReducer,
  markCollectAvailableBalanceListDirtyReducer,
} = createNormalizedListReducers(
  'CollectAvailableBalance',
  (state: Draft<CollectableState>) => state.balances.available,
  (global, available) => ({ ...global, balances: { ...global.balances, available } }),
  defaultBalanceListState,
  (state: Draft<CollectableState>) => state.balances.entities,
  (global, entities) => ({ ...global, balances: { ...global.balances, entities } }),
  extractCollectableBalanceId,
  createCollectableBalanceKey,
);

const {
  storeCollectLockedBalanceReducer,
  storeMultipleCollectLockedBalanceReducer,
  storeCollectLockedBalanceListParametersReducer,
  markCollectLockedBalanceDirtyReducer,
  storeCollectLockedBalanceListDataReducer,
  markCollectLockedBalanceListDirtyReducer,
} = createNormalizedListReducers(
  'CollectLockedBalance',
  (state: Draft<CollectableState>) => state.balances.locked,
  (global, locked) => ({ ...global, balances: { ...global.balances, locked } }),
  defaultBalanceListState,
  (state: Draft<CollectableState>) => state.balances.entities,
  (global, entities) => ({ ...global, balances: { ...global.balances, entities } }),
  extractCollectableBalanceId,
  createCollectableBalanceKey,
);

const { storeCollectableBalanceReducer, markCollectableBalanceDirtyReducer } = createSingleReducers(
  'CollectableBalance',
  (state: Draft<CollectableState>) => state.balances.entities,
  (global, entities) => ({
    ...global,
    balances: { ...global.balances, entities },
  }),
  createCollectableBalanceKey,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markCollectTaskDirty, markCollectTaskDirtyReducer)
    .addCase(markMultipleCollectTaskDirty, markMultipleCollectTaskDirtyReducer)
    .addCase(storeCollectTask, storeCollectTaskReducer)

    .addCase(markCollectTaskSummaryDirty, markCollectTaskSummaryDirtyReducer)
    .addCase(markMultipleCollectTaskSummaryDirty, markMultipleCollectTaskSummaryDirtyReducer)
    .addCase(storeCollectTaskSummary, storeCollectTaskSummaryReducer)
    .addCase(storeMultipleCollectTaskSummary, storeMultipleCollectTaskSummaryReducer)
    .addCase(storeCollectTaskSummaryListParameters, storeCollectTaskSummaryListParametersReducer)
    .addCase(storeCollectTaskSummaryListData, storeCollectTaskSummaryListDataReducer)
    .addCase(markCollectTaskSummaryListDirty, markCollectTaskSummaryListDirtyReducer)

    .addCase(storeCollectTasksForAddressesListData, storeCollectTasksForAddressesListDataReducer)
    .addCase(markCollectTasksForAddressesListDirty, markCollectTasksForAddressesListDirtyReducer)
    .addCase(storeCollectTasksForAddressesListParameters, storeCollectTasksForAddressesListParametersReducer)

    .addCase(markCollectableTransactionDirty, markCollectableTransactionDirtyReducer)
    .addCase(storeCollectableTransaction, storeCollectableTransactionReducer)
    .addCase(storeMultipleCollectableTransaction, storeMultipleCollectableTransactionReducer)

    .addCase(markCollectEntityProcessTransactionDirty, markCollectEntityProcessTransactionDirtyReducer)
    .addCase(storeCollectEntityProcessTransaction, storeCollectEntityProcessTransactionReducer)

    .addCase(markCollectThresholdDirty, markCollectThresholdDirtyReducer)
    .addCase(storeCollectThreshold, storeCollectThresholdReducer)

    .addCase(markCollectScheduleDirty, markCollectScheduleDirtyReducer)
    .addCase(storeCollectSchedule, storeCollectScheduleReducer)

    .addCase(markCollectableBalanceDirty, markCollectableBalanceDirtyReducer)
    .addCase(storeCollectableBalance, storeCollectableBalanceReducer)

    .addCase(markCollectAvailableBalanceDirty, markCollectAvailableBalanceDirtyReducer)
    .addCase(storeCollectAvailableBalance, storeCollectAvailableBalanceReducer)
    .addCase(storeMultipleCollectAvailableBalance, storeMultipleCollectAvailableBalanceReducer)
    .addCase(storeCollectAvailableBalanceListParameters, storeCollectAvailableBalanceListParametersReducer)
    .addCase(storeCollectAvailableBalanceListData, storeCollectAvailableBalanceListDataReducer)
    .addCase(markCollectAvailableBalanceListDirty, markCollectAvailableBalanceListDirtyReducer)

    .addCase(markCollectLockedBalanceDirty, markCollectLockedBalanceDirtyReducer)
    .addCase(storeCollectLockedBalance, storeCollectLockedBalanceReducer)
    .addCase(storeMultipleCollectLockedBalance, storeMultipleCollectLockedBalanceReducer)
    .addCase(storeCollectLockedBalanceListParameters, storeCollectLockedBalanceListParametersReducer)
    .addCase(storeCollectLockedBalanceListData, storeCollectLockedBalanceListDataReducer)
    .addCase(markCollectLockedBalanceListDirty, markCollectLockedBalanceListDirtyReducer)

    .addCase(storePendingCollectTasksRefreshableAfter, (state, { payload: pendingRefreshableAfter }) => ({
      ...state,
      tasks: { ...state.tasks, pendingRefreshableAfter },
    }))
    .addCase(markPendingCollectTasksInitialized, (state, { payload: { refreshableAfter } }) => ({
      ...state,
      tasks: { ...state.tasks, pendingRefreshableAfter: refreshableAfter, initialized: true },
    }))

    .addCase(notifyNetworkUpdated, (state) => ({
      ...initialState,
      tasks: {
        ...initialState.tasks,
        columnState: state.tasks.columnState,
        list: {
          ...initialState.tasks.list,
          sortBy: state.tasks.list.sortBy,
        },
      },
    }))
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address || previous?.activeCompanyId !== current?.activeCompanyId
        ? initialState
        : state,
    );

  listenerMiddleware.startListening({
    matcher: isAnyOf(storeCollectTask, storeMultipleCollectTaskSummary, storeCollectTaskSummaryListData),
    effect: (_, listenerApi) => {
      const werePending = makeSelectPendingCollectTaskSummaries()(listenerApi.getOriginalState()).data ?? [];
      const arePending = makeSelectPendingCollectTaskSummaries()(listenerApi.getState()).data ?? [];
      if (arePending.length || werePending.length) {
        const updated = makeSelectMultipleCollectTaskSummary(
          [...werePending, ...arePending].map(extractCollectTaskId).filter(onlyUnique),
        )(listenerApi.getState());
        const succeeded = updated
          .filter(({ data }) => data.data?.status && CollectTaskStatusAPIModel.Succeeded === data.data.status)
          .map(({ data }) => data.data)
          .filter(notEmpty);
        const failed = updated
          .filter(({ data }) => data.data?.status === CollectTaskStatusAPIModel.Failed)
          .map(({ data }) => data.data)
          .filter(notEmpty);

        succeeded.forEach((task) =>
          listenerApi.dispatch(
            createNotification({
              severity: 'success',
              messageI18n: I18nFeatureCollectable.MESSAGES_PENDING_FINISHED_SUCCESS,
              messageI18nValues: { ln: { type: 'link', to: collectTaskViewLink(task.id) } },
            }),
          ),
        );

        failed.forEach((task) =>
          listenerApi.dispatch(
            createNotification({
              severity: 'error',
              messageI18n: I18nFeatureCollectable.MESSAGES_PENDING_FINISHED_FAILURE,
              messageI18nValues: { ln: { type: 'link', to: collectTaskViewLink(task.id) } },
            }),
          ),
        );

        if (succeeded.length || failed.length) {
          const taskIds = [...succeeded, ...failed].map(({ id }) => id);
          suppressPromise(listenerApi.dispatch(markCollectTasksDependentDataDirty(taskIds)));
        }
      }
    },
  });
});

export default reducer;
