import React, { useCallback, useMemo } from 'react';

import { FormattedMessage, MessageToSupport } from '@/components';
import RecaptchaActionContext from '@/features/recaptcha/hocs/withReCaptchaSupport/context';
import { ReCaptchaType } from '@/features/recaptcha/types';
import { executeRecaptchaMocked, isRecaptchaError, recaptchaError } from '@/features/recaptcha/utils';
import { I18nFeatureRecaptcha } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import type { Func } from '@/infrastructure/utils/ts';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import { useV2ReCaptcha, useV3ReCaptcha } from './hooks';

import type { ReCaptchaContextInitializerProps } from './types';

const ReCaptchaContextInitializer: React.FC<ReCaptchaContextInitializerProps> = ({ isMocked, children }) => {
  const { execute: executeV2ReCaptcha, contextHolder } = useV2ReCaptcha();
  const { execute: executeV3ReCaptcha } = useV3ReCaptcha();

  const executeNoRecaptcha = useMemo(() => {
    const token = window.env.RECAPTCHA_BOT_TOKEN;
    return token ? () => Promise.resolve(token) : executeRecaptchaMocked;
  }, []);

  const { notification } = useNotification();
  const withReCaptchaAction = useCallback(
    (action: string) =>
      <V extends unknown[], R>(func: (v: { recaptchaToken: string; recaptchaType: ReCaptchaType }) => Func<V, R>) =>
      async (...params: V) => {
        try {
          const recaptchaToken = await (isMocked ? executeNoRecaptcha() : executeV3ReCaptcha?.(action));
          if (!recaptchaToken) {
            throw recaptchaError();
          }
          const result = await func({ recaptchaToken, recaptchaType: ReCaptchaType.V3 })(...params);
          goalReached(YMGoals.CAPTCHA_INVISIBLE_SUCCEEDED);
          return result;
        } catch (eV3) {
          if (isRecaptchaError(eV3)) {
            goalReached(YMGoals.CAPTCHA_INVISIBLE_FAILED);
            const recaptchaToken = await (isMocked ? executeNoRecaptcha() : executeV2ReCaptcha());
            if (!recaptchaToken) {
              throw recaptchaError();
            }
            try {
              const result = await func({ recaptchaToken, recaptchaType: ReCaptchaType.V2 })(...params);
              goalReached(YMGoals.CAPTCHA_VISIBLE_SUCCEEDED);
              return result;
            } catch (eV2) {
              if (isRecaptchaError(eV2)) {
                goalReached(YMGoals.CAPTCHA_VISIBLE_FAILED);
                notification?.error({
                  message: <FormattedMessage id={I18nFeatureRecaptcha.MESSAGE_INVALID_TOKEN} />,
                  description: <MessageToSupport />,
                  duration: 3,
                });
              }
              throw eV2;
            }
          }
          throw eV3;
        }
      },
    [executeNoRecaptcha, executeV2ReCaptcha, executeV3ReCaptcha, isMocked, notification],
  );
  const context: Parameters<typeof RecaptchaActionContext.Provider>[0]['value'] = useMemo(
    () => ({ wrapped: true, initialized: !!executeV3ReCaptcha, withReCaptchaAction }),
    [executeV3ReCaptcha, withReCaptchaAction],
  );

  return (
    <RecaptchaActionContext.Provider value={context}>
      {contextHolder}
      {children}
    </RecaptchaActionContext.Provider>
  );
};

const ReCaptchaContextInitializerMemo = React.memo(ReCaptchaContextInitializer);

export default ReCaptchaContextInitializerMemo;
