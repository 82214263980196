import { Alert, Tag } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, WarningTooltip } from '@/components';
import { BlockchainLabelList } from '@/features/dictionary/blockchain/components';
import OperationAddUserAddress from '@/features/user/components/OperationAddUserAddress';
import OperationRefreshUser from '@/features/user/components/OperationRefreshUser';
import type { UserAddressParticularIssueBannerProps } from '@/features/user/components/UserAddressIssueBanner/types';
import { I18nFeatureUser } from '@/generated/i18n/i18n';
import ProfileLink from '@/pages/settings/profile/components/ProfileLink';

const UserAddressNotAddedBanner: React.FC<UserAddressParticularIssueBannerProps> = ({
  'data-test': dataTest,
  api,
  mode,
  style,
  className,
  type,
}) => {
  const details = useMemo(
    () =>
      type === 'action' ? (
        <FormattedMessageWithMarkup
          id={I18nFeatureUser.COMPONENTS_USER_ADDRESS_ISSUE_BANNER_ISSUES_NOT_ADDED_ACTION}
          values={{
            networks: <BlockchainLabelList api={api} mode="short" />,
            deploy: (text: React.ReactNode) => (
              <OperationAddUserAddress
                data-test={dataTest && `${dataTest}-opDeploy`}
                api={api}
                mode="link"
                title={text}
              />
            ),
            check: (text: React.ReactNode) => (
              <OperationRefreshUser data-test={dataTest && `${dataTest}-opCheck`} mode="link" title={text} />
            ),
          }}
        />
      ) : (
        <FormattedMessageWithMarkup
          id={I18nFeatureUser.COMPONENTS_USER_ADDRESS_ISSUE_BANNER_ISSUES_NOT_ADDED_LINK}
          values={{
            networks: <BlockchainLabelList api={api} mode="icon" />,
            link: (text: React.ReactNode) => (
              <ProfileLink data-test={dataTest && `${dataTest}-profileLink`} title={text} mode="text" />
            ),
            check: (text: React.ReactNode) => (
              <OperationRefreshUser data-test={dataTest && `${dataTest}-opCheck`} mode="link" title={text} />
            ),
          }}
        />
      ),
    [api, dataTest, type],
  );
  switch (mode) {
    case 'tag':
      return (
        <WarningTooltip type="error" title={details}>
          <Tag data-test={dataTest} color="error" className={className} style={{ ...style, marginRight: 0 }}>
            <FormattedMessage id={I18nFeatureUser.COMPONENTS_USER_ADDRESS_ISSUE_BANNER_ISSUES_NOT_ADDED_TAG} />
          </Tag>
        </WarningTooltip>
      );
    case 'alert':
      return (
        <Alert
          data-test={dataTest}
          className={className}
          style={style}
          message={
            <FormattedMessage id={I18nFeatureUser.COMPONENTS_USER_ADDRESS_ISSUE_BANNER_ISSUES_NOT_ADDED_TITLE} />
          }
          description={details}
          type="error"
          showIcon
        />
      );
    case 'message':
      return details;
  }
};

const UserAddressNotAddedBannerMemo = React.memo(UserAddressNotAddedBanner) as typeof UserAddressNotAddedBanner;

export default UserAddressNotAddedBannerMemo;
