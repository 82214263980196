import React from 'react';

import { OperationRefresh } from '@/components';
import { useUser } from '@/features/user/hooks';

import type { OperationRefreshUserProps } from './types';

const OperationRefreshUser: React.FC<OperationRefreshUserProps> = ({
  'data-test': dataTest,
  title,
  mode = 'inline',
}) => {
  const { forceRefresh } = useUser();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} messages={{ title }} />;
};

const OperationRefreshUserMemo = React.memo(OperationRefreshUser);

export default OperationRefreshUserMemo;
