import React from 'react';

import { FormattedMessage, InProgressView, PageLoadingIcon } from '@/components';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';

import type { Web3AuthInProgressProps } from './types';

const Web3AuthInProgress: React.FC<Web3AuthInProgressProps> = ({ 'data-test': dataTest }) => (
  <InProgressView
    data-test={dataTest}
    Icon={PageLoadingIcon}
    message={<FormattedMessage id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_IN_PROGRESS_MESSAGE} />}
  />
);

const Web3AuthInProgressMemo = React.memo(Web3AuthInProgress);

export default Web3AuthInProgressMemo;
