import { getAddress, getContract, publicActions } from 'viem';

import { type BCRpcRequestAPIMeta, getRpcClient } from '@/features/dictionary/blockchain/web3-eth-api';
import { withTimeout } from '@/infrastructure/utils/functions';

import type BigNumber from 'bignumber.js';
import type { Account, Chain, TransactionReceipt, Transport, WalletClient } from 'viem';

const walletAbi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'address[]',
        name: 'destinations',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: 'amounts',
        type: 'uint256[]',
      },
    ],
    name: 'distribute',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'totalAmount',
        type: 'uint256',
      },
    ],
    name: 'calculateFees',
    outputs: [
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const;

export const queryDistributeFee = async (
  bcMeta: BCRpcRequestAPIMeta,
  walletAddress: string,
  totalAmount: BigNumber,
  initOverrides?: RequestInit,
): Promise<bigint> => {
  const client = getRpcClient(bcMeta);
  const contract = getContract({ address: getAddress(walletAddress), abi: walletAbi, client });
  return (
    await withTimeout(
      async () => contract.read.calculateFees([BigInt(totalAmount.toString())]),
      7000,
      undefined,
      initOverrides?.signal ?? undefined,
    )
  )[2];
};

export const requestDistribute = async (
  client: WalletClient<Transport, Chain, Account>,
  walletAddress: string,
  tokenAddress: string,
  destinations: string[],
  amounts: string[],
): Promise<TransactionReceipt> => {
  const contract = getContract({ address: getAddress(walletAddress), abi: walletAbi, client });
  const gasPrice = await client.extend(publicActions).getGasPrice();
  const args = [
    getAddress(tokenAddress),
    destinations.map((value) => getAddress(value)),
    amounts.map((value) => BigInt(value)),
  ] as const;
  const gasLimit = await contract.estimateGas.distribute(args);
  const tx = await contract.write.distribute(args, { gasPrice, gasLimit });
  return client.extend(publicActions).waitForTransactionReceipt({ hash: tx, confirmations: 3 });
};
