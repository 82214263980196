/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum DonationSortByAPIModel {
    CreatedAt = 'CreatedAt'
}


export function DonationSortByAPIModelFromJSON(json: any): DonationSortByAPIModel {
    return DonationSortByAPIModelFromJSONTyped(json, false);
}

export function DonationSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DonationSortByAPIModel {
    return json as DonationSortByAPIModel;
}

export function DonationSortByAPIModelToJSON(value?: DonationSortByAPIModel | null): any {
    return value as any;
}

