/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { TransactionStatusAPIModel } from './TransactionStatusAPIModel';
import {
    TransactionStatusAPIModelFromJSON,
    TransactionStatusAPIModelFromJSONTyped,
    TransactionStatusAPIModelToJSON,
} from './TransactionStatusAPIModel';
import type { WithdrawalJobStatusAPIModel } from './WithdrawalJobStatusAPIModel';
import {
    WithdrawalJobStatusAPIModelFromJSON,
    WithdrawalJobStatusAPIModelFromJSONTyped,
    WithdrawalJobStatusAPIModelToJSON,
} from './WithdrawalJobStatusAPIModel';
import type { WithdrawalReconciliationStatusAPIModel } from './WithdrawalReconciliationStatusAPIModel';
import {
    WithdrawalReconciliationStatusAPIModelFromJSON,
    WithdrawalReconciliationStatusAPIModelFromJSONTyped,
    WithdrawalReconciliationStatusAPIModelToJSON,
} from './WithdrawalReconciliationStatusAPIModel';

/**
 * 
 * @export
 * @interface WithdrawalAPIModel
 */
export interface WithdrawalAPIModel {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAPIModel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalAPIModel
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAPIModel
     */
    destinationAddress?: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof WithdrawalAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAPIModel
     */
    estimatedAmount: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAPIModel
     */
    estimatedAmountInvoice: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAPIModel
     */
    estimatedAmountPush: string;
    /**
     * 
     * @type {Date}
     * @memberof WithdrawalAPIModel
     */
    estimatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAPIModel
     */
    processingFeeUnpaid: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAPIModel
     */
    activeProcessingFeeTx?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAPIModel
     */
    fullness: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAPIModel
     */
    collectFullness: string;
    /**
     * 
     * @type {TransactionStatusAPIModel}
     * @memberof WithdrawalAPIModel
     */
    collectStatus: TransactionStatusAPIModel;
    /**
     * 
     * @type {WithdrawalJobStatusAPIModel}
     * @memberof WithdrawalAPIModel
     */
    status: WithdrawalJobStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAPIModel
     */
    errorCode?: string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalAPIModel
     */
    batchCount?: number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalAPIModel
     */
    hash?: string;
    /**
     * 
     * @type {Date}
     * @memberof WithdrawalAPIModel
     */
    withdrawnAt?: Date;
    /**
     * 
     * @type {WithdrawalReconciliationStatusAPIModel}
     * @memberof WithdrawalAPIModel
     */
    reconciliationStatus: WithdrawalReconciliationStatusAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof WithdrawalAPIModel
     */
    isPayout: boolean;
}

/**
 * Check if a given object implements the WithdrawalAPIModel interface.
 */
export function instanceOfWithdrawalAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('estimatedAmount' in value)) return false;
    if (!('estimatedAmountInvoice' in value)) return false;
    if (!('estimatedAmountPush' in value)) return false;
    if (!('estimatedAt' in value)) return false;
    if (!('processingFeeUnpaid' in value)) return false;
    if (!('fullness' in value)) return false;
    if (!('collectFullness' in value)) return false;
    if (!('collectStatus' in value)) return false;
    if (!('status' in value)) return false;
    if (!('reconciliationStatus' in value)) return false;
    if (!('isPayout' in value)) return false;
    return true;
}

export function WithdrawalAPIModelFromJSON(json: any): WithdrawalAPIModel {
    return WithdrawalAPIModelFromJSONTyped(json, false);
}

export function WithdrawalAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'destinationAddress': json['destinationAddress'] == null ? undefined : json['destinationAddress'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'estimatedAmount': json['estimatedAmount'],
        'estimatedAmountInvoice': json['estimatedAmountInvoice'],
        'estimatedAmountPush': json['estimatedAmountPush'],
        'estimatedAt': (new Date(json['estimatedAt'])),
        'processingFeeUnpaid': json['processingFeeUnpaid'],
        'activeProcessingFeeTx': json['activeProcessingFeeTx'] == null ? undefined : json['activeProcessingFeeTx'],
        'fullness': json['fullness'],
        'collectFullness': json['collectFullness'],
        'collectStatus': TransactionStatusAPIModelFromJSON(json['collectStatus']),
        'status': WithdrawalJobStatusAPIModelFromJSON(json['status']),
        'errorCode': json['errorCode'] == null ? undefined : json['errorCode'],
        'batchCount': json['batchCount'] == null ? undefined : json['batchCount'],
        'hash': json['hash'] == null ? undefined : json['hash'],
        'withdrawnAt': json['withdrawnAt'] == null ? undefined : (new Date(json['withdrawnAt'])),
        'reconciliationStatus': WithdrawalReconciliationStatusAPIModelFromJSON(json['reconciliationStatus']),
        'isPayout': json['isPayout'],
    };
}

export function WithdrawalAPIModelToJSON(value?: WithdrawalAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'destinationAddress': value['destinationAddress'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'estimatedAmount': value['estimatedAmount'],
        'estimatedAmountInvoice': value['estimatedAmountInvoice'],
        'estimatedAmountPush': value['estimatedAmountPush'],
        'estimatedAt': ((value['estimatedAt']).toISOString()),
        'processingFeeUnpaid': value['processingFeeUnpaid'],
        'activeProcessingFeeTx': value['activeProcessingFeeTx'],
        'fullness': value['fullness'],
        'collectFullness': value['collectFullness'],
        'collectStatus': TransactionStatusAPIModelToJSON(value['collectStatus']),
        'status': WithdrawalJobStatusAPIModelToJSON(value['status']),
        'errorCode': value['errorCode'],
        'batchCount': value['batchCount'],
        'hash': value['hash'],
        'withdrawnAt': value['withdrawnAt'] == null ? undefined : ((value['withdrawnAt']).toISOString()),
        'reconciliationStatus': WithdrawalReconciliationStatusAPIModelToJSON(value['reconciliationStatus']),
        'isPayout': value['isPayout'],
    };
}

