import { namedHOC } from '@/infrastructure/utils/react';

import { YandeMetrikaNavigationTracker } from './components';

import type React from 'react';
import type { PropsWithChildren } from 'react';

const withYandexMetrikaNavigation = <P extends PropsWithChildren>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithYandexMetrikaNavigation',
  )((props) => {
    const ymIsEnabled = window.env.YM_ENABLED && !!window.env.YM_ID;

    return (
      <>
        {ymIsEnabled ? <YandeMetrikaNavigationTracker /> : null}
        <Component {...props} />
      </>
    );
  });

export default withYandexMetrikaNavigation;
