import React from 'react';

import { BlockchainAPILabel } from '@/features/dictionary/blockchain/components';
import type { ReadonlyBlockchainAPIProps } from '@/features/dictionary/blockchain/components/BlockchainAPIItem/components/ReadonlyBlockchainAPI/types';

const ReadonlyBlockchainAPI: React.FC<ReadonlyBlockchainAPIProps> = ({ 'data-test': dataTest, value }) =>
  value && <BlockchainAPILabel data-test={dataTest} api={value} mode="tag" />;

const ReadonlyBlockchainAPIMemo = React.memo(ReadonlyBlockchainAPI) as typeof ReadonlyBlockchainAPI;

export default ReadonlyBlockchainAPIMemo;
