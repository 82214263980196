import { useCallback, useRef } from 'react';

import useStateMountSafe from '@/hooks/useStateMountSafe';
import { withFinally } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';

export function useSingleRun(updateState = false) {
  const updatingRef = useRef(false);
  const [updating, setUpdating] = useStateMountSafe(false);
  const withSingleRunNoStateUpdate = useCallback(
    <V extends unknown[]>(func: Func<V, void>) =>
      async (...params: Parameters<typeof func>): Promise<void> => {
        if (!updatingRef.current) {
          updatingRef.current = true;
          await withFinally(func, () => {
            updatingRef.current = false;
          })(...params);
        }
      },
    [],
  );
  const withSingleRunStateUpdate = useCallback(
    <V extends unknown[]>(func: Func<V, void>) =>
      async (...params: Parameters<typeof func>): Promise<void> => {
        if (!updating) {
          setUpdating(true);
          await withFinally(withSingleRunNoStateUpdate(func), () => {
            setUpdating(false);
          })(...params);
        }
      },
    [setUpdating, updating, withSingleRunNoStateUpdate],
  );
  return {
    withSingleRun: updateState ? withSingleRunStateUpdate : withSingleRunNoStateUpdate,
    inProgress: updatingRef.current || updating,
  };
}

export default useSingleRun;
