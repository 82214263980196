import React from 'react';

import type { Web3WalletType } from '@/features/web3/components/Web3WalletLink/types';
import { noop } from '@/infrastructure/utils/functions';

const Web3SolanaInitContext = React.createContext<{
  initialized: boolean;
  initialize: () => void;
  supportedWallets: Web3WalletType[];
  setWalletsError: (error?: Error) => void;
  walletsError?: Error;
}>({
  initialized: false,
  initialize: noop,
  supportedWallets: [],
  setWalletsError: noop,
});

export default Web3SolanaInitContext;
