import { createAction } from '@reduxjs/toolkit';
import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import type { BlockchainMetaAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import {
  BlockchainAPITypeAPIModel,
  BlockchainNetworkTypeAPIModel,
  BlockchainTypeAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { apiBasePath } from '@/infrastructure/api.provider';
import type { CommonLoadingState } from '@/infrastructure/model';
import { mapLoadingState } from '@/infrastructure/model';
import { withAPICall } from '@/infrastructure/model/api';
import { createFullActions } from '@/infrastructure/model/full/actions';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import createCache from '@/infrastructure/utils/cache';
import { assertNotNil } from '@/infrastructure/utils/functions';
import { callWithThunkError } from '@/infrastructure/utils/redux';
import { addressToHex } from '@/infrastructure/utils/tron/address';
import { enumByKey, enumToObject } from '@/infrastructure/utils/ts';

import { queryAssets, queryBlockchains, queryBlockchainSystemInfo, queryTokenHistoryBalance } from './api';
import {
  makeSelectAllowance,
  makeSelectAssetFullData,
  makeSelectBlockchainFullData,
  makeSelectBlockchainSystemInfos,
  makeSelectContractExistence,
  makeSelectNativeBalance,
  makeSelectTokenBalance,
  makeSelectTokenHistoryBalance,
} from './selectors';
import { NAMESPACE } from './types';
import {
  createAllowanceKey,
  createAssetAddressHistoryKey,
  createAssetAddressKey,
  createBlockchainAddressKey,
  explorers,
} from './utils';
import web3EthAPI from './web3-eth-api';
import web3SolanaAPI from './web3-solana-api';

import type {
  AllowanceId,
  Asset,
  AssetAddress,
  AssetAddressHistoryKey,
  AssetFilterPredicate,
  AssetHistoryBalanceData,
  AssetSortByColumn,
  BalanceData,
  BlockchainAddress,
  BlockchainInfoFilterPredicate,
  BlockchainInfoSortByColumn,
  BlockchainSystemInfo,
  BTCBlockchainSystemInfo,
  FetchAllowancePayload,
  FetchAssetAddressDataPayload,
  FetchAssetAddressHistoryBalancePayload,
  FetchBlockchainAddressDataPayload,
  FetchBlockchainAPIPayload,
  SolanaBlockchainSystemInfo,
  TronBlockchainSystemInfo,
  Web3BlockchainSystemInfo,
  FetchBlockchainAssetPayload,
} from './types';

export const notifyNetworkUpdated = createAction<{
  previous: BlockchainNetworkTypeAPIModel;
  current: BlockchainNetworkTypeAPIModel;
}>(`${NAMESPACE}/notifyNetworkUpdated`);

export const storeSelectedNetwork = createAction<BlockchainNetworkTypeAPIModel>(`${NAMESPACE}/storeSelectedNetwork`);

export const networkCache = createCache<string>(import.meta.env.VITE_NETWORK_KEY, 'persistent');

export const initNetwork = createAppAsyncThunk(`${NAMESPACE}/initNetwork`, (_, { dispatch }) => {
  const network = enumByKey(BlockchainNetworkTypeAPIModel, networkCache.safeRead());
  if (network) dispatch(storeSelectedNetwork(network));
});

export const { storeAssetsFullData, markAssetsFullDirty } = createFullActions<
  Asset,
  'Assets',
  AssetFilterPredicate,
  AssetSortByColumn
>(NAMESPACE, 'Assets');
const assetsFetchLimit = pLimit(1);
export const fetchAssets = createAppAsyncThunk(
  `${NAMESPACE}/fetchAssets`,
  async ({ force }: { force?: boolean }, { getState, dispatch, signal }) =>
    assetsFetchLimit(async () => {
      const stored = makeSelectAssetFullData()(getState());
      if (!stored.isDirty && !force) return stored;

      const queried = await withAPICall(queryAssets)({ signal });
      dispatch(storeAssetsFullData(queried));
      return makeSelectAssetFullData()(getState());
    }),
);

export const { storeBlockchainsFullData, markBlockchainsFullDirty } = createFullActions<
  BlockchainMetaAPIModel,
  'Blockchains',
  BlockchainInfoFilterPredicate,
  BlockchainInfoSortByColumn
>(NAMESPACE, 'Blockchains');
const blockchainsFetchLimit = pLimit(1);
export const fetchBlockchains = createAppAsyncThunk(
  `${NAMESPACE}/fetchBlockchains`,
  async ({ force }: { force?: boolean }, { getState, dispatch, signal }) =>
    blockchainsFetchLimit(async () => {
      const stored = makeSelectBlockchainFullData()(getState());
      if (!stored.isDirty && !force) return stored;

      const queried = await withAPICall(queryBlockchains)({ signal });
      dispatch(storeBlockchainsFullData(queried));
      return makeSelectBlockchainFullData()(getState());
    }),
);

const addressToHexSafe = (address: string): string => {
  if (!address) return address;

  try {
    return addressToHex(address);
  } catch (e) {
    console.error(e, (e as Error).stack);
    return address;
  }
};

export const markBlockchainSystemInfoDirty = createAction(`${NAMESPACE}/markBlockchainSystemInfoDirty`);
export const storeBlockchainSystemInfo = createAction<
  CommonLoadingState<Partial<Record<BlockchainTypeAPIModel, BlockchainSystemInfo>>>
>(`${NAMESPACE}/storeBlockchainSystemInfo`);
const blockchainsSystemFetchLimit = pLimit(1);
export const fetchBlockchainsSystemInfo = createAppAsyncThunk(
  `${NAMESPACE}/fetchBlockchainsSystemInfo`,
  async ({ force }: { force?: boolean }, { getState, dispatch, signal }) =>
    blockchainsSystemFetchLimit(async () => {
      const stored = makeSelectBlockchainSystemInfos()(getState());
      if (!stored.isDirty && !force) return stored;

      const queried = await withAPICall(queryBlockchainSystemInfo)({ signal });
      const dataToStore = mapLoadingState(queried, (data) =>
        enumToObject(
          BlockchainTypeAPIModel,
          (bt): BlockchainSystemInfo | undefined => {
            const bcApi = data.api[bt];
            const api =
              (bcApi?.securedApi && `${apiBasePath}/${bcApi.securedApi}`)
              || (bcApi?.openApi && `${apiBasePath}/${bcApi.openApi}`)
              || bcApi?.publicApi;
            const apiAuth = !!bcApi?.securedApi;
            const app = explorers[bt];
            const web3BC = data.blockchains.networks[bt];
            const btcBC = data.blockchains.btcNetworks[bt];
            const tronBC = data.blockchains.tronNetworks[bt];
            const solanaBC = data.blockchains.solanaNetworks[bt];
            const bc:
              | Web3BlockchainSystemInfo
              | BTCBlockchainSystemInfo
              | TronBlockchainSystemInfo
              | SolanaBlockchainSystemInfo
              | undefined =
              (web3BC ? { ...web3BC, apiType: BlockchainAPITypeAPIModel.EVM } : undefined)
              ?? (btcBC ? { ...btcBC, apiType: BlockchainAPITypeAPIModel.BTC } : undefined)
              ?? (tronBC
                ? {
                    ...{
                      batchBalancesContractAddress: addressToHexSafe(tronBC.batchBalancesContractAddress),
                      forwarderFactoryAddress: addressToHexSafe(tronBC.forwarderFactoryAddress),
                      forwarderBatchCreateAddress: addressToHexSafe(tronBC.forwarderBatchCreateAddress),
                      merchantWalletProtoAddress: addressToHexSafe(tronBC.merchantWalletProtoAddress),
                      merchantWalletFactoryAddress: addressToHexSafe(tronBC.merchantWalletFactoryAddress),
                    },
                    apiType: BlockchainAPITypeAPIModel.Tron,
                  }
                : undefined)
              ?? (solanaBC ? { ...solanaBC, apiType: BlockchainAPITypeAPIModel.Solana } : undefined);
            const isEnabled = Boolean(data.blockchains.enabled[bt]);
            const isForwarderSupported = Boolean(data.blockchains.supportsForwarder[bt]);
            const isForwarderRequired =
              bc?.apiType === BlockchainAPITypeAPIModel.Tron || bc?.apiType === BlockchainAPITypeAPIModel.Solana;
            // eslint-disable-next-line no-nested-ternary
            const forwarder = isForwarderRequired ? 'required' : isForwarderSupported ? 'supported' : 'not-supported';
            return bc && api ? { ...bc, links: { api, apiAuth, app }, isEnabled, forwarder, bt } : undefined;
          },
          true,
        ),
      );
      dispatch(storeBlockchainSystemInfo(dataToStore));
      return makeSelectBlockchainSystemInfos()(getState());
    }),
);

// TODO: make the result api dependent
export const fetchBlockchainAPI = createAppAsyncThunk(
  `${NAMESPACE}/fetchBlockchainAPI`,
  async ({ force, bt: btBase, asset, api }: FetchBlockchainAPIPayload, { dispatch }) => {
    let bt = btBase;
    if (!btBase) {
      const maybeAssets = await dispatch(fetchAssets({})).unwrap();
      const assets = maybeAssets.data ? maybeAssets : await dispatch(fetchAssets({ force })).unwrap();
      bt = (assets.data ?? []).find(({ code }) => code === asset)?.blockchain;
    }
    assertNotNil(
      bt,
      () => new Error(asset ? `Blockchain for asset "${asset}" not found` : `Blockchain "${btBase}" not found`),
    );

    const maybeSystemInfo = await dispatch(fetchBlockchainsSystemInfo({})).unwrap();
    const systemInfo = maybeSystemInfo.data
      ? maybeSystemInfo
      : await dispatch(fetchBlockchainsSystemInfo({ force })).unwrap();
    const bc = systemInfo.data?.[bt];
    assertNotNil(bc, () => new Error(`Blockchain "${bt}" API data not found`));
    if (!bc.isEnabled) throw new Error(`Blockchain "${bt}" API is disabled`);
    if (!bc.links.api) throw new Error(`Blockchain "${bt}" API link is unset`);

    const apis = api && (Array.isArray(api) ? api : [api]);
    if (apis && !apis.includes(bc.apiType)) throw new Error(`Blockchain "${bt}" API is not ${apis.join(',')}`);

    return { host: bc.links.api, auth: !!bc.links.apiAuth, ...bc };
  },
);

// TODO: make the result api dependent
export const fetchBlockchainAssetAPI = createAppAsyncThunk(
  `${NAMESPACE}/fetchBlockchainAssetAPI`,
  async ({ force, asset: assetId, api }: FetchBlockchainAssetPayload, { dispatch }) => {
    const maybeAssets = await dispatch(fetchAssets({})).unwrap();
    const assets = maybeAssets.data ? maybeAssets : await dispatch(fetchAssets({ force })).unwrap();
    const asset = assets.data?.find(({ code }) => code === assetId);

    assertNotNil(asset, () => new Error(`Asset "${assetId}" not found`));
    assertNotNil(asset.blockchain, () => new Error(`Asset "${assetId}" is not crypto asset`));
    assertNotNil(asset.address, () => new Error(`Asset "${assetId}" contract not defined`));

    const bcAPI = await dispatch(fetchBlockchainAPI({ bt: asset.blockchain, api, force })).unwrap();

    return { ...asset, address: asset.address, blockchain: asset.blockchain, api: bcAPI };
  },
);

export const { storeNativeBalance, markNativeBalanceDirty } = createSingleActions<
  BalanceData,
  'NativeBalance',
  BlockchainAddress
>(NAMESPACE, 'NativeBalance');
const nativeFetchLimit = pLimit(1);
export const fetchNativeBalance = createAppAsyncThunk(
  `${NAMESPACE}/fetchNativeBalance`,
  async ({ force, ...key }: FetchBlockchainAddressDataPayload, { getState, dispatch, signal }) =>
    nativeFetchLimit(async () => {
      const stored = makeSelectNativeBalance(key)(getState());
      if (!stored.isDirty && !force) return stored;
      const balance = await callWithThunkError(async () => {
        const bcMeta = await dispatch(
          fetchBlockchainAPI({
            bt: key.bt,
            api: [BlockchainAPITypeAPIModel.EVM, BlockchainAPITypeAPIModel.Tron, BlockchainAPITypeAPIModel.Solana],
            force,
          }),
        ).unwrap();
        const queried = await (() => {
          switch (bcMeta.apiType) {
            case BlockchainAPITypeAPIModel.EVM:
            case BlockchainAPITypeAPIModel.Tron:
              return withAPICall(web3EthAPI.queryNativeBalance)(bcMeta, key.address, { signal });
            case BlockchainAPITypeAPIModel.Solana:
              return withAPICall(web3SolanaAPI.queryNativeBalance)(bcMeta, key.address, { signal });
            case BlockchainAPITypeAPIModel.BTC:
              throw new Error('Not supported: BTC balance');
          }
        })();

        return mapLoadingState(queried, (amount) => ({ amount, updatedAt: new Date() }));
      });
      dispatch(storeNativeBalance({ id: key, data: balance }));
      return makeSelectNativeBalance(key)(getState());
    }),
  { idGenerator: createBlockchainAddressKey },
);

export const { storeTokenBalance, markTokenBalanceDirty } = createSingleActions<
  BalanceData,
  'TokenBalance',
  AssetAddress
>(NAMESPACE, 'TokenBalance');
const tokenFetchLimit = pLimit(1);
export const fetchTokenBalance = createAppAsyncThunk(
  `${NAMESPACE}/fetchTokenBalance`,
  async ({ force, ...key }: FetchAssetAddressDataPayload, { getState, dispatch, signal }) =>
    tokenFetchLimit(async () => {
      const stored = makeSelectTokenBalance(key)(getState());
      if (!stored.isDirty && !force) return stored;

      const balance = await callWithThunkError(async () => {
        const asset = await dispatch(
          fetchBlockchainAssetAPI({
            asset: key.assetId,
            api: [BlockchainAPITypeAPIModel.EVM, BlockchainAPITypeAPIModel.Tron, BlockchainAPITypeAPIModel.Solana],
            force,
          }),
        ).unwrap();
        const queried = await (() => {
          switch (asset.api.apiType) {
            case BlockchainAPITypeAPIModel.EVM:
            case BlockchainAPITypeAPIModel.Tron:
              return withAPICall(web3EthAPI.queryTokenBalance)(asset.api, asset.address, key.address, { signal });
            case BlockchainAPITypeAPIModel.Solana:
              return withAPICall(web3SolanaAPI.queryOwnerTokenBalance)(asset.api, asset.address, key.address, {
                signal,
              });
            case BlockchainAPITypeAPIModel.BTC:
              throw new Error('Not supported: BTC balance');
          }
        })();
        return mapLoadingState(queried, (amount) => ({ amount, updatedAt: new Date() }));
      });
      dispatch(storeTokenBalance({ id: key, data: balance }));
      return makeSelectTokenBalance(key)(getState());
    }),
  { idGenerator: createAssetAddressKey },
);

export const { storeTokenHistoryBalance, markTokenHistoryBalanceDirty } = createSingleActions<
  AssetHistoryBalanceData,
  'TokenHistoryBalance',
  AssetAddressHistoryKey
>(NAMESPACE, 'TokenHistoryBalance');
const tokenHistoryFetchLimit = pLimit(1);
export const fetchTokenHistoryBalance = createAppAsyncThunk(
  `${NAMESPACE}/fetchTokenHistoryBalance`,
  async ({ force, ...key }: FetchAssetAddressHistoryBalancePayload, { getState, dispatch, signal }) =>
    tokenHistoryFetchLimit(async () => {
      const stored = makeSelectTokenHistoryBalance(key)(getState());
      if (!stored.isDirty && !force) return stored;

      const balance = await withAPICall(queryTokenHistoryBalance)(key.assetId, key.address, key.blockNum, { signal });
      dispatch(storeTokenHistoryBalance({ id: key, data: balance }));
      return makeSelectTokenHistoryBalance(key)(getState());
    }),
  { idGenerator: createAssetAddressHistoryKey },
);

export const { storeContractExistence, markContractExistenceDirty } = createSingleActions<
  boolean,
  'ContractExistence',
  BlockchainAddress
>(NAMESPACE, 'ContractExistence');
const contractFetchLimit = pLimit(1);
export const fetchContractExistence = createAppAsyncThunk(
  `${NAMESPACE}/fetchContractExistence`,
  async ({ force, ...key }: FetchBlockchainAddressDataPayload, { getState, dispatch, signal }) =>
    contractFetchLimit(async () => {
      const stored = makeSelectContractExistence(key)(getState());
      if (!stored.isDirty && !force) return stored;

      const data = await callWithThunkError(async () => {
        const bcMeta = await dispatch(
          fetchBlockchainAPI({
            bt: key.bt,
            api: [BlockchainAPITypeAPIModel.EVM, BlockchainAPITypeAPIModel.Tron],
            force,
          }),
        ).unwrap();
        return withAPICall(web3EthAPI.queryContractExistence)(bcMeta, key.address, { signal });
      });
      dispatch(storeContractExistence({ id: key, data }));
      return makeSelectContractExistence(key)(getState());
    }),
  { idGenerator: createBlockchainAddressKey },
);

export const { storeAllowance, markAllowanceDirty } = createSingleActions<bigint, 'Allowance', AllowanceId>(
  NAMESPACE,
  'Allowance',
);
const allowanceFetchLimit = pLimit(1);
export const fetchAllowance = createAppAsyncThunk(
  `${NAMESPACE}/fetchAllowance`,
  async ({ force, ...key }: FetchAllowancePayload, { getState, dispatch, signal }) =>
    allowanceFetchLimit(async () => {
      const stored = makeSelectAllowance(key)(getState());
      if (!stored.isDirty && !force) return stored;

      const data = await callWithThunkError(async () => {
        const asset = await dispatch(
          fetchBlockchainAssetAPI({
            asset: key.assetId,
            api: [BlockchainAPITypeAPIModel.EVM, BlockchainAPITypeAPIModel.Tron],
            force,
          }),
        ).unwrap();
        return withAPICall(web3EthAPI.queryAllowance)(asset.api, asset.address, key.owner, key.spender, { signal });
      });
      dispatch(storeAllowance({ id: key, data }));
      return makeSelectAllowance(key)(getState());
    }),
  { idGenerator: createAllowanceKey },
);
