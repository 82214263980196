import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';

import { GeneralSettingsCard, SettingsComplianceBreadcrumb } from './components';

import type React from 'react';

const dataTest: string | undefined = 'compliance';

const SettingsCompliancePage: React.FC = () => (
  <SettingsPageLayout
    data-test={dataTest && `${dataTest}-container`}
    selected={SettingsTabType.compliance}
    breadcrumb={<SettingsComplianceBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <GeneralSettingsCard data-test={dataTest && `${dataTest}-general`} />
  </SettingsPageLayout>
);

export default SettingsCompliancePage;
