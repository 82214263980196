import { DisconnectOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormattedMessage, Operation } from '@/components';
import { web3ButtonProps } from '@/features/web3/components/utils';
import { useWeb3EVMConnector } from '@/features/web3/hooks';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';
import { useDefaultNotification } from '@/hooks';
import { emptyWith, suppressPromise, withLogError } from '@/infrastructure/utils/functions';

import type { Web3EVMWalletDisconnectProps } from './types';

const defaultStyle = { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 };

const Web3EVMWalletDisconnect: React.FC<Web3EVMWalletDisconnectProps> = ({
  'data-test': dataTest,
  className,
  style,
  onDisconnect,
  withDisconnect = emptyWith,
}) => {
  const navigate = useNavigate();
  const { withDefaultError } = useDefaultNotification();
  const { connector, refresh } = useWeb3EVMConnector();

  const doDisconnect = useMemo(
    () =>
      withLogError(
        withDefaultError(
          withDisconnect(async () => {
            await connector.disconnect();
            await refresh();
            await onDisconnect?.();
            // dirty hack: we are reloading the page if connector is still "authorized" when disconnect called
            if (await connector.isAuthorized()) suppressPromise(navigate(0));
          }),
        ),
      ),
    [connector, navigate, onDisconnect, refresh, withDefaultError, withDisconnect],
  );

  return (
    <Operation
      ButtonProps={web3ButtonProps}
      style={useMemo(() => ({ ...defaultStyle, ...style }), [style])}
      className={className}
      title={
        <FormattedMessage
          id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_DISCONNECT_TITLE}
          values={{ provider: connector.name }}
        />
      }
      data-test={dataTest && `${dataTest}-disconnect`}
      icon={<DisconnectOutlined />}
      onClick={doDisconnect}
      mode="inline"
    />
  );
};

const Web3EVMWalletDisconnectMemo = React.memo(Web3EVMWalletDisconnect) as typeof Web3EVMWalletDisconnect;

export default Web3EVMWalletDisconnectMemo;
