/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MerchantFeatureFlagsAPIModel
 */
export interface MerchantFeatureFlagsAPIModel {
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    disableRecaptcha: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    disableSignup: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    disableBtc: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    disableCustomers: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    disableEmailAuth: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    disableGoogleAuth: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    disableReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    disableSubscriptions: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    disableTawk: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    disableWeb3Auth: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableWeb3AuthKeyExport: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableWeb3AuthReset: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    disableTron: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    disableSolana: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableAuxAmount: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableReconciliation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableGasHistory: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableBranding: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableBrandingContacts: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableBrandingLogo: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableBrandingPoweredBy: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableBrandingTos: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableCompanyDomains: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableCompanyEvents: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableDonations: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableMaintenance: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableRecharges: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableSubscriptionStatistics: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableSubscriptionStatisticsMock: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enablePayouts: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    strictCookiePolicy: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableCoinbase: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableWalletConnect: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantFeatureFlagsAPIModel
     */
    enableSafeWallet: boolean;
}

/**
 * Check if a given object implements the MerchantFeatureFlagsAPIModel interface.
 */
export function instanceOfMerchantFeatureFlagsAPIModel(value: object): boolean {
    if (!('disableRecaptcha' in value)) return false;
    if (!('disableSignup' in value)) return false;
    if (!('disableBtc' in value)) return false;
    if (!('disableCustomers' in value)) return false;
    if (!('disableEmailAuth' in value)) return false;
    if (!('disableGoogleAuth' in value)) return false;
    if (!('disableReports' in value)) return false;
    if (!('disableSubscriptions' in value)) return false;
    if (!('disableTawk' in value)) return false;
    if (!('disableWeb3Auth' in value)) return false;
    if (!('enableWeb3AuthKeyExport' in value)) return false;
    if (!('enableWeb3AuthReset' in value)) return false;
    if (!('disableTron' in value)) return false;
    if (!('disableSolana' in value)) return false;
    if (!('enableAuxAmount' in value)) return false;
    if (!('enableReconciliation' in value)) return false;
    if (!('enableGasHistory' in value)) return false;
    if (!('enableBranding' in value)) return false;
    if (!('enableBrandingContacts' in value)) return false;
    if (!('enableBrandingLogo' in value)) return false;
    if (!('enableBrandingPoweredBy' in value)) return false;
    if (!('enableBrandingTos' in value)) return false;
    if (!('enableCompanyDomains' in value)) return false;
    if (!('enableCompanyEvents' in value)) return false;
    if (!('enableDonations' in value)) return false;
    if (!('enableMaintenance' in value)) return false;
    if (!('enableRecharges' in value)) return false;
    if (!('enableSubscriptionStatistics' in value)) return false;
    if (!('enableSubscriptionStatisticsMock' in value)) return false;
    if (!('enablePayouts' in value)) return false;
    if (!('strictCookiePolicy' in value)) return false;
    if (!('enableCoinbase' in value)) return false;
    if (!('enableWalletConnect' in value)) return false;
    if (!('enableSafeWallet' in value)) return false;
    return true;
}

export function MerchantFeatureFlagsAPIModelFromJSON(json: any): MerchantFeatureFlagsAPIModel {
    return MerchantFeatureFlagsAPIModelFromJSONTyped(json, false);
}

export function MerchantFeatureFlagsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantFeatureFlagsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'disableRecaptcha': json['disableRecaptcha'],
        'disableSignup': json['disableSignup'],
        'disableBtc': json['disableBtc'],
        'disableCustomers': json['disableCustomers'],
        'disableEmailAuth': json['disableEmailAuth'],
        'disableGoogleAuth': json['disableGoogleAuth'],
        'disableReports': json['disableReports'],
        'disableSubscriptions': json['disableSubscriptions'],
        'disableTawk': json['disableTawk'],
        'disableWeb3Auth': json['disableWeb3Auth'],
        'enableWeb3AuthKeyExport': json['enableWeb3AuthKeyExport'],
        'enableWeb3AuthReset': json['enableWeb3AuthReset'],
        'disableTron': json['disableTron'],
        'disableSolana': json['disableSolana'],
        'enableAuxAmount': json['enableAuxAmount'],
        'enableReconciliation': json['enableReconciliation'],
        'enableGasHistory': json['enableGasHistory'],
        'enableBranding': json['enableBranding'],
        'enableBrandingContacts': json['enableBrandingContacts'],
        'enableBrandingLogo': json['enableBrandingLogo'],
        'enableBrandingPoweredBy': json['enableBrandingPoweredBy'],
        'enableBrandingTos': json['enableBrandingTos'],
        'enableCompanyDomains': json['enableCompanyDomains'],
        'enableCompanyEvents': json['enableCompanyEvents'],
        'enableDonations': json['enableDonations'],
        'enableMaintenance': json['enableMaintenance'],
        'enableRecharges': json['enableRecharges'],
        'enableSubscriptionStatistics': json['enableSubscriptionStatistics'],
        'enableSubscriptionStatisticsMock': json['enableSubscriptionStatisticsMock'],
        'enablePayouts': json['enablePayouts'],
        'strictCookiePolicy': json['strictCookiePolicy'],
        'enableCoinbase': json['enableCoinbase'],
        'enableWalletConnect': json['enableWalletConnect'],
        'enableSafeWallet': json['enableSafeWallet'],
    };
}

export function MerchantFeatureFlagsAPIModelToJSON(value?: MerchantFeatureFlagsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'disableRecaptcha': value['disableRecaptcha'],
        'disableSignup': value['disableSignup'],
        'disableBtc': value['disableBtc'],
        'disableCustomers': value['disableCustomers'],
        'disableEmailAuth': value['disableEmailAuth'],
        'disableGoogleAuth': value['disableGoogleAuth'],
        'disableReports': value['disableReports'],
        'disableSubscriptions': value['disableSubscriptions'],
        'disableTawk': value['disableTawk'],
        'disableWeb3Auth': value['disableWeb3Auth'],
        'enableWeb3AuthKeyExport': value['enableWeb3AuthKeyExport'],
        'enableWeb3AuthReset': value['enableWeb3AuthReset'],
        'disableTron': value['disableTron'],
        'disableSolana': value['disableSolana'],
        'enableAuxAmount': value['enableAuxAmount'],
        'enableReconciliation': value['enableReconciliation'],
        'enableGasHistory': value['enableGasHistory'],
        'enableBranding': value['enableBranding'],
        'enableBrandingContacts': value['enableBrandingContacts'],
        'enableBrandingLogo': value['enableBrandingLogo'],
        'enableBrandingPoweredBy': value['enableBrandingPoweredBy'],
        'enableBrandingTos': value['enableBrandingTos'],
        'enableCompanyDomains': value['enableCompanyDomains'],
        'enableCompanyEvents': value['enableCompanyEvents'],
        'enableDonations': value['enableDonations'],
        'enableMaintenance': value['enableMaintenance'],
        'enableRecharges': value['enableRecharges'],
        'enableSubscriptionStatistics': value['enableSubscriptionStatistics'],
        'enableSubscriptionStatisticsMock': value['enableSubscriptionStatisticsMock'],
        'enablePayouts': value['enablePayouts'],
        'strictCookiePolicy': value['strictCookiePolicy'],
        'enableCoinbase': value['enableCoinbase'],
        'enableWalletConnect': value['enableWalletConnect'],
        'enableSafeWallet': value['enableSafeWallet'],
    };
}

