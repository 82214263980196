import { css, cx } from '@emotion/css';
import { Space } from 'antd';
import React, { useMemo } from 'react';

import { DataFetchError } from '@/components';
import { useBlockchain } from '@/features/dictionary/blockchain/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { useStateMountSafe } from '@/hooks';
import { emptyWith, withFinally } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';

import { EVMOperations, SolanaOperations, Web3WalletNotSupportedOperation } from './components';

import type { Web3WalletContainerProps } from './types';

const Web3WalletContainer = <
  Success = unknown,
  Account = string,
  BT extends BlockchainTypeAPIModel | undefined = undefined,
  EVMExtraProps = undefined,
  SolanaExtraProps = undefined,
>({
  'data-test': dataTest,
  onSuccess,
  description,
  api,
  bt,
  withInProgress: baseWithInProgress,
  inProgressMode,
  className,
  style,
  cancellation,
  evm,
  solana,
}: Web3WalletContainerProps<Success, Account, BT, EVMExtraProps, SolanaExtraProps>) => {
  const bcState = useBlockchain(!api ? bt : undefined);
  const apiType = api ?? bcState.data.data?.apiType;
  const [inProgress, setInProgress] = useStateMountSafe<string>();
  const withInProgress = useMemo(
    () =>
      (selected: string) =>
      <V extends unknown[] = unknown[]>(func: Func<V, Success>): Func<V, Success> =>
      async (...args: V) => {
        setInProgress(selected);
        return withFinally((baseWithInProgress?.(selected) ?? emptyWith)(func), () => setInProgress(undefined))(
          ...args,
        );
      },
    [baseWithInProgress, setInProgress],
  );

  return (
    <Space
      direction="vertical"
      style={style}
      className={cx(
        css`
          display: flex;
          flex: 1;
        `,
        className,
      )}
    >
      {description}
      <Space
        direction="vertical"
        className={css`
          display: flex;
          flex: 1;
        `}
        size="middle"
      >
        {!apiType && bcState.data.error && (
          <DataFetchError data-test={dataTest && `${dataTest}-error`} refresh={bcState.forceRefresh} />
        )}
        {apiType === BlockchainAPITypeAPIModel.EVM
          && (evm?.Operation ? (
            <EVMOperations<Success, Account, BT, EVMExtraProps>
              key="evm"
              data-test={dataTest && `${dataTest}-ops`}
              bt={bt}
              onSuccess={onSuccess}
              inProgress={inProgress}
              inProgressMode={inProgressMode}
              withInProgress={withInProgress}
              cancellation={cancellation}
              {...evm}
            />
          ) : (
            <Web3WalletNotSupportedOperation api={apiType} />
          ))}
        {apiType === BlockchainAPITypeAPIModel.Solana
          && (solana?.Operation ? (
            <SolanaOperations<Success, Account, BT, SolanaExtraProps>
              key="solana"
              data-test={dataTest && `${dataTest}-ops`}
              bt={bt}
              onSuccess={onSuccess}
              inProgress={inProgress}
              inProgressMode={inProgressMode}
              withInProgress={withInProgress}
              cancellation={cancellation}
              {...solana}
            />
          ) : (
            <Web3WalletNotSupportedOperation api={apiType} />
          ))}
        {(apiType === BlockchainAPITypeAPIModel.BTC || apiType === BlockchainAPITypeAPIModel.Tron) && (
          <Web3WalletNotSupportedOperation api={apiType} />
        )}
      </Space>
    </Space>
  );
};

const Web3WalletContainerMemo = React.memo(Web3WalletContainer) as typeof Web3WalletContainer;

export default Web3WalletContainerMemo;
