import { css } from '@emotion/css';
import { Space, Typography } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { GasWalletDerivedList } from '@/features/gas-wallets/components';
import type { GasWalletDerived } from '@/features/gas-wallets/types';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { stringComparator } from '@/infrastructure/model/comparators';

import { withGasWalletDataLoading } from './hocs';

import type { GasWalletDerivedCardProps } from './types';

const { Paragraph } = Typography;

const GasWalletCard: React.FC<GasWalletDerivedCardProps> = ({ data, 'data-test': dataTest }) => {
  const sorted = useMemo(
    () => [...data.derived].sort(stringComparator<GasWalletDerived>(({ address }) => address)('ASC')),
    [data.derived],
  );
  return (
    <Space direction="vertical" className={css`display: flex`}>
      <Paragraph data-test={dataTest && `${dataTest}-description`}>
        <FormattedMessage id={I18nPageSettings.COMPANY_GAS_WALLET_COMPONENTS_DERIVED_CARD_DESCRIPTION} />
      </Paragraph>
      <GasWalletDerivedList data-test={dataTest && `${dataTest}-list`} data={sorted} />
    </Space>
  );
};

const GasWalletCardHOC = withGasWalletDataLoading(GasWalletCard);

const GasWalletCardMemo = React.memo(GasWalletCardHOC) as typeof GasWalletCardHOC;

export default GasWalletCardMemo;
