import { Tag } from 'antd';
import React from 'react';

import { ReadonlyComponent } from '@/components';
import { withBlockchainAPIType } from '@/features/dictionary/blockchain/hocs';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import BlockchainAPIIcon from '../BlockchainAPIIcon';

import type { BlockchainAPILabelProps } from './types';

const tags: Record<BlockchainAPITypeAPIModel, string> = {
  [BlockchainAPITypeAPIModel.BTC]: 'Bitcoin',
  [BlockchainAPITypeAPIModel.Solana]: 'Solana',
  [BlockchainAPITypeAPIModel.EVM]: 'EVM based',
  [BlockchainAPITypeAPIModel.Tron]: 'Tron',
};

const labels: Record<BlockchainAPITypeAPIModel, string> = {
  [BlockchainAPITypeAPIModel.BTC]: 'Bitcoin',
  [BlockchainAPITypeAPIModel.Solana]: 'Solana',
  [BlockchainAPITypeAPIModel.EVM]: 'EVM based',
  [BlockchainAPITypeAPIModel.Tron]: 'Tron',
};

const BlockchainAPILabel: React.FC<BlockchainAPILabelProps> = ({
  api,
  'data-test': dataTest,
  mode = 'medium',
  className,
  style,
  copyable = true,
}) => {
  switch (mode) {
    case 'tag':
      return (
        <Tag data-test={dataTest} className={className} style={style}>
          {tags[api]}
        </Tag>
      );
    case 'short':
      return (
        <ReadonlyComponent
          className={className}
          style={style}
          value={labels[api]}
          data-test={dataTest}
          copyable={copyable}
        />
      );
    case 'medium':
      return (
        <ReadonlyComponent
          className={className}
          style={style}
          value={
            <>
              <BlockchainAPIIcon api={api} />
              {labels[api]}
            </>
          }
          data-test={dataTest}
          copyable={copyable}
        />
      );
  }
};
const BlockchainAPILabelHOC = withBlockchainAPIType<BlockchainAPILabelProps>(BlockchainAPILabel);
const BlockchainAPILabelMemo = React.memo(BlockchainAPILabelHOC);

export default BlockchainAPILabelMemo;
