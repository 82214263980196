import { SolanaSignAndSendTransaction, SolanaSignMessage } from '@solana/wallet-standard-features';
import { StandardConnect, StandardDisconnect } from '@wallet-standard/core';
import { useWallets } from '@wallet-standard/react';
import { useContext, useEffect, useMemo, useRef } from 'react';

import Web3SolanaInitContext from '@/features/web3/hocs/withWeb3Support/hocs/withSolanaWalletsSupport/context';
import type { SolanaWalletMeta, Web3SolanaApi } from '@/features/web3/types';
import { useStateMountSafe } from '@/hooks';
import { onlyUnique } from '@/infrastructure/utils/functions';

import useWalletsSafe from './useWalletsSafe';

export type UseWeb3SolanaWallets = Web3SolanaApi;

export default function useWeb3SolanaWallets(): UseWeb3SolanaWallets {
  const { walletsError } = useContext(Web3SolanaInitContext);
  // Yes, we are violating the React Hooks rules, but this code is wrapped by the SolanaWalletErrorBoundary, which
  // manages the walletsError from Web3SolanaInitContext. This ensures that if an error occurs, the entire component
  // tree is rebuilt, and the new tree will contain a static walletsError, meaning no dynamic hook appearance takes place
  const baseWallets = (walletsError ? useWalletsSafe : useWallets)();
  const { supportedWallets } = useContext(Web3SolanaInitContext);
  const [ready, setReady] = useStateMountSafe<string[]>([]);
  const setUpFor = useRef(0);
  useEffect(() => {
    if (setUpFor.current !== baseWallets.length) {
      setUpFor.current = baseWallets.length;
      baseWallets.forEach((w) => {
        setReady((prev) => [...prev, w.name.toLowerCase()].filter(onlyUnique));
      });
    }
  }, [baseWallets, setReady]);
  const wallets: Web3SolanaApi['wallets'] = useMemo(
    () =>
      supportedWallets
        .map((id) => ({
          id,
          wallet: baseWallets.find(
            (wallet) => wallet.name.toLowerCase() === id.toLowerCase() && wallet.features.includes(SolanaSignMessage),
          ),
        }))
        .map(({ id, wallet }): SolanaWalletMeta => {
          const canSignMessage = !!wallet?.features.includes(SolanaSignMessage);
          const canSendTransaction = !!wallet?.features.includes(SolanaSignAndSendTransaction);
          const connectable = !!wallet?.features.includes(StandardConnect);
          const disconnectable = !!wallet?.features.includes(StandardDisconnect);

          return {
            id,
            wallet,
            available: ready.includes(id),
            isLocal: false,
            disconnectable,
            connectable,
            canSignMessage,
            canSendTransaction,
          };
        }),
    [baseWallets, ready, supportedWallets],
  );
  const orderedWallets = useMemo(
    () => [...wallets.filter(({ available }) => available), ...wallets.filter(({ available }) => !available)],
    [wallets],
  );

  return { wallets, orderedWallets };
}
