import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import listenerMiddleware from '@/app/listenerMiddleware';
import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { extractQRContractId } from '@/features/company-settings/utils';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { createNotification } from '@/features/global/actions';
import { ForwarderDelegateStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';
import { auditQRDelegateContractViewLink } from '@/pages/audit/routes';

import {
  markQRDelegateContractsDirty,
  storeQRDelegateContracts,
  markCompanySettingsDirty,
  storeCompanySettings,
  markAPIKeysDirty,
  storeAPIKeys,
  storeDeployingQRDelegateContractsRefreshableAfter,
} from './actions';
import { makeSelectDeployingQRDelegateContracts, makeSelectQRDelegateContracts } from './selectors';
import { type CompanySettingsState } from './types';

import type { Draft } from 'immer';

const initialState: CompanySettingsState = {
  settings: storedDirtyData,
  keys: storedDirtyData,
  qr: { delegates: storedDirtyData, refreshableAfter: new Date() },
};

const { storeCompanySettingsReducer, markCompanySettingsDirtyReducer } = createLoadingDataReducers(
  'CompanySettings',
  (state: Draft<CompanySettingsState>) => state.settings,
  (state, settings) => ({ ...state, settings }),
);

const { storeAPIKeysReducer, markAPIKeysDirtyReducer } = createLoadingDataReducers(
  'APIKeys',
  (state: Draft<CompanySettingsState>) => state.keys,
  (state, keys) => ({ ...state, keys }),
);

const { storeQRDelegateContractsReducer, markQRDelegateContractsDirtyReducer } = createLoadingDataReducers(
  'QRDelegateContracts',
  (state: Draft<CompanySettingsState>) => state.qr.delegates,
  (state, delegates) => ({ ...state, qr: { ...state.qr, delegates } }),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markCompanySettingsDirty, markCompanySettingsDirtyReducer)
    .addCase(storeCompanySettings, storeCompanySettingsReducer)

    .addCase(markAPIKeysDirty, markAPIKeysDirtyReducer)
    .addCase(storeAPIKeys, storeAPIKeysReducer)

    .addCase(markQRDelegateContractsDirty, markQRDelegateContractsDirtyReducer)
    .addCase(storeQRDelegateContracts, storeQRDelegateContractsReducer)

    .addCase(storeDeployingQRDelegateContractsRefreshableAfter, (state, { payload: refreshableAfter }) => ({
      ...state,
      qr: { ...state.qr, refreshableAfter },
    }))

    .addCase(notifyNetworkUpdated, () => initialState)
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address || previous?.activeCompanyId !== current?.activeCompanyId
        ? initialState
        : state,
    );

  listenerMiddleware.startListening({
    matcher: isAnyOf(storeQRDelegateContracts),
    effect: (_, listenerApi) => {
      const wereDeploying =
        makeSelectDeployingQRDelegateContracts()(listenerApi.getOriginalState()).data?.map(extractQRContractId) ?? [];
      if (wereDeploying.length) {
        const updated =
          makeSelectQRDelegateContracts()(listenerApi.getState()).data?.filter(({ id }) =>
            wereDeploying.includes(id),
          ) ?? [];
        const succeeded = updated.filter(({ status }) => ForwarderDelegateStatusAPIModel.Deployed === status);
        const failed = updated.filter(({ status }) => ForwarderDelegateStatusAPIModel.Failed === status);

        succeeded.forEach((contract) =>
          listenerApi.dispatch(
            createNotification({
              severity: 'success',
              messageI18n: I18nFeatureCompanySettings.MESSAGES_QR_DEPLOYING_FINISHED_SUCCESS_MESSAGES,
              descriptionI18n: I18nFeatureCompanySettings.MESSAGES_QR_DEPLOYING_FINISHED_SUCCESS_DESCRIPTION,
              descriptionI18nValues: {
                ln: { type: 'link', to: auditQRDelegateContractViewLink(contract.blockchain) },
                bt: contract.blockchain,
              },
            }),
          ),
        );

        failed.forEach((contract) =>
          listenerApi.dispatch(
            createNotification({
              severity: 'success',
              messageI18n: I18nFeatureCompanySettings.MESSAGES_QR_DEPLOYING_FINISHED_FAILURE,
              messageI18nValues: { ln: { type: 'link', to: auditQRDelegateContractViewLink(contract.blockchain) } },
            }),
          ),
        );
      }
    },
  });
});

export default reducer;
