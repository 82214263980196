import { CheckOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Modal } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { defaultDialogFormTailLayout, FormattedMessage, Operation } from '@/components';
import { useAuthActions } from '@/features/auth/hooks';
import { useActiveCompany } from '@/features/company/hooks';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';
import { useFormVisible, useNotification } from '@/hooks';

import { SelectCompanyForm } from './components';

import type { OperationSelectCompanyProps } from './types';

const notificationOptions = {
  error: () => <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_SELECT_COMPANY_MESSAGE_ERROR} />,
  success: () => <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_SELECT_COMPANY_MESSAGE_SUCCESS} />,
};

const OperationSelectCompany: React.FC<OperationSelectCompanyProps> = ({
  'data-test': dataTest,
  title,
  disabled,
  inProgress,
  mode = 'inline',
  value,
}) => {
  const { data: company } = useActiveCompany();
  const { relogin } = useAuthActions();
  const formControl = useFormVisible(false);
  const { withNotification } = useNotification();

  const updateCompany = useMemo(
    () => withNotification(formControl.withFormHide(relogin.act), notificationOptions),
    [withNotification, formControl, relogin.act],
  );

  const onClick = useCallback(
    () => (value ? updateCompany(value) : formControl.show()),
    [formControl, updateCompany, value],
  );

  return (
    <>
      {formControl.visible && (
        <Modal
          open
          footer={null}
          title={<FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_SELECT_COMPANY_FORM_TITLE} />}
          closable
          onCancel={formControl.hide}
        >
          <SelectCompanyForm
            values={{ company: company.data?.id }}
            layout={{ labelCol: { span: 0 } }}
            tailLayout={defaultDialogFormTailLayout}
            data-test={dataTest && `${dataTest}-form`}
            onReset={formControl.hide}
            onSubmit={updateCompany}
          />
        </Modal>
      )}
      <Operation
        title={title ?? <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_SELECT_COMPANY_LABEL} />}
        icon={<CheckOutlined />}
        onClick={onClick}
        className={css`
          &.ant-typography {
            color: unset;
          }
        `}
        disabled={disabled || (!!value && value === company.data?.id)}
        inProgress={inProgress || formControl.visible}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationSelectCompanyMemo = React.memo(OperationSelectCompany);

export default OperationSelectCompanyMemo;
