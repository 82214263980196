/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  SettleNowRequestAPIModel,
  SettlementIntentAPIModel,
  SettlementIntentFilterAPIModel,
  SettlementIntentTransactionAPIModel,
  SettlementIntentTransactionDetailsAPIModel,
  SettlementIntentTransactionFilterAPIModel,
  SliceSettlementIntentSettlementIntentSortByAPIModel,
  SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    SettleNowRequestAPIModelFromJSON,
    SettleNowRequestAPIModelToJSON,
    SettlementIntentAPIModelFromJSON,
    SettlementIntentAPIModelToJSON,
    SettlementIntentFilterAPIModelFromJSON,
    SettlementIntentFilterAPIModelToJSON,
    SettlementIntentTransactionAPIModelFromJSON,
    SettlementIntentTransactionAPIModelToJSON,
    SettlementIntentTransactionDetailsAPIModelFromJSON,
    SettlementIntentTransactionDetailsAPIModelToJSON,
    SettlementIntentTransactionFilterAPIModelFromJSON,
    SettlementIntentTransactionFilterAPIModelToJSON,
    SliceSettlementIntentSettlementIntentSortByAPIModelFromJSON,
    SliceSettlementIntentSettlementIntentSortByAPIModelToJSON,
    SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModelFromJSON,
    SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModelToJSON,
} from '../models/index';

export interface GetSettlementIntentByIdRequest {
    intentId: string;
}

export interface GetSettlementIntentTransactionRequest {
    txId: string;
}

export interface GetSettlementIntentTransactionDetailsRequest {
    txId: string;
}

export interface SearchSettlementIntentTransactionsRequest {
    settlementIntentTransactionFilterAPIModel: SettlementIntentTransactionFilterAPIModel;
    withTotal?: boolean;
}

export interface SearchSettlementIntentsRequest {
    settlementIntentFilterAPIModel: SettlementIntentFilterAPIModel;
    withTotal?: boolean;
}

export interface TriggerSettleNowRequest {
    settleNowRequestAPIModel: SettleNowRequestAPIModel;
}

/**
 * SettlementIntentApi - interface
 * 
 * @export
 * @interface SettlementIntentApiInterface
 */
export interface SettlementIntentApiInterface {
    /**
     * Returns settlement intent
     * @param {string} intentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementIntentApiInterface
     */
    getSettlementIntentByIdRaw(requestParameters: GetSettlementIntentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SettlementIntentAPIModel>>;

    /**
     * Returns settlement intent
     */
    getSettlementIntentById(intentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SettlementIntentAPIModel>;

    /**
     * Returns settlement intent transaction
     * @param {string} txId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementIntentApiInterface
     */
    getSettlementIntentTransactionRaw(requestParameters: GetSettlementIntentTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SettlementIntentTransactionAPIModel>>;

    /**
     * Returns settlement intent transaction
     */
    getSettlementIntentTransaction(txId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SettlementIntentTransactionAPIModel>;

    /**
     * Returns settlement intent transaction details
     * @param {string} txId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementIntentApiInterface
     */
    getSettlementIntentTransactionDetailsRaw(requestParameters: GetSettlementIntentTransactionDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SettlementIntentTransactionDetailsAPIModel>>;

    /**
     * Returns settlement intent transaction details
     */
    getSettlementIntentTransactionDetails(txId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SettlementIntentTransactionDetailsAPIModel>;

    /**
     * Returns settlement intent transactions
     * @param {SettlementIntentTransactionFilterAPIModel} settlementIntentTransactionFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementIntentApiInterface
     */
    searchSettlementIntentTransactionsRaw(requestParameters: SearchSettlementIntentTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModel>>;

    /**
     * Returns settlement intent transactions
     */
    searchSettlementIntentTransactions(settlementIntentTransactionFilterAPIModel: SettlementIntentTransactionFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModel>;

    /**
     * Returns scheduled settlement intents by filter
     * @param {SettlementIntentFilterAPIModel} settlementIntentFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementIntentApiInterface
     */
    searchSettlementIntentsRaw(requestParameters: SearchSettlementIntentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceSettlementIntentSettlementIntentSortByAPIModel>>;

    /**
     * Returns scheduled settlement intents by filter
     */
    searchSettlementIntents(settlementIntentFilterAPIModel: SettlementIntentFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceSettlementIntentSettlementIntentSortByAPIModel>;

    /**
     * Trigger settle for the specific asset
     * @param {SettleNowRequestAPIModel} settleNowRequestAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementIntentApiInterface
     */
    triggerSettleNowRaw(requestParameters: TriggerSettleNowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SettlementIntentAPIModel>>;

    /**
     * Trigger settle for the specific asset
     */
    triggerSettleNow(settleNowRequestAPIModel: SettleNowRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SettlementIntentAPIModel>;

}

/**
 * 
 */
export class SettlementIntentApi extends runtime.BaseAPI implements SettlementIntentApiInterface {

    /**
     * Returns settlement intent
     */
    async getSettlementIntentByIdRaw(requestParameters: GetSettlementIntentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SettlementIntentAPIModel>> {
        if (requestParameters['intentId'] == null) {
            throw new runtime.RequiredError(
                'intentId',
                'Required parameter "intentId" was null or undefined when calling getSettlementIntentById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settlements/intents/{intentId}`.replace(`{${"intentId"}}`, encodeURIComponent(String(requestParameters['intentId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettlementIntentAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns settlement intent
     */
    async getSettlementIntentById(intentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SettlementIntentAPIModel> {
        const response = await this.getSettlementIntentByIdRaw({ intentId: intentId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns settlement intent transaction
     */
    async getSettlementIntentTransactionRaw(requestParameters: GetSettlementIntentTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SettlementIntentTransactionAPIModel>> {
        if (requestParameters['txId'] == null) {
            throw new runtime.RequiredError(
                'txId',
                'Required parameter "txId" was null or undefined when calling getSettlementIntentTransaction().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settlements/intents/transactions/{txId}`.replace(`{${"txId"}}`, encodeURIComponent(String(requestParameters['txId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettlementIntentTransactionAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns settlement intent transaction
     */
    async getSettlementIntentTransaction(txId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SettlementIntentTransactionAPIModel> {
        const response = await this.getSettlementIntentTransactionRaw({ txId: txId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns settlement intent transaction details
     */
    async getSettlementIntentTransactionDetailsRaw(requestParameters: GetSettlementIntentTransactionDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SettlementIntentTransactionDetailsAPIModel>> {
        if (requestParameters['txId'] == null) {
            throw new runtime.RequiredError(
                'txId',
                'Required parameter "txId" was null or undefined when calling getSettlementIntentTransactionDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settlements/intents/transactions/{txId}/details`.replace(`{${"txId"}}`, encodeURIComponent(String(requestParameters['txId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettlementIntentTransactionDetailsAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns settlement intent transaction details
     */
    async getSettlementIntentTransactionDetails(txId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SettlementIntentTransactionDetailsAPIModel> {
        const response = await this.getSettlementIntentTransactionDetailsRaw({ txId: txId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns settlement intent transactions
     */
    async searchSettlementIntentTransactionsRaw(requestParameters: SearchSettlementIntentTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModel>> {
        if (requestParameters['settlementIntentTransactionFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'settlementIntentTransactionFilterAPIModel',
                'Required parameter "settlementIntentTransactionFilterAPIModel" was null or undefined when calling searchSettlementIntentTransactions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settlements/intents/transactions/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettlementIntentTransactionFilterAPIModelToJSON(requestParameters['settlementIntentTransactionFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns settlement intent transactions
     */
    async searchSettlementIntentTransactions(settlementIntentTransactionFilterAPIModel: SettlementIntentTransactionFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModel> {
        const response = await this.searchSettlementIntentTransactionsRaw({ settlementIntentTransactionFilterAPIModel: settlementIntentTransactionFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

    /**
     * Returns scheduled settlement intents by filter
     */
    async searchSettlementIntentsRaw(requestParameters: SearchSettlementIntentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceSettlementIntentSettlementIntentSortByAPIModel>> {
        if (requestParameters['settlementIntentFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'settlementIntentFilterAPIModel',
                'Required parameter "settlementIntentFilterAPIModel" was null or undefined when calling searchSettlementIntents().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settlements/intents/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettlementIntentFilterAPIModelToJSON(requestParameters['settlementIntentFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceSettlementIntentSettlementIntentSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns scheduled settlement intents by filter
     */
    async searchSettlementIntents(settlementIntentFilterAPIModel: SettlementIntentFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceSettlementIntentSettlementIntentSortByAPIModel> {
        const response = await this.searchSettlementIntentsRaw({ settlementIntentFilterAPIModel: settlementIntentFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

    /**
     * Trigger settle for the specific asset
     */
    async triggerSettleNowRaw(requestParameters: TriggerSettleNowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SettlementIntentAPIModel>> {
        if (requestParameters['settleNowRequestAPIModel'] == null) {
            throw new runtime.RequiredError(
                'settleNowRequestAPIModel',
                'Required parameter "settleNowRequestAPIModel" was null or undefined when calling triggerSettleNow().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settlements/intents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettleNowRequestAPIModelToJSON(requestParameters['settleNowRequestAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettlementIntentAPIModelFromJSON(jsonValue));
    }

    /**
     * Trigger settle for the specific asset
     */
    async triggerSettleNow(settleNowRequestAPIModel: SettleNowRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SettlementIntentAPIModel> {
        const response = await this.triggerSettleNowRaw({ settleNowRequestAPIModel: settleNowRequestAPIModel }, initOverrides);
        return await response.value();
    }

}
