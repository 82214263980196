import { useMemo } from 'react';

import { noSingleDataIdFn, type UseAppSingleData, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchSignupStatus } from '@/features/auth/actions';
import { makeSelectAddressSignupStatus } from '@/features/auth/selectors';
import { makeSelectPending } from '@/features/global/selectors';
import type { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { type LoadingStateWithDirty } from '@/infrastructure/model';

export type UseAddressSignedUpType = UseAppSingleData<boolean>;

const fetchFactory = (
  { address, addressType }: { addressType: BlockchainAPITypeAPIModel; address: string },
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<boolean>, Parameters<typeof fetchSignupStatus>[0]> =>
  fetchSignupStatus({ address, addressType, force });

const dataSelector = ({ address }: { addressType: BlockchainAPITypeAPIModel; address: string }) =>
  makeSelectAddressSignupStatus(address);

export default function useAddressSignedUp(
  addressType: BlockchainAPITypeAPIModel,
  address: string | undefined,
): UseAddressSignedUpType {
  const id = useMemo(() => (address ? { address, addressType } : undefined), [address, addressType]);
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchSignupStatus, address), [address]);
  return useAppSingleData(fetchFactory, dataSelector, noSingleDataIdFn(), id, dataFetchingSelector);
}
