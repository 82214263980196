import { createWebhookId } from '@/features/company-settings/utils';
import { MerchantCompaniesApi, type RenewEmailConfirmationResponseAPIModel } from '@/generated/api/ncps-api';
import type {
  BlockchainNetworkTypeAPIModel,
  CompanyNotificationSettingsAPIModel,
  CompanySettingsAPIModel,
  ForwarderDelegateAPIModel,
  ForwarderSettingsAPIModel,
  UpdatePaymentSettingsAPIModel,
  KYTToleranceLevelAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { CompanyApi, CompanyKeysApi, ForwarderDelegateStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { apiConfigurationFactory, coreConfigurationFactory } from '@/infrastructure/api.provider';

import { QRDelegateContractIssue } from './types';

import type { CompanySettings } from './types';

const companyApi = new CompanyApi(coreConfigurationFactory('auth'));
const companyKeysApi = new CompanyKeysApi(coreConfigurationFactory('auth'));
const companiesApiNoAuth = new MerchantCompaniesApi(apiConfigurationFactory('public'));
const companiesApiAuth = new MerchantCompaniesApi(apiConfigurationFactory('auth'));

const parseSettings = ({
  activeAssets,
  notificationSettings,
  email,
  gasWallets,
  forwarderSettings,
  defaultAsset,
  ...rest
}: CompanySettingsAPIModel): CompanySettings => ({
  ...rest,
  activeAssets: activeAssets ?? [],
  webhooks: notificationSettings?.map((webhook) => ({ ...webhook, id: createWebhookId(webhook) })) ?? [],
  qrSupport: forwarderSettings ?? [],
  email: email?.endsWith('@mmail.ink') ? '' : email,
  statisticsAsset: defaultAsset,
});

export const querySettings = async (initOverrides?: RequestInit): Promise<CompanySettings> =>
  parseSettings(await companyApi.getCompanySettings(initOverrides));

export const requestUpdateActiveAssets = async (
  activeAssets: string[],
  initOverrides?: RequestInit,
): Promise<CompanySettings> => parseSettings(await companyApi.updateActiveAssets({ activeAssets }, initOverrides));
export const requestUpdateForwarderSettings = async (
  settings: ForwarderSettingsAPIModel[],
  initOverrides?: RequestInit,
): Promise<CompanySettings> => parseSettings(await companyApi.updateForwarderSettings({ settings }, initOverrides));
export const requestUpdateDefaultAsset = async (asset: string, initOverrides?: RequestInit): Promise<CompanySettings> =>
  parseSettings(await companyApi.updateDefaultAsset({ asset }, initOverrides));
export const requestUpdateWebsite = async (url: string, initOverrides?: RequestInit): Promise<CompanySettings> =>
  parseSettings(await companyApi.updateWebsite({ url }, initOverrides));
export const requestUpdatePaymentSettings = async (
  paymentSetting: UpdatePaymentSettingsAPIModel,
  initOverrides?: RequestInit,
) => parseSettings(await companyApi.updatePaymentSettings(paymentSetting, initOverrides));
export const requestUpdateKYTLevel = async (
  level: KYTToleranceLevelAPIModel,
  initOverrides?: RequestInit,
): Promise<CompanySettings> => parseSettings(await companyApi.updateKYTToleranceLevel({ level }, initOverrides));
export const requestUpdateWebhooks = async (
  settings: CompanyNotificationSettingsAPIModel[],
  initOverrides?: RequestInit,
) => parseSettings(await companyApi.updateNotification({ settings }, initOverrides));

export const queryAPIKeys = async (initOverrides?: RequestInit) =>
  (await companyKeysApi.getAllApiKeys(initOverrides)).data ?? [];
export const requestAddAPIKey = async (network: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit) =>
  companyKeysApi.createApiKey({ network }, initOverrides);
export const requestDeleteAPIKey = (key: string, network: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit) =>
  companyKeysApi.deleteApiKey(network, key, initOverrides);
export const requestActivateAPIKey = async (
  key: string,
  network: BlockchainNetworkTypeAPIModel,
  initOverrides?: RequestInit,
) => companyKeysApi.activateApiKey(network, key, initOverrides);
export const requestDeactivateAPIKey = async (
  key: string,
  network: BlockchainNetworkTypeAPIModel,
  initOverrides?: RequestInit,
) => companyKeysApi.deactivateApiKey(network, key, initOverrides);

export const requestResetEmail = async (initOverrides?: RequestInit) =>
  parseSettings(await companyApi.resetEmail({}, initOverrides));
export const requestUpdateEmail = async (email: string, initOverrides?: RequestInit) =>
  companiesApiAuth.updateEmail({ email }, initOverrides);
export const requestConfirmEmail = async (
  request: { token: string; email: string; companyId: number },
  initOverrides?: RequestInit,
): Promise<RenewEmailConfirmationResponseAPIModel> => companiesApiNoAuth.confirmEmailNoAuth(request, initOverrides);

const detectDelegateContractIssue = (delegate: ForwarderDelegateAPIModel) => {
  switch (delegate.status) {
    case ForwarderDelegateStatusAPIModel.Defined:
      return QRDelegateContractIssue.NOT_DEPLOYED;
    case ForwarderDelegateStatusAPIModel.Pending:
      return QRDelegateContractIssue.DEPLOYING;
    case ForwarderDelegateStatusAPIModel.Failed:
      return QRDelegateContractIssue.FAILED;
    case ForwarderDelegateStatusAPIModel.Deployed:
      return undefined;
  }
};
const parseDelegateContract = (delegate: ForwarderDelegateAPIModel) => ({
  ...delegate,
  issue: detectDelegateContractIssue(delegate),
});

export const queryQRDelegateContracts = async (initOverrides?: RequestInit) =>
  ((await companyApi.getActiveCompanyForwarderDelegates(initOverrides)).delegates ?? []).map(parseDelegateContract);
export const requestDeployQRDelegateContracts = async (id: string, initOverrides?: RequestInit) =>
  parseDelegateContract(await companyApi.deployActiveCompanyForwarderDelegate(id, initOverrides));
