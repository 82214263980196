import React from 'react';

import type { ReCaptchaParams } from '@/features/recaptcha/types';
import type { Func } from '@/infrastructure/utils/ts';

const RecaptchaActionContext = React.createContext<{
  initialized: boolean;
  wrapped: boolean;
  withReCaptchaAction?: (
    action: string,
  ) => <T extends unknown[] = [], R = unknown>(func: (v: ReCaptchaParams) => Func<T, R>) => (...args: T) => Promise<R>;
}>({ initialized: false, wrapped: false });

export default RecaptchaActionContext;
