import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import type { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { mapStoredState } from '@/infrastructure/model';
import { createLoadingDataSelectors } from '@/infrastructure/model/common/selectors';

import { NAMESPACE } from './types';

export const { makeSelectUser } = createLoadingDataSelectors((state: AppRootState) => state[NAMESPACE].user, 'User');

export const makeSelectUserAddress = (addressType: BlockchainAPITypeAPIModel) =>
  createSelector(makeSelectUser(), (userState) =>
    mapStoredState(userState, (user) => ({
      value: user.addresses.find((address) => address.addressType === addressType),
    })),
  );

export const { makeSelectPreferences } = createLoadingDataSelectors(
  (state: AppRootState) => state[NAMESPACE].preferences,
  'Preferences',
);
