import { createAction } from '@reduxjs/toolkit';
import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { markCompanySettingsDirty } from '@/features/company-settings/actions';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { withAPICall } from '@/infrastructure/model/api';
import { createNormalizedPartialActions } from '@/infrastructure/model/partial/actions';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import {
  queryGasWallet,
  queryGasWallets,
  requestCreateGasWallet,
  requestDeleteGasWallet,
  requestRefundGasWallet,
} from './api';
import { makeSelectGasWallet, makeSelectGasWalletBatch } from './selectors';
import { NAMESPACE } from './types';

import type { NewGasWallet, GasWallet } from './types';
import type { Address } from 'viem';

export const {
  storeGasWallet,
  markGasWalletDirty,
  storeRemoveGasWallet,
  markGasWalletBatchDirty,
  storeGasWalletBatch,
} = createNormalizedPartialActions<GasWallet, 'GasWallet', string, BlockchainTypeAPIModel>(NAMESPACE, 'GasWallet');

export const createGasWallet = createAppAsyncThunk(
  `${NAMESPACE}/createGasWallet`,
  async ({ newGasWallet: { bt, ...newGasWallet } }: { newGasWallet: NewGasWallet }, { dispatch, signal }) => {
    const data = await withAPICall(requestCreateGasWallet, 'unable to create gas wallet')(
      { blockchain: bt, ...newGasWallet },
      { signal },
    );
    if (data.data) {
      dispatch(storeGasWallet({ id: bt, data }));
      dispatch(markGasWalletBatchDirty());
      dispatch(markCompanySettingsDirty());
    }
    return data;
  },
  { idGenerator: ({ newGasWallet: { bt } }) => bt },
);
export const initRefundListener = createAction(`${NAMESPACE}/initRefundListener`);

export const refundGasWallet = createAppAsyncThunk(
  `${NAMESPACE}/refundGasWallet`,
  async ({ bt, to }: { bt: BlockchainTypeAPIModel; to: Address }, { dispatch, signal }) => {
    const data = await withAPICall(requestRefundGasWallet, 'unable to start gas wallet refund')(bt, to, { signal });
    if (data.data) {
      dispatch(storeGasWallet({ id: bt, data }));
      dispatch(markCompanySettingsDirty());
      goalReached(YMGoals.GAS_WALLET_REFUND_TRIGGERED);
    }
    dispatch(initRefundListener());
    return data;
  },
  { idGenerator: ({ bt }) => bt },
);

export const deleteGasWallet = createAppAsyncThunk(
  `${NAMESPACE}/deleteGasWallet`,
  async (bt: BlockchainTypeAPIModel, { dispatch, signal }) => {
    const data = await withAPICall(requestDeleteGasWallet, 'unable to delete gas wallet')(bt, { signal });
    if (data.data) {
      dispatch(storeRemoveGasWallet(bt));
      dispatch(markGasWalletBatchDirty());
      dispatch(markCompanySettingsDirty());
    }
    return data;
  },
  { idGenerator: (bt) => bt },
);

const walletFetchLimit = pLimit(1);
export const fetchGasWallet = createAppAsyncThunk(
  `${NAMESPACE}/fetchGasWallet`,
  async ({ bt, force }: { bt: BlockchainTypeAPIModel; force?: boolean }, { dispatch, getState, signal }) =>
    walletFetchLimit(async () => {
      const saved = makeSelectGasWallet(bt)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryGasWallet, 'unable to fetch gas wallets')(bt, { signal });
      dispatch(storeGasWallet({ id: bt, data }));

      return makeSelectGasWallet(bt)(getState());
    }),
);

export const fetchGasWallets = createAppAsyncThunk(
  `${NAMESPACE}/fetchGasWallets`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    walletFetchLimit(async () => {
      const saved = makeSelectGasWalletBatch()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryGasWallets, 'unable to fetch gas wallets')({ signal });
      dispatch(storeGasWalletBatch(data));

      return makeSelectGasWalletBatch()(getState());
    }),
);
