import { UnlockTwoTone } from '@ant-design/icons';
import React, { useContext, useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { useAuthActions } from '@/features/auth/hooks';
import { withFeatureToggleGuard } from '@/features/feature-toggle/hocs';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useFormVisible } from '@/hooks';

import { SecureWeb3AuthPrivateKeyModal } from './components';

import type { OperationSecureWeb3AuthPrivateKeyProps } from './types';

const OperationSecureWeb3AuthPrivateKey: React.FC<OperationSecureWeb3AuthPrivateKeyProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  onSuccess,
}) => {
  const formControl = useFormVisible(false);
  const { confirmWeb3AuthAddressOwnership } = useAuthActions();
  const { web3Auth } = useContext(Web3AuthContext);

  const doSuccess = useMemo(
    () => (onSuccess ? formControl.withFormHide(onSuccess) : formControl.hide),
    [formControl, onSuccess],
  );
  const doCancel = formControl.hide;
  const doShow = formControl.show;

  return (
    <>
      {formControl.visible && web3Auth?.state && (
        <SecureWeb3AuthPrivateKeyModal
          data-test={dataTest && `${dataTest}-dialog`}
          state={web3Auth.state}
          onSuccess={doSuccess}
          onCancel={doCancel}
        />
      )}
      <Operation
        title={
          title ?? <FormattedMessage id={I18nFeatureAuth.COMPONENTS_OPERATION_SECURE_WEB3AUTH_PRIVATE_KEY_TITLE} />
        }
        tooltip={<FormattedMessage id={I18nFeatureAuth.COMPONENTS_OPERATION_SECURE_WEB3AUTH_PRIVATE_KEY_TOOLTIP} />}
        disabled={!confirmWeb3AuthAddressOwnership.available || !web3Auth?.state}
        icon={<UnlockTwoTone />}
        inProgress={confirmWeb3AuthAddressOwnership.inAction}
        onClick={doShow}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationSecureWeb3AuthPrivateKeyGuarded = withFeatureToggleGuard('enableWeb3AuthKeyExport')(
  OperationSecureWeb3AuthPrivateKey,
);

const OperationSecureWeb3AuthPrivateKeyMemo = React.memo(OperationSecureWeb3AuthPrivateKeyGuarded);

export default OperationSecureWeb3AuthPrivateKeyMemo;
