/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  AuthenticatedUserAPIModel,
  EmailAuthAPIModel,
  TokenWithUserAPIModel,
  Web3AddressAuthParamsAPIModel,
  Web3AddressAuthRequestAPIModel,
  Web3EVMCredentialsAPIModel,
  Web3SolanaCredentialsAPIModel,
} from '../models/index';
import {
    AuthenticatedUserAPIModelFromJSON,
    AuthenticatedUserAPIModelToJSON,
    EmailAuthAPIModelFromJSON,
    EmailAuthAPIModelToJSON,
    TokenWithUserAPIModelFromJSON,
    TokenWithUserAPIModelToJSON,
    Web3AddressAuthParamsAPIModelFromJSON,
    Web3AddressAuthParamsAPIModelToJSON,
    Web3AddressAuthRequestAPIModelFromJSON,
    Web3AddressAuthRequestAPIModelToJSON,
    Web3EVMCredentialsAPIModelFromJSON,
    Web3EVMCredentialsAPIModelToJSON,
    Web3SolanaCredentialsAPIModelFromJSON,
    Web3SolanaCredentialsAPIModelToJSON,
} from '../models/index';

export interface AuthCompanyRequest {
    companyId: number;
}

export interface AuthEVMRequest {
    xRecaptcha: string;
    web3EVMCredentialsAPIModel: Web3EVMCredentialsAPIModel;
    xRecaptchaType?: AuthEVMXRecaptchaTypeEnum;
}

export interface AuthEmailRequest {
    emailAuthAPIModel: EmailAuthAPIModel;
}

export interface AuthSolanaRequest {
    xRecaptcha: string;
    web3SolanaCredentialsAPIModel: Web3SolanaCredentialsAPIModel;
    xRecaptchaType?: AuthSolanaXRecaptchaTypeEnum;
}

export interface RequestAuthWeb3ParamsRequest {
    web3AddressAuthRequestAPIModel: Web3AddressAuthRequestAPIModel;
}

/**
 * MerchantAuthApi - interface
 * 
 * @export
 * @interface MerchantAuthApiInterface
 */
export interface MerchantAuthApiInterface {
    /**
     * Reauthenticate user (refreshes the token) with new company
     * @summary 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantAuthApiInterface
     */
    authCompanyRaw(requestParameters: AuthCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithUserAPIModel>>;

    /**
     * Reauthenticate user (refreshes the token) with new company
     * 
     */
    authCompany(companyId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithUserAPIModel>;

    /**
     * Auth merchant by web3 signature
     * @summary 
     * @param {string} xRecaptcha 
     * @param {Web3EVMCredentialsAPIModel} web3EVMCredentialsAPIModel 
     * @param {'v2' | 'v3'} [xRecaptchaType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantAuthApiInterface
     */
    authEVMRaw(requestParameters: AuthEVMRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithUserAPIModel>>;

    /**
     * Auth merchant by web3 signature
     * 
     */
    authEVM(xRecaptcha: string, web3EVMCredentialsAPIModel: Web3EVMCredentialsAPIModel, xRecaptchaType?: AuthEVMXRecaptchaTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithUserAPIModel>;

    /**
     * Auth merchant by web3 signature with email
     * @summary 
     * @param {EmailAuthAPIModel} emailAuthAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantAuthApiInterface
     */
    authEmailRaw(requestParameters: AuthEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithUserAPIModel>>;

    /**
     * Auth merchant by web3 signature with email
     * 
     */
    authEmail(emailAuthAPIModel: EmailAuthAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithUserAPIModel>;

    /**
     * Auth merchant by web3 signature
     * @summary 
     * @param {string} xRecaptcha 
     * @param {Web3SolanaCredentialsAPIModel} web3SolanaCredentialsAPIModel 
     * @param {'v2' | 'v3'} [xRecaptchaType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantAuthApiInterface
     */
    authSolanaRaw(requestParameters: AuthSolanaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithUserAPIModel>>;

    /**
     * Auth merchant by web3 signature
     * 
     */
    authSolana(xRecaptcha: string, web3SolanaCredentialsAPIModel: Web3SolanaCredentialsAPIModel, xRecaptchaType?: AuthSolanaXRecaptchaTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithUserAPIModel>;

    /**
     * Queries the authenticated user
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantAuthApiInterface
     */
    getAuthenticatedUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthenticatedUserAPIModel>>;

    /**
     * Queries the authenticated user
     * 
     */
    getAuthenticatedUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthenticatedUserAPIModel>;

    /**
     * Queries the registered address nonce to sign the auth message
     * @summary 
     * @param {Web3AddressAuthRequestAPIModel} web3AddressAuthRequestAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantAuthApiInterface
     */
    requestAuthWeb3ParamsRaw(requestParameters: RequestAuthWeb3ParamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Web3AddressAuthParamsAPIModel>>;

    /**
     * Queries the registered address nonce to sign the auth message
     * 
     */
    requestAuthWeb3Params(web3AddressAuthRequestAPIModel: Web3AddressAuthRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Web3AddressAuthParamsAPIModel>;

}

/**
 * 
 */
export class MerchantAuthApi extends runtime.BaseAPI implements MerchantAuthApiInterface {

    /**
     * Reauthenticate user (refreshes the token) with new company
     * 
     */
    async authCompanyRaw(requestParameters: AuthCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithUserAPIModel>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling authCompany().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("dashboard-header", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-bff/auth/company/{companyId}/auth`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters['companyId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenWithUserAPIModelFromJSON(jsonValue));
    }

    /**
     * Reauthenticate user (refreshes the token) with new company
     * 
     */
    async authCompany(companyId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithUserAPIModel> {
        const response = await this.authCompanyRaw({ companyId: companyId }, initOverrides);
        return await response.value();
    }

    /**
     * Auth merchant by web3 signature
     * 
     */
    async authEVMRaw(requestParameters: AuthEVMRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithUserAPIModel>> {
        if (requestParameters['xRecaptcha'] == null) {
            throw new runtime.RequiredError(
                'xRecaptcha',
                'Required parameter "xRecaptcha" was null or undefined when calling authEVM().'
            );
        }

        if (requestParameters['web3EVMCredentialsAPIModel'] == null) {
            throw new runtime.RequiredError(
                'web3EVMCredentialsAPIModel',
                'Required parameter "web3EVMCredentialsAPIModel" was null or undefined when calling authEVM().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['xRecaptcha'] != null) {
            headerParameters['x-recaptcha'] = String(requestParameters['xRecaptcha']);
        }

        if (requestParameters['xRecaptchaType'] != null) {
            headerParameters['x-recaptcha-type'] = String(requestParameters['xRecaptchaType']);
        }

        const response = await this.request({
            path: `/merchant-bff/auth/web3/evm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Web3EVMCredentialsAPIModelToJSON(requestParameters['web3EVMCredentialsAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenWithUserAPIModelFromJSON(jsonValue));
    }

    /**
     * Auth merchant by web3 signature
     * 
     */
    async authEVM(xRecaptcha: string, web3EVMCredentialsAPIModel: Web3EVMCredentialsAPIModel, xRecaptchaType?: AuthEVMXRecaptchaTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithUserAPIModel> {
        const response = await this.authEVMRaw({ xRecaptcha: xRecaptcha, web3EVMCredentialsAPIModel: web3EVMCredentialsAPIModel, xRecaptchaType: xRecaptchaType }, initOverrides);
        return await response.value();
    }

    /**
     * Auth merchant by web3 signature with email
     * 
     */
    async authEmailRaw(requestParameters: AuthEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithUserAPIModel>> {
        if (requestParameters['emailAuthAPIModel'] == null) {
            throw new runtime.RequiredError(
                'emailAuthAPIModel',
                'Required parameter "emailAuthAPIModel" was null or undefined when calling authEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/merchant-bff/auth/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailAuthAPIModelToJSON(requestParameters['emailAuthAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenWithUserAPIModelFromJSON(jsonValue));
    }

    /**
     * Auth merchant by web3 signature with email
     * 
     */
    async authEmail(emailAuthAPIModel: EmailAuthAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithUserAPIModel> {
        const response = await this.authEmailRaw({ emailAuthAPIModel: emailAuthAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Auth merchant by web3 signature
     * 
     */
    async authSolanaRaw(requestParameters: AuthSolanaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithUserAPIModel>> {
        if (requestParameters['xRecaptcha'] == null) {
            throw new runtime.RequiredError(
                'xRecaptcha',
                'Required parameter "xRecaptcha" was null or undefined when calling authSolana().'
            );
        }

        if (requestParameters['web3SolanaCredentialsAPIModel'] == null) {
            throw new runtime.RequiredError(
                'web3SolanaCredentialsAPIModel',
                'Required parameter "web3SolanaCredentialsAPIModel" was null or undefined when calling authSolana().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['xRecaptcha'] != null) {
            headerParameters['x-recaptcha'] = String(requestParameters['xRecaptcha']);
        }

        if (requestParameters['xRecaptchaType'] != null) {
            headerParameters['x-recaptcha-type'] = String(requestParameters['xRecaptchaType']);
        }

        const response = await this.request({
            path: `/merchant-bff/auth/web3/solana`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Web3SolanaCredentialsAPIModelToJSON(requestParameters['web3SolanaCredentialsAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenWithUserAPIModelFromJSON(jsonValue));
    }

    /**
     * Auth merchant by web3 signature
     * 
     */
    async authSolana(xRecaptcha: string, web3SolanaCredentialsAPIModel: Web3SolanaCredentialsAPIModel, xRecaptchaType?: AuthSolanaXRecaptchaTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithUserAPIModel> {
        const response = await this.authSolanaRaw({ xRecaptcha: xRecaptcha, web3SolanaCredentialsAPIModel: web3SolanaCredentialsAPIModel, xRecaptchaType: xRecaptchaType }, initOverrides);
        return await response.value();
    }

    /**
     * Queries the authenticated user
     * 
     */
    async getAuthenticatedUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthenticatedUserAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("dashboard-header", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-bff/auth/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticatedUserAPIModelFromJSON(jsonValue));
    }

    /**
     * Queries the authenticated user
     * 
     */
    async getAuthenticatedUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthenticatedUserAPIModel> {
        const response = await this.getAuthenticatedUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * Queries the registered address nonce to sign the auth message
     * 
     */
    async requestAuthWeb3ParamsRaw(requestParameters: RequestAuthWeb3ParamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Web3AddressAuthParamsAPIModel>> {
        if (requestParameters['web3AddressAuthRequestAPIModel'] == null) {
            throw new runtime.RequiredError(
                'web3AddressAuthRequestAPIModel',
                'Required parameter "web3AddressAuthRequestAPIModel" was null or undefined when calling requestAuthWeb3Params().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/merchant-bff/auth/web3/nonce`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Web3AddressAuthRequestAPIModelToJSON(requestParameters['web3AddressAuthRequestAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Web3AddressAuthParamsAPIModelFromJSON(jsonValue));
    }

    /**
     * Queries the registered address nonce to sign the auth message
     * 
     */
    async requestAuthWeb3Params(web3AddressAuthRequestAPIModel: Web3AddressAuthRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Web3AddressAuthParamsAPIModel> {
        const response = await this.requestAuthWeb3ParamsRaw({ web3AddressAuthRequestAPIModel: web3AddressAuthRequestAPIModel }, initOverrides);
        return await response.value();
    }

}

/**
  * @export
  * @enum {string}
  */
export enum AuthEVMXRecaptchaTypeEnum {
    v2 = 'v2',
    v3 = 'v3'
}
/**
  * @export
  * @enum {string}
  */
export enum AuthSolanaXRecaptchaTypeEnum {
    v2 = 'v2',
    v3 = 'v3'
}
