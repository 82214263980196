import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { type Account, type Chain, type Client, getAddress, parseSignature, type Transport, walletActions } from 'viem';

import { useAppDispatch } from '@/app/hooks';
import { useOwnedCompany } from '@/features/company/hooks';
import { confirmMerchantWalletOwnership } from '@/features/merchant-wallets/actions';
import type { MerchantWalletSignature } from '@/features/merchant-wallets/types';
import { useUserAddress } from '@/features/user/hooks';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';
import { useSubmitting } from '@/hooks';
import { type HookAction } from '@/infrastructure/model';
import { someOrFail } from '@/infrastructure/utils/functions';
import { cancel, type Cancellable, runCancellable } from '@/infrastructure/utils/ui';

import useMerchantWalletOwnership from '../useMerchantWalletOwnership';

export type ConfirmUnavailabilityReason =
  | 'loading'
  | 'already-confirmed'
  | 'no-data'
  | 'no-owned-company'
  | 'no-user-address';

export interface UseMerchantWalletsActionsType {
  confirmOwnership: HookAction<
    [client: Client<Transport, Chain, Account>, { cancellation?: Cancellable }],
    MerchantWalletSignature,
    ConfirmUnavailabilityReason
  >;
}

export default function useMerchantWalletsActions(): UseMerchantWalletsActionsType {
  const { withExtractDataDispatch } = useAppDispatch();
  const ownedCompany = useOwnedCompany();
  const ownershipState = useMerchantWalletOwnership();

  const { formatMessage } = useIntl();
  const userAddressState = useUserAddress(BlockchainAPITypeAPIModel.EVM);

  const confirmUnavailabilityReason: UseMerchantWalletsActionsType['confirmOwnership']['unavailabilityReason'] =
    useMemo(() => {
      if (
        userAddressState.data.isDirty
        || (userAddressState.loading && !userAddressState.data.data)
        || ownedCompany.data.isDirty
        || (ownedCompany.loading && !ownedCompany.data.data)
        || ownershipState.data.isDirty
        || (ownershipState.loading && !ownershipState.data.data)
      )
        return 'loading';
      if (!userAddressState.data.data) return 'no-data';
      if (!userAddressState.data.data.value) return 'no-user-address';
      if (!ownedCompany.data.data) return 'no-owned-company';
      if (ownershipState.data.data) return 'already-confirmed';
      return undefined;
    }, [
      userAddressState.data.isDirty,
      userAddressState.data.data,
      userAddressState.loading,
      ownedCompany.data.isDirty,
      ownedCompany.data.data,
      ownedCompany.loading,
      ownershipState.data.isDirty,
      ownershipState.data.data,
      ownershipState.loading,
    ]);

  const [confirming, withConfirming] = useSubmitting(false);
  const createAction: UseMerchantWalletsActionsType['confirmOwnership']['act'] = useMemo(
    () =>
      withConfirming(async (client, { cancellation } = {}) => {
        const ownerAddress = getAddress(someOrFail(userAddressState.data.data?.value, () => new Error('no-data')));
        const partnerAddress = someOrFail(ownedCompany.data.data, () => new Error('no-owned-company')).partnerAddress;
        const signer = client.extend(walletActions);
        const messageToSign = partnerAddress
          ? formatMessage(
              { id: I18nFeatureMerchantWallets.HOOKS_ACTIONS_CONFIRM_OWNERSHIP_MESSAGE_WITH_BROKER },
              { partner: partnerAddress.toLowerCase() },
            )
          : formatMessage(
              { id: I18nFeatureMerchantWallets.HOOKS_ACTIONS_CONFIRM_OWNERSHIP_MESSAGE_NO_BROKER },
              { address: ownerAddress.toLowerCase() },
            );
        const signature = await (async () => {
          try {
            const rawSignature = await (cancellation
              ? runCancellable(signer.signMessage({ message: messageToSign, account: ownerAddress }), cancellation)
              : await signer.signMessage({ message: messageToSign, account: ownerAddress }));
            return parseSignature(rawSignature);
          } catch {
            throw cancel();
          }
        })();
        return withExtractDataDispatch(confirmMerchantWalletOwnership)({
          signature: { ...signature, v: Number(signature.v) },
          bootstrapMessage: messageToSign,
          brokerAddress: partnerAddress || ownerAddress,
        });
      }),
    [formatMessage, ownedCompany.data.data, userAddressState.data.data?.value, withConfirming, withExtractDataDispatch],
  );
  const confirmOwnership: UseMerchantWalletsActionsType['confirmOwnership'] = {
    act: createAction,
    available: !confirmUnavailabilityReason,
    unavailabilityReason: confirmUnavailabilityReason,
    inAction: confirming,
  };

  return { confirmOwnership };
}
