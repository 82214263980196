/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReportScheduleStatusAPIModel {
    Active = 'Active',
    Disabled = 'Disabled'
}


export function ReportScheduleStatusAPIModelFromJSON(json: any): ReportScheduleStatusAPIModel {
    return ReportScheduleStatusAPIModelFromJSONTyped(json, false);
}

export function ReportScheduleStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportScheduleStatusAPIModel {
    return json as ReportScheduleStatusAPIModel;
}

export function ReportScheduleStatusAPIModelToJSON(value?: ReportScheduleStatusAPIModel | null): any {
    return value as any;
}

