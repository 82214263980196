import type { EmptyObject } from '@/infrastructure/utils/ts';

import { withEVMConnectorsSupport, withSolanaWalletsSupport } from './hocs';

import type React from 'react';

const withWeb3Support = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  withEVMConnectorsSupport(withSolanaWalletsSupport(Component));

export default withWeb3Support;
