import { useContext } from 'react';

import type { Web3SolanaWalletContextType } from '@/features/web3/hocs/withWeb3SolanaWallet/context';
import Web3SolanaWalletContext from '@/features/web3/hocs/withWeb3SolanaWallet/context';
import { someOrFail } from '@/infrastructure/utils/functions';

export default function useWeb3SolanaWallet(): Web3SolanaWalletContextType {
  return someOrFail(
    useContext(Web3SolanaWalletContext),
    () =>
      new Error(
        'No web3 solana wallet is available. Please make sure you wrapped the Component with withWeb3SolanaWallet',
      ),
  );
}
