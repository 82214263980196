import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';

import {
  markChurnRateDirty,
  markGrowthRateDirty,
  markRevenueDirty,
  storeChurnRate,
  storeGrowthRate,
  storeRevenue,
} from './actions';

import type { SubscriptionStatisticsState } from './types';
import type { Draft } from 'immer';

const initialState: SubscriptionStatisticsState = {
  churnRate: storedDirtyData,
  growthRate: storedDirtyData,
  revenue: storedDirtyData,
};

const { markChurnRateDirtyReducer, storeChurnRateReducer } = createLoadingDataReducers(
  'ChurnRate',
  (state: Draft<SubscriptionStatisticsState>) => state.churnRate,
  (global, churnRate) => ({ ...global, churnRate }),
);

const { markGrowthRateDirtyReducer, storeGrowthRateReducer } = createLoadingDataReducers(
  'GrowthRate',
  (state: Draft<SubscriptionStatisticsState>) => state.growthRate,
  (global, growthRate) => ({ ...global, growthRate }),
);

const { markRevenueDirtyReducer } = createLoadingDataReducers(
  'Revenue',
  (state: Draft<SubscriptionStatisticsState>) => state.revenue,
  (global, revenue) => ({ ...global, revenue }),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markChurnRateDirty, markChurnRateDirtyReducer)
    .addCase(storeChurnRate, storeChurnRateReducer)

    .addCase(markGrowthRateDirty, markGrowthRateDirtyReducer)
    .addCase(storeGrowthRate, storeGrowthRateReducer)

    .addCase(markRevenueDirty, markRevenueDirtyReducer)
    .addCase(storeRevenue, (state, { payload: { asset, data } }) => ({
      ...state,
      asset,
      revenue: { ...data, isDirty: false },
    }))

    .addCase(notifyNetworkUpdated, () => initialState)
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address || previous?.activeCompanyId !== current?.activeCompanyId
        ? initialState
        : state,
    );
});

export default reducer;
