import { Navigate, Route, Routes } from 'react-router-dom';

import { BasicLayout } from '@/layouts';
import { withBasicPageGuard } from '@/pages/hocs';
import { useMenuData } from '@/pages/hooks';
import { AppPageRoutes } from '@/pages/routes';

import MerchantWalletBalanceViewPage from './balance-view';
import MerchantWalletBalancesPage from './balances';
import DeployingMerchantWalletsPage from './deploying-wallets';
import QRDelegateContractViewPage from './qr-contract-view';
import QRDelegateContractsPage from './qr-contracts';
import { auditWalletTransfersLink, AuditPageRoutes } from './routes';
import MerchantWalletTransferEntityPage from './transfer-entity';
import MerchantWalletTransferViewPage from './transfer-view';
import MerchantWalletTransfersPage from './transfers';
import MerchantWalletViewPage from './wallet-view';
import MerchantWalletsPage from './wallets';

import type React from 'react';

export const AuditDefaultRoute: React.FC = () => <Navigate to={auditWalletTransfersLink()} replace />;

const MerchantWalletTransferPages: React.FC = () => {
  const menu = useMenuData();
  return (
    <BasicLayout routes={menu}>
      <Routes>
        <Route path={AuditPageRoutes.BALANCES} Component={MerchantWalletBalancesPage} />
        <Route path={AuditPageRoutes.BALANCE_VIEW} Component={MerchantWalletBalanceViewPage} />
        <Route path={AuditPageRoutes.TRANSFERS} Component={MerchantWalletTransfersPage} />
        <Route path={AuditPageRoutes.TRANSFER_VIEW} Component={MerchantWalletTransferViewPage} />
        <Route path={AuditPageRoutes.TRANSFER_ENTITY} Component={MerchantWalletTransferEntityPage} />
        <Route path={AuditPageRoutes.QR_CONTRACTS} Component={QRDelegateContractsPage} />
        <Route path={AuditPageRoutes.QR_CONTRACT_VIEW} Component={QRDelegateContractViewPage} />
        <Route path={AuditPageRoutes.WALLETS} Component={MerchantWalletsPage} />
        <Route path={AuditPageRoutes.WALLET_VIEW} Component={MerchantWalletViewPage} />
        <Route path={AuditPageRoutes.DEPLOYING_WALLETS} Component={DeployingMerchantWalletsPage} />
        <Route path="*" Component={AuditDefaultRoute} />
      </Routes>
    </BasicLayout>
  );
};

const MerchantWalletTransferPagesHOC: React.FC = withBasicPageGuard(MerchantWalletTransferPages);

export default (
  <Route key={AppPageRoutes.AUDIT} path={`${AppPageRoutes.AUDIT}/*`} Component={MerchantWalletTransferPagesHOC} />
);
