/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { CollectThresholdAPIModel } from './CollectThresholdAPIModel';
import {
    CollectThresholdAPIModelFromJSON,
    CollectThresholdAPIModelFromJSONTyped,
    CollectThresholdAPIModelToJSON,
} from './CollectThresholdAPIModel';

/**
 * 
 * @export
 * @interface CollectThresholdsAPIModel
 */
export interface CollectThresholdsAPIModel {
    /**
     * 
     * @type {Array<CollectThresholdAPIModel>}
     * @memberof CollectThresholdsAPIModel
     */
    thresholds?: Array<CollectThresholdAPIModel>;
}

/**
 * Check if a given object implements the CollectThresholdsAPIModel interface.
 */
export function instanceOfCollectThresholdsAPIModel(value: object): boolean {
    return true;
}

export function CollectThresholdsAPIModelFromJSON(json: any): CollectThresholdsAPIModel {
    return CollectThresholdsAPIModelFromJSONTyped(json, false);
}

export function CollectThresholdsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectThresholdsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'thresholds': json['thresholds'] == null ? undefined : ((json['thresholds'] as Array<any>).map(CollectThresholdAPIModelFromJSON)),
    };
}

export function CollectThresholdsAPIModelToJSON(value?: CollectThresholdsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'thresholds': value['thresholds'] == null ? undefined : ((value['thresholds'] as Array<any>).map(CollectThresholdAPIModelToJSON)),
    };
}

