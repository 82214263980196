import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { appPath } from '@/infrastructure/utils/http';

function useURLChanged(onURLChanged: (newURL: string) => void) {
  // inspired by: https://github.com/vercel/next.js/issues/56882
  const previousURL = useRef('');

  const { pathname, search } = useLocation();
  const url = appPath + pathname + search;

  useEffect(() => {
    if (url !== previousURL.current) {
      previousURL.current = url;
      onURLChanged(url);
    }
  }, [onURLChanged, url]);
}

export default useURLChanged;
