import { address as toAddress, createSolanaRpc } from '@solana/kit';
import BigNumber from 'bignumber.js';

import storeHolder from '@/app/store.holder';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { withTimeout } from '@/infrastructure/utils/functions';

import type { createHttpTransport } from '@solana/rpc-transport-http';

export interface BCRpcRequestAPIMeta {
  host: string;
  auth?: boolean;
}

export const getRpcClient = ({ host, auth }: BCRpcRequestAPIMeta) => {
  const token = storeHolder.store ? makeSelectAuthToken()(storeHolder.store.getState()).data?.token : undefined;
  if (auth && !token) {
    console.warn(`No token with required auth for host "${host}`);
  }
  const authHeaders =
    // suppressing validation amid the header is our proxy header and wouldn't propagate to Solana RPC
    (auth && token ? { [window.env.AUTH_HEADER_TOKEN]: `Bearer ${token}` } : {}) as Parameters<
      typeof createHttpTransport
    >[0]['headers'];
  return createSolanaRpc(host, { headers: authHeaders });
};

export const queryNativeBalance = async (
  bcMeta: BCRpcRequestAPIMeta,
  address: string,
  initOverrides?: RequestInit,
): Promise<BigNumber> => {
  const client = getRpcClient(bcMeta);
  const request = client.getBalance(toAddress(address), { commitment: 'confirmed' });
  return new BigNumber(
    (
      await withTimeout(
        () => request.send({ abortSignal: initOverrides?.signal ?? undefined }),
        7000,
        undefined,
        initOverrides?.signal ?? undefined,
      )
    ).value.toString(),
  );
};

export const queryAccountTokenBalance = async (
  bcMeta: BCRpcRequestAPIMeta,
  // tokenAddress: string,
  address: string,
  initOverrides?: RequestInit,
) => {
  const client = getRpcClient(bcMeta);
  const request = client.getTokenAccountBalance(toAddress(address), {});
  return new BigNumber(
    (
      await withTimeout(
        () => request.send({ abortSignal: initOverrides?.signal ?? undefined }),
        7000,
        undefined,
        initOverrides?.signal ?? undefined,
      )
    ).value.amount,
  );
};

export const queryOwnerTokenBalance = async (
  bcMeta: BCRpcRequestAPIMeta,
  tokenAddress: string,
  address: string,
  initOverrides?: RequestInit,
) => {
  const client = getRpcClient(bcMeta);
  const request = client.getTokenAccountsByOwner(
    toAddress(address),
    { programId: toAddress(tokenAddress) },
    { encoding: 'jsonParsed' },
  );
  return new BigNumber(
    (
      await withTimeout(
        () => request.send({ abortSignal: initOverrides?.signal ?? undefined }),
        7000,
        undefined,
        initOverrides?.signal ?? undefined,
      )
    ).value
      .map(({ account }) => ({
        token: account.owner,
        amount: new BigNumber(account.data.parsed.info.tokenAmount.amount),
      }))
      .filter(({ token }) => token === tokenAddress)
      .reduce((result, { amount }) => result.plus(amount), new BigNumber(0)),
  );
};

export default { queryNativeBalance, queryAccountTokenBalance, queryOwnerTokenBalance, getRpcClient };
