import React, { useMemo } from 'react';

import { useListBlockchains } from '@/features/dictionary/blockchain/hooks';

import BlockchainLabel from '../BlockchainLabel';

import type { BlockchainLabelListProps } from './types';

const BlockchainLabelList: React.FC<BlockchainLabelListProps> = ({
  network,
  api,
  'data-test': dataTest,
  mode = 'medium',
  withNetworkMark,
  className,
  style,
}) => {
  const { data: blockchains } = useListBlockchains(network);
  const data = useMemo(
    () => (api ? blockchains.data?.filter(({ apiType }) => apiType === api) : blockchains.data) ?? [],
    [api, blockchains.data],
  );
  return (
    <span className={className} style={style} data-test={dataTest}>
      {data.map(({ blockchain }, idx) => (
        <React.Fragment key={blockchain}>
          <BlockchainLabel
            data-test={dataTest && `${dataTest}-${blockchain}`}
            bt={blockchain}
            mode={mode}
            withNetworkMark={withNetworkMark}
            copyable={false}
          />
          {idx < data.length - 1 && ', '}
        </React.Fragment>
      ))}
    </span>
  );
};

const BlockchainLabelListMemo = React.memo(BlockchainLabelList);

export default BlockchainLabelListMemo;
