import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { mapLoadingState, storedDataError, storedDataLoaded } from '@/infrastructure/model';
import { withAPICall } from '@/infrastructure/model/api';
import { createLoadingDataActions } from '@/infrastructure/model/common/actions';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import {
  queryBrandingProfile,
  queryBrandingProfiles,
  queryDomains,
  queryToS,
  requestActivateBrandingProfile,
  requestCreateBrandingProfile,
  requestCreateDomain,
  requestDeactivateBrandingProfile,
  requestDeleteBrandingProfile,
  requestDeleteDomain,
  requestDeleteToS,
  requestSaveToS,
  requestUpdateBrandingProfile,
  requestUpdateDomainStatus,
} from './api';
import {
  makeSelectBranding,
  makeSelectBrandingProfile,
  makeSelectMerchantDomains,
  makeSelectMerchantToS,
} from './selectors';
import { NAMESPACE } from './types';

import type { MerchantBrandingCommon, MerchantBrandingProfileContent, MerchantDomain, PaymentsBranding } from './types';

export const { storeBranding, markBrandingDirty } = createLoadingDataActions<MerchantBrandingCommon, 'Branding'>(
  NAMESPACE,
  'Branding',
);

const brandingProfilesFetchLimit = pLimit(1);
export const fetchBrandingProfiles = createAppAsyncThunk(
  `${NAMESPACE}/fetchBrandingProfiles`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    brandingProfilesFetchLimit(async () => {
      const saved = makeSelectBranding()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryBrandingProfiles, 'unable to fetch branding')({ signal });
      dispatch(storeBranding(data));

      return makeSelectBranding()(getState());
    }),
);

export const { storeBrandingProfile, storeRemoveBrandingProfile, markBrandingProfileDirty } = createSingleActions<
  PaymentsBranding,
  'BrandingProfile'
>(NAMESPACE, 'BrandingProfile');

export const createBrandingProfile = createAppAsyncThunk(
  `${NAMESPACE}/createBrandingProfile`,
  async ({ name, data }: MerchantBrandingProfileContent, { dispatch, signal }) => {
    const result = await withAPICall(requestCreateBrandingProfile, 'unable to create profile')(name, data, {
      signal,
    });
    if (result.data) {
      goalReached(YMGoals.BRANDING_PROFILE_CREATED);
      dispatch(storeBrandingProfile({ id: result.data.id, data: mapLoadingState(result, (profile) => profile.data) }));
      dispatch(markBrandingDirty());
    }
    return result;
  },
);

export const updateBrandingProfile = createAppAsyncThunk(
  `${NAMESPACE}/updateBrandingProfile`,
  async ({ id, content }: { id: string; content: MerchantBrandingProfileContent }, { dispatch, signal }) => {
    const { name, data } = content;
    const result = await withAPICall(requestUpdateBrandingProfile, 'unable to update profile')(id, name, data, {
      signal,
    });
    if (result.data) {
      goalReached(YMGoals.BRANDING_PROFILE_UPDATED);
      dispatch(storeBrandingProfile({ id, data: storedDataLoaded(content.data) }));
      dispatch(storeBranding(result));
    }
    return result;
  },
);

export const updateBrandingProfileState = createAppAsyncThunk(
  `${NAMESPACE}/updateBrandingProfileState`,
  async ({ id, activate }: { id: string; activate: boolean }, { dispatch, signal }) => {
    const result = await withAPICall(
      activate ? requestActivateBrandingProfile : requestDeactivateBrandingProfile,
      'unable to update profile state',
    )(id, { signal });
    if (result.data) {
      goalReached(activate ? YMGoals.BRANDING_PROFILE_ACTIVATED : YMGoals.BRANDING_PROFILE_DEACTIVATED);
      dispatch(storeBranding(result));
    }
    return result;
  },
);

export const removeBrandingProfile = createAppAsyncThunk(
  `${NAMESPACE}/removeBrandingProfile`,
  async (id: string, { dispatch, signal }) => {
    const result = await withAPICall(requestDeleteBrandingProfile, 'unable to delete profile')(id, { signal });
    if (result.data) {
      dispatch(storeRemoveBrandingProfile(id));
      dispatch(storeBranding(result));
    }
    return result;
  },
);

const brandingProfileFetchLimit = pLimit(1);
export const fetchBrandingProfile = createAppAsyncThunk(
  `${NAMESPACE}/fetchBrandingProfile`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    brandingProfileFetchLimit(async () => {
      const saved = makeSelectBrandingProfile(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const result = await withAPICall(queryBrandingProfile, 'unable to fetch profile')(id, { signal });
      dispatch(storeBrandingProfile({ id, data: mapLoadingState(result, (profile) => profile.data) }));

      return makeSelectBrandingProfile(id)(getState());
    }),
);

export const { storeMerchantToS, markMerchantToSDirty } = createLoadingDataActions<string | undefined, 'MerchantToS'>(
  NAMESPACE,
  'MerchantToS',
);

const toSFetchLimit = pLimit(1);
export const fetchMerchantToS = createAppAsyncThunk(
  `${NAMESPACE}/fetchMerchantToS`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    toSFetchLimit(async () => {
      const saved = makeSelectMerchantToS()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryToS, 'unable to fetch ToS')({ signal });
      dispatch(storeMerchantToS(data));

      return makeSelectMerchantToS()(getState());
    }),
);

export const saveMerchantToS = createAppAsyncThunk(
  `${NAMESPACE}/saveMerchantToS`,
  async (content: string, { dispatch, signal }) => {
    const result = await withAPICall(requestSaveToS, 'unable to update ToS')(content, { signal });
    if (!result.error) {
      goalReached(YMGoals.TOS_UPDATED);
      dispatch(storeMerchantToS(storedDataLoaded(content)));
    }
    return result.error ? storedDataError<string>(result.error) : storedDataLoaded(content);
  },
);

export const removeMerchantToS = createAppAsyncThunk(
  `${NAMESPACE}/removeMerchantToS`,
  async (_, { dispatch, signal }) => {
    const result = await withAPICall(requestDeleteToS, 'unable to delete ToS')({ signal });
    if (!result.error) {
      goalReached(YMGoals.TOS_CLEARED);
      dispatch(storeMerchantToS(storedDataLoaded()));
    }
    return result;
  },
);

export const { storeMerchantDomains, markMerchantDomainsDirty } = createLoadingDataActions<
  MerchantDomain[],
  'MerchantDomains'
>(NAMESPACE, 'MerchantDomains');

const merchantDomainsFetchLimit = pLimit(1);
export const fetchMerchantDomains = createAppAsyncThunk(
  `${NAMESPACE}/fetchMerchantDomains`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    merchantDomainsFetchLimit(async () => {
      const saved = makeSelectMerchantDomains()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryDomains, 'unable to fetch merchant domains')({ signal });
      dispatch(storeMerchantDomains(data));

      return makeSelectMerchantDomains()(getState());
    }),
);

export const createMerchantDomain = createAppAsyncThunk(
  `${NAMESPACE}/createMerchantDomain`,
  async ({ url }: { url: string }, { dispatch, signal }) => {
    const result = await withAPICall(requestCreateDomain, 'unable to create merchant domain')(url, { signal });
    if (result.data) {
      dispatch(storeMerchantDomains(result));
    }
    return mapLoadingState(result, (data) => data.find((domain) => domain.url === url)!);
  },
);

export const updateMerchantDomainState = createAppAsyncThunk(
  `${NAMESPACE}/updateMerchantDomainState`,
  async ({ id, activate }: { id: string; activate: boolean }, { dispatch, signal }) => {
    const result = await withAPICall(requestUpdateDomainStatus, 'unable to update merchant domain state')(
      id,
      activate,
      { signal },
    );
    if (result.data) {
      dispatch(markMerchantDomainsDirty());
    }
    return result;
  },
);

export const removeMerchantDomain = createAppAsyncThunk(
  `${NAMESPACE}/removeMerchantDomain`,
  async ({ id }: { id: string }, { dispatch, signal }) => {
    const result = await withAPICall(requestDeleteDomain, 'unable to delete merchant domain')(id, {
      signal,
    });
    if (result.data) {
      dispatch(storeMerchantDomains(result));
    }
    return result;
  },
);
