import { useCallback, useContext, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { storeAuthStatus } from '@/features/auth/actions';
import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { AuthStatus } from '@/features/auth/types';
import { storeEmailConfirmationState } from '@/features/email/actions';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import { OpenIdProviderTypeAPIModel } from '@/generated/api/ncps-api';
import { useSubmitting } from '@/hooks';
import type { HookAction } from '@/infrastructure/model';
import type { Web3Auth } from '@/infrastructure/security/web3-auth/types';
import {
  getSolanaPublicKeyByWeb3Auth,
  signEVMMessageByWeb3Auth,
  signSolanaMessageByWeb3Auth,
} from '@/infrastructure/security/web3-auth/utils';
import { assertNotNil, suppressError, suppressPromise, withOnError } from '@/infrastructure/utils/functions';
import { runCancellable } from '@/infrastructure/utils/ui';

import useAuthActions, { type UseAuthActionsType } from '../useAuthActions';

export type EmailAuthOptions = Parameters<UseAuthActionsType['authEmail']['act']>[1] & {};

export interface UseEmailAuthType {
  loginByEmail: HookAction<[{ email: string; emailToken: string }, EmailAuthOptions]>;
  loginByGoogle: HookAction<[{ emailToken: string }, EmailAuthOptions]>;
}

const useEmailAuth = (): UseEmailAuthType => {
  const { dispatch, withDispatch } = useAppDispatch();
  const { web3Auth, initialized } = useContext(Web3AuthContext);
  const { authEmail: baseAuth } = useAuthActions();
  const featureFlags = useFeatureToggle();

  const doAuth = baseAuth.act;
  const [authInProgress, withAuth] = useSubmitting(false);

  const doWeb3AuthLogin = useCallback(
    async ({
      triggerLogin,
      token,
      options,
    }: {
      triggerLogin: (web3Auth: Web3Auth) => Promise<void>;
      token: { provider: OpenIdProviderTypeAPIModel; idToken: string };
      options: EmailAuthOptions & { email?: string };
    }) =>
      withOnError(
        withAuth(async () => {
          assertNotNil(web3Auth, () => new Error('Web3Auth not initialized'));
          withDispatch(storeAuthStatus)({ newState: AuthStatus.PROCESSING });
          try {
            await (options.cancellation
              ? runCancellable(triggerLogin(web3Auth), options.cancellation)
              : triggerLogin(web3Auth));
          } finally {
            const { email } = options;
            if (email) suppressPromise(dispatch(storeEmailConfirmationState({ email })));
          }
          if (!web3Auth.isLoggedIn()) throw new Error('LoginFailed');
          if (!web3Auth.isAuthorized()) throw new Error('AuthFailed');

          assertNotNil(web3Auth.state, () => new Error('AuthorizationFailed'));

          const { address } = web3Auth.state;
          const signEVMMessage = async (message: string) =>
            signEVMMessageByWeb3Auth(web3Auth.instance, address, message);
          const signSolanaMessage = !featureFlags.data.data?.disableSolana
            ? async (message: string) => signSolanaMessageByWeb3Auth(web3Auth.instance, message)
            : undefined;
          const solanaAddress = !featureFlags.data.data?.disableSolana
            ? await getSolanaPublicKeyByWeb3Auth(web3Auth.instance)
            : undefined;
          return doAuth(
            {
              emailToken: token.idToken,
              emailProvider: token.provider,
              evm: { address, signMessage: signEVMMessage },
              solana:
                solanaAddress && signSolanaMessage
                  ? { address: solanaAddress, signMessage: signSolanaMessage }
                  : undefined,
            },
            options,
          );
        }),
        async () => {
          if (web3Auth?.logout) await suppressError(web3Auth.logout(), (e) => console.warn(e));
          withDispatch(storeAuthStatus)({ newState: AuthStatus.UNAUTHORIZED, expected: AuthStatus.PROCESSING });
        },
      )(),
    [withAuth, web3Auth, withDispatch, featureFlags.data.data?.disableSolana, doAuth, dispatch],
  );

  const loginByGoogleAction: UseEmailAuthType['loginByGoogle']['act'] = useCallback(
    ({ emailToken }, options) =>
      doWeb3AuthLogin({
        triggerLogin: (t) => t.triggerGoogleAuth(emailToken),
        token: {
          provider: OpenIdProviderTypeAPIModel.google,
          idToken: emailToken,
        },
        options,
      }),
    [doWeb3AuthLogin],
  );
  const loginByGoogle = useMemo(
    () => ({
      act: loginByGoogleAction,
      available: baseAuth.available && initialized && !!web3Auth && !web3Auth.isLoggedIn(),
      inAction: authInProgress,
    }),
    [loginByGoogleAction, baseAuth.available, initialized, web3Auth, authInProgress],
  );

  const loginByEmailAction: UseEmailAuthType['loginByEmail']['act'] = useCallback(
    ({ email, emailToken }, options) =>
      doWeb3AuthLogin({
        triggerLogin: (t) => t.triggerEmailAuth(emailToken),
        token: {
          provider: OpenIdProviderTypeAPIModel.auth0,
          idToken: emailToken,
        },
        options: { ...options, email },
      }),
    [doWeb3AuthLogin],
  );
  const loginByEmail = useMemo(
    () => ({
      act: loginByEmailAction,
      available: baseAuth.available && initialized && !!web3Auth && !web3Auth.isLoggedIn(),
      inAction: authInProgress,
    }),
    [loginByEmailAction, baseAuth.available, initialized, web3Auth, authInProgress],
  );

  return { loginByGoogle, loginByEmail };
};

export default useEmailAuth;
