import { useCallback, useMemo } from 'react';

import { DataFetchError, PageCard } from '@/components';
import CompanyActiveAssetsEditForm from '@/features/company-settings/components/CompanyActiveAssetsEditForm';
import { useCompanySettings } from '@/features/company-settings/hooks';
import { useFirstRenderEffect, useFormPageAction, useStateMountSafe } from '@/hooks';
import { mapStoredState } from '@/infrastructure/model';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import { goalReached, YMGoals } from '@/infrastructure/ym';
import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';
import { settingsCompanySummaryLink } from '@/pages/settings/routes';

import { UpdateActiveAssetsBreadcrumb } from './components';

import type React from 'react';

const dataTest: string | undefined = 'assets';

const UpdateActiveAssetsPage: React.FC = () => {
  const { data, forceRefresh, loading } = useCompanySettings();

  const { redirectOnSubmit } = useFormPageAction();
  const doRedirect = useMemo<Func>(() => redirectOnSubmit(settingsCompanySummaryLink()), [redirectOnSubmit]);
  const [onSubmit, setOnSubmit] = useStateMountSafe<Func | undefined>();
  const updateOnSubmit = useCallback(
    (func?: Func) =>
      setOnSubmit(() =>
        func
          ? withOnSuccess(func, () => {
              goalReached(YMGoals.COMPANY_ASSET_UPDATED);
              doRedirect();
            })
          : undefined,
      ),
    [doRedirect, setOnSubmit],
  );

  useFirstRenderEffect(() => {
    goalReached(YMGoals.COMPANY_ASSET_UPDATE_INIT);
  });

  const doSubmit = useMemo(() => ({ onClick: onSubmit ?? noop, disabled: !onSubmit }), [onSubmit]);

  const initialValues = useMemo(() => mapStoredState(data, ({ activeAssets }) => activeAssets), [data]);
  return (
    <SettingsPageLayout
      data-test={dataTest && `${dataTest}-container`}
      selected={SettingsTabType.company}
      breadcrumb={<UpdateActiveAssetsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
      submit={doSubmit}
      cancel={doRedirect}
    >
      <PageCard loading={(!initialValues.data && initialValues.isDirty) || loading} cardSize="big">
        {
          /* eslint-disable-next-line no-nested-ternary */
          !initialValues.data ? (
            !initialValues.isDirty ? (
              <DataFetchError refresh={forceRefresh} back message={initialValues.error} />
            ) : null
          ) : (
            <CompanyActiveAssetsEditForm
              data-test={dataTest && `${dataTest}-form`}
              selected={initialValues.data}
              onSubmit={doRedirect}
              onCancel={doRedirect}
              submitCallback={updateOnSubmit}
            />
          )
        }
      </PageCard>
    </SettingsPageLayout>
  );
};

export default UpdateActiveAssetsPage;
