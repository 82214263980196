/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  MerchantFeatureFlagsAPIModel,
  MerchantPreferencesToStoreAPIModel,
  StoredMerchantPreferencesAPIModel,
} from '../models/index';
import {
    MerchantFeatureFlagsAPIModelFromJSON,
    MerchantFeatureFlagsAPIModelToJSON,
    MerchantPreferencesToStoreAPIModelFromJSON,
    MerchantPreferencesToStoreAPIModelToJSON,
    StoredMerchantPreferencesAPIModelFromJSON,
    StoredMerchantPreferencesAPIModelToJSON,
} from '../models/index';

export interface SaveOwnPreferencesRequest {
    merchantPreferencesToStoreAPIModel: MerchantPreferencesToStoreAPIModel;
}

/**
 * MerchantUserApi - interface
 * 
 * @export
 * @interface MerchantUserApiInterface
 */
export interface MerchantUserApiInterface {
    /**
     * Finds saved logged in user preferences
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantUserApiInterface
     */
    getOwnPreferencesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoredMerchantPreferencesAPIModel>>;

    /**
     * Finds saved logged in user preferences
     * 
     */
    getOwnPreferences(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoredMerchantPreferencesAPIModel>;

    /**
     * Saves logged in user preferences
     * @summary 
     * @param {MerchantPreferencesToStoreAPIModel} merchantPreferencesToStoreAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantUserApiInterface
     */
    saveOwnPreferencesRaw(requestParameters: SaveOwnPreferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoredMerchantPreferencesAPIModel>>;

    /**
     * Saves logged in user preferences
     * 
     */
    saveOwnPreferences(merchantPreferencesToStoreAPIModel: MerchantPreferencesToStoreAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoredMerchantPreferencesAPIModel>;

    /**
     * Requests the authorized user feature flags
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantUserApiInterface
     */
    userFeatureFlagsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantFeatureFlagsAPIModel>>;

    /**
     * Requests the authorized user feature flags
     * 
     */
    userFeatureFlags(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantFeatureFlagsAPIModel>;

}

/**
 * 
 */
export class MerchantUserApi extends runtime.BaseAPI implements MerchantUserApiInterface {

    /**
     * Finds saved logged in user preferences
     * 
     */
    async getOwnPreferencesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoredMerchantPreferencesAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("dashboard-header", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-bff/user/preferences`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredMerchantPreferencesAPIModelFromJSON(jsonValue));
    }

    /**
     * Finds saved logged in user preferences
     * 
     */
    async getOwnPreferences(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoredMerchantPreferencesAPIModel> {
        const response = await this.getOwnPreferencesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Saves logged in user preferences
     * 
     */
    async saveOwnPreferencesRaw(requestParameters: SaveOwnPreferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoredMerchantPreferencesAPIModel>> {
        if (requestParameters['merchantPreferencesToStoreAPIModel'] == null) {
            throw new runtime.RequiredError(
                'merchantPreferencesToStoreAPIModel',
                'Required parameter "merchantPreferencesToStoreAPIModel" was null or undefined when calling saveOwnPreferences().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("dashboard-header", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-bff/user/preferences`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MerchantPreferencesToStoreAPIModelToJSON(requestParameters['merchantPreferencesToStoreAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredMerchantPreferencesAPIModelFromJSON(jsonValue));
    }

    /**
     * Saves logged in user preferences
     * 
     */
    async saveOwnPreferences(merchantPreferencesToStoreAPIModel: MerchantPreferencesToStoreAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoredMerchantPreferencesAPIModel> {
        const response = await this.saveOwnPreferencesRaw({ merchantPreferencesToStoreAPIModel: merchantPreferencesToStoreAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Requests the authorized user feature flags
     * 
     */
    async userFeatureFlagsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantFeatureFlagsAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("dashboard-header", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-bff/user/flags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantFeatureFlagsAPIModelFromJSON(jsonValue));
    }

    /**
     * Requests the authorized user feature flags
     * 
     */
    async userFeatureFlags(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantFeatureFlagsAPIModel> {
        const response = await this.userFeatureFlagsRaw(initOverrides);
        return await response.value();
    }

}
