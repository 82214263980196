import { useMemo } from 'react';

import type { UseAppFullData } from '@/app/hooks';
import { useAppFullData, useAppSelector } from '@/app/hooks';
import { fetchAssets } from '@/features/dictionary/blockchain/actions';
import {
  makeSelectAssetFullData,
  makeSelectExchangeableAssets,
  makeSelectSupportedAssets,
} from '@/features/dictionary/blockchain/selectors';
import type { Asset, AssetWithNetwork } from '@/features/dictionary/blockchain/types';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import type { BlockchainNetworkTypeAPIModel, BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { mapStoredState } from '@/infrastructure/model';
import type { LoadingFullDataState } from '@/infrastructure/model/full/types';
import { asType } from '@/infrastructure/utils/ts';

import useListBlockchains from '../useListBlockchains';

export interface UseListAssetsType extends UseAppFullData<AssetWithNetwork> {
  supportedAssets: LoadingStateWithDirty<AssetWithNetwork[]>;
  exchangeableAssets: LoadingStateWithDirty<AssetWithNetwork[]>;
}

const dataSelector = makeSelectAssetFullData();
const supportedSelector = makeSelectSupportedAssets();
const exchangeableAssetsSelector = makeSelectExchangeableAssets();
const dataFetchingSelector = makeSelectPending(fetchAssets);
const fetchFactory = (force: boolean) => fetchAssets({ force });

export default function useListAssets(network?: BlockchainNetworkTypeAPIModel): UseListAssetsType {
  const ff = useFeatureToggle();
  const blockchainsData = useListBlockchains();
  const blockchains = useMemo(
    () =>
      (blockchainsData.data.data ?? [])
        .filter(({ net }) => !network || network === net)
        .map(({ blockchain }) => blockchain),
    [blockchainsData.data.data, network],
  );
  const blockchainsNetwork = useMemo(
    () =>
      (blockchainsData.data.data ?? []).reduce(
        (r, { blockchain, net, apiType }) => ({ ...r, [blockchain]: { net, apiType } }),
        asType<
          Partial<
            Record<
              BlockchainTypeAPIModel,
              {
                net: BlockchainNetworkTypeAPIModel;
                apiType: BlockchainAPITypeAPIModel;
              }
            >
          >
        >({}),
      ),
    [blockchainsData.data.data],
  );

  const fullData = useAppFullData<Asset, LoadingFullDataState<Asset>>(fetchFactory, dataFetchingSelector, dataSelector);
  const data = useMemo(
    () =>
      mapStoredState<Asset[], AssetWithNetwork[]>(fullData.data, (assets) =>
        assets
          .filter(({ blockchain }) => !network || (blockchain && blockchains.includes(blockchain)))
          .map((asset) => ({
            ...asset,
            net: asset.blockchain ? blockchainsNetwork[asset.blockchain]?.net : undefined,
            apiType: asset.blockchain ? blockchainsNetwork[asset.blockchain]?.apiType : undefined,
          })),
      ),
    [network, fullData.data, blockchains, blockchainsNetwork],
  );
  const allSupportedAssets = useAppSelector(supportedSelector);
  const allExchangeableAssets = useAppSelector(exchangeableAssetsSelector);
  const supportedAssets = useMemo(
    () =>
      mapStoredState<Asset[], AssetWithNetwork[]>(allSupportedAssets, (assets) =>
        assets
          .filter(({ blockchain }) => !network || (blockchain && blockchains.includes(blockchain)))
          .filter(({ blockchain }) =>
            ff.data.data?.disableBtc
              ? blockchainsData.data.data?.find((bc) => bc.blockchain === blockchain)?.apiType
                !== BlockchainAPITypeAPIModel.BTC
              : true,
          )
          .filter(({ blockchain }) =>
            ff.data.data?.disableTron
              ? blockchainsData.data.data?.find((bc) => bc.blockchain === blockchain)?.apiType
                !== BlockchainAPITypeAPIModel.Tron
              : true,
          )
          .filter(({ blockchain }) =>
            ff.data.data?.disableSolana
              ? blockchainsData.data.data?.find((bc) => bc.blockchain === blockchain)?.apiType
                !== BlockchainAPITypeAPIModel.Solana
              : true,
          )
          .map((asset) => ({
            ...asset,
            net: asset.blockchain ? blockchainsNetwork[asset.blockchain]?.net : undefined,
            apiType: asset.blockchain ? blockchainsNetwork[asset.blockchain]?.apiType : undefined,
          })),
      ),
    [
      allSupportedAssets,
      blockchains,
      blockchainsData.data.data,
      blockchainsNetwork,
      ff.data.data?.disableBtc,
      ff.data.data?.disableSolana,
      ff.data.data?.disableTron,
      network,
    ],
  );
  const exchangeableAssets = useMemo(
    () =>
      mapStoredState<Asset[], AssetWithNetwork[]>(allExchangeableAssets, (assets) =>
        assets
          .filter(({ blockchain }) => !network || (blockchain && blockchains.includes(blockchain)))
          .map((asset) => ({
            ...asset,
            net: asset.blockchain ? blockchainsNetwork[asset.blockchain]?.net : undefined,
            apiType: asset.blockchain ? blockchainsNetwork[asset.blockchain]?.apiType : undefined,
          })),
      ),
    [allExchangeableAssets, blockchains, blockchainsNetwork, network],
  );

  return useMemo(
    () => ({
      ...fullData,
      data,
      loading: (!!network && blockchainsData.loading) || fullData.loading,
      supportedAssets,
      exchangeableAssets,
    }),
    [blockchainsData.loading, data, exchangeableAssets, fullData, network, supportedAssets],
  );
}
