/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  MerchantReportAPIModel,
  PayoutAPIModel,
  SliceWithdrawalWithdrawalSortByAPIModel,
  WithdrawalAPIModel,
  WithdrawalFilterAPIModel,
  WithdrawalPredicatesAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    MerchantReportAPIModelFromJSON,
    MerchantReportAPIModelToJSON,
    PayoutAPIModelFromJSON,
    PayoutAPIModelToJSON,
    SliceWithdrawalWithdrawalSortByAPIModelFromJSON,
    SliceWithdrawalWithdrawalSortByAPIModelToJSON,
    WithdrawalAPIModelFromJSON,
    WithdrawalAPIModelToJSON,
    WithdrawalFilterAPIModelFromJSON,
    WithdrawalFilterAPIModelToJSON,
    WithdrawalPredicatesAPIModelFromJSON,
    WithdrawalPredicatesAPIModelToJSON,
} from '../models/index';

export interface ExportReconciliationTransactionsByWithdrawalPredicatesRequest {
    withdrawalPredicatesAPIModel: WithdrawalPredicatesAPIModel;
}

export interface ExportReconciliationsByWithdrawalPredicatesRequest {
    withdrawalPredicatesAPIModel: WithdrawalPredicatesAPIModel;
}

export interface GetWithdrawalRequest {
    withdrawalId: string;
}

export interface GetWithdrawalPayoutsRequest {
    withdrawalId: string;
}

export interface GetWithdrawalsRequest {
    withdrawalFilterAPIModel: WithdrawalFilterAPIModel;
}

/**
 * WithdrawApi - interface
 * 
 * @export
 * @interface WithdrawApiInterface
 */
export interface WithdrawApiInterface {
    /**
     * Exports reconciliation transactions by withdrawal predicates
     * @param {WithdrawalPredicatesAPIModel} withdrawalPredicatesAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    exportReconciliationTransactionsByWithdrawalPredicatesRaw(requestParameters: ExportReconciliationTransactionsByWithdrawalPredicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>>;

    /**
     * Exports reconciliation transactions by withdrawal predicates
     */
    exportReconciliationTransactionsByWithdrawalPredicates(withdrawalPredicatesAPIModel: WithdrawalPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel>;

    /**
     * Exports reconciliations by withdrawal predicates
     * @param {WithdrawalPredicatesAPIModel} withdrawalPredicatesAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    exportReconciliationsByWithdrawalPredicatesRaw(requestParameters: ExportReconciliationsByWithdrawalPredicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>>;

    /**
     * Exports reconciliations by withdrawal predicates
     */
    exportReconciliationsByWithdrawalPredicates(withdrawalPredicatesAPIModel: WithdrawalPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel>;

    /**
     * Returns withdrawal by id
     * @param {string} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    getWithdrawalRaw(requestParameters: GetWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>>;

    /**
     * Returns withdrawal by id
     */
    getWithdrawal(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel>;

    /**
     * Withdrawal payouts
     * @param {string} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    getWithdrawalPayoutsRaw(requestParameters: GetWithdrawalPayoutsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PayoutAPIModel>>>;

    /**
     * Withdrawal payouts
     */
    getWithdrawalPayouts(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PayoutAPIModel>>;

    /**
     * Returns withdrawals according to filter
     * @param {WithdrawalFilterAPIModel} withdrawalFilterAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    getWithdrawalsRaw(requestParameters: GetWithdrawalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceWithdrawalWithdrawalSortByAPIModel>>;

    /**
     * Returns withdrawals according to filter
     */
    getWithdrawals(withdrawalFilterAPIModel: WithdrawalFilterAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceWithdrawalWithdrawalSortByAPIModel>;

}

/**
 * 
 */
export class WithdrawApi extends runtime.BaseAPI implements WithdrawApiInterface {

    /**
     * Exports reconciliation transactions by withdrawal predicates
     */
    async exportReconciliationTransactionsByWithdrawalPredicatesRaw(requestParameters: ExportReconciliationTransactionsByWithdrawalPredicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>> {
        if (requestParameters['withdrawalPredicatesAPIModel'] == null) {
            throw new runtime.RequiredError(
                'withdrawalPredicatesAPIModel',
                'Required parameter "withdrawalPredicatesAPIModel" was null or undefined when calling exportReconciliationTransactionsByWithdrawalPredicates().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/export/reconciliation-transactions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WithdrawalPredicatesAPIModelToJSON(requestParameters['withdrawalPredicatesAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantReportAPIModelFromJSON(jsonValue));
    }

    /**
     * Exports reconciliation transactions by withdrawal predicates
     */
    async exportReconciliationTransactionsByWithdrawalPredicates(withdrawalPredicatesAPIModel: WithdrawalPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel> {
        const response = await this.exportReconciliationTransactionsByWithdrawalPredicatesRaw({ withdrawalPredicatesAPIModel: withdrawalPredicatesAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Exports reconciliations by withdrawal predicates
     */
    async exportReconciliationsByWithdrawalPredicatesRaw(requestParameters: ExportReconciliationsByWithdrawalPredicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>> {
        if (requestParameters['withdrawalPredicatesAPIModel'] == null) {
            throw new runtime.RequiredError(
                'withdrawalPredicatesAPIModel',
                'Required parameter "withdrawalPredicatesAPIModel" was null or undefined when calling exportReconciliationsByWithdrawalPredicates().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/export/reconciliations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WithdrawalPredicatesAPIModelToJSON(requestParameters['withdrawalPredicatesAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantReportAPIModelFromJSON(jsonValue));
    }

    /**
     * Exports reconciliations by withdrawal predicates
     */
    async exportReconciliationsByWithdrawalPredicates(withdrawalPredicatesAPIModel: WithdrawalPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel> {
        const response = await this.exportReconciliationsByWithdrawalPredicatesRaw({ withdrawalPredicatesAPIModel: withdrawalPredicatesAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Returns withdrawal by id
     */
    async getWithdrawalRaw(requestParameters: GetWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling getWithdrawal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/{withdrawalId}`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns withdrawal by id
     */
    async getWithdrawal(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel> {
        const response = await this.getWithdrawalRaw({ withdrawalId: withdrawalId }, initOverrides);
        return await response.value();
    }

    /**
     * Withdrawal payouts
     */
    async getWithdrawalPayoutsRaw(requestParameters: GetWithdrawalPayoutsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PayoutAPIModel>>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling getWithdrawalPayouts().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/{withdrawalId}/payouts`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PayoutAPIModelFromJSON));
    }

    /**
     * Withdrawal payouts
     */
    async getWithdrawalPayouts(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PayoutAPIModel>> {
        const response = await this.getWithdrawalPayoutsRaw({ withdrawalId: withdrawalId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns withdrawals according to filter
     */
    async getWithdrawalsRaw(requestParameters: GetWithdrawalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceWithdrawalWithdrawalSortByAPIModel>> {
        if (requestParameters['withdrawalFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'withdrawalFilterAPIModel',
                'Required parameter "withdrawalFilterAPIModel" was null or undefined when calling getWithdrawals().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WithdrawalFilterAPIModelToJSON(requestParameters['withdrawalFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceWithdrawalWithdrawalSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns withdrawals according to filter
     */
    async getWithdrawals(withdrawalFilterAPIModel: WithdrawalFilterAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceWithdrawalWithdrawalSortByAPIModel> {
        const response = await this.getWithdrawalsRaw({ withdrawalFilterAPIModel: withdrawalFilterAPIModel }, initOverrides);
        return await response.value();
    }

}
