import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import React, { useCallback } from 'react';

import { withFeatureToggleGuard } from '@/features/feature-toggle/hocs';
import { someOrFail } from '@/infrastructure/utils/functions';

import type { GoogleLoginButtonProps } from './types';
import type { CredentialResponse } from '@react-oauth/google';

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({ 'data-test': dataTest, onAuth, onSelect }) => {
  const doAuth = useCallback(
    ({ credential }: CredentialResponse) => onAuth({ emailToken: someOrFail(credential) }),
    [onAuth],
  );
  return (
    <GoogleLogin
      data-test={dataTest}
      onSuccess={doAuth}
      click_listener={onSelect}
      text="continue_with"
      shape="circle"
      locale="en"
      width={400}
      use_fedcm_for_prompt
      useOneTap={window.env.AUTH_WEB3AUTH_GOOGLE_ONE_TAP}
      size="large"
    />
  );
};

const GoogleLoginButtonWrapper: React.FC<GoogleLoginButtonProps> = (props) => (
  <GoogleOAuthProvider clientId={window.env.AUTH_WEB3AUTH_GOOGLE_CLIENT_ID}>
    <GoogleLoginButton {...props} />
  </GoogleOAuthProvider>
);

const GoogleLoginButtonMemo: typeof GoogleLoginButton = React.memo(
  withFeatureToggleGuard('disableGoogleAuth', false)(GoogleLoginButtonWrapper),
);

export default GoogleLoginButtonMemo;
