import { Route, Routes } from 'react-router-dom';

import { useCompanyPermissions } from '@/features/company/hooks';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import { BasicLayout } from '@/layouts';
import { withBasicPageGuard } from '@/pages/hocs';
import { useMenuData } from '@/pages/hooks';
import { AppPageRoutes } from '@/pages/routes';

import { SettingsBrandingDefaultRoute } from './branding/components';
import BrandingDomainsPage from './branding/domains';
import BrandingProfileAddPage from './branding/profile-add';
import BrandingProfileEditPage from './branding/profile-edit';
import BrandingProfileViewPage from './branding/profile-view';
import BrandingProfilesPage from './branding/profiles';
import BrandingSummaryPage from './branding/summary';
import BrandingTermsOfServicePage from './branding/tos';
import UpdateActiveAssetsPage from './company/assets-edit';
import { SettingsCompanyDefaultRoute } from './company/components';
import GasWalletViewPage from './company/gas-wallet';
import GasWalletsPage from './company/gas-wallets';
import UpdateQRSupportPage from './company/qr-support-edit';
import CompanySummaryPage from './company/summary';
import SettingsCompliancePage from './compliance';
import { SettingsDefaultRoute } from './components';
import SettingsIntegrationPage from './integration';
import SettingsPermissionsPage from './permissions';
import SettingsProfilePage from './profile';
import { SettingsPageRoutes, BrandingPageRoutes, CompanyPageRoutes } from './routes';

import type React from 'react';

export { SettingsDefaultRoute } from './components';

const SettingsPages: React.FC = () => {
  const routes = useMenuData();
  const {
    data: { data: featureFlags },
  } = useFeatureToggle();
  const {
    data: { data: permissions },
  } = useCompanyPermissions();
  return (
    <BasicLayout routes={routes}>
      <Routes>
        <Route path={SettingsPageRoutes.COMPANY}>
          {permissions?.assetsManagement && (
            <Route path={CompanyPageRoutes.ASSETS} Component={UpdateActiveAssetsPage} />
          )}
          {permissions?.gasWalletsManagement && (
            <Route path={CompanyPageRoutes.GAS_WALLET_VIEW} Component={GasWalletViewPage} />
          )}
          {permissions?.gasWalletsManagement && (
            <Route path={CompanyPageRoutes.GAS_WALLETS} Component={GasWalletsPage} />
          )}
          <Route path={CompanyPageRoutes.QR_SUPPORT} Component={UpdateQRSupportPage} />
          <Route path={CompanyPageRoutes.SUMMARY} Component={CompanySummaryPage} />
          <Route path="*" Component={SettingsCompanyDefaultRoute} />
        </Route>
        {(permissions?.brandingConfiguration || permissions?.termsOfServiceSet)
          && (featureFlags?.enableBranding || featureFlags?.enableBrandingTos) && (
            <Route path={SettingsPageRoutes.BRANDING}>
              <Route path={BrandingPageRoutes.SUMMARY} Component={BrandingSummaryPage} />
              {permissions.brandingConfiguration && featureFlags.enableCompanyDomains && (
                <Route path={BrandingPageRoutes.DOMAINS} Component={BrandingDomainsPage} />
              )}
              {permissions.brandingConfiguration && featureFlags.enableBranding && (
                <Route path={BrandingPageRoutes.PROFILES} Component={BrandingProfilesPage} />
              )}
              {permissions.brandingConfiguration && featureFlags.enableBranding && (
                <Route path={BrandingPageRoutes.PROFILE_ADD} Component={BrandingProfileAddPage} />
              )}
              {permissions.brandingConfiguration && featureFlags.enableBranding && (
                <Route path={BrandingPageRoutes.PROFILE_EDIT} Component={BrandingProfileEditPage} />
              )}
              {permissions.brandingConfiguration && featureFlags.enableBranding && (
                <Route path={BrandingPageRoutes.PROFILE_VIEW} Component={BrandingProfileViewPage} />
              )}
              {permissions.termsOfServiceSet && featureFlags.enableBrandingTos && (
                <Route path={BrandingPageRoutes.TOS} Component={BrandingTermsOfServicePage} />
              )}
              <Route path="*" Component={SettingsBrandingDefaultRoute} />
            </Route>
          )}
        {permissions?.integrationManagement && (
          <Route path={SettingsPageRoutes.INTEGRATION} Component={SettingsIntegrationPage} />
        )}
        <Route path={SettingsPageRoutes.COMPLIANCE} Component={SettingsCompliancePage} />
        <Route path={SettingsPageRoutes.PROFILE} Component={SettingsProfilePage} />
        {permissions?.usersManagement && (
          <Route path={SettingsPageRoutes.PERMISSIONS} Component={SettingsPermissionsPage} />
        )}
        <Route path="*" Component={SettingsDefaultRoute} />
      </Routes>
    </BasicLayout>
  );
};

const SettingsPagesGuarded: React.FC = withBasicPageGuard(SettingsPages);

export default (
  <Route key={AppPageRoutes.SETTINGS} path={`${AppPageRoutes.SETTINGS}/*`} Component={SettingsPagesGuarded} />
);
