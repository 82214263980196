import { Form } from 'antd';
import React from 'react';

import KYTLevelSelectionInput from '../KYTLevelSelectionInput';

import type { KYTLevelSelectionItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const KYTLevelSelectionItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
}: KYTLevelSelectionItemProps<Values>) => (
  <Form.Item<Values> noStyle name={name}>
    <KYTLevelSelectionInput data-test={dataTest} />
  </Form.Item>
);

const KYTLevelSelectionItemMemo = React.memo(KYTLevelSelectionItem) as typeof KYTLevelSelectionItem;

export default KYTLevelSelectionItemMemo;
