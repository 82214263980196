import { getWallets } from '@wallet-standard/core';
import { getOrCreateUiWalletForStandardWallet_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } from '@wallet-standard/ui-registry';
import { useEffect } from 'react';

import { useStateMountSafe } from '@/hooks';
import { notEmpty } from '@/infrastructure/utils/ts';

import type { UiWallet } from '@wallet-standard/ui';

function useWalletsSafe(): readonly UiWallet[] {
  const [uiWallets, setUiWallets] = useStateMountSafe<UiWallet[]>([]);
  useEffect(() => {
    const wallets = getWallets().get();
    setUiWallets(
      wallets
        .map((wallet) => {
          try {
            return getOrCreateUiWalletForStandardWallet_DO_NOT_USE_OR_YOU_WILL_BE_FIRED(wallet);
          } catch (e) {
            console.error(e);
          }
        })
        .filter(notEmpty),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return uiWallets;
}

export default useWalletsSafe;
