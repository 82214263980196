import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { extractEntityTransactionId } from '@/features/collectable/utils';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import {
  createNestedNormalizedListReducers,
  createNormalizedListReducers,
  createSplitNormalizedListReducers,
} from '@/infrastructure/model/list/reducers';

import {
  markMultipleRechargeAddressDirty,
  markRechargeAddressDirty,
  markRechargeAddressListDirty,
  markRechargeTransactionForAddressListDirty,
  markRechargeTransactionListDirty,
  storeMultipleRechargeAddress,
  storeRechargeAddress,
  storeRechargeAddressListData,
  storeRechargeAddressListParameters,
  storeRechargeTransactionForAddressListData,
  storeRechargeTransactionForAddressListParameters,
  storeRechargeTransactionListData,
  storeRechargeTransactionListParameters,
} from './actions';
import { defaultRechargeAddressListState, defaultRechargeTransactionListState } from './types';
import { extractRechargeAddressId } from './utils';

import type { RechargesState } from './types';
import type { Draft } from 'immer';

const initialState: RechargesState = {
  list: defaultRechargeAddressListState,
  entities: {},
  transactions: {
    list: defaultRechargeTransactionListState,
    columnState: {},
    byAddress: {},
  },
};

const {
  storeRechargeAddressReducer,
  storeMultipleRechargeAddressReducer,
  storeRechargeAddressListDataReducer,
  storeRechargeAddressListParametersReducer,
  markMultipleRechargeAddressDirtyReducer,
  markRechargeAddressListDirtyReducer,
  markRechargeAddressDirtyReducer,
} = createNormalizedListReducers(
  'RechargeAddress' as const,
  (state: Draft<RechargesState>) => state.list,
  (state, list) => ({ ...state, list }),
  defaultRechargeAddressListState,
  // this cast is workaround caused by BigNumber in the store
  (state: Draft<RechargesState>) => state.entities as RechargesState['entities'],
  (state, entities) => ({ ...state, entities }),
  extractRechargeAddressId,
);

const {
  storeRechargeTransactionForAddressListDataReducer,
  storeRechargeTransactionForAddressListParametersReducer,
  markRechargeTransactionForAddressListDirtyReducer,
} = createNestedNormalizedListReducers(
  'RechargeTransactionForAddress' as const,
  (state: Draft<RechargesState>, addressId: string | undefined) =>
    addressId ? state.transactions.byAddress[addressId] : undefined,
  (state, addressId, newListState) => ({
    ...state,
    transactions: {
      ...state.transactions,
      byAddress: { ...state.transactions.byAddress, [addressId]: newListState },
    },
  }),
  (state) => state.transactions.columnState,
  (state, columnState) => ({ ...state, transactions: { ...state.transactions, columnState } }),
  defaultRechargeTransactionListState,
  extractEntityTransactionId,
);

const {
  storeRechargeTransactionListDataReducer,
  storeRechargeTransactionListParametersReducer,
  markRechargeTransactionListDirtyReducer,
} = createSplitNormalizedListReducers(
  'RechargeTransaction' as const,
  (state: Draft<RechargesState>) => state.transactions.list,
  (state, list) => ({ ...state, transactions: { ...state.transactions, list } }),
  (state: Draft<RechargesState>) => state.transactions.columnState,
  (state, columnState) => ({ ...state, transactions: { ...state.transactions, columnState } }),
  defaultRechargeTransactionListState,
  extractEntityTransactionId,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(storeRechargeTransactionForAddressListData, storeRechargeTransactionForAddressListDataReducer)
    .addCase(storeRechargeTransactionForAddressListParameters, storeRechargeTransactionForAddressListParametersReducer)
    .addCase(markRechargeTransactionForAddressListDirty, markRechargeTransactionForAddressListDirtyReducer)

    .addCase(storeRechargeAddress, storeRechargeAddressReducer)
    .addCase(storeMultipleRechargeAddress, storeMultipleRechargeAddressReducer)
    .addCase(storeRechargeAddressListData, storeRechargeAddressListDataReducer)
    .addCase(storeRechargeAddressListParameters, storeRechargeAddressListParametersReducer)
    .addCase(markRechargeAddressListDirty, markRechargeAddressListDirtyReducer)
    .addCase(markRechargeAddressDirty, markRechargeAddressDirtyReducer)
    .addCase(markMultipleRechargeAddressDirty, markMultipleRechargeAddressDirtyReducer)

    .addCase(storeRechargeTransactionListData, storeRechargeTransactionListDataReducer)
    .addCase(storeRechargeTransactionListParameters, storeRechargeTransactionListParametersReducer)
    .addCase(markRechargeTransactionListDirty, markRechargeTransactionListDirtyReducer)

    .addCase(notifyNetworkUpdated, (state) => ({
      ...initialState,
      list: {
        ...initialState.list,
        columnState: state.list.columnState,
        filter: state.list.filter,
        sortBy: state.list.sortBy,
      },
      transactions: {
        ...state.transactions,
        columnState: state.list.columnState,
        list: {
          ...state.transactions.list,
          filter: state.transactions.list.filter,
          sortBy: state.transactions.list.sortBy,
        },
      },
    }))
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address || previous?.activeCompanyId !== current?.activeCompanyId
        ? initialState
        : state,
    );
});

export default reducer;
