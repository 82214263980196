import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MemoryStorage from 'memorystorage';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { createClient, http } from 'viem';
import { createConfig, WagmiProvider, createStorage } from 'wagmi';
import * as allChains from 'wagmi/chains';
import { coinbaseWallet, metaMask, walletConnect } from 'wagmi/connectors';

import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import { useCookiesAgreement } from '@/features/global/hooks';
import { useStateMountSafe } from '@/hooks';
import type { Web3Auth } from '@/infrastructure/security/web3-auth/types';
import { withSuppressPromise } from '@/infrastructure/utils/functions';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import Web3EVMInitContext from './context';

// @ts-expect-error
import type { BaseStorage } from '@wagmi/core/src/createStorage';
import type React from 'react';

const chains = [
  // mainnet:
  allChains.bsc,
  allChains.arbitrum,
  allChains.mainnet,
  allChains.polygon,
  // testnet:
  allChains.bscTestnet,
  allChains.arbitrumSepolia,
  allChains.sepolia,
  allChains.polygonAmoy,
] as const;

const realConfig = async ({
  coinbaseEnabled,
  walletConnectEnabled,
  web3Auth,
  storage,
}: {
  coinbaseEnabled: boolean;
  walletConnectEnabled: boolean;
  web3Auth?: Web3Auth;
  storage: BaseStorage;
}) =>
  createConfig({
    storage: createStorage({ storage }),
    multiInjectedProviderDiscovery: false,
    chains,
    connectors: [
      ...(web3Auth ? [(await import('./web3auth-connector')).default({ web3Auth })] : []),
      metaMask({
        dappMetadata: {
          name: 'Smarty Pay Dashboard',
          url: 'https://dashboard.smartypay.io',
          iconUrl: 'https://dashboard.smartypay.io/logo192.png',
        },
      }),
      ...(coinbaseEnabled ? [coinbaseWallet({ version: '4', appName: 'Smarty Pay Dashboard' })] : []),
      ...(walletConnectEnabled
        ? [
            walletConnect({
              projectId: window.env.WALLET_CONNECT_PROJECT_ID,
              showQrModal: true,
              qrModalOptions: { themeVariables: { '--wcm-z-index': '1005' } },
            }),
          ]
        : []),
    ],
    client: ({ chain }) => createClient({ chain, transport: http() }),
  });

const noConnectorConfig = createConfig({
  multiInjectedProviderDiscovery: false,
  chains,
  connectors: [],
  client: ({ chain }) => createClient({ chain, transport: http() }),
});

const queryClient = new QueryClient();

const isWeb3AuthReady = (web3Auth?: Web3Auth) => !!web3Auth?.isConfirmed;

const withEVMConnectorsSupport = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithWeb3Support',
  )((props) => {
    const [initialized, setInitialized] = useStateMountSafe(false);
    const featureFlags = useFeatureToggle();
    const { accepted: cookiesAccepted } = useCookiesAgreement();
    const { web3Auth, state: web3AuthState } = useContext(Web3AuthContext);
    const client = useRef(noConnectorConfig);
    const initializedWithWeb3Auth = useRef(false);
    const initializationCalled = useRef<boolean>(false);
    const doInitialize = useCallback(() => {
      setTimeout(
        withSuppressPromise(async () => {
          initializedWithWeb3Auth.current = isWeb3AuthReady(web3Auth);
          client.current = await realConfig({
            web3Auth: isWeb3AuthReady(web3Auth) ? web3Auth : undefined,
            storage:
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              (cookiesAccepted && (window.localStorage ?? window.sessionStorage))
              || new MemoryStorage('web3auth-connector'),
            walletConnectEnabled: !!featureFlags.data.data?.enableWalletConnect,
            coinbaseEnabled: !!featureFlags.data.data?.enableCoinbase,
          });
          setInitialized(true);
        }),
        0,
      );
    }, [
      web3Auth,
      cookiesAccepted,
      featureFlags.data.data?.enableWalletConnect,
      featureFlags.data.data?.enableCoinbase,
      setInitialized,
    ]);
    const initialize = useCallback(() => {
      if (!initializationCalled.current) {
        initializationCalled.current = true;
        doInitialize();
      }
    }, [doInitialize]);
    useEffect(() => {
      if (initializationCalled.current && isWeb3AuthReady(web3Auth) !== initializedWithWeb3Auth.current) doInitialize();
      // web3AuthState here is to refresh state
    }, [doInitialize, web3Auth, web3AuthState]);
    const context = useMemo(() => ({ initialized, initialize }), [initialize, initialized]);
    return (
      <Web3EVMInitContext.Provider value={context}>
        <WagmiProvider config={client.current}>
          <QueryClientProvider client={queryClient}>
            <Component {...props} />
          </QueryClientProvider>
        </WagmiProvider>
      </Web3EVMInitContext.Provider>
    );
  });

export default withEVMConnectorsSupport;
