/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum StableCoinCryptoPeggyAPIModel {
    USDC = 'USDC',
    USDT = 'USDT'
}


export function StableCoinCryptoPeggyAPIModelFromJSON(json: any): StableCoinCryptoPeggyAPIModel {
    return StableCoinCryptoPeggyAPIModelFromJSONTyped(json, false);
}

export function StableCoinCryptoPeggyAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): StableCoinCryptoPeggyAPIModel {
    return json as StableCoinCryptoPeggyAPIModel;
}

export function StableCoinCryptoPeggyAPIModelToJSON(value?: StableCoinCryptoPeggyAPIModel | null): any {
    return value as any;
}

