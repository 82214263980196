import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import difference from 'lodash-es/difference';

import listenerMiddleware from '@/app/listenerMiddleware';
import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { createNotification } from '@/features/global/actions';
import {
  SettlementIntentStatusAPIModel,
  SettlementIntentTransactionStatusAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';
import { createNestedNormalizedListReducers, createNormalizedListReducers } from '@/infrastructure/model/list/reducers';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';
import { onlyUnique, suppressPromise } from '@/infrastructure/utils/functions';
import { notEmpty } from '@/infrastructure/utils/ts';
import { settlementIntentViewLink } from '@/pages/settlements/routes';

import {
  markDistributeFeeDirty,
  markMultipleSettlementIntentDirty,
  markSettlementDirty,
  markSettlementIntentDirty,
  markSettlementIntentListDirty,
  markSettlementIntentTransactionDetailsDirty,
  markSettlementIntentTransactionDirty,
  markSettlementIntentTransactionsForIntentListDirty,
  markSettlementIntentsDependentDataDirty,
  markSettlementListDirty,
  markSettlementScheduleDirty,
  markSettlementsForAssetListDirty,
  storeDistributeFee,
  storeMultipleSettlement,
  storeMultipleSettlementIntent,
  storeMultipleSettlementIntentTransaction,
  storeMultipleSettlementIntentTransactionDetails,
  markPendingIntentsInitialized,
  storePendingIntentsRefreshableAfter,
  storeSettlement,
  storeSettlementIntent,
  storeSettlementIntentListData,
  storeSettlementIntentListParameters,
  storeSettlementIntentTransaction,
  storeSettlementIntentTransactionDetails,
  storeSettlementIntentTransactionsForIntentListData,
  storeSettlementIntentTransactionsForIntentListParameters,
  storeSettlementListData,
  storeSettlementListParameters,
  storeSettlementSchedule,
  storeSettlementsForAssetListData,
  storeSettlementsForAssetListParameters,
  fetchMultipleSettlementIntent,
} from './actions';
import {
  makeSelectMultipleSettlementIntent,
  makeSelectMultipleSettlementIntentTransaction,
  makeSelectPendingIntents,
  makeSelectPendingIntentTransactions,
} from './selectors';
import {
  defaultIntentsListState,
  defaultSettlementIntentTransactionsForIntentListState,
  defaultSettlementsForAssetListState,
  defaultSettlementsListState,
  type SettlementsState,
} from './types';
import {
  createDistributeFeeKey,
  extractSettlementId,
  extractSettlementIntentId,
  extractSettlementIntentTransactionId,
} from './utils';

import type { Draft } from 'immer';

const initialState: SettlementsState = {
  schedule: storedDirtyData,

  settlements: {
    entities: {},
    columnState: {},
    list: defaultSettlementsForAssetListState,
    byAsset: {},
  },

  intents: {
    entities: {},
    list: defaultIntentsListState,
    pendingRefreshableAfter: new Date(),
    pendingInitialized: false,
  },

  transactions: {
    columnState: {},
    entities: {},
    byIntent: {},
    details: {},
  },

  distributeFees: {},
};

const {
  storeSettlementReducer,
  storeMultipleSettlementReducer,
  markSettlementDirtyReducer,
  storeSettlementListDataReducer,
  markSettlementListDirtyReducer,
  storeSettlementListParametersReducer,
} = createNormalizedListReducers(
  'Settlement',
  ({ settlements }: Draft<SettlementsState>) => ({ ...settlements.list, columnState: settlements.columnState }),
  ({ settlements, ...state }, { columnState, ...list }) => ({
    ...state,
    settlements: { ...settlements, list, columnState },
  }),
  defaultSettlementsListState,
  ({ settlements }: Draft<SettlementsState>) => settlements.entities as SettlementsState['settlements']['entities'],
  ({ settlements, ...global }, entities) => ({ ...global, settlements: { ...settlements, entities } }),
  extractSettlementId,
);

const {
  storeSettlementsForAssetListDataReducer,
  markSettlementsForAssetListDirtyReducer,
  storeSettlementsForAssetListParametersReducer,
} = createNestedNormalizedListReducers(
  'SettlementsForAsset',
  ({ settlements }: Draft<SettlementsState>, settlementId: string | undefined) =>
    settlementId ? settlements.byAsset[settlementId] : undefined,
  ({ settlements, ...state }, settlementId, newListState) => ({
    ...state,
    settlements: { ...settlements, byAsset: { ...settlements.byAsset, [settlementId]: newListState } },
  }),
  ({ settlements }) => settlements.columnState,
  ({ settlements, ...state }, columnState) => ({ ...state, settlements: { ...settlements, columnState } }),
  defaultSettlementsForAssetListState,
  extractSettlementId,
);

const {
  storeSettlementIntentReducer,
  storeMultipleSettlementIntentReducer,
  markSettlementIntentDirtyReducer,
  markMultipleSettlementIntentDirtyReducer,
  storeSettlementIntentListDataReducer,
  markSettlementIntentListDirtyReducer,
  storeSettlementIntentListParametersReducer,
} = createNormalizedListReducers(
  'SettlementIntent',
  ({ intents }: Draft<SettlementsState>) => intents.list,
  ({ intents, ...state }, list) => ({ ...state, intents: { ...intents, list } }),
  defaultIntentsListState,
  ({ intents }: Draft<SettlementsState>) => intents.entities as SettlementsState['intents']['entities'],
  ({ intents, ...global }, entities) => ({ ...global, intents: { ...intents, entities } }),
  extractSettlementIntentId,
);

const {
  storeSettlementIntentTransactionReducer,
  markSettlementIntentTransactionDirtyReducer,
  storeMultipleSettlementIntentTransactionReducer,
} = createSingleReducers(
  'SettlementIntentTransaction' as const,
  (state: Draft<SettlementsState>) => state.transactions.entities,
  ({ transactions, ...state }, entities) => ({ ...state, transactions: { ...transactions, entities } }),
  undefined,
);

const {
  storeSettlementIntentTransactionsForIntentListDataReducer,
  markSettlementIntentTransactionsForIntentListDirtyReducer,
  storeSettlementIntentTransactionsForIntentListParametersReducer,
} = createNestedNormalizedListReducers(
  'SettlementIntentTransactionsForIntent',
  ({ transactions }: Draft<SettlementsState>, intentId: string | undefined) =>
    intentId ? transactions.byIntent[intentId] : undefined,
  ({ transactions, ...state }, intentId, newListState) => ({
    ...state,
    transactions: { ...transactions, byIntent: { ...transactions.byIntent, [intentId]: newListState } },
  }),
  ({ transactions }) => transactions.columnState,
  ({ transactions, ...state }, columnState) => ({ ...state, transactions: { ...transactions, columnState } }),
  defaultSettlementIntentTransactionsForIntentListState,
  extractSettlementIntentTransactionId,
);

const {
  storeSettlementIntentTransactionDetailsReducer,
  markSettlementIntentTransactionDetailsDirtyReducer,
  storeMultipleSettlementIntentTransactionDetailsReducer,
} = createSingleReducers(
  'SettlementIntentTransactionDetails' as const,
  (state: Draft<SettlementsState>) => state.transactions.details,
  ({ transactions, ...state }, details) => ({ ...state, transactions: { ...transactions, details } }),
  undefined,
);

const { markSettlementScheduleDirtyReducer, storeSettlementScheduleReducer } = createLoadingDataReducers(
  'SettlementSchedule' as const,
  (state: Draft<SettlementsState>) => state.schedule,
  (state, schedule) => ({ ...state, schedule }),
);

const { storeDistributeFeeReducer, markDistributeFeeDirtyReducer } = createSingleReducers(
  'DistributeFee' as const,
  (state: Draft<SettlementsState>) => state.distributeFees,
  (state, distributeFees) => ({ ...state, distributeFees }),
  createDistributeFeeKey,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markSettlementDirty, markSettlementDirtyReducer)
    .addCase(storeSettlement, storeSettlementReducer)
    .addCase(storeMultipleSettlement, storeMultipleSettlementReducer)
    .addCase(markSettlementListDirty, markSettlementListDirtyReducer)
    .addCase(storeSettlementListData, storeSettlementListDataReducer)
    .addCase(storeSettlementListParameters, storeSettlementListParametersReducer)

    .addCase(markSettlementsForAssetListDirty, markSettlementsForAssetListDirtyReducer)
    .addCase(storeSettlementsForAssetListData, storeSettlementsForAssetListDataReducer)
    .addCase(storeSettlementsForAssetListParameters, storeSettlementsForAssetListParametersReducer)

    .addCase(markSettlementIntentDirty, markSettlementIntentDirtyReducer)
    .addCase(storeSettlementIntent, storeSettlementIntentReducer)
    .addCase(markMultipleSettlementIntentDirty, markMultipleSettlementIntentDirtyReducer)
    .addCase(storeMultipleSettlementIntent, storeMultipleSettlementIntentReducer)
    .addCase(markSettlementIntentListDirty, markSettlementIntentListDirtyReducer)
    .addCase(storeSettlementIntentListData, storeSettlementIntentListDataReducer)
    .addCase(storeSettlementIntentListParameters, storeSettlementIntentListParametersReducer)

    .addCase(markSettlementIntentTransactionDirty, markSettlementIntentTransactionDirtyReducer)
    .addCase(storeSettlementIntentTransaction, storeSettlementIntentTransactionReducer)
    .addCase(storeMultipleSettlementIntentTransaction, storeMultipleSettlementIntentTransactionReducer)

    .addCase(
      storeSettlementIntentTransactionsForIntentListData,
      storeSettlementIntentTransactionsForIntentListDataReducer,
    )
    .addCase(
      storeSettlementIntentTransactionsForIntentListParameters,
      storeSettlementIntentTransactionsForIntentListParametersReducer,
    )
    .addCase(
      markSettlementIntentTransactionsForIntentListDirty,
      markSettlementIntentTransactionsForIntentListDirtyReducer,
    )

    .addCase(markSettlementIntentTransactionDetailsDirty, markSettlementIntentTransactionDetailsDirtyReducer)
    .addCase(storeSettlementIntentTransactionDetails, storeSettlementIntentTransactionDetailsReducer)
    .addCase(storeMultipleSettlementIntentTransactionDetails, storeMultipleSettlementIntentTransactionDetailsReducer)

    .addCase(markSettlementScheduleDirty, markSettlementScheduleDirtyReducer)
    .addCase(storeSettlementSchedule, storeSettlementScheduleReducer)

    .addCase(storePendingIntentsRefreshableAfter, (state, { payload: pendingRefreshableAfter }) => ({
      ...state,
      intents: { ...state.intents, pendingRefreshableAfter },
    }))
    .addCase(markPendingIntentsInitialized, (state, { payload: { refreshableAfter } }) => ({
      ...state,
      intents: { ...state.intents, pendingRefreshableAfter: refreshableAfter, pendingInitialized: true },
    }))

    .addCase(markDistributeFeeDirty, markDistributeFeeDirtyReducer)
    .addCase(storeDistributeFee, storeDistributeFeeReducer)

    .addCase(notifyNetworkUpdated, (state) => ({
      ...initialState,
      settlements: {
        ...initialState.settlements,
        columnState: state.settlements.columnState,
        list: {
          ...initialState.settlements.list,
          sortBy: state.settlements.list.sortBy,
        },
      },
      intents: {
        ...initialState.intents,
        list: {
          ...initialState.intents.list,
          columnState: state.intents.list.columnState,
          sortBy: state.intents.list.sortBy,
        },
      },
      transactions: {
        ...initialState.transactions,
        columnState: state.transactions.columnState,
      },
    }))
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address || previous?.activeCompanyId !== current?.activeCompanyId
        ? initialState
        : state,
    );

  listenerMiddleware.startListening({
    matcher: isAnyOf(storeSettlementIntent, storeMultipleSettlementIntent, storeSettlementIntentListData),
    effect: (_, listenerApi) => {
      const werePending = makeSelectPendingIntents()(listenerApi.getOriginalState()).data ?? [];
      const arePending = makeSelectPendingIntents()(listenerApi.getState()).data ?? [];
      if (arePending.length || werePending.length) {
        const updated = makeSelectMultipleSettlementIntent(
          [...werePending, ...arePending].map(extractSettlementIntentId).filter(onlyUnique),
        )(listenerApi.getState());
        const succeeded = updated
          .filter(
            ({ data }) =>
              data.data?.status
              && [SettlementIntentStatusAPIModel.Succeeded, SettlementIntentStatusAPIModel.PartlySucceeded].includes(
                data.data.status,
              ),
          )
          .map(({ data }) => data.data)
          .filter(notEmpty);
        const failed = updated
          .filter(({ data }) => data.data?.status === SettlementIntentStatusAPIModel.Failed)
          .map(({ data }) => data.data)
          .filter(notEmpty);

        succeeded.forEach((intent) =>
          listenerApi.dispatch(
            createNotification({
              severity: 'success',
              messageI18n: I18nFeatureSettlements.MESSAGES_PENDING_FINISHED_SUCCESS,
              messageI18nValues: { ln: { type: 'link', to: settlementIntentViewLink(intent.id) } },
            }),
          ),
        );

        failed.forEach((intent) =>
          listenerApi.dispatch(
            createNotification({
              severity: 'error',
              messageI18n: I18nFeatureSettlements.MESSAGES_PENDING_FINISHED_FAILURE,
              messageI18nValues: { ln: { type: 'link', to: settlementIntentViewLink(intent.id) } },
            }),
          ),
        );

        if (succeeded.length || failed.length) {
          const intentIds = [...succeeded, ...failed].map(({ id }) => id);
          suppressPromise(listenerApi.dispatch(markSettlementIntentsDependentDataDirty(intentIds)));
        }
      }
    },
  });

  listenerMiddleware.startListening({
    matcher: isAnyOf(
      storeSettlementIntentTransaction,
      storeMultipleSettlementIntentTransaction,
      storeSettlementIntentTransactionsForIntentListData,
    ),
    effect: (_, listenerApi) => {
      const werePending = makeSelectPendingIntentTransactions()(listenerApi.getOriginalState()).data ?? [];
      const arePending = makeSelectPendingIntentTransactions()(listenerApi.getState()).data ?? [];
      if (arePending.length || werePending.length) {
        const updatedIds = [
          ...arePending
            .filter((pending) => {
              const wasPending = werePending.find(({ id }) => id === pending.id);
              return !wasPending || wasPending.status !== pending.status;
            })
            .map(({ id }) => id),
          ...difference(
            werePending.map(({ id }) => id),
            arePending.map(({ id }) => id),
          ),
        ];
        if (updatedIds.length) {
          const updatedIntentIds = makeSelectMultipleSettlementIntentTransaction(
            [...werePending, ...arePending].map(extractSettlementIntentTransactionId).filter(onlyUnique),
          )(listenerApi.getState())
            .filter(
              ({ data }) =>
                data.data?.status
                && [
                  SettlementIntentTransactionStatusAPIModel.Succeeded,
                  SettlementIntentTransactionStatusAPIModel.Failed,
                ].includes(data.data.status),
            )
            .map(({ data }) => data.data?.intentId)
            .filter(notEmpty);
          if (updatedIntentIds.length) {
            listenerApi.dispatch(markMultipleSettlementIntentDirty(updatedIntentIds));
            suppressPromise(listenerApi.dispatch(fetchMultipleSettlementIntent({ ids: updatedIntentIds })));
          }
        }
      }
    },
  });
});

export default reducer;
