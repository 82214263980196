import { useBlockchain } from '@/features/dictionary/blockchain/hooks';
import type { BlockchainAPITypeAPIModel, BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { namedHOC } from '@/infrastructure/utils/react';

import withBlockchainType from './withBlockchainType';

import type React from 'react';

const emptyComponent = () => null;

const withBlockchainAPITypeLoad = <T extends { api: BlockchainAPITypeAPIModel }>(
  Component: React.ComponentType<Omit<T, 'api'> & { api: BlockchainAPITypeAPIModel }>,
  EmptyComponent: React.ComponentType<Omit<T, 'api'>>,
) =>
  namedHOC<Omit<T, 'api'> & { api: BlockchainAPITypeAPIModel }, Omit<T, 'api'> & { bt: BlockchainTypeAPIModel }>(
    Component,
    'WithBlockchainAPITypeLoad',
  )((props) => {
    const { bt } = props;
    const bc = useBlockchain(bt);
    return bc.data.data?.apiType ? <Component {...props} api={bc.data.data.apiType} /> : <EmptyComponent {...props} />;
  });

const withBlockchainAPIType = <T extends { api: BlockchainAPITypeAPIModel }>(
  Component: React.ComponentType<Omit<T, 'api'> & { api: BlockchainAPITypeAPIModel }>,
  EmptyComponent: React.ComponentType<Omit<T, 'api'>> = emptyComponent,
) => {
  const BlockchainComponent = withBlockchainAPITypeLoad<T>(Component, EmptyComponent);
  const AssetComponent = withBlockchainType(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    BlockchainComponent,
    EmptyComponent,
  );
  return namedHOC<
    Omit<T, 'api'> & { api: BlockchainAPITypeAPIModel },
    | (Omit<T, 'api'> & { api: BlockchainAPITypeAPIModel })
    | (Omit<T, 'api'> & { bt: BlockchainTypeAPIModel })
    | (Omit<T, 'api'> & { assetId: string })
  >(
    Component,
    'WithBlockchainAPIType',
  )((props) => {
    if ('api' in props) return <Component {...props} />;
    if ('bt' in props) return <BlockchainComponent {...props} />;
    if ('assetId' in props) return <AssetComponent {...props} />;
    return null;
  });
};

export default withBlockchainAPIType;
