/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PaymentTransactionAPIModel } from './PaymentTransactionAPIModel';
import {
    PaymentTransactionAPIModelFromJSON,
    PaymentTransactionAPIModelFromJSONTyped,
    PaymentTransactionAPIModelToJSON,
} from './PaymentTransactionAPIModel';

/**
 * 
 * @export
 * @interface PaymentTransactionSearchResultAPIModel
 */
export interface PaymentTransactionSearchResultAPIModel {
    /**
     * 
     * @type {Array<PaymentTransactionAPIModel>}
     * @memberof PaymentTransactionSearchResultAPIModel
     */
    list?: Array<PaymentTransactionAPIModel>;
}

/**
 * Check if a given object implements the PaymentTransactionSearchResultAPIModel interface.
 */
export function instanceOfPaymentTransactionSearchResultAPIModel(value: object): boolean {
    return true;
}

export function PaymentTransactionSearchResultAPIModelFromJSON(json: any): PaymentTransactionSearchResultAPIModel {
    return PaymentTransactionSearchResultAPIModelFromJSONTyped(json, false);
}

export function PaymentTransactionSearchResultAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTransactionSearchResultAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(PaymentTransactionAPIModelFromJSON)),
    };
}

export function PaymentTransactionSearchResultAPIModelToJSON(value?: PaymentTransactionSearchResultAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(PaymentTransactionAPIModelToJSON)),
    };
}

