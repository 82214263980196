import { Segmented, Space, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { authWeb3 } from '@/features/auth/actions';
import { WalletAuthInProgress } from '@/features/auth/components';
import { BlockchainAPIIcon } from '@/features/dictionary/blockchain/components';
import { useIsFeatureEnabled } from '@/features/feature-toggle/hooks';
import { useActionPending } from '@/features/global/hooks';
import { withWeb3EVMContext, withWeb3SolanaContext } from '@/features/web3/hocs';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageAuth } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import createCache from '@/infrastructure/utils/cache';
import { withFinally } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import { enumByKey } from '@/infrastructure/utils/ts';
import { cancellable } from '@/infrastructure/utils/ui';
import { withHideIfOtherActive } from '@/pages/auth/login/hocs';

import { EVMWallets, SolanaWallets } from './components';
import { useStyles } from './hooks';
import { WalletAuthType } from './types';

import type { WalletsBlockProps } from './types';

const { Text } = Typography;

const walletTypeCache = createCache<string>('ncps.auth.wallet-type');

const web3WalletPrefix = 'web3-';

const WalletsBlock: React.FC<WalletsBlockProps> = ({
  'data-test': dataTest,
  activeProvider,
  onSelectProvider,
  withLogIn,
}) => {
  const styles = useStyles();
  const disableSolana = useIsFeatureEnabled('disableSolana');

  const withSelectProvider = useMemo(
    () =>
      <V extends unknown[] = unknown[], R = unknown>(func: Func<V, R>): Func<V> =>
        withFinally(func, onSelectProvider),
    [onSelectProvider],
  );

  const onPrepareCancel = useCallback(
    (selected: string) => () =>
      cancellable((reject) =>
        onSelectProvider({
          id: `${web3WalletPrefix}${selected}`,
          onCancel: () => {
            reject();
            onSelectProvider();
          },
        }),
      ),
    [onSelectProvider],
  );

  const [walletType, setWalletType] = useStateMountSafe(
    () => enumByKey(WalletAuthType, walletTypeCache.safeRead()) || WalletAuthType.eth,
  );
  const saveWalletType = useCallback(
    (newWalletType: WalletAuthType) => {
      walletTypeCache.safeSave(newWalletType);
      setWalletType(newWalletType);
    },
    [setWalletType],
  );

  const signatureIsValidating = useActionPending(authWeb3);
  if (signatureIsValidating) return <WalletAuthInProgress data-test={dataTest && `${dataTest}-progress`} />;

  return (
    <Space direction="vertical" style={{ display: 'flex', flex: 1 }} size="large">
      {!activeProvider && (
        <Text data-test={dataTest && `${dataTest}-description`}>
          <FormattedMessage id={I18nPageAuth.COMPONENTS_WALLETS_BLOCK_DESCRIPTION} />
        </Text>
      )}
      <div className={!activeProvider && !disableSolana ? styles.container : undefined}>
        {!activeProvider && !disableSolana && (
          <Segmented<WalletAuthType>
            size="middle"
            className={styles.segmented}
            options={[
              {
                icon: <BlockchainAPIIcon api={BlockchainAPITypeAPIModel.EVM} />,
                label: <FormattedMessage id={I18nPageAuth.COMPONENTS_WALLETS_BLOCK_OPTIONS_ETH} />,
                value: WalletAuthType.eth,
                className: styles.segmentedItem,
              },
              {
                icon: <BlockchainAPIIcon api={BlockchainAPITypeAPIModel.Solana} />,
                label: <FormattedMessage id={I18nPageAuth.COMPONENTS_WALLETS_BLOCK_OPTIONS_SOL} />,
                value: WalletAuthType.sol,
                className: styles.segmentedItem,
              },
            ]}
            block
            value={walletType}
            onChange={saveWalletType}
          />
        )}
        <div className={!activeProvider && !disableSolana ? styles.wallets : undefined}>
          {walletType === WalletAuthType.eth && (
            <EVMWallets
              data-test={dataTest}
              withLogIn={withLogIn}
              withSelectProvider={withSelectProvider}
              activeProvider={activeProvider?.replace(web3WalletPrefix, '')}
              onPrepareCancel={onPrepareCancel}
            />
          )}
          {walletType === WalletAuthType.sol && (
            <SolanaWallets
              data-test={dataTest}
              withLogIn={withLogIn}
              withSelectProvider={withSelectProvider}
              activeProvider={activeProvider?.replace(web3WalletPrefix, '')}
              onPrepareCancel={onPrepareCancel}
            />
          )}
        </div>
      </div>
    </Space>
  );
};

const WalletsBlockHOC = withWeb3EVMContext(
  withWeb3SolanaContext(
    withHideIfOtherActive<WalletsBlockProps>(
      (activeProvider) => !activeProvider || activeProvider.startsWith(web3WalletPrefix),
    )(WalletsBlock),
  ),
);

const WalletsBlockMemo: typeof WalletsBlock = React.memo(WalletsBlockHOC);

export default WalletsBlockMemo;
