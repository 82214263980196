import { Alert } from 'antd';
import React from 'react';

import { FormattedMessageWithMarkup } from '@/components';
import { BlockchainLabelList } from '@/features/dictionary/blockchain/components';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';

import type { Web3WalletNotSupportedOperationProps } from './types';

const Web3WalletNotSupportedOperation: React.FC<Web3WalletNotSupportedOperationProps> = ({
  'data-test': dataTest,
  api,
}) => (
  <Alert
    data-test={dataTest}
    type="warning"
    message={
      <FormattedMessageWithMarkup
        id={I18nFeatureWeb3.COMPONENTS_WALLET_CONTAINER_MESSAGES_NOT_SUPPORTED}
        values={{ blockchains: <BlockchainLabelList api={api} /> }}
      />
    }
  />
);

const Web3WalletNotSupportedOperationMemo = React.memo(
  Web3WalletNotSupportedOperation,
) as typeof Web3WalletNotSupportedOperation;

export default Web3WalletNotSupportedOperationMemo;
