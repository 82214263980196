/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewReportScheduleAPIModel
 */
export interface NewReportScheduleAPIModel {
    /**
     * 
     * @type {string}
     * @memberof NewReportScheduleAPIModel
     */
    template: string;
    /**
     * 
     * @type {string}
     * @memberof NewReportScheduleAPIModel
     */
    cron: string;
    /**
     * 
     * @type {any}
     * @memberof NewReportScheduleAPIModel
     */
    parameters?: any;
}

/**
 * Check if a given object implements the NewReportScheduleAPIModel interface.
 */
export function instanceOfNewReportScheduleAPIModel(value: object): boolean {
    if (!('template' in value)) return false;
    if (!('cron' in value)) return false;
    return true;
}

export function NewReportScheduleAPIModelFromJSON(json: any): NewReportScheduleAPIModel {
    return NewReportScheduleAPIModelFromJSONTyped(json, false);
}

export function NewReportScheduleAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewReportScheduleAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'template': json['template'],
        'cron': json['cron'],
        'parameters': json['parameters'] == null ? undefined : json['parameters'],
    };
}

export function NewReportScheduleAPIModelToJSON(value?: NewReportScheduleAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'template': value['template'],
        'cron': value['cron'],
        'parameters': value['parameters'],
    };
}

