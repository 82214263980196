import { useCallback } from 'react';

import { useWeb3EVMConnector } from '@/features/web3/hooks';
import { someOrFail } from '@/infrastructure/utils/functions';

import usePayoutActions from '../usePayoutActions';

import type { UsePayoutWeb3Actions } from './types';

export default function usePayoutWeb3Actions(payoutId: string | undefined): UsePayoutWeb3Actions {
  const { client, chainId, account } = useWeb3EVMConnector();
  const { start: startAction, ...actions } = usePayoutActions(payoutId, chainId, account);
  const start = {
    ...startAction,
    act: useCallback(async () => startAction.act(someOrFail(client)), [startAction, client]),
  };

  return { ...actions, start };
}
