/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  MerchantReportAPIModel,
  MerchantWalletTransferAPIModel,
  MerchantWalletTransferFilterAPIModel,
  MerchantWalletTransferPredicatesAPIModel,
  SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    MerchantReportAPIModelFromJSON,
    MerchantReportAPIModelToJSON,
    MerchantWalletTransferAPIModelFromJSON,
    MerchantWalletTransferAPIModelToJSON,
    MerchantWalletTransferFilterAPIModelFromJSON,
    MerchantWalletTransferFilterAPIModelToJSON,
    MerchantWalletTransferPredicatesAPIModelFromJSON,
    MerchantWalletTransferPredicatesAPIModelToJSON,
    SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModelFromJSON,
    SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModelToJSON,
} from '../models/index';

export interface ExportMerchantWalletTransfersRequest {
    merchantWalletTransferPredicatesAPIModel: MerchantWalletTransferPredicatesAPIModel;
}

export interface GetMerchantWalletTransferRequest {
    txId: string;
}

export interface GetMerchantWalletTransfersRequest {
    merchantWalletTransferFilterAPIModel: MerchantWalletTransferFilterAPIModel;
    withTotal?: boolean;
}

/**
 * MerchantWalletTransferApi - interface
 * 
 * @export
 * @interface MerchantWalletTransferApiInterface
 */
export interface MerchantWalletTransferApiInterface {
    /**
     * Exports merchant wallets transfers
     * @param {MerchantWalletTransferPredicatesAPIModel} merchantWalletTransferPredicatesAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantWalletTransferApiInterface
     */
    exportMerchantWalletTransfersRaw(requestParameters: ExportMerchantWalletTransfersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>>;

    /**
     * Exports merchant wallets transfers
     */
    exportMerchantWalletTransfers(merchantWalletTransferPredicatesAPIModel: MerchantWalletTransferPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel>;

    /**
     * Returns merchant wallet transfer by id
     * @param {string} txId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantWalletTransferApiInterface
     */
    getMerchantWalletTransferRaw(requestParameters: GetMerchantWalletTransferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletTransferAPIModel>>;

    /**
     * Returns merchant wallet transfer by id
     */
    getMerchantWalletTransfer(txId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletTransferAPIModel>;

    /**
     * Returns merchant wallet transfers according to filter
     * @param {MerchantWalletTransferFilterAPIModel} merchantWalletTransferFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantWalletTransferApiInterface
     */
    getMerchantWalletTransfersRaw(requestParameters: GetMerchantWalletTransfersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModel>>;

    /**
     * Returns merchant wallet transfers according to filter
     */
    getMerchantWalletTransfers(merchantWalletTransferFilterAPIModel: MerchantWalletTransferFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModel>;

}

/**
 * 
 */
export class MerchantWalletTransferApi extends runtime.BaseAPI implements MerchantWalletTransferApiInterface {

    /**
     * Exports merchant wallets transfers
     */
    async exportMerchantWalletTransfersRaw(requestParameters: ExportMerchantWalletTransfersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>> {
        if (requestParameters['merchantWalletTransferPredicatesAPIModel'] == null) {
            throw new runtime.RequiredError(
                'merchantWalletTransferPredicatesAPIModel',
                'Required parameter "merchantWalletTransferPredicatesAPIModel" was null or undefined when calling exportMerchantWalletTransfers().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-wallet-transfer/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MerchantWalletTransferPredicatesAPIModelToJSON(requestParameters['merchantWalletTransferPredicatesAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantReportAPIModelFromJSON(jsonValue));
    }

    /**
     * Exports merchant wallets transfers
     */
    async exportMerchantWalletTransfers(merchantWalletTransferPredicatesAPIModel: MerchantWalletTransferPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel> {
        const response = await this.exportMerchantWalletTransfersRaw({ merchantWalletTransferPredicatesAPIModel: merchantWalletTransferPredicatesAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Returns merchant wallet transfer by id
     */
    async getMerchantWalletTransferRaw(requestParameters: GetMerchantWalletTransferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletTransferAPIModel>> {
        if (requestParameters['txId'] == null) {
            throw new runtime.RequiredError(
                'txId',
                'Required parameter "txId" was null or undefined when calling getMerchantWalletTransfer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-wallet-transfer/{txId}`.replace(`{${"txId"}}`, encodeURIComponent(String(requestParameters['txId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantWalletTransferAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns merchant wallet transfer by id
     */
    async getMerchantWalletTransfer(txId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletTransferAPIModel> {
        const response = await this.getMerchantWalletTransferRaw({ txId: txId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns merchant wallet transfers according to filter
     */
    async getMerchantWalletTransfersRaw(requestParameters: GetMerchantWalletTransfersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModel>> {
        if (requestParameters['merchantWalletTransferFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'merchantWalletTransferFilterAPIModel',
                'Required parameter "merchantWalletTransferFilterAPIModel" was null or undefined when calling getMerchantWalletTransfers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-wallet-transfer/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MerchantWalletTransferFilterAPIModelToJSON(requestParameters['merchantWalletTransferFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns merchant wallet transfers according to filter
     */
    async getMerchantWalletTransfers(merchantWalletTransferFilterAPIModel: MerchantWalletTransferFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceMerchantWalletTransferMerchantWalletTransferSortByAPIModel> {
        const response = await this.getMerchantWalletTransfersRaw({ merchantWalletTransferFilterAPIModel: merchantWalletTransferFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

}
