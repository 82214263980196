import BigNumber from 'bignumber.js';
import { createPublicClient, getAddress, getContract, http, erc20Abi } from 'viem';

import storeHolder from '@/app/store.holder';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { withTimeout } from '@/infrastructure/utils/functions';

export interface BCRpcRequestAPIMeta {
  host: string;
  auth?: boolean;
}

export const getRpcClient = ({ host, auth }: BCRpcRequestAPIMeta) => {
  const token = storeHolder.store ? makeSelectAuthToken()(storeHolder.store.getState()).data?.token : undefined;
  if (auth && !token) {
    console.warn(`No token with required auth for host "${host}`);
  }
  const authHeaders = auth && token ? { [window.env.AUTH_HEADER_TOKEN]: `Bearer ${token}` } : {};
  return createPublicClient({ transport: http(host, { fetchOptions: { headers: authHeaders } }) });
};

export const queryNativeBalance = async (
  bcMeta: BCRpcRequestAPIMeta,
  address: string,
  initOverrides?: RequestInit,
): Promise<BigNumber> => {
  const client = getRpcClient(bcMeta);
  return new BigNumber(
    (
      await withTimeout(
        () => client.getBalance({ address: getAddress(address) }),
        7000,
        undefined,
        initOverrides?.signal ?? undefined,
      )
    ).toString(),
  );
};

export const queryTokenBalance = async (
  bcMeta: BCRpcRequestAPIMeta,
  tokenAddress: string,
  address: string,
  initOverrides?: RequestInit,
) => {
  const client = getRpcClient(bcMeta);
  const contract = getContract({
    address: getAddress(tokenAddress),
    abi: erc20Abi,
    client,
  });
  return new BigNumber(
    (
      await withTimeout(
        async () => contract.read.balanceOf([getAddress(address)]),
        7000,
        undefined,
        initOverrides?.signal ?? undefined,
      )
    ).toString(),
  );
};

export const queryContractExistence = async (
  bcMeta: BCRpcRequestAPIMeta,
  address: string,
  initOverrides?: RequestInit,
): Promise<boolean> => {
  const client = getRpcClient(bcMeta);
  return (
    (await withTimeout(
      async () => client.getCode({ address: getAddress(address) }),
      7000,
      undefined,
      initOverrides?.signal ?? undefined,
    )) !== '0x'
  );
};

export const queryAllowance = async (
  bcMeta: BCRpcRequestAPIMeta,
  tokenAddress: string,
  owner: string,
  spender: string,
  initOverrides?: RequestInit,
): Promise<bigint> => {
  const client = getRpcClient(bcMeta);
  const contract = getContract({
    address: getAddress(tokenAddress),
    abi: erc20Abi,
    client,
  });
  return withTimeout(
    async () => contract.read.allowance([getAddress(owner), getAddress(spender)]),
    7000,
    undefined,
    initOverrides?.signal ?? undefined,
  );
};

export default { getRpcClient, queryTokenBalance, queryNativeBalance, queryContractExistence, queryAllowance };
