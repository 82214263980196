import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';

import { storeEmailCodeState, storeEmailConfirmationState } from './actions';

import type { EmailState } from './types';

const initialState: EmailState = {
  codeState: {},
  confirmationState: {},
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(storeEmailCodeState, (state, { payload: { email, data } }) => ({
      ...state,
      codeState: { ...state.codeState, [email]: data },
    }))
    .addCase(storeEmailConfirmationState, (state, { payload: { email, data } }) => ({
      ...state,
      confirmationState: { ...state.confirmationState, [email]: data },
    }))

    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address || previous?.activeCompanyId !== current?.activeCompanyId
        ? { ...initialState, codeState: state.codeState }
        : state,
    );
});

export default reducer;
