/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { OpenIdProviderTypeAPIModel } from './OpenIdProviderTypeAPIModel';
import {
    OpenIdProviderTypeAPIModelFromJSON,
    OpenIdProviderTypeAPIModelFromJSONTyped,
    OpenIdProviderTypeAPIModelToJSON,
} from './OpenIdProviderTypeAPIModel';
import type { Web3EVMCredentialsAPIModel } from './Web3EVMCredentialsAPIModel';
import {
    Web3EVMCredentialsAPIModelFromJSON,
    Web3EVMCredentialsAPIModelFromJSONTyped,
    Web3EVMCredentialsAPIModelToJSON,
} from './Web3EVMCredentialsAPIModel';
import type { Web3SolanaCredentialsAPIModel } from './Web3SolanaCredentialsAPIModel';
import {
    Web3SolanaCredentialsAPIModelFromJSON,
    Web3SolanaCredentialsAPIModelFromJSONTyped,
    Web3SolanaCredentialsAPIModelToJSON,
} from './Web3SolanaCredentialsAPIModel';

/**
 * 
 * @export
 * @interface EmailAuthAPIModel
 */
export interface EmailAuthAPIModel {
    /**
     * 
     * @type {string}
     * @memberof EmailAuthAPIModel
     */
    emailToken: string;
    /**
     * 
     * @type {OpenIdProviderTypeAPIModel}
     * @memberof EmailAuthAPIModel
     */
    emailTokenProvider: OpenIdProviderTypeAPIModel;
    /**
     * 
     * @type {Web3EVMCredentialsAPIModel}
     * @memberof EmailAuthAPIModel
     */
    evm?: Web3EVMCredentialsAPIModel;
    /**
     * 
     * @type {Web3SolanaCredentialsAPIModel}
     * @memberof EmailAuthAPIModel
     */
    solana?: Web3SolanaCredentialsAPIModel;
}

/**
 * Check if a given object implements the EmailAuthAPIModel interface.
 */
export function instanceOfEmailAuthAPIModel(value: object): boolean {
    if (!('emailToken' in value)) return false;
    if (!('emailTokenProvider' in value)) return false;
    return true;
}

export function EmailAuthAPIModelFromJSON(json: any): EmailAuthAPIModel {
    return EmailAuthAPIModelFromJSONTyped(json, false);
}

export function EmailAuthAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailAuthAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'emailToken': json['emailToken'],
        'emailTokenProvider': OpenIdProviderTypeAPIModelFromJSON(json['emailTokenProvider']),
        'evm': json['evm'] == null ? undefined : Web3EVMCredentialsAPIModelFromJSON(json['evm']),
        'solana': json['solana'] == null ? undefined : Web3SolanaCredentialsAPIModelFromJSON(json['solana']),
    };
}

export function EmailAuthAPIModelToJSON(value?: EmailAuthAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'emailToken': value['emailToken'],
        'emailTokenProvider': OpenIdProviderTypeAPIModelToJSON(value['emailTokenProvider']),
        'evm': Web3EVMCredentialsAPIModelToJSON(value['evm']),
        'solana': Web3SolanaCredentialsAPIModelToJSON(value['solana']),
    };
}

