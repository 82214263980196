import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import type { Web3WalletType } from '@/features/web3/components/Web3WalletLink/types';
import { useStateMountSafe } from '@/hooks';
import type { Web3Auth } from '@/infrastructure/security/web3-auth/types';
import { withSuppressPromise } from '@/infrastructure/utils/functions';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import Web3SolanaInitContext from './context';

import type React from 'react';

const isWeb3AuthReady = (web3Auth?: Web3Auth) => !!web3Auth?.isConfirmed;

const supportedWallets: Web3WalletType[] = ['phantom', 'solflare', 'trust'];

const withSolanaWalletsSupport = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithWeb3Support',
  )((props) => {
    const [initialized, setInitialized] = useStateMountSafe(false);
    const [walletsError, setWalletsError] = useStateMountSafe<Error>();
    const featureFlags = useFeatureToggle();
    const { web3Auth, state: web3AuthState } = useContext(Web3AuthContext);
    const initializedWithWeb3Auth = useRef(false);
    const initializationCalled = useRef<boolean>(false);
    const doInitialize = useCallback(() => {
      setTimeout(
        withSuppressPromise(() => {
          initializedWithWeb3Auth.current = isWeb3AuthReady(web3Auth);
          setInitialized(true);
        }),
        0,
      );
    }, [web3Auth, setInitialized]);
    const initialize = useCallback(() => {
      if (!initializationCalled.current) {
        initializationCalled.current = true;
        doInitialize();
      }
    }, [doInitialize]);
    useEffect(() => {
      if (initializationCalled.current && isWeb3AuthReady(web3Auth) !== initializedWithWeb3Auth.current) doInitialize();
      // web3AuthState here is to refresh state
    }, [doInitialize, web3Auth, web3AuthState]);
    const context = useMemo(
      () => ({
        initialized,
        initialize,
        supportedWallets: !featureFlags.data.data?.disableSolana ? supportedWallets : [],
        setWalletsError,
        walletsError,
      }),
      [featureFlags.data.data?.disableSolana, initialize, initialized, setWalletsError, walletsError],
    );
    return (
      <Web3SolanaInitContext.Provider value={context}>
        <Component {...props} />
      </Web3SolanaInitContext.Provider>
    );
  });

export default withSolanaWalletsSupport;
