import { useCallback, useMemo } from 'react';

import { useBlockchainSystemInfos } from '@/features/dictionary/blockchain/hooks';
import { BlockchainAPITypeAPIModel, type BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { combine } from '@/infrastructure/model';
import { notEmpty } from '@/infrastructure/utils/ts';

import useMerchantWallets from '../useMerchantWallets';

const useActiveFactoryMerchantWallets = (network?: BlockchainNetworkTypeAPIModel) => {
  const blockchainsState = useBlockchainSystemInfos();
  const walletsState = useMerchantWallets(network);
  const data = useMemo(
    () =>
      combine(blockchainsState.data, walletsState.data, (blockchains, wallets) =>
        blockchains
          .map((blockchain) =>
            blockchain.apiType === BlockchainAPITypeAPIModel.EVM
            || blockchain.apiType === BlockchainAPITypeAPIModel.Tron
              ? { bt: blockchain.bt, factory: blockchain.merchantWalletFactoryAddress }
              : undefined,
          )
          .filter(notEmpty)
          .map(({ bt, factory }) => ({
            bt,
            wallet: wallets.find(({ blockchain, factoryAddress }) => blockchain === bt && factoryAddress === factory),
          })),
      ),
    [blockchainsState.data, walletsState.data],
  );
  const forceRefresh = useCallback(
    async () => Promise.all([walletsState.forceRefresh(), blockchainsState.forceRefresh()]),
    [blockchainsState, walletsState],
  );
  const loading = walletsState.loading || blockchainsState.loading;
  return useMemo(() => ({ loading, forceRefresh, data }), [data, forceRefresh, loading]);
};

export default useActiveFactoryMerchantWallets;
