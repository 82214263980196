/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';

/**
 * 
 * @export
 * @interface PaymentBlockAPIModel
 */
export interface PaymentBlockAPIModel {
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof PaymentBlockAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof PaymentBlockAPIModel
     */
    blockNum: string;
}

/**
 * Check if a given object implements the PaymentBlockAPIModel interface.
 */
export function instanceOfPaymentBlockAPIModel(value: object): boolean {
    if (!('blockchain' in value)) return false;
    if (!('blockNum' in value)) return false;
    return true;
}

export function PaymentBlockAPIModelFromJSON(json: any): PaymentBlockAPIModel {
    return PaymentBlockAPIModelFromJSONTyped(json, false);
}

export function PaymentBlockAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentBlockAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'blockNum': json['blockNum'],
    };
}

export function PaymentBlockAPIModelToJSON(value?: PaymentBlockAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'blockNum': value['blockNum'],
    };
}

