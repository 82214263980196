import { PlusCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useMemo } from 'react';

import { defaultDialogFormTailLayout, FormattedMessage, Operation } from '@/components';
import { useCompanyUsers, useCompanyUsersActions } from '@/features/company/hooks';
import { useUser } from '@/features/user/hooks';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';
import { useFormVisible, useNotification } from '@/hooks';
import type { NotificationOptions } from '@/hooks/useNotification';
import { withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import { AddUserForm } from './components';

import type { OperationAddCompanyUserProps } from './types';

const layout = { labelCol: { span: 0 } };

const notificationOptions: NotificationOptions = {
  success: () => <FormattedMessage id={I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_SUCCESS_MESSAGE} />,
  error: () => <FormattedMessage id={I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_ERROR_MESSAGE} />,
};

const OperationAddUser: React.FC<OperationAddCompanyUserProps> = ({ 'data-test': dataTest, mode = 'inline' }) => {
  const { withNotification } = useNotification();
  const formControl = useFormVisible(false);
  const { data: users } = useCompanyUsers();
  const { data: user } = useUser();
  const { add: addAction } = useCompanyUsersActions();

  const create = useMemo(
    () =>
      withSuppressError(
        formControl.withFormHide(
          withOnSuccess(withNotification(addAction.act, notificationOptions), () =>
            goalReached(YMGoals.COMPANY_USER_ADDED),
          ),
        ),
      ),
    [addAction.act, formControl, withNotification],
  );

  const disabledMessage = useMemo(
    () =>
      addAction.unavailabilityReason === 'no-company' ? (
        <FormattedMessage id={I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_ERROR_NO_COMPANY} />
      ) : undefined,
    [addAction.unavailabilityReason],
  );

  const registered = useMemo(() => (users.data ?? []).map(({ address }) => address), [users.data]);
  const owner = useMemo(() => (user.data?.addresses ?? []).map(({ address }) => address), [user.data?.addresses]);

  return (
    <>
      {formControl.visible && (
        <Modal
          open
          maskClosable={false}
          footer={null}
          title={<FormattedMessage id={I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_TITLE} />}
          closable
          onCancel={formControl.hide}
        >
          <AddUserForm
            registered={registered}
            owner={owner}
            layout={layout}
            tailLayout={defaultDialogFormTailLayout}
            data-test={dataTest && `${dataTest}-form`}
            onReset={formControl.hide}
            onSubmit={create}
          />
        </Modal>
      )}
      <Operation
        title={<FormattedMessage id={I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_TITLE} />}
        tooltip={<FormattedMessage id={I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_TOOLTIP} />}
        icon={<PlusCircleOutlined />}
        onClick={formControl.show}
        disabled={!addAction.available}
        inProgress={formControl.visible || addAction.inAction}
        disabledMessage={disabledMessage}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationAddUserMemo = React.memo(OperationAddUser);

export default OperationAddUserMemo;
