/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SortAttributeSubscriptionPlanSortByAPIModel } from './SortAttributeSubscriptionPlanSortByAPIModel';
import {
    SortAttributeSubscriptionPlanSortByAPIModelFromJSON,
    SortAttributeSubscriptionPlanSortByAPIModelFromJSONTyped,
    SortAttributeSubscriptionPlanSortByAPIModelToJSON,
} from './SortAttributeSubscriptionPlanSortByAPIModel';

/**
 * 
 * @export
 * @interface PageSubscriptionPlanSortByAPIModel
 */
export interface PageSubscriptionPlanSortByAPIModel {
    /**
     * 
     * @type {number}
     * @memberof PageSubscriptionPlanSortByAPIModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PageSubscriptionPlanSortByAPIModel
     */
    offset: number;
    /**
     * 
     * @type {Array<SortAttributeSubscriptionPlanSortByAPIModel>}
     * @memberof PageSubscriptionPlanSortByAPIModel
     */
    sortBy?: Array<SortAttributeSubscriptionPlanSortByAPIModel>;
}

/**
 * Check if a given object implements the PageSubscriptionPlanSortByAPIModel interface.
 */
export function instanceOfPageSubscriptionPlanSortByAPIModel(value: object): boolean {
    if (!('limit' in value)) return false;
    if (!('offset' in value)) return false;
    return true;
}

export function PageSubscriptionPlanSortByAPIModelFromJSON(json: any): PageSubscriptionPlanSortByAPIModel {
    return PageSubscriptionPlanSortByAPIModelFromJSONTyped(json, false);
}

export function PageSubscriptionPlanSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageSubscriptionPlanSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'limit': json['limit'],
        'offset': json['offset'],
        'sortBy': json['sortBy'] == null ? undefined : ((json['sortBy'] as Array<any>).map(SortAttributeSubscriptionPlanSortByAPIModelFromJSON)),
    };
}

export function PageSubscriptionPlanSortByAPIModelToJSON(value?: PageSubscriptionPlanSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'limit': value['limit'],
        'offset': value['offset'],
        'sortBy': value['sortBy'] == null ? undefined : ((value['sortBy'] as Array<any>).map(SortAttributeSubscriptionPlanSortByAPIModelToJSON)),
    };
}

