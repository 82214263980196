import * as Sentry from '@sentry/react';
import React from 'react';

import Web3SolanaInitContext from '@/features/web3/hocs/withWeb3Support/hocs/withSolanaWalletsSupport/context';
import type { TestableProps } from '@/infrastructure/utils/react';

import type { PropsWithChildren, ReactNode, ErrorInfo } from 'react';

class SolanaWalletErrorBoundary extends React.Component<
  PropsWithChildren<TestableProps>,
  {
    error?: Error;
    errorInfo?: ErrorInfo;
  }
> {
  constructor(props: PropsWithChildren<TestableProps>) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error, errorInfo });
    Sentry.captureException(error);
  }

  render(): ReactNode {
    const {
      props: { children },
      state: { error },
    } = this;

    return (
      <>
        {error && (
          <Web3SolanaInitContext.Consumer>
            {({ setWalletsError, walletsError }) => {
              if (!walletsError) setWalletsError(error);
              return null;
            }}
          </Web3SolanaInitContext.Consumer>
        )}
        {children}
      </>
    );
  }
}

export default SolanaWalletErrorBoundary;
