import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { usePayout } from '@/features/payouts/hooks';
import { Web3WalletModalContainer } from '@/features/web3/components';
import { withWeb3EVMContext } from '@/features/web3/hocs';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';

import OperationStartPayoutWithConnector from '../OperationStartPayoutWithConnector';

import type { StartPayoutModalProps } from './types';

const StartPayoutModal: React.FC<StartPayoutModalProps> = ({
  'data-test': dataTest,
  onSuccess,
  onCancel,
  payoutId,
}) => {
  const { loading } = usePayout(payoutId);

  return (
    <Web3WalletModalContainer
      data-test={dataTest}
      onSuccess={onSuccess}
      onClose={onCancel}
      loading={loading}
      title={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_DIALOG_TITLE} />}
      description={
        <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_DIALOG_DESCRIPTION} tagName="span" />
      }
      api={BlockchainAPITypeAPIModel.EVM}
      bt={undefined}
      evm={useMemo(
        () => ({
          Operation: OperationStartPayoutWithConnector,
          extra: payoutId,
        }),
        [payoutId],
      )}
    />
  );
};

const StartPayoutModalContexted = withWeb3EVMContext(StartPayoutModal, React.Fragment);

const StartPayoutModalMemo = React.memo(StartPayoutModalContexted);

export default StartPayoutModalMemo;
