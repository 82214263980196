import { Form, Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import BlockchainAPILabel from '@/features/dictionary/blockchain/components/BlockchainAPILabel';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import { ReadonlyBlockchainAPI } from './components';

import type { BlockchainAPIItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const BlockchainAPIItem = <Values extends Store = Store>({
  name,
  readonly,
  required,
  disabled,
  messages,
  'data-test': dataTest,
  disabledValues,
  multiselect,
  style,
  className,
  ItemProps,
}: BlockchainAPIItemProps<Values>) => {
  const isRequired = !readonly && !disabled && (ItemProps?.required === true || required);
  const rules = useMemo(
    () => [
      ...(ItemProps?.rules ?? []),
      ...(isRequired
        ? [
            {
              required: true,
              message: messages?.required ?? (
                <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_BLOCKCHAIN_API_ITEM_REQUIRED} />
              ),
            },
          ]
        : []),
    ],
    [ItemProps?.rules, isRequired, messages?.required],
  );
  const options = useMemo(
    () =>
      (multiselect
        ? enumValues(BlockchainAPITypeAPIModel).map((val) => ({
            value: val,
            inline: <BlockchainAPILabel data-test={dataTest && `${dataTest}-selected-${val}`} api={val} mode="short" />,
            label: <BlockchainAPILabel data-test={dataTest && `${dataTest}-value-${val}`} api={val} mode="short" />,
          }))
        : enumValues(BlockchainAPITypeAPIModel).map((val) => ({
            value: val,
            label: <BlockchainAPILabel data-test={dataTest && `${dataTest}-value-${val}`} api={val} mode="short" />,
          }))
      ).filter(
        ({ value }) =>
          !disabledValues || !(Array.isArray(disabledValues) ? disabledValues : [disabledValues]).includes(value),
      ),
    [dataTest, disabledValues, multiselect],
  );
  return (
    <Form.Item<Values>
      label={messages?.label ?? <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_BLOCKCHAIN_API_ITEM_LABEL} />}
      name={name}
      required={required && !disabled && !readonly}
      {...ItemProps}
      rules={rules}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {readonly ? (
        <ReadonlyBlockchainAPI data-test={dataTest} />
      ) : multiselect ? (
        <Select<Values>
          data-test={dataTest}
          style={style}
          mode="tags"
          className={className}
          disabled={disabled}
          placeholder={<FormattedMessage id={I18nFeatureDictionary.COMPONENTS_BLOCKCHAIN_API_ITEM_PLACEHOLDER} />}
          optionLabelProp="inline"
          options={options}
        />
      ) : (
        <Select<Values>
          data-test={dataTest}
          style={style}
          className={className}
          disabled={disabled}
          placeholder={<FormattedMessage id={I18nFeatureDictionary.COMPONENTS_BLOCKCHAIN_API_ITEM_PLACEHOLDER} />}
          options={options}
        />
      )}
    </Form.Item>
  );
};

const BlockchainAPIItemMemo = React.memo(BlockchainAPIItem) as typeof BlockchainAPIItem;

export default BlockchainAPIItemMemo;
