import { useCallback } from 'react';

import { PageLoading } from '@/components';
import { useCompanySettings } from '@/features/company-settings/hooks';
import { usePreferencesActions, useUser } from '@/features/user/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import { CompanyStep, CongratulationsStep } from './components';
import { withOnboardingStep } from './hocs';
import { OnboardingStep } from './types';

import type React from 'react';

export const OnboardPage: React.FC<{ currentStep: OnboardingStep }> = ({ currentStep }) => {
  const user = useUser();
  const settings = useCompanySettings();

  const onCompanyCreated = useCallback(async () => {
    await settings.forceRefresh();
    goalReached(YMGoals.ONBOARD_STEP1_FINISHED);
  }, [settings]);

  const { persist } = usePreferencesActions();
  const onFinish = useCallback(async () => {
    await withSuppressError(persist.act)({ onboarded: true });
    goalReached(YMGoals.ONBOARD_STEP2_FINISHED);
  }, [persist]);

  if (user.data.isDirty) {
    return <PageLoading type="OnboardPage" />;
  }

  return (
    <>
      {currentStep === OnboardingStep.company && (
        <CompanyStep data-test="company-step" userEmail={user.data.data?.email} onSubmit={onCompanyCreated} />
      )}
      {currentStep === OnboardingStep.congratulations && (
        <CongratulationsStep
          data-test="congratulations-step"
          userEmail={user.data.data?.email}
          settings={settings.data.data}
          onSubmit={onFinish}
        />
      )}
    </>
  );
};

export default withOnboardingStep(OnboardPage);
