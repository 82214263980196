import dayjs from 'dayjs';
import ms from 'ms';
import { useCallback, useMemo } from 'react';
import { getAddress, walletActions } from 'viem';

import { useAppDispatch } from '@/app/hooks';
import { useAsset, useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import { useActionPending } from '@/features/global/hooks';
import {
  fetchPayoutDestinations,
  prepareMerklePayoutData,
  removePayout,
  startPayout,
  updatePayout,
  updatePayoutTitle,
} from '@/features/payouts/actions';
import type { PayoutSummary, PayoutUpdate } from '@/features/payouts/types';
import { requestSignMerklePayout } from '@/features/payouts/web3-api';
import { useActualAssetBalance } from '@/features/statistics/hooks';
import { useUserAddress } from '@/features/user/hooks';
import { BlockchainAPITypeAPIModel, PayoutStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { useSubmitting } from '@/hooks';
import { mapStoredState } from '@/infrastructure/model';
import { numberToRaw } from '@/infrastructure/utils/bigNumber';
import { someOrFail } from '@/infrastructure/utils/functions';
import { withCancelledByUser } from '@/infrastructure/utils/ui';
import { isSameAddress } from '@/infrastructure/utils/web3/address';

import usePayout from '../usePayout';

import type {
  RemoveUnavailabilityReason,
  UpdateTitleUnavailabilityReason,
  UpdateUnavailabilityReason,
  UsePayoutActions,
} from './types';
import type { Address } from 'viem';

const isUpdatable = (payout: PayoutSummary) => payout.status === PayoutStatusAPIModel.Created;
const isRemovable = (payout: PayoutSummary) => payout.status === PayoutStatusAPIModel.Created;
const isStartable = (payout: PayoutSummary) => payout.status === PayoutStatusAPIModel.Created;

export default function usePayoutActions(
  payoutId: string | undefined,
  web3ClientChainId?: number,
  web3ClientAccount?: Address,
): UsePayoutActions {
  const { data: payoutData } = usePayout(payoutId);
  const { withExtractDataDispatch } = useAppDispatch();
  const { data: balance } = useActualAssetBalance(payoutData.data?.amount.asset);

  const isUpdatingTitle = useActionPending(updatePayoutTitle);
  const isUpdatingData = useActionPending(updatePayout);
  const isUpdating = isUpdatingTitle || isUpdatingData;
  const updateDataAction: UsePayoutActions['update']['act'] = useCallback(
    (data: PayoutUpdate) => withExtractDataDispatch(updatePayout)({ id: someOrFail(payoutId), ...data }),
    [payoutId, withExtractDataDispatch],
  );
  // eslint-disable-next-line no-nested-ternary
  const updateDataUnavailability: UpdateUnavailabilityReason | undefined = !payoutData.data
    ? 'no-data'
    : !isUpdatable(payoutData.data)
      ? 'invalid-status'
      : undefined;
  const updateDataHook: UsePayoutActions['update'] = {
    act: updateDataAction,
    inAction: isUpdating,
    available: !updateDataUnavailability,
    unavailabilityReason: updateDataUnavailability,
  };

  const updateTitleUnavailability: UpdateTitleUnavailabilityReason | undefined = !payoutData.data
    ? 'no-data'
    : undefined;
  const updateTitleAction: UsePayoutActions['updateTitle']['act'] = useCallback(
    (title: string) => withExtractDataDispatch(updatePayoutTitle)({ id: someOrFail(payoutId), title }),
    [payoutId, withExtractDataDispatch],
  );
  const updateTitleHook: UsePayoutActions['updateTitle'] = {
    act: updateTitleAction,
    inAction: isUpdating,
    available: !updateTitleUnavailability,
    unavailabilityReason: updateTitleUnavailability,
  };

  const isRemoving = useActionPending(removePayout);
  // eslint-disable-next-line no-nested-ternary
  const removeUnavailability: RemoveUnavailabilityReason | undefined = !payoutData.data
    ? 'no-data'
    : !isRemovable(payoutData.data)
      ? 'invalid-status'
      : undefined;
  const removeAction: UsePayoutActions['remove']['act'] = useCallback(
    () => withExtractDataDispatch(removePayout)({ id: someOrFail(payoutId) }),
    [payoutId, withExtractDataDispatch],
  );
  const removeHook: UsePayoutActions['remove'] = {
    act: removeAction,
    inAction: isRemoving,
    available: !removeUnavailability,
    unavailabilityReason: removeUnavailability,
  };

  const bcSystemState = useBlockchainSystemInfo(payoutData.data?.blockchain);
  const bcState = useMemo(
    () =>
      mapStoredState(bcSystemState.data, (state) => (state.apiType === BlockchainAPITypeAPIModel.EVM ? state : null)),
    [bcSystemState.data],
  );
  const bcChainId = bcState.data?.chainId;
  const { data: assetState } = useAsset(payoutData.data?.amount.asset);
  const { data: addressState } = useUserAddress(BlockchainAPITypeAPIModel.EVM);

  const startUnavailabilityReason: UsePayoutActions['start']['unavailabilityReason'] = useMemo(() => {
    if (!payoutData.data || !addressState.data || !assetState.data) return 'no-data';
    if (!isStartable(payoutData.data)) return 'invalid-status';
    if (!bcState.data?.apiType) return 'unsupported-chain';
    if (web3ClientAccount && !isSameAddress(addressState.data.value, web3ClientAccount)) return 'invalid-account';
    if (web3ClientChainId && bcChainId && bcChainId !== web3ClientChainId) return 'invalid-chain-id';
    if (balance.data?.available.value.lte(payoutData.data.amount.value)) return 'insufficient-balance';
    return undefined;
  }, [
    addressState.data,
    assetState.data,
    balance.data?.available.value,
    bcChainId,
    bcState.data?.apiType,
    payoutData.data,
    web3ClientAccount,
    web3ClientChainId,
  ]);

  const [starting, withStarting] = useSubmitting(false);
  const startAction: UsePayoutActions['start']['act'] = useMemo(
    () =>
      withStarting(async (client) => {
        const payout = someOrFail(payoutData.data, () => new Error('no-data'));
        const decimals = someOrFail(assetState.data?.formatDecimals, () => new Error('no-data'));
        const assetAddress = someOrFail(assetState.data?.address, () => new Error('no-data'));
        const destinations = await withExtractDataDispatch(fetchPayoutDestinations)({ id: payout.id, force: true });
        const rawDestinations = destinations.map(({ num, address, amount }) => ({
          num,
          address: getAddress(address),
          rawAmount: BigInt(numberToRaw(amount.value, decimals).toString()),
        }));
        const walletClient = client.extend(walletActions);
        // switch (payout.type) {
        //   case PayoutTypeAPIModel.Simple: {
        //     const eip712signature = await withRejectedByUser(requestSignSimplePayout)(
        //       walletClient,
        //       getAddress(walletAddress),
        //       getAddress(assetAddress),
        //       rawDestinations,
        //     );
        //     return withExtractDataDispatch(startPayout)({
        //       id: payout.id,
        //       signature: {
        //         eip712signature: { v: Number(eip712signature.v), r: eip712signature.r, s: eip712signature.s },
        //         destinations: rawDestinations.map(({ id }) => id),
        //       },
        //     });
        //   }
        //   case PayoutTypeAPIModel.MerkleTree: {
        const treeSignature = await withExtractDataDispatch(prepareMerklePayoutData)({
          id: payout.id,
          destinations: rawDestinations,
        });
        const expiresAt = dayjs()
          .add(ms(window.env.NCPS_PAYOUT_EXPIRATION_PERIOD), 'ms')
          .set('millisecond', 0)
          .toDate();
        const eip712signature = await withCancelledByUser(requestSignMerklePayout)(
          walletClient,
          getAddress(assetAddress),
          treeSignature.rootProof,
          expiresAt,
        );
        return withExtractDataDispatch(startPayout)({
          id: payout.id,
          signature: {
            // eslint-disable-next-line @typescript-eslint/no-deprecated
            eip712signature: { v: Number(eip712signature.v), r: eip712signature.r, s: eip712signature.s },
            expiresAt,
            ...treeSignature,
          },
        });
        // }
        // }
      }),
    [withStarting, payoutData.data, assetState.data?.formatDecimals, assetState.data?.address, withExtractDataDispatch],
  );
  const startHook: UsePayoutActions['start'] = {
    act: startAction,
    inAction: starting,
    unavailabilityReason: startUnavailabilityReason,
    available: !startUnavailabilityReason,
  };

  return { update: updateDataHook, updateTitle: updateTitleHook, remove: removeHook, start: startHook };
}
