import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useAsset } from '@/features/dictionary/blockchain/hooks';
import { useMerchantGasWallet } from '@/features/gas-wallets/hooks';
import { useActionPending } from '@/features/global/hooks';
import {
  removeSubscriptionPlan,
  updateSubscriptionPlan,
  updateSubscriptionPlanStatus,
} from '@/features/subscription-plans/actions';
import type { SubscriptionPlan, UpdateSubscriptionPlan } from '@/features/subscription-plans/types';
import { SubscriptionPlanStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { CommonLoadingState, HookAction } from '@/infrastructure/model';

import useSubscriptionPlan from './useSubscriptionPlan';

type UpdateUnavailabilityReason = 'no-data' | 'invalid-status';
type UpdateStatusUnavailabilityReason = 'no-data' | 'invalid-status';
type RemoveUnavailabilityReason = 'no-data' | 'invalid-status';
type ActivateUnavailabilityReason = 'no-data' | 'invalid-status' | 'gas-wallet-issue';

export interface UseSubscriptionPlanActions {
  update: HookAction<[UpdateSubscriptionPlan], SubscriptionPlan, UpdateUnavailabilityReason>;
  activate: HookAction<[], SubscriptionPlan, ActivateUnavailabilityReason>;
  deactivate: HookAction<[], SubscriptionPlan, UpdateStatusUnavailabilityReason>;
  remove: HookAction<[], void, UpdateStatusUnavailabilityReason>;
}

export default function useSubscriptionPlanActions(subscriptionPlanId: string): UseSubscriptionPlanActions {
  const { withExtractDataDispatch, withVoidDispatch } = useAppDispatch();
  const { data } = useSubscriptionPlan(subscriptionPlanId);
  const { data: assetState } = useAsset(data.data?.amount.asset);
  const { data: gasWalletState, loading: gasWalletLoading } = useMerchantGasWallet(assetState.data?.blockchain);

  const updateUnavailabilityReason = useMemo<UpdateUnavailabilityReason | undefined>(() => {
    if (!data.data) {
      return 'no-data';
    }
    if (data.data.status !== SubscriptionPlanStatusAPIModel.Draft) {
      return 'invalid-status';
    }
    return undefined;
  }, [data.data]);
  const updating = useActionPending(updateSubscriptionPlan, subscriptionPlanId);
  const updateAction: UseSubscriptionPlanActions['update']['act'] = useCallback(
    (newData: UpdateSubscriptionPlan) =>
      withExtractDataDispatch(updateSubscriptionPlan)({ id: subscriptionPlanId, data: newData }),
    [subscriptionPlanId, withExtractDataDispatch],
  );
  const update: UseSubscriptionPlanActions['update'] = {
    act: updateAction,
    unavailabilityReason: updateUnavailabilityReason,
    available: !updateUnavailabilityReason,
    inAction: updating,
  };

  const activateUnavailabilityReason = useMemo<ActivateUnavailabilityReason | undefined>(() => {
    if (!data.data || (!gasWalletState.data && (gasWalletState.isDirty || gasWalletLoading))) {
      return 'no-data';
    }
    if (
      data.data.status !== SubscriptionPlanStatusAPIModel.Draft
      && data.data.status !== SubscriptionPlanStatusAPIModel.Archived
    ) {
      return 'invalid-status';
    }
    if (!gasWalletState.data?.isReadyForAction) {
      return 'gas-wallet-issue';
    }
    return undefined;
  }, [data.data, gasWalletLoading, gasWalletState.data, gasWalletState.isDirty]);
  const activating = useActionPending(updateSubscriptionPlan, subscriptionPlanId);
  const activateAction: UseSubscriptionPlanActions['activate']['act'] = useCallback(
    () =>
      withExtractDataDispatch(updateSubscriptionPlanStatus)({
        id: subscriptionPlanId,
        newStatus: SubscriptionPlanStatusAPIModel.Active,
      }),
    [subscriptionPlanId, withExtractDataDispatch],
  );
  const activate: UseSubscriptionPlanActions['activate'] = {
    act: activateAction,
    available: !activateUnavailabilityReason,
    unavailabilityReason: activateUnavailabilityReason,
    inAction: activating,
  };

  const deactivateUnavailabilityReason = useMemo<UpdateStatusUnavailabilityReason | undefined>(() => {
    if (!data.data) {
      return 'no-data';
    }
    if (data.data.status !== SubscriptionPlanStatusAPIModel.Active) {
      return 'invalid-status';
    }
    return undefined;
  }, [data.data]);
  const deactivating = useActionPending(updateSubscriptionPlan, subscriptionPlanId);
  const deactivateAction: UseSubscriptionPlanActions['deactivate']['act'] = useCallback(
    () =>
      withExtractDataDispatch(updateSubscriptionPlanStatus)({
        id: subscriptionPlanId,
        newStatus: SubscriptionPlanStatusAPIModel.Archived,
      }),
    [subscriptionPlanId, withExtractDataDispatch],
  );
  const deactivate: UseSubscriptionPlanActions['deactivate'] = {
    act: deactivateAction,
    available: !deactivateUnavailabilityReason,
    unavailabilityReason: deactivateUnavailabilityReason,
    inAction: deactivating,
  };

  const removeUnavailabilityReason = useMemo<RemoveUnavailabilityReason | undefined>(() => {
    if (!data.data) {
      return 'no-data';
    }
    if (data.data.status !== SubscriptionPlanStatusAPIModel.Draft) {
      return 'invalid-status';
    }
    return undefined;
  }, [data.data]);
  const removing = useActionPending(removeSubscriptionPlan, subscriptionPlanId);
  const removeAction: UseSubscriptionPlanActions['remove']['act'] = useCallback(
    () => withVoidDispatch<string, CommonLoadingState<void>>(removeSubscriptionPlan)(subscriptionPlanId),
    [subscriptionPlanId, withVoidDispatch],
  );
  const remove: UseSubscriptionPlanActions['remove'] = {
    act: removeAction,
    available: !removeUnavailabilityReason,
    unavailabilityReason: removeUnavailabilityReason,
    inAction: removing,
  };

  return { activate, deactivate, update, remove };
}
