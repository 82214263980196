import { isRejectedByWeb3WalletUser } from '@/features/web3/utils';
import type { Func } from '@/infrastructure/utils/ts';

export type Cancelled = 'Cancelled';
export const cancelled: Cancelled = 'Cancelled';
export type CancellationError = Omit<Error, 'message'> & { message: Cancelled };
export type Cancellable = Promise<Cancelled>;

export const cancel = () => {
  const error = new Error(cancelled);
  const arr = error.stack?.split('\n');
  arr?.splice(1, 2);
  error.stack = arr?.join('\n');
  return error;
};

export const cancellable = (fn: (reject: () => void) => unknown) =>
  new Promise<Cancelled>((resolve) => fn(() => resolve(cancelled)));

export const isCancelled = <T = unknown>(
  value: T | Cancelled | CancellationError,
): value is Cancelled | CancellationError =>
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  !!value && (value === cancelled || (typeof value === 'object' && 'message' in value && value.message === cancelled));

export function assertNotCancelled<T = unknown>(value: T | Cancelled | CancellationError): asserts value is T {
  if (isCancelled(value)) throw cancel();
}

export const runCancellable = async <Result = unknown>(
  action: Promise<Result> | Result,
  onCancel: Cancellable,
): Promise<Result> => {
  const result = await Promise.race([action, onCancel]);
  assertNotCancelled(result);
  return result;
};

export const withCancelledByUser =
  <V extends unknown[], R>(func: Func<V, R>): ((...args: V) => Promise<R>) =>
  async (...args: V): Promise<R> => {
    try {
      return await func(...args);
    } catch (error) {
      if (isRejectedByWeb3WalletUser(error)) throw cancel();
      throw error;
    }
  };
