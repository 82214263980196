import { QuestionCircleOutlined } from '@ant-design/icons';
import { cx } from '@emotion/css';
import { Form, Input, Space, Tooltip } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { withFeatureToggleGuard } from '@/features/feature-toggle/hocs';
import { OperationAddUserAddress as BaseOperationAddUserAddress } from '@/features/user/components';
import { withUserAddressAvailability } from '@/features/user/hocs';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageSettings } from '@/generated/i18n/i18n';

import type { ProfileSolanaAddressItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const OperationAddUserAddress = withUserAddressAvailability(
  BlockchainAPITypeAPIModel.Solana,
  false,
)(BaseOperationAddUserAddress);

const ProfileSolanaAddressItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  style,
  className,
}: ProfileSolanaAddressItemProps<Values>) => (
  <Form.Item<Values>
    name={name}
    label={
      <Space>
        <FormattedMessage id={I18nPageSettings.PROFILE_COMPONENTS_PROFILE_CARD_SOLANA_ADDRESS_ITEM_LABEL} />
        <Tooltip
          title={<FormattedMessage id={I18nPageSettings.PROFILE_COMPONENTS_PROFILE_CARD_SOLANA_ADDRESS_ITEM_HELP} />}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </Space>
    }
  >
    <Input
      data-test={dataTest && `${dataTest}-value`}
      disabled
      className={cx('ym-hide-content', className)}
      style={style}
      suffix={
        <OperationAddUserAddress
          data-test={dataTest && `${dataTest}-opAdd`}
          api={BlockchainAPITypeAPIModel.Solana}
          mode="icon"
        />
      }
    />
  </Form.Item>
);

const ProfileSolanaAddressItemHOC = withFeatureToggleGuard('disableSolana', false)(ProfileSolanaAddressItem);
const ProfileSolanaAddressItemMemo = React.memo(ProfileSolanaAddressItemHOC) as typeof ProfileSolanaAddressItem;

export default ProfileSolanaAddressItemMemo;
