/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { KYTToleranceLevelAPIModel } from './KYTToleranceLevelAPIModel';
import {
    KYTToleranceLevelAPIModelFromJSON,
    KYTToleranceLevelAPIModelFromJSONTyped,
    KYTToleranceLevelAPIModelToJSON,
} from './KYTToleranceLevelAPIModel';

/**
 * 
 * @export
 * @interface UpdateKYTLevelAPIModel
 */
export interface UpdateKYTLevelAPIModel {
    /**
     * 
     * @type {KYTToleranceLevelAPIModel}
     * @memberof UpdateKYTLevelAPIModel
     */
    level: KYTToleranceLevelAPIModel;
}

/**
 * Check if a given object implements the UpdateKYTLevelAPIModel interface.
 */
export function instanceOfUpdateKYTLevelAPIModel(value: object): boolean {
    if (!('level' in value)) return false;
    return true;
}

export function UpdateKYTLevelAPIModelFromJSON(json: any): UpdateKYTLevelAPIModel {
    return UpdateKYTLevelAPIModelFromJSONTyped(json, false);
}

export function UpdateKYTLevelAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateKYTLevelAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'level': KYTToleranceLevelAPIModelFromJSON(json['level']),
    };
}

export function UpdateKYTLevelAPIModelToJSON(value?: UpdateKYTLevelAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'level': KYTToleranceLevelAPIModelToJSON(value['level']),
    };
}

