import { List } from 'antd';
import React, { useCallback } from 'react';

import { AssetInputListItem } from './components';

import type { AssetsInputProps, AssetsInputRow } from './types';

const replaceItem = (item: AssetsInputRow, values: AssetsInputRow[]) =>
  values.map((asset) => (asset.code === item.code ? item : asset));

const AssetsInput: React.FC<AssetsInputProps> = ({
  'data-test': dataTest,
  className,
  style,
  withNetworkMark,
  withBlockchainMark,
  value,
  onChange,
  readonly,
  actions,
  labels,
}) => (
  <List<AssetsInputRow>
    size="small"
    className={className}
    style={style}
    itemLayout="horizontal"
    loadMore={false}
    dataSource={value ?? []}
    renderItem={useCallback(
      (item: AssetsInputRow) => (
        <AssetInputListItem
          withNetworkMark={withNetworkMark}
          withBlockchainMark={withBlockchainMark}
          key={item.code}
          data-test={dataTest && `${dataTest}-${item.code}`}
          data={item}
          readonly={readonly}
          onSelectionChange={(isActive) => onChange?.(replaceItem({ ...item, isActive }, value ?? []))}
          selectionDisabled={item.disabled}
          selectionDisabledMessage={item.disabledMessage}
          actions={actions?.(item)}
          labels={labels?.(item)}
        />
      ),
      [dataTest, onChange, readonly, actions, labels, value, withBlockchainMark, withNetworkMark],
    )}
  />
);

const AssetsInputMemo = React.memo(AssetsInput);

export default AssetsInputMemo;
