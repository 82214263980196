import { Form } from 'antd';
import React, { useEffect, useMemo } from 'react';

import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { useForm, useSharedStyles } from '@/hooks';
import { defaultSettingsFormLayout } from '@/pages/settings/utils';

import {
  ProfileEVMAddressItem,
  ProfileEmailItem,
  ProfileTronAddressItem,
  ProfileSolanaAddressItem,
} from './components';
import { withProfileDataLoading } from './hocs';

import type { ProfileCardProps } from './types';

interface ProfileCardValues {
  email?: string;
  evm?: string;
  tron?: string;
  solana?: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ data, 'data-test': dataTest }) => {
  const { disabledTextInput } = useSharedStyles();

  const { form } = useForm<ProfileCardValues>();
  // to refresh form values on data update
  useEffect(() => {
    setTimeout(() => form.resetFields(), 0);
  }, [form, data]);
  const initialValues = useMemo<ProfileCardValues>(
    () => ({
      email: data.user.email,
      evm: data.user.addresses.find(({ addressType }) => addressType === BlockchainAPITypeAPIModel.EVM)?.address,
      solana: data.user.addresses.find(({ addressType }) => addressType === BlockchainAPITypeAPIModel.Solana)?.address,
      tron: data.user.addresses.find(({ addressType }) => addressType === BlockchainAPITypeAPIModel.Tron)?.address,
    }),
    [data.user.addresses, data.user.email],
  );
  return (
    <Form<ProfileCardValues> {...defaultSettingsFormLayout} initialValues={initialValues} preserve>
      <ProfileEmailItem<ProfileCardValues>
        data-test={dataTest && `${dataTest}-email`}
        name="email"
        className={disabledTextInput}
      />
      <ProfileEVMAddressItem<ProfileCardValues>
        data-test={dataTest && `${dataTest}-evm`}
        name="evm"
        className={disabledTextInput}
      />
      <ProfileTronAddressItem<ProfileCardValues>
        data-test={dataTest && `${dataTest}-tron`}
        name="tron"
        className={disabledTextInput}
      />
      <ProfileSolanaAddressItem<ProfileCardValues>
        data-test={dataTest && `${dataTest}-solana`}
        name="solana"
        className={disabledTextInput}
      />
    </Form>
  );
};

const ProfileCardLoaded = withProfileDataLoading(ProfileCard);

const ProfileCardMemo = React.memo(ProfileCardLoaded);

export default ProfileCardMemo;
