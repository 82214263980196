/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum BlockchainAPITypeAPIModel {
    EVM = 'EVM',
    BTC = 'BTC',
    Solana = 'Solana',
    Tron = 'Tron'
}


export function BlockchainAPITypeAPIModelFromJSON(json: any): BlockchainAPITypeAPIModel {
    return BlockchainAPITypeAPIModelFromJSONTyped(json, false);
}

export function BlockchainAPITypeAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlockchainAPITypeAPIModel {
    return json as BlockchainAPITypeAPIModel;
}

export function BlockchainAPITypeAPIModelToJSON(value?: BlockchainAPITypeAPIModel | null): any {
    return value as any;
}

