/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SortAttributeReportScheduleSortByAPIModel } from './SortAttributeReportScheduleSortByAPIModel';
import {
    SortAttributeReportScheduleSortByAPIModelFromJSON,
    SortAttributeReportScheduleSortByAPIModelFromJSONTyped,
    SortAttributeReportScheduleSortByAPIModelToJSON,
} from './SortAttributeReportScheduleSortByAPIModel';

/**
 * 
 * @export
 * @interface PageReportScheduleSortByAPIModel
 */
export interface PageReportScheduleSortByAPIModel {
    /**
     * 
     * @type {number}
     * @memberof PageReportScheduleSortByAPIModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PageReportScheduleSortByAPIModel
     */
    offset: number;
    /**
     * 
     * @type {Array<SortAttributeReportScheduleSortByAPIModel>}
     * @memberof PageReportScheduleSortByAPIModel
     */
    sortBy?: Array<SortAttributeReportScheduleSortByAPIModel>;
}

/**
 * Check if a given object implements the PageReportScheduleSortByAPIModel interface.
 */
export function instanceOfPageReportScheduleSortByAPIModel(value: object): boolean {
    if (!('limit' in value)) return false;
    if (!('offset' in value)) return false;
    return true;
}

export function PageReportScheduleSortByAPIModelFromJSON(json: any): PageReportScheduleSortByAPIModel {
    return PageReportScheduleSortByAPIModelFromJSONTyped(json, false);
}

export function PageReportScheduleSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageReportScheduleSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'limit': json['limit'],
        'offset': json['offset'],
        'sortBy': json['sortBy'] == null ? undefined : ((json['sortBy'] as Array<any>).map(SortAttributeReportScheduleSortByAPIModelFromJSON)),
    };
}

export function PageReportScheduleSortByAPIModelToJSON(value?: PageReportScheduleSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'limit': value['limit'],
        'offset': value['offset'],
        'sortBy': value['sortBy'] == null ? undefined : ((value['sortBy'] as Array<any>).map(SortAttributeReportScheduleSortByAPIModelToJSON)),
    };
}

