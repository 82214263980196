import { useMemo } from 'react';

import type { ReCaptchaParams } from '@/features/recaptcha/types';
import { createSignMessage } from '@/features/web3/evm-utils';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { useSubmitting } from '@/hooks';
import type { HookAction } from '@/infrastructure/model';
import type { JWTTokenInfo } from '@/infrastructure/security/jwt/types';
import type { Func } from '@/infrastructure/utils/ts';

import useAuthActions from '../useAuthActions';

import type { UseAuthActionsType } from '../useAuthActions';
import type { Account, Chain, Client, Transport } from 'viem';

export interface UseAuthWeb3EVMActions {
  auth: HookAction<
    [
      {
        client: Client<Transport, Chain, Account>;
        withRecaptcha: <T extends unknown[] = [], R = unknown>(
          func: (v: ReCaptchaParams) => Func<T, R>,
        ) => (...args: T) => Promise<R>;
      },
      Parameters<UseAuthActionsType['authWeb3']['act']>[1],
    ],
    JWTTokenInfo
  >;
  disconnectWeb3Auth: HookAction<
    [{ client: Client<Transport, Chain, Account> }, Parameters<UseAuthActionsType['disconnectWeb3Auth']['act']>[1]],
    Awaited<ReturnType<UseAuthActionsType['disconnectWeb3Auth']['act']>>
  >;
  confirmWeb3AuthAddressOwnership: HookAction<
    [
      { client: Client<Transport, Chain, Account> },
      Parameters<UseAuthActionsType['confirmWeb3AuthAddressOwnership']['act']>[1],
    ],
    Awaited<ReturnType<UseAuthActionsType['confirmWeb3AuthAddressOwnership']['act']>>
  >;
}

export default function useAuthWeb3EVMActions(): UseAuthWeb3EVMActions {
  const {
    authWeb3: baseAuth,
    disconnectWeb3Auth: baseDisconnectWeb3Auth,
    confirmWeb3AuthAddressOwnership: baseConfirmWeb3AuthAddressOwnership,
  } = useAuthActions();

  const doAuth = baseAuth.act;
  const [authInProgress, withAuth] = useSubmitting(false);
  const loginAction: UseAuthWeb3EVMActions['auth']['act'] = useMemo(
    () =>
      withAuth(async ({ client, withRecaptcha }, options) =>
        doAuth(
          {
            address: client.account.address,
            addressType: BlockchainAPITypeAPIModel.EVM,
            signMessage: createSignMessage(client),
            withRecaptcha,
          },
          options,
        ),
      ),
    [withAuth, doAuth],
  );
  const login = useMemo(
    () => ({
      act: loginAction,
      available: baseAuth.available,
      inAction: authInProgress || baseAuth.inAction,
    }),
    [loginAction, baseAuth.available, baseAuth.inAction, authInProgress],
  );

  const doDisconnectWeb3Auth = baseDisconnectWeb3Auth.act;
  const [disconnecting, withDisconnecting] = useSubmitting(false);
  const disconnectWeb3AuthAction: UseAuthWeb3EVMActions['disconnectWeb3Auth']['act'] = useMemo(
    () =>
      withDisconnecting(async ({ client }, options) =>
        doDisconnectWeb3Auth({ signMessage: createSignMessage(client) }, options),
      ),
    [withDisconnecting, doDisconnectWeb3Auth],
  );
  const disconnectWeb3Auth = useMemo(
    () => ({
      act: disconnectWeb3AuthAction,
      available: baseDisconnectWeb3Auth.available,
      inAction: disconnecting,
    }),
    [disconnectWeb3AuthAction, baseDisconnectWeb3Auth.available, disconnecting],
  );

  const doConfirmWeb3AuthAddressOwnership = baseConfirmWeb3AuthAddressOwnership.act;
  const [confirming, withConfirming] = useSubmitting(false);
  const confirmWeb3AuthAddressOwnershipAction: UseAuthWeb3EVMActions['confirmWeb3AuthAddressOwnership']['act'] =
    useMemo(
      () =>
        withConfirming(async ({ client }, options) =>
          doConfirmWeb3AuthAddressOwnership({ signMessage: createSignMessage(client) }, options),
        ),
      [withConfirming, doConfirmWeb3AuthAddressOwnership],
    );
  const confirmWeb3AuthAddressOwnership = useMemo(
    () => ({
      act: confirmWeb3AuthAddressOwnershipAction,
      available: baseConfirmWeb3AuthAddressOwnership.available,
      inAction: confirming,
    }),
    [confirmWeb3AuthAddressOwnershipAction, baseConfirmWeb3AuthAddressOwnership.available, confirming],
  );

  return {
    auth: login,
    disconnectWeb3Auth: disconnectWeb3Auth,
    confirmWeb3AuthAddressOwnership: confirmWeb3AuthAddressOwnership,
  };
}
