import { Form, Modal } from 'antd';
import { useMemo } from 'react';
import { getAddress } from 'viem';

import { useAppSelector } from '@/app/hooks';
import {
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
} from '@/components';
import { makeSelectAuthTokenData } from '@/features/auth/selectors';
import { AddressItem, BlockchainItem } from '@/features/dictionary/blockchain/components';
import { withGasWallet } from '@/features/gas-wallets/hocs';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { BlockchainAPITypeAPIModel, BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureGasWallets } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { asType, nameof } from '@/infrastructure/utils/ts';

import type { GasWalletRefundDialogProps } from './types';
import type React from 'react';
import type { Address } from 'viem';

interface FormProps {
  address: Address;
  bt: BlockchainTypeAPIModel;
}

const selectAuth = makeSelectAuthTokenData();

const requiredFields = [nameof<FormProps>('address')];

const GasWalletRefundDialog: React.FC<GasWalletRefundDialogProps> = ({
  'data-test': dataTest,
  wallet,
  onSubmit,
  onCancel,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const { form, withResetForm } = useForm<FormProps>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const authState = useAppSelector(selectAuth);
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const submit = useMemo(
    () => withSubmitting((val: FormProps): unknown => onSubmit(val.address)),
    [onSubmit, withSubmitting],
  );
  const reset = useMemo(() => withErrorHandling(withResetForm(onCancel)), [onCancel, withErrorHandling, withResetForm]);
  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    return <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_REFUND_WALLET_FORM_ERROR} />;
  }, [error]);

  return (
    <Modal
      open
      maskClosable={false}
      footer={null}
      title={<FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_REFUND_WALLET_FORM_TITLE} />}
      closable
      onCancel={reset}
      width={560}
    >
      <Form<FormProps>
        {...layout}
        initialValues={asType<Partial<FormProps>>({
          bt: wallet.bt,
          address: authState?.info.address ? getAddress(authState.info.address) : undefined,
        })}
        autoComplete="off"
        form={form}
        size="middle"
        onFinish={submit}
        onReset={reset}
      >
        {errorMessage && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={errorMessage} />}
        <BlockchainItem
          readonly
          name={nameof<FormProps>('bt')}
          data-test={dataTest && `${dataTest}-blockchain`}
          blockchainApis={[BlockchainAPITypeAPIModel.EVM]}
        />
        <AddressItem
          messages={{
            label: <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_REFUND_WALLET_FORM_ADDRESS} />,
          }}
          name={nameof<FormProps>('address')}
          data-test={dataTest && `${dataTest}-address`}
          blockchainApis={[BlockchainAPITypeAPIModel.EVM]}
          net={BlockchainNetworkTypeAPIModel.MainNet}
        />
        <FormCompletenessItem requiredFields={requiredFields} onChange={setFormComplete} />
        <FormFooter
          tailLayout={tailLayout}
          submitting={submitting}
          submitDisabled={!isFormComplete}
          messages={{
            submit: (
              <FormattedMessage
                id={I18nFeatureGasWallets.COMPONENTS_OPERATION_REFUND_WALLET_FORM_SUBMIT}
                tagName="span"
              />
            ),
          }}
          data-test={dataTest && `${dataTest}-submit`}
        />
      </Form>
    </Modal>
  );
};

const GasWalletRefundDialogMemo = withGasWallet<GasWalletRefundDialogProps>(GasWalletRefundDialog);

export default GasWalletRefundDialogMemo;
