import { UserRejectedRequestError } from 'viem';

import type { Connector } from 'wagmi';

export enum ConnectorId {
  metaMaskSDK = 'metaMaskSDK',
  walletConnect = 'walletConnect',
  // web3Auth = Web3AuthConnectorId.toLowerCase() as const,
}

const localConnectorInitChecker: Partial<Record<string, () => boolean>> = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  [ConnectorId.metaMaskSDK]: () => !!window.ethereum?.isMetaMask,
};

export const localConnectorSupport = (connector: Connector) => {
  const checker = localConnectorInitChecker[connector.id];
  return { isSupported: !!checker, isInitialized: !!checker?.() };
};

const disconnectSupported: Partial<Record<string, boolean>> = {
  [ConnectorId.metaMaskSDK]: true,
  [ConnectorId.walletConnect]: true,
};

export const disconnectConnectorSupport = (connector: Connector) => !!disconnectSupported[connector.id];

export const isRejectedByViemWallet = (e?: unknown) =>
  !!e && typeof e === 'object' && 'code' in e && e.code === UserRejectedRequestError.code;

export const isRejectedByMetaWalletConnectUser = (e?: unknown) =>
  !!e
  && typeof e === 'object'
  && 'message' in e
  && typeof e.message === 'string'
  && e.message.toLowerCase().includes('user denied');

export const isRejectedByWeb3WalletUser = (e?: unknown) =>
  isRejectedByViemWallet(e) || isRejectedByMetaWalletConnectUser();
