import { DisconnectOutlined } from '@ant-design/icons';
import { useDisconnect } from '@wallet-standard/react';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useWeb3SolanaWallet } from '@/features/web3/hooks';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';
import { useDefaultNotification } from '@/hooks';
import { emptyWith, withLogError } from '@/infrastructure/utils/functions';

import type { Web3SolanaWalletDisconnectProps } from './types';

const defaultStyle = { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 };

const Web3SolanaWalletDisconnect: React.FC<Web3SolanaWalletDisconnectProps> = ({
  'data-test': dataTest,
  className,
  style,
  onDisconnect,
  withDisconnect = emptyWith,
}) => {
  const { wallet } = useWeb3SolanaWallet();
  const [, disconnect] = useDisconnect(wallet);
  const { withDefaultError } = useDefaultNotification();

  const doDisconnect = useMemo(
    () =>
      withLogError(
        withDefaultError(
          withDisconnect(async () => {
            await disconnect();
            await onDisconnect?.();
          }),
        ),
      ),
    [withDefaultError, withDisconnect, disconnect, onDisconnect],
  );

  return (
    <Operation
      ButtonProps={{ size: 'large' }}
      style={useMemo(() => ({ ...defaultStyle, ...style }), [style])}
      className={className}
      title={
        <FormattedMessage
          id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_DISCONNECT_TITLE}
          values={{ provider: wallet.name }}
        />
      }
      data-test={dataTest && `${dataTest}-disconnect`}
      icon={<DisconnectOutlined />}
      onClick={doDisconnect}
      mode="inline"
    />
  );
};

const Web3SolanaWalletDisconnectMemo = React.memo(Web3SolanaWalletDisconnect) as typeof Web3SolanaWalletDisconnect;

export default Web3SolanaWalletDisconnectMemo;
