import type { MerchantFeatureFlagsAPIModel } from '@/generated/api/ncps-api';
import { MerchantSystemApi, MerchantUserApi } from '@/generated/api/ncps-api';
import { apiConfigurationFactory } from '@/infrastructure/api.provider';

const merchantSystemApi = new MerchantSystemApi(apiConfigurationFactory('public'));
const merchantUserSecuredApi = new MerchantUserApi(apiConfigurationFactory('auth'));

export const querySystemFlags = async (): Promise<MerchantFeatureFlagsAPIModel> =>
  await merchantSystemApi.systemFeatureFlags();
export const queryUserFlags = async (): Promise<MerchantFeatureFlagsAPIModel> =>
  await merchantUserSecuredApi.userFeatureFlags();
