import { useCallback, useMemo } from 'react';

import { PageCard } from '@/components';
import { BrandingToSForm } from '@/features/branding/components';
import { useFormPageAction, useStateMountSafe } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import type { TestableProps } from '@/infrastructure/utils/react';
import type { Func } from '@/infrastructure/utils/ts';
import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';
import { settingsBrandingSummaryLink } from '@/pages/settings/routes';

import { ToSBreadcrumb } from './components';

import type React from 'react';

const BrandingTermsOfServicePage: React.FC<TestableProps> = ({ 'data-test': dataTest = 'tos' }) => {
  const { redirectOnSubmit } = useFormPageAction();
  const doRedirect = useMemo<Func>(() => redirectOnSubmit(settingsBrandingSummaryLink()), [redirectOnSubmit]);
  const [onSubmit, setOnSubmit] = useStateMountSafe<Func | undefined>();
  const updateOnSubmit = useCallback(
    (func?: Func) => setOnSubmit(() => (func ? withOnSuccess(func, doRedirect) : undefined)),
    [doRedirect, setOnSubmit],
  );

  const submit = useMemo(() => ({ onClick: onSubmit ?? noop, disabled: !onSubmit }), [onSubmit]);

  return (
    <SettingsPageLayout
      data-test="container"
      selected={SettingsTabType.branding}
      breadcrumb={<ToSBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
      submit={submit}
      cancel={doRedirect}
    >
      <PageCard cardSize="big">
        <BrandingToSForm data-test={dataTest && `${dataTest}-form`} mode="edit" submitCallback={updateOnSubmit} />
      </PageCard>
    </SettingsPageLayout>
  );
};

export default BrandingTermsOfServicePage;
