import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Input, Space, Tooltip } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage } from '@/components';
import { useCompanyPermissions } from '@/features/company/hooks';
import { OperationCancelSettlementSchedule, OperationSetSettlementSchedule } from '@/features/settlements/components';
import { useSettlementSchedule } from '@/features/settlements/hooks';
import { WithdrawalPeriodAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import ActualBalancesLink from '@/pages/balances/actual/components/ActualBalancesLink';
import SettlementScheduledLink from '@/pages/settlements/scheduled/components/SettlementScheduleLink';

import type { SettlementScheduleItemProps } from './types';

const SettlementScheduleItem: React.FC<SettlementScheduleItemProps> = ({ 'data-test': dataTest, style, className }) => {
  const {
    data: { data: permissions },
  } = useCompanyPermissions();
  const {
    data: { data: schedule },
  } = useSettlementSchedule();
  const { formatMessage } = useIntl();
  return (
    <Form.Item
      label={
        <>
          <FormattedMessage
            id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SETTLEMENT_SCHEDULE_ITEM_LABEL}
            tagName="span"
          />
          &nbsp;
          <Tooltip
            title={
              permissions?.settleManagement ? (
                <FormattedMessage
                  id={
                    I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SETTLEMENT_SCHEDULE_ITEM_HELP_PERMISSIONS_STANDALONE
                  }
                  values={{
                    balance: (text: React.ReactNode) => <ActualBalancesLink title={text} mode="text" />,
                    schedule: (text: React.ReactNode) => <SettlementScheduledLink title={text} mode="text" />,
                  }}
                />
              ) : (
                <FormattedMessage
                  id={
                    I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SETTLEMENT_SCHEDULE_ITEM_HELP_PERMISSIONS_PARTNERED
                  }
                  values={{
                    schedule: (text: React.ReactNode) => <SettlementScheduledLink title={text} mode="text" />,
                  }}
                />
              )
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      }
    >
      <Input
        style={style}
        className={className}
        disabled
        data-test={dataTest}
        value={
          !schedule || schedule.period === WithdrawalPeriodAPIModel.NotSet
            ? formatMessage({
                id: I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SETTLEMENT_SCHEDULE_ITEM_VALUE_UNSET,
              })
            : formatMessage(
                { id: I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SETTLEMENT_SCHEDULE_ITEM_VALUE_SET },
                { period: schedule.period, amount: schedule.unifiedMinAmount },
              )
        }
        suffix={
          <Space>
            <OperationSetSettlementSchedule
              data-test={dataTest && `${dataTest}-opSet`}
              schedule={schedule}
              mode="icon"
            />
            <OperationCancelSettlementSchedule data-test={dataTest && `${dataTest}-opCancel`} mode="icon" />
            <SettlementScheduledLink
              data-test={dataTest && `${dataTest}-link`}
              mode="inline"
              title={
                <FormattedMessage
                  id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SETTLEMENT_SCHEDULE_ITEM_LINK}
                />
              }
            />
          </Space>
        }
      />
    </Form.Item>
  );
};

const SettlementScheduleItemMemo = React.memo(SettlementScheduleItem);

export default SettlementScheduleItemMemo;
