import { Tooltip } from 'antd';
import React from 'react';

import type { AssetTooltipProps } from './types';

const mapping: Partial<Record<string, string>> = {
  USDT: '(ERC-20)',
  USDC: '(ERC-20)',
  sUSDT: '(ERC-20, TestNet)',
  sUSDC: '(ERC-20, TestNet)',
  bUSDT: '(BEP-20)',
  bUSDC: '(BEP-20)',
  btUSDTv2: '(BEP-20, TestNet)',
  tsUSDt: '(TRC-20, TestNet)',
  pUSDC: '(Polygon PoS)',
  pUSDT: '(Polygon PoS)',
  paUSDC: '(Polygon PoS, TestNet)',
  paUSDT: '(Polygon PoS, TestNet)',
  aUSDC: '(Arbitrum, MainNet)',
  aUSDT: '(Arbitrum, MainNet)',
  asUSDC: '(Arbitrum, TestNet)',
  asUSDT: '(Arbitrum, TestNet)',
  BTC: '(Bitcoin)',
  tBTC: '(Bitcoin, TestNet)',
  SOL: '(Solana)',
  smUSDT: '(SPL)',
  smUSDC: '(SPL)',
  sdUSDC: '(SPL, DevNet)',

  // unsupported
  DAI: '(ERC-20)',
  EURx: '(ERC-20)',
  USDx: '(ERC-20)',
  gUSDT: '(ERC-20, TestNet)',
  gUSDC: '(ERC-20, TestNet)',
  bBUSD: '(BEP-20)',
  btUSDT: '(BEP-20, TestNet)',
  btBUSD: '(BEP-20, TestNet)',
  btMNXe: '(BEP-20, TestNet)',
  tsMNXe: '(TRC-20, TestNet)',
  atUSDC: '(Arbitrum, TestNet)',
  atUSDT: '(Arbitrum, TestNet)',
  pmUSDC: '(Polygon PoS, TestNet)',
  pmUSDT: '(Polygon PoS, TestNet)',
};

const AssetTooltip: React.FC<AssetTooltipProps> = ({ type, children }) => {
  const title = mapping[type];
  return title ? <Tooltip title={title}>{children}</Tooltip> : <>{children}</>;
};

const AssetTooltipMemo = React.memo(AssetTooltip);

export default AssetTooltipMemo;
