/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { DonationSortByAPIModel } from './DonationSortByAPIModel';
import {
    DonationSortByAPIModelFromJSON,
    DonationSortByAPIModelFromJSONTyped,
    DonationSortByAPIModelToJSON,
} from './DonationSortByAPIModel';

/**
 * 
 * @export
 * @interface SortAttributeDonationSortByAPIModel
 */
export interface SortAttributeDonationSortByAPIModel {
    /**
     * 
     * @type {DonationSortByAPIModel}
     * @memberof SortAttributeDonationSortByAPIModel
     */
    attr: DonationSortByAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof SortAttributeDonationSortByAPIModel
     */
    asc: boolean;
}

/**
 * Check if a given object implements the SortAttributeDonationSortByAPIModel interface.
 */
export function instanceOfSortAttributeDonationSortByAPIModel(value: object): boolean {
    if (!('attr' in value)) return false;
    if (!('asc' in value)) return false;
    return true;
}

export function SortAttributeDonationSortByAPIModelFromJSON(json: any): SortAttributeDonationSortByAPIModel {
    return SortAttributeDonationSortByAPIModelFromJSONTyped(json, false);
}

export function SortAttributeDonationSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortAttributeDonationSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'attr': DonationSortByAPIModelFromJSON(json['attr']),
        'asc': json['asc'],
    };
}

export function SortAttributeDonationSortByAPIModelToJSON(value?: SortAttributeDonationSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attr': DonationSortByAPIModelToJSON(value['attr']),
        'asc': value['asc'],
    };
}

