import type { MerchantPreferencesToStoreAPIModel } from '@/generated/api/ncps-api';
import type { UserAddressAPIModel, UserAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

export const NAMESPACE = 'user';

export type User = Omit<UserAPIModel, 'roles' | 'addresses' | 'nonce'> & {
  addresses: UserAddressAPIModel[];
};

export type UserPreferences = MerchantPreferencesToStoreAPIModel;

export interface UserState {
  user: LoadingStateWithDirty<User>;
  preferences: LoadingStateWithDirty<UserPreferences>;
}
