import { css } from '@emotion/css';
import { Space, Typography } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, Operation } from '@/components';
import { Web3WalletModalContainer } from '@/features/web3/components';
import { Web3AuthConnectorId } from '@/features/web3/hocs/withWeb3Support/hocs/withEVMConnectorsSupport/web3auth-connector-id';
import type { EVMConnectorMeta } from '@/features/web3/types';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';

import OperationSecureWeb3AuthPrivateKeyWithConnector from '../OperationSecureWeb3AuthPrivateKeyWithConnector';

import type { SecureWeb3AuthPrivateKeyModalProps } from './types';

const { Text } = Typography;

const SecureWeb3AuthPrivateKeyModal: React.FC<SecureWeb3AuthPrivateKeyModalProps> = ({
  'data-test': dataTest,
  state,
  onSuccess,
  onCancel,
}) => {
  const [retrieved, setRetrieved] = useStateMountSafe(false);

  return (
    <Web3WalletModalContainer
      data-test={dataTest}
      onSuccess={onSuccess}
      onClose={onCancel}
      title={<FormattedMessage id={I18nFeatureAuth.COMPONENTS_OPERATION_SECURE_WEB3AUTH_PRIVATE_KEY_DIALOG_TITLE} />}
      description={
        <Space
          direction="vertical"
          className={css`
            display: flex;
            flex: 1;
          `}
        >
          <FormattedMessageWithMarkup
            id={I18nFeatureAuth.COMPONENTS_OPERATION_SECURE_WEB3AUTH_PRIVATE_KEY_DIALOG_DESCRIPTION}
            tagName="span"
            values={{
              retrieve: (title: React.ReactNode) => (
                <Operation mode="link" title={title} onClick={() => setRetrieved(true)} icon={null} />
              ),
            }}
          />
          {retrieved && (
            <FormattedMessage
              id={I18nFeatureAuth.COMPONENTS_OPERATION_SECURE_WEB3AUTH_PRIVATE_KEY_DIALOG_PRIVATE_KEY}
              tagName="span"
              values={{
                key: (
                  <Text code copyable className="ym-hide-content">
                    {state.privateKey}
                  </Text>
                ),
              }}
            />
          )}
        </Space>
      }
      api={BlockchainAPITypeAPIModel.EVM}
      bt={undefined}
      evm={useMemo(
        () => ({
          filter: ({ id }: EVMConnectorMeta) => id !== Web3AuthConnectorId,
          Operation: OperationSecureWeb3AuthPrivateKeyWithConnector,
          extra: state,
        }),
        [state],
      )}
    />
  );
};

const SecureWeb3AuthPrivateKeyModalMemo = React.memo(
  SecureWeb3AuthPrivateKeyModal,
) as typeof SecureWeb3AuthPrivateKeyModal;

export default SecureWeb3AuthPrivateKeyModalMemo;
