import React from 'react';

import { FormattedMessage, InProgressView, PageLoadingIcon } from '@/components';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';

import type { WalletAuthInProgressProps } from './types';

const WalletAuthInProgress: React.FC<WalletAuthInProgressProps> = ({ 'data-test': dataTest }) => (
  <InProgressView
    data-test={dataTest}
    Icon={PageLoadingIcon}
    message={<FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_AUTH_IN_PROGRESS_MESSAGE} />}
  />
);

const WalletAuthInProgressMemo = React.memo(WalletAuthInProgress);

export default WalletAuthInProgressMemo;
