import { Space, Tooltip } from 'antd';
import React from 'react';

import { ReadonlyComponent } from '@/components';
import { withBlockchainType } from '@/features/dictionary/blockchain/hocs';

import BlockchainIcon from '../BlockchainIcon';

import { NetworkMark } from './components';

import type { BlockchainLabelProps } from './types';

const BlockchainLabel: React.FC<BlockchainLabelProps> = ({
  bt,
  'data-test': dataTest,
  mode = 'medium',
  withNetworkMark,
  className,
  style,
  copyable = true,
}) => {
  if (mode === 'icon') {
    return (
      <Space data-test={dataTest}>
        {withNetworkMark && <NetworkMark value={bt} />}
        <Tooltip title={bt}>
          <span>
            <BlockchainIcon bt={bt} className={className} style={style} />
          </span>
        </Tooltip>
      </Space>
    );
  }
  if (mode === 'medium') {
    return (
      <ReadonlyComponent
        className={className}
        style={style}
        value={
          <Space>
            {withNetworkMark && <NetworkMark value={bt} />}
            <BlockchainIcon bt={bt} />
            {bt}
          </Space>
        }
        data-test={dataTest}
        copyable={copyable ? { text: bt } : copyable}
      />
    );
  }
  return <ReadonlyComponent className={className} style={style} value={bt} data-test={dataTest} copyable={copyable} />;
};

const BlockchainLabelHOC = withBlockchainType<BlockchainLabelProps>(BlockchainLabel);
const BlockchainLabelMemo = React.memo(BlockchainLabelHOC);

export default BlockchainLabelMemo;
