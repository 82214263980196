import { css } from '@emotion/css';
import { theme } from 'antd';
import { useMemo } from 'react';

const useStyles = (fullHeight = true) => {
  const { token } = theme.useToken();
  return useMemo(
    () => ({
      card: css`
        margin: ${token.margin}px auto 0px;
        min-height: ${fullHeight ? 550 : 500}px;
        max-width: 800px;
        width: 95%;
        border-radius: 8px;
        display: flex;

        > div.ant-pro-card-body {
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: center;
          padding: ${token.paddingLG}px;

          > h2 {
            margin-top: 0;
          }
        }
      `,

      title: css`
        justify-self: center;
      `,

      container: css`
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;

        > form {
          width: 100%;
        }
      `,

      steps: css`
        padding: 25px;
      `,

      description: css`
        text-align: center;
      `,

      submit: css`
        align-self: flex-start;
      `,
    }),
    [fullHeight, token.margin, token.paddingLG],
  );
};

export default useStyles;
