import isNil from 'lodash-es/isNil';

import type { Func } from '@/infrastructure/utils/ts';

export const withVoidOrThrow =
  <V extends unknown[], R>(func: Func<V, R>) =>
  async (...args: V): Promise<void> => {
    await func(...args);
  };

export const withSuppressError =
  <V extends unknown[], R>(func: Func<V, R>, errorFn?: Func) =>
  async (...args: V): Promise<void> => {
    try {
      await func(...args);
    } catch (e) {
      await errorFn?.(e);
    }
  };

export const suppressError = <R>(
  promise: R | Promise<R>,
  errorFn: (error: unknown) => void = (e) => console.error(e, (e as Error | undefined)?.stack),
) => withSuppressError(() => promise, errorFn)();

export const withLogError = <V extends unknown[], R>(func: Func<V, R>) =>
  withSuppressError(func, (e) => console.error(e, (e as Error | undefined)?.stack));

export interface WithLogErrorPure {
  <V extends unknown[], R, E>(func: (...args: V) => R, onError: (e: unknown) => E): (...args: V) => R | E;

  <V extends unknown[], R>(func: (...args: V) => R): (...args: V) => R | undefined;
}

export const withLogErrorPure: WithLogErrorPure =
  <V extends unknown[], R, E>(func: (...args: V) => R, onError?: (e: unknown) => E) =>
  (...args: V): R | E | undefined => {
    try {
      return func(...args);
    } catch (e) {
      if (onError) {
        return onError(e);
      }
    }
  };

export const withCatchError =
  <V extends unknown[], R>(func: Func<V, R>, errorFn: (error: unknown) => R) =>
  async (...args: V) => {
    try {
      return await func(...args);
    } catch (e) {
      return errorFn(e);
    }
  };

export const wrap =
  <V extends unknown[], R>(func: Func<V, R>, before: () => unknown, after: () => unknown) =>
  async (...args: V) => {
    try {
      before();
      return await func(...args);
    } finally {
      after();
    }
  };

export const withRethrowError =
  <V extends unknown[], R>(func: Func<V, R>, errorFn?: (error: unknown) => Error) =>
  async (...args: V): Promise<void> => {
    try {
      await func(...args);
    } catch (e) {
      throw errorFn ? errorFn(e) : e;
    }
  };

export const withSuppressPromise =
  <V extends unknown[], R>(
    func: Func<V, R>,
    errorFn: Func = (e) => console.error(e, (e as Error | undefined)?.stack),
  ) =>
  (...args: V): void => {
    (async () => withSuppressError(func, errorFn)(...args))().catch((e: unknown) => console.error(e));
  };

export const suppressPromise = <R>(
  promise: R | Promise<R>,
  errorFn: Func = (e) => console.error(e, (e as Error | undefined)?.stack),
) => {
  (async () => suppressError(promise, errorFn))().catch((e: unknown) => console.error(e));
};

export const withOnSuccess =
  <V extends unknown[], R>(func: Func<V, R>, onSuccess: Func<[R]>): ((...args: V) => Promise<R>) =>
  async (...args: V): Promise<R> => {
    const result = await func(...args);
    await onSuccess(result);
    return result;
  };

export const withOnError =
  <V extends unknown[], R>(
    func: Func<V, R>,
    onError: (error: unknown) => Promise<void> | void,
  ): ((...args: V) => Promise<R>) =>
  async (...args: V): Promise<R> => {
    try {
      return await func(...args);
    } catch (e) {
      suppressPromise(onError(e));
      throw e;
    }
  };

export const withFinally =
  <V extends unknown[], R>(func: Func<V, R>, onFinally: Func) =>
  async (...args: V): Promise<R> => {
    try {
      return await func(...args);
    } finally {
      await withSuppressError(onFinally)();
    }
  };

export function assertNotNil<V>(
  value: V | undefined | null,
  failWith: () => Error = () => new Error('no data'),
): asserts value is NonNullable<V> {
  if (isNil(value)) {
    const error = failWith();
    const arr = error.stack?.split('\n');
    arr?.splice(1, 2);
    error.stack = arr?.join('\n');
    throw error;
  }
}

export const someOrFail = <V>(value: V | undefined | null, failWith: () => Error = () => new Error('no data')): V => {
  assertNotNil<V>(value, failWith);
  return value;
};

export const uniqueBy =
  <T, K>(extractField: (value: T) => K) =>
  (_: T, index: number, self: T[]) =>
    self.findIndex(extractField) === index;

export const onlyUnique = <T>(value: T, index: number, self: T[]) => self.indexOf(value) === index;

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noopAsync = async () => {};
export const identity = <A>(x: A) => x;
export const equality = <A>(v1: A, v2: A) => v1 === v2;

export const emptyWith: <V extends unknown[], R, F = Func<V, R>>(func: F) => F = (func) => func;

export const simpleHash = (str: string): number => {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);

    hash = (hash << 5) - hash + char;

    hash |= 0; // Convert to 32-bit integer
  }
  return hash;
};

export const setAbortableTimeout = (
  callback: () => unknown,
  delayInMilliseconds: number,
  signal?: AbortSignal,
  onAbort?: () => unknown,
) => {
  const internalCallback = () => {
    signal?.removeEventListener('abort', handleAbort);
    callback();
  };

  signal?.addEventListener('abort', handleAbort);
  const internalTimer = setTimeout(internalCallback, delayInMilliseconds);

  function handleAbort() {
    console.warn('Canceling timer (%s) via signal abort.', internalTimer);
    clearTimeout(internalTimer);
    onAbort?.();
    signal?.removeEventListener('abort', handleAbort);
  }
};

export const timeout = async (timeoutMS = 1000, signal?: AbortSignal) =>
  new Promise((resolve, reject) => {
    setAbortableTimeout(
      () => resolve(''),
      timeoutMS,
      signal,
      () => reject(new Error('Aborted')),
    );
  });

export const withTimeout = async <T = unknown>(
  func: () => Promise<T>,
  timeoutMS = 7000,
  onTimeout?: () => unknown,
  signal?: AbortSignal,
  onAbort?: () => unknown,
): Promise<T> =>
  Promise.race<T>([
    func(),
    // eslint-disable-next-line promise/param-names
    new Promise<T>((_, reject) => {
      setAbortableTimeout(
        () => {
          onTimeout?.();
          reject(new Error('Request timeout'));
        },
        timeoutMS,
        signal,
        onAbort,
      );
    }),
  ]);
