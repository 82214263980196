import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { removeCompanyUser } from '@/features/company/actions';
import type { CompanyUser } from '@/features/company/types';
import { useActionPending } from '@/features/global/hooks';
import type { HookAction } from '@/infrastructure/model';
import { mapStoredState } from '@/infrastructure/model';
import { someOrFail } from '@/infrastructure/utils/functions';
import { isSameAddress } from '@/infrastructure/utils/web3/address';

import useCompanyUsers from '../useCompanyUsers';

type RemoveUserUnavailabilityReason = 'no-user';

export interface UseCompanyUserActions {
  remove: HookAction<[], void, RemoveUserUnavailabilityReason>;
  data?: CompanyUser;
}

export default function useCompanyUserActions(address: string): UseCompanyUserActions {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data: users } = useCompanyUsers();
  const user = useMemo(
    () => mapStoredState(users, (list) => list.find((saved) => isSameAddress(saved.address, address))),
    [address, users],
  );

  const unavailabilityReason = useMemo<RemoveUserUnavailabilityReason | undefined>(
    () => (!user.data ? 'no-user' : undefined),
    [user.data],
  );
  const removing = useActionPending(removeCompanyUser, address);
  const removeAction: UseCompanyUserActions['remove']['act'] = useCallback(
    () => withExtractDataDispatch(removeCompanyUser)({ userId: someOrFail(user.data?.id) }),
    [user.data?.id, withExtractDataDispatch],
  );
  const removeHook: UseCompanyUserActions['remove'] = useMemo(
    () => ({
      act: removeAction,
      available: !unavailabilityReason,
      inAction: removing,
      unavailabilityReason,
    }),
    [removeAction, unavailabilityReason, removing],
  );

  return { remove: removeHook, data: user.data };
}
