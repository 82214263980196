/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  CollectableEntityProcessTransactionAPIModel,
  DonationAPIModel,
  DonationFilterAPIModel,
  DonationStatusRequestAPIModel,
  NewDonationAPIModel,
  PublicDonationAPIModel,
  SliceDonationDonationSortByAPIModel,
  UpdateDonationAPIModel,
  UpdateDonationImageAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    CollectableEntityProcessTransactionAPIModelFromJSON,
    CollectableEntityProcessTransactionAPIModelToJSON,
    DonationAPIModelFromJSON,
    DonationAPIModelToJSON,
    DonationFilterAPIModelFromJSON,
    DonationFilterAPIModelToJSON,
    DonationStatusRequestAPIModelFromJSON,
    DonationStatusRequestAPIModelToJSON,
    NewDonationAPIModelFromJSON,
    NewDonationAPIModelToJSON,
    PublicDonationAPIModelFromJSON,
    PublicDonationAPIModelToJSON,
    SliceDonationDonationSortByAPIModelFromJSON,
    SliceDonationDonationSortByAPIModelToJSON,
    UpdateDonationAPIModelFromJSON,
    UpdateDonationAPIModelToJSON,
    UpdateDonationImageAPIModelFromJSON,
    UpdateDonationImageAPIModelToJSON,
} from '../models/index';

export interface CreateDonationRequest {
    newDonationAPIModel: NewDonationAPIModel;
}

export interface GetDonationRequest {
    donationId: string;
}

export interface GetDonationAssetDeployTransactionRequest {
    id: string;
    asset: string;
}

export interface GetDonationPublicRequest {
    donationId: string;
}

export interface SearchDonationsRequest {
    donationFilterAPIModel: DonationFilterAPIModel;
    withTotal?: boolean;
}

export interface UpdateDonationRequest {
    donationId: string;
    updateDonationAPIModel: UpdateDonationAPIModel;
}

export interface UpdateDonationImageRequest {
    donationId: string;
    updateDonationImageAPIModel: UpdateDonationImageAPIModel;
}

export interface UpdateDonationStatusRequest {
    donationId: string;
    donationStatusRequestAPIModel: DonationStatusRequestAPIModel;
}

/**
 * DonationApi - interface
 * 
 * @export
 * @interface DonationApiInterface
 */
export interface DonationApiInterface {
    /**
     * Creates new donation campaign
     * @param {NewDonationAPIModel} newDonationAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationApiInterface
     */
    createDonationRaw(requestParameters: CreateDonationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DonationAPIModel>>;

    /**
     * Creates new donation campaign
     */
    createDonation(newDonationAPIModel: NewDonationAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DonationAPIModel>;

    /**
     * Returns donation campaign by id
     * @param {string} donationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationApiInterface
     */
    getDonationRaw(requestParameters: GetDonationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DonationAPIModel>>;

    /**
     * Returns donation campaign by id
     */
    getDonation(donationId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DonationAPIModel>;

    /**
     * Returns donation asset address deploy transaction
     * @param {string} id 
     * @param {string} asset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationApiInterface
     */
    getDonationAssetDeployTransactionRaw(requestParameters: GetDonationAssetDeployTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectableEntityProcessTransactionAPIModel>>;

    /**
     * Returns donation asset address deploy transaction
     */
    getDonationAssetDeployTransaction(id: string, asset: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectableEntityProcessTransactionAPIModel>;

    /**
     * Returns donation campaign public details by id
     * @param {string} donationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationApiInterface
     */
    getDonationPublicRaw(requestParameters: GetDonationPublicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicDonationAPIModel>>;

    /**
     * Returns donation campaign public details by id
     */
    getDonationPublic(donationId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicDonationAPIModel>;

    /**
     * Returns donation campaigns by filter
     * @param {DonationFilterAPIModel} donationFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationApiInterface
     */
    searchDonationsRaw(requestParameters: SearchDonationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceDonationDonationSortByAPIModel>>;

    /**
     * Returns donation campaigns by filter
     */
    searchDonations(donationFilterAPIModel: DonationFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceDonationDonationSortByAPIModel>;

    /**
     * Updates the donation campaign
     * @param {string} donationId 
     * @param {UpdateDonationAPIModel} updateDonationAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationApiInterface
     */
    updateDonationRaw(requestParameters: UpdateDonationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DonationAPIModel>>;

    /**
     * Updates the donation campaign
     */
    updateDonation(donationId: string, updateDonationAPIModel: UpdateDonationAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DonationAPIModel>;

    /**
     * Updates donation campaign image
     * @param {string} donationId 
     * @param {UpdateDonationImageAPIModel} updateDonationImageAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationApiInterface
     */
    updateDonationImageRaw(requestParameters: UpdateDonationImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DonationAPIModel>>;

    /**
     * Updates donation campaign image
     */
    updateDonationImage(donationId: string, updateDonationImageAPIModel: UpdateDonationImageAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DonationAPIModel>;

    /**
     * Updates donation campaign status
     * @param {string} donationId 
     * @param {DonationStatusRequestAPIModel} donationStatusRequestAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationApiInterface
     */
    updateDonationStatusRaw(requestParameters: UpdateDonationStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DonationAPIModel>>;

    /**
     * Updates donation campaign status
     */
    updateDonationStatus(donationId: string, donationStatusRequestAPIModel: DonationStatusRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DonationAPIModel>;

}

/**
 * 
 */
export class DonationApi extends runtime.BaseAPI implements DonationApiInterface {

    /**
     * Creates new donation campaign
     */
    async createDonationRaw(requestParameters: CreateDonationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DonationAPIModel>> {
        if (requestParameters['newDonationAPIModel'] == null) {
            throw new runtime.RequiredError(
                'newDonationAPIModel',
                'Required parameter "newDonationAPIModel" was null or undefined when calling createDonation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/donations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewDonationAPIModelToJSON(requestParameters['newDonationAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DonationAPIModelFromJSON(jsonValue));
    }

    /**
     * Creates new donation campaign
     */
    async createDonation(newDonationAPIModel: NewDonationAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DonationAPIModel> {
        const response = await this.createDonationRaw({ newDonationAPIModel: newDonationAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Returns donation campaign by id
     */
    async getDonationRaw(requestParameters: GetDonationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DonationAPIModel>> {
        if (requestParameters['donationId'] == null) {
            throw new runtime.RequiredError(
                'donationId',
                'Required parameter "donationId" was null or undefined when calling getDonation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/donations/{donationId}`.replace(`{${"donationId"}}`, encodeURIComponent(String(requestParameters['donationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DonationAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns donation campaign by id
     */
    async getDonation(donationId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DonationAPIModel> {
        const response = await this.getDonationRaw({ donationId: donationId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns donation asset address deploy transaction
     */
    async getDonationAssetDeployTransactionRaw(requestParameters: GetDonationAssetDeployTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectableEntityProcessTransactionAPIModel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getDonationAssetDeployTransaction().'
            );
        }

        if (requestParameters['asset'] == null) {
            throw new runtime.RequiredError(
                'asset',
                'Required parameter "asset" was null or undefined when calling getDonationAssetDeployTransaction().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/donations/{id}/asset/{asset}/tx`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters['asset']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectableEntityProcessTransactionAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns donation asset address deploy transaction
     */
    async getDonationAssetDeployTransaction(id: string, asset: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectableEntityProcessTransactionAPIModel> {
        const response = await this.getDonationAssetDeployTransactionRaw({ id: id, asset: asset }, initOverrides);
        return await response.value();
    }

    /**
     * Returns donation campaign public details by id
     */
    async getDonationPublicRaw(requestParameters: GetDonationPublicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicDonationAPIModel>> {
        if (requestParameters['donationId'] == null) {
            throw new runtime.RequiredError(
                'donationId',
                'Required parameter "donationId" was null or undefined when calling getDonationPublic().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/donations/{donationId}/public`.replace(`{${"donationId"}}`, encodeURIComponent(String(requestParameters['donationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicDonationAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns donation campaign public details by id
     */
    async getDonationPublic(donationId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicDonationAPIModel> {
        const response = await this.getDonationPublicRaw({ donationId: donationId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns donation campaigns by filter
     */
    async searchDonationsRaw(requestParameters: SearchDonationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceDonationDonationSortByAPIModel>> {
        if (requestParameters['donationFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'donationFilterAPIModel',
                'Required parameter "donationFilterAPIModel" was null or undefined when calling searchDonations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/donations/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DonationFilterAPIModelToJSON(requestParameters['donationFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceDonationDonationSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns donation campaigns by filter
     */
    async searchDonations(donationFilterAPIModel: DonationFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceDonationDonationSortByAPIModel> {
        const response = await this.searchDonationsRaw({ donationFilterAPIModel: donationFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

    /**
     * Updates the donation campaign
     */
    async updateDonationRaw(requestParameters: UpdateDonationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DonationAPIModel>> {
        if (requestParameters['donationId'] == null) {
            throw new runtime.RequiredError(
                'donationId',
                'Required parameter "donationId" was null or undefined when calling updateDonation().'
            );
        }

        if (requestParameters['updateDonationAPIModel'] == null) {
            throw new runtime.RequiredError(
                'updateDonationAPIModel',
                'Required parameter "updateDonationAPIModel" was null or undefined when calling updateDonation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/donations/{donationId}`.replace(`{${"donationId"}}`, encodeURIComponent(String(requestParameters['donationId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDonationAPIModelToJSON(requestParameters['updateDonationAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DonationAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates the donation campaign
     */
    async updateDonation(donationId: string, updateDonationAPIModel: UpdateDonationAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DonationAPIModel> {
        const response = await this.updateDonationRaw({ donationId: donationId, updateDonationAPIModel: updateDonationAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Updates donation campaign image
     */
    async updateDonationImageRaw(requestParameters: UpdateDonationImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DonationAPIModel>> {
        if (requestParameters['donationId'] == null) {
            throw new runtime.RequiredError(
                'donationId',
                'Required parameter "donationId" was null or undefined when calling updateDonationImage().'
            );
        }

        if (requestParameters['updateDonationImageAPIModel'] == null) {
            throw new runtime.RequiredError(
                'updateDonationImageAPIModel',
                'Required parameter "updateDonationImageAPIModel" was null or undefined when calling updateDonationImage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/donations/{donationId}/image`.replace(`{${"donationId"}}`, encodeURIComponent(String(requestParameters['donationId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDonationImageAPIModelToJSON(requestParameters['updateDonationImageAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DonationAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates donation campaign image
     */
    async updateDonationImage(donationId: string, updateDonationImageAPIModel: UpdateDonationImageAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DonationAPIModel> {
        const response = await this.updateDonationImageRaw({ donationId: donationId, updateDonationImageAPIModel: updateDonationImageAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Updates donation campaign status
     */
    async updateDonationStatusRaw(requestParameters: UpdateDonationStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DonationAPIModel>> {
        if (requestParameters['donationId'] == null) {
            throw new runtime.RequiredError(
                'donationId',
                'Required parameter "donationId" was null or undefined when calling updateDonationStatus().'
            );
        }

        if (requestParameters['donationStatusRequestAPIModel'] == null) {
            throw new runtime.RequiredError(
                'donationStatusRequestAPIModel',
                'Required parameter "donationStatusRequestAPIModel" was null or undefined when calling updateDonationStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/donations/{donationId}/status`.replace(`{${"donationId"}}`, encodeURIComponent(String(requestParameters['donationId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DonationStatusRequestAPIModelToJSON(requestParameters['donationStatusRequestAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DonationAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates donation campaign status
     */
    async updateDonationStatus(donationId: string, donationStatusRequestAPIModel: DonationStatusRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DonationAPIModel> {
        const response = await this.updateDonationStatusRaw({ donationId: donationId, donationStatusRequestAPIModel: donationStatusRequestAPIModel }, initOverrides);
        return await response.value();
    }

}
