/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  DeleteWithdrawalScheduleResponseAPIModel,
  GetWithdrawalScheduleResponseAPIModel,
  UpdateWithdrawalScheduleRequestAPIModel,
  UpdateWithdrawalScheduleResponseAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    DeleteWithdrawalScheduleResponseAPIModelFromJSON,
    DeleteWithdrawalScheduleResponseAPIModelToJSON,
    GetWithdrawalScheduleResponseAPIModelFromJSON,
    GetWithdrawalScheduleResponseAPIModelToJSON,
    UpdateWithdrawalScheduleRequestAPIModelFromJSON,
    UpdateWithdrawalScheduleRequestAPIModelToJSON,
    UpdateWithdrawalScheduleResponseAPIModelFromJSON,
    UpdateWithdrawalScheduleResponseAPIModelToJSON,
} from '../models/index';

export interface UpdateWithdrawalScheduleRequest {
    updateWithdrawalScheduleRequestAPIModel: UpdateWithdrawalScheduleRequestAPIModel;
}

/**
 * WithdrawScheduleApi - interface
 * 
 * @export
 * @interface WithdrawScheduleApiInterface
 */
export interface WithdrawScheduleApiInterface {
    /**
     * Clears withdrawal schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawScheduleApiInterface
     */
    deleteWithdrawalScheduleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteWithdrawalScheduleResponseAPIModel>>;

    /**
     * Clears withdrawal schedule
     */
    deleteWithdrawalSchedule(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteWithdrawalScheduleResponseAPIModel>;

    /**
     * Returns withdrawal schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawScheduleApiInterface
     */
    getWithdrawalScheduleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetWithdrawalScheduleResponseAPIModel>>;

    /**
     * Returns withdrawal schedule
     */
    getWithdrawalSchedule(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetWithdrawalScheduleResponseAPIModel>;

    /**
     * Updates withdrawal schedule
     * @param {UpdateWithdrawalScheduleRequestAPIModel} updateWithdrawalScheduleRequestAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawScheduleApiInterface
     */
    updateWithdrawalScheduleRaw(requestParameters: UpdateWithdrawalScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateWithdrawalScheduleResponseAPIModel>>;

    /**
     * Updates withdrawal schedule
     */
    updateWithdrawalSchedule(updateWithdrawalScheduleRequestAPIModel: UpdateWithdrawalScheduleRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateWithdrawalScheduleResponseAPIModel>;

}

/**
 * 
 */
export class WithdrawScheduleApi extends runtime.BaseAPI implements WithdrawScheduleApiInterface {

    /**
     * Clears withdrawal schedule
     */
    async deleteWithdrawalScheduleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteWithdrawalScheduleResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/schedule`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteWithdrawalScheduleResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Clears withdrawal schedule
     */
    async deleteWithdrawalSchedule(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteWithdrawalScheduleResponseAPIModel> {
        const response = await this.deleteWithdrawalScheduleRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns withdrawal schedule
     */
    async getWithdrawalScheduleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetWithdrawalScheduleResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/schedule`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWithdrawalScheduleResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns withdrawal schedule
     */
    async getWithdrawalSchedule(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetWithdrawalScheduleResponseAPIModel> {
        const response = await this.getWithdrawalScheduleRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates withdrawal schedule
     */
    async updateWithdrawalScheduleRaw(requestParameters: UpdateWithdrawalScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateWithdrawalScheduleResponseAPIModel>> {
        if (requestParameters['updateWithdrawalScheduleRequestAPIModel'] == null) {
            throw new runtime.RequiredError(
                'updateWithdrawalScheduleRequestAPIModel',
                'Required parameter "updateWithdrawalScheduleRequestAPIModel" was null or undefined when calling updateWithdrawalSchedule().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/schedule`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWithdrawalScheduleRequestAPIModelToJSON(requestParameters['updateWithdrawalScheduleRequestAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateWithdrawalScheduleResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates withdrawal schedule
     */
    async updateWithdrawalSchedule(updateWithdrawalScheduleRequestAPIModel: UpdateWithdrawalScheduleRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateWithdrawalScheduleResponseAPIModel> {
        const response = await this.updateWithdrawalScheduleRaw({ updateWithdrawalScheduleRequestAPIModel: updateWithdrawalScheduleRequestAPIModel }, initOverrides);
        return await response.value();
    }

}
