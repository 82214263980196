import { Alert } from 'antd';
import React from 'react';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import { BlockchainLabelList } from '@/features/dictionary/blockchain/components';
import Web3WalletModalContainer from '@/features/web3/components/Web3WalletModalContainer';
import { I18nFeatureUser } from '@/generated/i18n/i18n';

import OperationAddUserEVMAddressWithConnector from '../OperationAddUserEVMAddressWithConnector';
import OperationAddUserSolanaAddressWithWallet from '../OperationAddUserSolanaAddressWithWallet';

import type { AddUserAddressModalProps } from './types';

const evm = { Operation: OperationAddUserEVMAddressWithConnector, extra: undefined };
const solana = { Operation: OperationAddUserSolanaAddressWithWallet, extra: undefined };

const AddUserAddressModal: React.FC<AddUserAddressModalProps> = ({
  'data-test': dataTest,
  api,
  onSuccess,
  onClose,
}) => (
  <Web3WalletModalContainer
    data-test={dataTest}
    api={api}
    onSuccess={onSuccess}
    onClose={onClose}
    title={<FormattedMessage id={I18nFeatureUser.COMPONENTS_OPERATION_ADD_USER_ADDRESS_DIALOG_TITLE} />}
    description={
      <>
        <FormattedMessageWithMarkup
          id={I18nFeatureUser.COMPONENTS_OPERATION_ADD_USER_ADDRESS_DIALOG_DESCRIPTION}
          values={{
            blockchains: <BlockchainLabelList api={api} mode="medium" />,
          }}
        />
        <Alert
          type="warning"
          message={<FormattedMessage id={I18nFeatureUser.COMPONENTS_OPERATION_ADD_USER_ADDRESS_DIALOG_WARNING} />}
        />
      </>
    }
    evm={evm}
    solana={solana}
    bt={undefined}
  />
);

const AddUserAddressModalMemo = React.memo(AddUserAddressModal);

export default AddUserAddressModalMemo;
