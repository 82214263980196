import React, { useCallback } from 'react';

import { FormattedMessage, InProgressView } from '@/components';
import Web3ProviderIcon from '@/features/web3/components/Web3ProviderIcon';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';
import type { StyledProps } from '@/infrastructure/utils/react';

import type { Web3WalletInProgressProps } from './types';

const Web3WalletInProgress: React.FC<Web3WalletInProgressProps> = ({
  'data-test': dataTest,
  cancel,
  wallet,
  message,
}) => {
  const WalletIcon = useCallback(
    (props: StyledProps) => <Web3ProviderIcon value={wallet.id} {...props} />,
    [wallet.id],
  );
  return (
    <InProgressView
      data-test={dataTest}
      cancel={cancel}
      Icon={WalletIcon}
      message={
        message ?? (
          <FormattedMessage
            id={I18nFeatureWeb3.COMPONENTS_WALLET_IN_PROGRESS_MESSAGE}
            values={{ provider: wallet.name }}
          />
        )
      }
    />
  );
};

const Web3WalletInProgressMemo = React.memo(Web3WalletInProgress);

export default Web3WalletInProgressMemo;
