import { PlayCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, Operation } from '@/components';
import { withCompanyPermissionsGuard } from '@/features/company/hocs';
import { usePayout, usePayoutActions } from '@/features/payouts/hooks';
import { OperationRefreshBalances } from '@/features/statistics/components';
import { useUserAddress } from '@/features/user/hooks';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nCommon, I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { useFormVisible } from '@/hooks';

import { StartPayoutModal } from './components';

import type { OperationStartPayoutProps } from './types';

const OperationStartPayout: React.FC<OperationStartPayoutProps> = ({
  'data-test': dataTest,
  payoutId,
  title,
  mode,
}) => {
  const formControl = useFormVisible(false);
  const { data: payout, loading } = usePayout(payoutId);
  const { start: startAction } = usePayoutActions(payoutId);
  const {
    data: { data: address },
  } = useUserAddress(BlockchainAPITypeAPIModel.EVM);
  const disabledMessage = useMemo(() => {
    switch (startAction.unavailabilityReason) {
      case 'no-data':
        return <FormattedMessage id={I18nCommon.MESSAGES_LOADING} />;
      case 'invalid-status':
        return (
          <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INVALID_STATUS} />
        );
      case 'invalid-account':
        return (
          <FormattedMessage
            id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INVALID_ACCOUNT}
            values={{ account: address?.value }}
          />
        );
      case 'invalid-chain-id':
        return (
          <FormattedMessageWithMarkup
            id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INVALID_CHAIN_ID}
            values={{ bt: payout.data?.blockchain }}
          />
        );
      case 'unsupported-chain':
        return (
          <FormattedMessageWithMarkup
            id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_UNSUPPORTED}
            values={{ bt: payout.data?.blockchain }}
          />
        );
      case 'insufficient-balance':
        return (
          <FormattedMessageWithMarkup
            id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INSUFFICIENT_BALANCE}
            values={{ ln: (title: React.ReactNode) => <OperationRefreshBalances title={title} mode="link" /> }}
          />
        );
      case undefined:
        return undefined;
    }
  }, [address?.value, payout.data?.blockchain, startAction.unavailabilityReason]);

  const doHide = useCallback(() => {
    formControl.hide();
  }, [formControl]);

  const doShow = useCallback(() => {
    formControl.show();
  }, [formControl]);

  return (
    <>
      {formControl.visible && payout.data?.blockchain && (
        <StartPayoutModal
          data-test={dataTest && `${dataTest}-dialog`}
          payoutId={payoutId}
          onSuccess={doHide}
          onCancel={doHide}
        />
      )}
      <Operation
        title={title ?? <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_TITLE} />}
        tooltip={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_TOOLTIP} />}
        disabled={!!disabledMessage}
        disabledMessage={disabledMessage}
        icon={<PlayCircleOutlined />}
        inProgress={payout.isDirty || loading}
        onClick={doShow}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationStartPayoutLoadedMemo = React.memo(
  withCompanyPermissionsGuard('settleManagement')(OperationStartPayout),
);

export default OperationStartPayoutLoadedMemo;
