import { Space, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { EmailEnterForm, GoogleInProgress } from '@/features/auth/components';
import type { EmailAuthOptions } from '@/features/auth/hooks';
import { withFeatureTogglesGuard } from '@/features/feature-toggle/hocs';
import { I18nPageAuth } from '@/generated/i18n/i18n';
import { useSubmitting } from '@/hooks';
import type { Func } from '@/infrastructure/utils/ts';
import { withHideIfOtherActive } from '@/pages/auth/login/hocs';

import { EmailLoginButton, GoogleLoginButton } from './components';
import { useEmailAuth, useGoogleAuth } from './hooks';

import type { EmailBlockProps } from './types';

const { Text } = Typography;

const EmailBlock: React.FC<EmailBlockProps> = ({
  'data-test': dataTest,
  activeProvider,
  withLogIn,
  onSelectProvider,
}) => {
  const [authInProgress, withAuthInProgress] = useSubmitting(false);
  const withAuth: <V extends unknown[] = unknown[], R = unknown>(
    func: (options: Omit<EmailAuthOptions, 'cancellation'>) => Func<V, R>,
  ) => Func<V> = useCallback((func) => withLogIn((onBeforeSignUp) => func({ onBeforeSignUp })), [withLogIn]);
  const emailAuth = useEmailAuth({ onSelectProvider, withAuth });
  const googleAuth = useGoogleAuth({ onSelectProvider, withAuth });
  const doEmailAuth = useMemo(() => withAuthInProgress(emailAuth.auth), [emailAuth.auth, withAuthInProgress]);
  const doGoogleAuth = useMemo(() => withAuthInProgress(googleAuth.auth), [googleAuth.auth, withAuthInProgress]);

  if (activeProvider === 'google') {
    return <GoogleInProgress data-test={dataTest && `${dataTest}-google`} authorizing={authInProgress} />;
  }

  if (activeProvider?.startsWith('email')) {
    return (
      <EmailEnterForm data-test={dataTest && `${dataTest}-email`} onLogin={doEmailAuth} authorizing={authInProgress} />
    );
  }

  return (
    <Space direction="vertical" style={{ display: 'flex', flex: 1 }} size="large">
      <Text data-test={dataTest && `${dataTest}-email-description`}>
        <FormattedMessage id={I18nPageAuth.COMPONENTS_EMAIL_BLOCK_DESCRIPTION} />
      </Text>
      <Space direction="vertical" style={{ display: 'flex', flex: 1 }} size="middle">
        <EmailLoginButton
          data-test={dataTest && `${dataTest}-email`}
          onSelect={emailAuth.select}
          disabled={!emailAuth.isAvailable || (!!activeProvider && activeProvider !== 'email')}
          inProgress={activeProvider === 'email'}
        />
        <GoogleLoginButton
          data-test={dataTest && `${dataTest}-google`}
          onAuth={doGoogleAuth}
          onSelect={googleAuth.select}
          disabled={!googleAuth.isAvailable || (!!activeProvider && activeProvider !== 'google')}
          inProgress={activeProvider === 'google'}
        />
      </Space>
    </Space>
  );
};

const EmailBlockMemo: typeof EmailBlock = React.memo(
  withFeatureTogglesGuard((flags) => !flags?.disableWeb3Auth && !(flags?.disableEmailAuth && flags.disableGoogleAuth))(
    withHideIfOtherActive<EmailBlockProps>(
      (activeProvider) => !activeProvider || activeProvider.startsWith('email') || activeProvider === 'google',
    )(EmailBlock),
  ),
);

export default EmailBlockMemo;
