/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { CompanyDomainAPIModel } from './CompanyDomainAPIModel';
import {
    CompanyDomainAPIModelFromJSON,
    CompanyDomainAPIModelFromJSONTyped,
    CompanyDomainAPIModelToJSON,
} from './CompanyDomainAPIModel';

/**
 * 
 * @export
 * @interface CompanyDomainsResponseAPIModel
 */
export interface CompanyDomainsResponseAPIModel {
    /**
     * 
     * @type {string}
     * @memberof CompanyDomainsResponseAPIModel
     */
    activeDomain?: string;
    /**
     * 
     * @type {Array<CompanyDomainAPIModel>}
     * @memberof CompanyDomainsResponseAPIModel
     */
    domains?: Array<CompanyDomainAPIModel>;
}

/**
 * Check if a given object implements the CompanyDomainsResponseAPIModel interface.
 */
export function instanceOfCompanyDomainsResponseAPIModel(value: object): boolean {
    return true;
}

export function CompanyDomainsResponseAPIModelFromJSON(json: any): CompanyDomainsResponseAPIModel {
    return CompanyDomainsResponseAPIModelFromJSONTyped(json, false);
}

export function CompanyDomainsResponseAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDomainsResponseAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'activeDomain': json['activeDomain'] == null ? undefined : json['activeDomain'],
        'domains': json['domains'] == null ? undefined : ((json['domains'] as Array<any>).map(CompanyDomainAPIModelFromJSON)),
    };
}

export function CompanyDomainsResponseAPIModelToJSON(value?: CompanyDomainsResponseAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'activeDomain': value['activeDomain'],
        'domains': value['domains'] == null ? undefined : ((value['domains'] as Array<any>).map(CompanyDomainAPIModelToJSON)),
    };
}

