import React, { useContext, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { AddressItem } from '@/features/dictionary/blockchain/components';
import { useAsset } from '@/features/dictionary/blockchain/hooks';
import type { PayoutDestinationTableEditFormValues } from '@/features/payouts/components/EditPayoutForm/components/PayoutDestinationsTableInput/hocs';
import { PayoutTableRowFormValueContext } from '@/features/payouts/components/EditPayoutForm/components/PayoutDestinationsTableInput/hocs';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';
import { isSameAddress } from '@/infrastructure/utils/web3/address';

import type { EditableAddressCellProps } from './types';

const blockchainApis = [BlockchainAPITypeAPIModel.EVM];
const ItemProps = { label: undefined };

const EditableAddressCell: React.FC<EditableAddressCellProps> = ({
  'data-test': dataTest,
  record,
  asset: assetId,
  existItems,
}) => {
  const { formItemName } = useContext(PayoutTableRowFormValueContext);
  const { data: asset } = useAsset(assetId);
  const rules = useMemo(
    () => [
      {
        // eslint-disable-next-line @typescript-eslint/require-await
        validator: async (_: unknown, value: string) => {
          if (
            value
            && !isSameAddress(value, record?.address)
            && existItems?.find((item) => isSameAddress(item.address, value))
          ) {
            throw new Error();
          }
        },
        message: (
          <FormattedMessage
            id={
              I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_EDIT_ERRORS_ADDRESS_EXISTS
            }
          />
        ),
      },
    ],
    [existItems, record?.address],
  );

  const name = useMemo(() => [formItemName, nameof<PayoutDestinationTableEditFormValues>('address')], [formItemName]);

  return asset.data?.net ? (
    <AddressItem
      data-test={dataTest}
      name={name}
      blockchainApis={blockchainApis}
      net={asset.data.net}
      ItemProps={ItemProps}
      rules={rules}
    />
  ) : null;
};

const EditableAddressCellMemo = React.memo(EditableAddressCell);

export default EditableAddressCellMemo;
