import * as walletStandardFeatures from '@solana/wallet-standard-features';
import { getWalletAccountFeature } from '@wallet-standard/react';
import { getWalletAccountForUiWalletAccount_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } from '@wallet-standard/ui-registry';
import { toHex } from 'viem';

import { someOrFail } from '@/infrastructure/utils/functions';
import { withCancelledByUser } from '@/infrastructure/utils/ui';

import type { SignMessageFn } from './types';
import type { SolanaSignMessageMethod } from '@solana/wallet-standard-features';
import type { UiWalletAccount } from '@wallet-standard/ui';

export const createSignMessage = (account: UiWalletAccount): SignMessageFn =>
  withCancelledByUser(async (message: string) => {
    const { signMessage } = getWalletAccountFeature(account, walletStandardFeatures.SolanaSignMessage) as {
      signMessage: SolanaSignMessageMethod;
    };
    const result = await signMessage({
      message: Buffer.from(message),
      account: getWalletAccountForUiWalletAccount_DO_NOT_USE_OR_YOU_WILL_BE_FIRED(account),
    });
    const { signature, signedMessage } = someOrFail(result, () => new Error('No signature'))[0];
    return { signature: toHex(signature), message: new TextDecoder().decode(signedMessage) };
  });
