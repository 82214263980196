import { EditOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { defaultDialogFormLayout, defaultDialogFormTailLayout, FormattedMessage, Operation } from '@/components';
import { useCollectScheduleActions } from '@/features/collectable/hooks';
import { withCompanyPermissionsGuard } from '@/features/company/hocs';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { useDefaultNotification, useFormVisible, useNotification } from '@/hooks';
import { withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import { UpdateCollectScheduleForm } from './components';

import type { OperationSetCollectScheduleProps } from './types';

const OperationSetCollectSchedule: React.FC<OperationSetCollectScheduleProps> = ({
  'data-test': dataTest,
  style,
  className,
  mode = 'inline',
  schedule,
}) => {
  const { set: setAction } = useCollectScheduleActions();
  const formControl = useFormVisible(false);
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();

  const doSubmit = useMemo(
    () =>
      withSuppressError(
        withOnSuccess(
          formControl.withFormHide(
            withSuccess(
              withDefaultError(setAction.act, {
                message: () => (
                  <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_SET_SCHEDULE_FAILED_MESSAGE} />
                ),
              }),
              () => ({
                message: (
                  <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_SET_SCHEDULE_SUCCESS_MESSAGE} />
                ),
              }),
            ),
          ),
          () => goalReached(YMGoals.COLLECT_SCHEDULE_UPDATED),
        ),
      ),
    [formControl, setAction.act, withDefaultError, withSuccess],
  );
  const doShow = useCallback(() => {
    formControl.show();
    goalReached(YMGoals.COLLECT_SCHEDULE_INIT);
  }, [formControl]);

  return (
    <>
      {formControl.visible && (
        <Modal
          open
          maskClosable={false}
          footer={null}
          title={<FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_SET_SCHEDULE_FORM_TITLE} />}
          closable
          onCancel={formControl.hide}
        >
          <UpdateCollectScheduleForm
            values={schedule}
            layout={defaultDialogFormLayout}
            tailLayout={defaultDialogFormTailLayout}
            data-test={dataTest && `${dataTest}-form`}
            onReset={formControl.hide}
            onSubmit={doSubmit}
          />
        </Modal>
      )}
      <Operation
        title={<FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_SET_SCHEDULE_TITLE} />}
        tooltip={<FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_SET_SCHEDULE_TOOLTIP} />}
        disabled={!setAction.available}
        icon={<EditOutlined />}
        onClick={doShow}
        data-test={dataTest}
        style={style}
        className={className}
        mode={mode}
      />
    </>
  );
};

const OperationSetCollectScheduleMemo = React.memo(
  withCompanyPermissionsGuard('collectManagement')(OperationSetCollectSchedule),
);

export default OperationSetCollectScheduleMemo;
