/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DonationStatusRequestAPIModel
 */
export interface DonationStatusRequestAPIModel {
    /**
     * 
     * @type {boolean}
     * @memberof DonationStatusRequestAPIModel
     */
    isActive: boolean;
}

/**
 * Check if a given object implements the DonationStatusRequestAPIModel interface.
 */
export function instanceOfDonationStatusRequestAPIModel(value: object): boolean {
    if (!('isActive' in value)) return false;
    return true;
}

export function DonationStatusRequestAPIModelFromJSON(json: any): DonationStatusRequestAPIModel {
    return DonationStatusRequestAPIModelFromJSONTyped(json, false);
}

export function DonationStatusRequestAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DonationStatusRequestAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'isActive': json['isActive'],
    };
}

export function DonationStatusRequestAPIModelToJSON(value?: DonationStatusRequestAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isActive': value['isActive'],
    };
}

