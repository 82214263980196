import { Alert } from 'antd';
import { useContext, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import Web3WalletLink from '@/features/web3/components/Web3WalletLink';
import { walletTypes, type Web3WalletType } from '@/features/web3/components/Web3WalletLink/types';
import { useWeb3SolanaWallets } from '@/features/web3/hooks';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import Web3SolanaWalletContext from './context';

import type { Web3SolanaWalletContextType } from './context';
import type { UiWallet } from '@wallet-standard/react';
import type React from 'react';

type Web3SolanaWalletProps = { walletId: string } | { wallet: UiWallet };

const withWeb3SolanaWalletSelection = <
  Original extends TestableProps = TestableProps,
  Wrapper extends Original & Web3SolanaWalletProps = Original & Web3SolanaWalletProps,
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithWeb3SolanaWalletSelection',
  )((props) => {
    const dataTest = props['data-test'];
    const walletId = ('walletId' in props ? props.walletId : props.wallet.name).toLowerCase();
    const { wallets } = useWeb3SolanaWallets();
    const meta = wallets.find(({ id }) => id === walletId);
    const wallet = meta?.wallet;

    const context = useMemo<Web3SolanaWalletContextType | undefined>(
      () => (wallet ? { ...meta, wallet, account: meta.wallet?.accounts[0], isReady: !!meta.wallet } : undefined),
      [meta, wallet],
    );

    if (!wallet) {
      return (
        <Alert
          data-test={dataTest && `${dataTest}-notSupported`}
          showIcon
          message={
            <FormattedMessage
              id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_NOT_SUPPORTED}
              values={{
                provider: walletTypes.find((v) => v === walletId) ? (
                  <Web3WalletLink type={walletId as Web3WalletType} />
                ) : (
                  <b>{walletId}</b>
                ),
              }}
              tagName="span"
            />
          }
        />
      );
    }

    return (
      <Web3SolanaWalletContext.Provider value={context}>
        <Component {...props} />
      </Web3SolanaWalletContext.Provider>
    );
  });

const withWeb3SolanaWallet = <
  Original extends NonNullable<unknown> = NonNullable<unknown>,
  Wrapper extends Original & Web3SolanaWalletProps = Original & Web3SolanaWalletProps,
>(
  Component: React.ComponentType<Original>,
) => {
  const CreationComponent = withWeb3SolanaWalletSelection(Component);
  return namedHOC<Original, Wrapper>(
    Component,
    'WithWeb3SolanaWallet',
  )((props) => {
    const walletId = 'walletId' in props ? props.walletId : props.wallet.name;
    const ctx = useContext(Web3SolanaWalletContext);
    const isCtxDefined = ctx?.id === walletId;
    return isCtxDefined ? <Component {...props} /> : <CreationComponent {...props} walletId={walletId} />;
  });
};

export default withWeb3SolanaWallet;
