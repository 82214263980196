import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import { useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import useSubscriptionWeb3Actions from '@/features/subscriptions/hooks/useSubscriptionWeb3Actions';
import { Web3EVMWalletOperation } from '@/features/web3/components';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nCommon, I18nFeatureSubscriptions } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { mapStoredState } from '@/infrastructure/model';
import { emptyWith, noop, withOnSuccess } from '@/infrastructure/utils/functions';
import { isCancelled } from '@/infrastructure/utils/ui';
import SubscriptionLink from '@/pages/subscriptions/subscription-view/components/SubscriptionLink';

import type { OperationCancelSubscriptionWithConnectorProps } from './types';
import type { ReactNode } from 'react';

const OperationCancelSubscriptionWithConnector: React.FC<OperationCancelSubscriptionWithConnectorProps> = ({
  'data-test': dataTest,
  connector,
  extra: subscription,
  requiredAccount,
  onSuccess,
  withInProgress,
}) => {
  const { cancel: cancelAction } = useSubscriptionWeb3Actions(subscription.id);
  const blockchainState = useBlockchainSystemInfo(subscription.blockchain);
  const blockchain = useMemo(
    () =>
      mapStoredState(blockchainState.data, (state) => (state.apiType === BlockchainAPITypeAPIModel.EVM ? state : null)),
    [blockchainState.data],
  );
  const { withNotification } = useNotification();
  const options = useMemo(
    () => ({
      error: (error?: unknown) =>
        !isCancelled(error)
          ? {
              message: (
                <FormattedMessage
                  id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_FAILED_MESSAGE}
                />
              ),
              description: (
                <FormattedMessageWithMarkup
                  id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_FAILED_DESCRIPTION}
                  values={{ account: requiredAccount }}
                />
              ),
            }
          : undefined,
      success: () => ({
        message: (
          <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_SUCCESS_MESSAGE} />
        ),
        description: (
          <FormattedMessage
            id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_SUCCESS_DESCRIPTION}
            values={{ ln: (text: ReactNode) => <SubscriptionLink value={subscription.id} title={text} mode="text" /> }}
          />
        ),
      }),
    }),
    [requiredAccount, subscription.id],
  );
  const cancel = useMemo(
    () => withNotification(withOnSuccess((withInProgress ?? emptyWith)(cancelAction.act), onSuccess ?? noop), options),
    [withInProgress, withNotification, cancelAction.act, onSuccess, options],
  );
  const disabledMessage = useMemo(() => {
    switch (cancelAction.unavailabilityReason) {
      case 'invalid-status':
        return (
          <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_INVALID_STATUS} />
        );
      case 'no-data':
      case 'no-blockchain-data':
        return <FormattedMessage id={I18nCommon.MESSAGES_LOADING} />;
      case 'invalid-chain-id':
        return (
          <FormattedMessage
            id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_INVALID_CHAIN_ID}
            values={{ network: subscription.blockchain, chainId: blockchain.data?.chainId }}
          />
        );
      case undefined:
        return undefined;
    }
    return undefined;
  }, [blockchain.data?.chainId, cancelAction.unavailabilityReason, subscription.blockchain]);

  return (
    <Web3EVMWalletOperation
      data-test={dataTest}
      connector={connector}
      disabled={!cancelAction.available}
      disabledMessage={disabledMessage}
      requiredChain={useMemo(
        () =>
          blockchain.data?.chainId ? { id: blockchain.data.chainId, network: subscription.blockchain } : undefined,
        [blockchain.data?.chainId, subscription.blockchain],
      )}
      requiredAccount={requiredAccount}
      inProgress={cancelAction.inAction}
      mainAction={useMemo(
        () => ({
          runOnConnect: true,
          onAction: cancel,
          title: (
            <FormattedMessage
              id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_CONNECTOR_TITLE}
              values={{ connector: connector.name }}
            />
          ),
        }),
        [cancel, connector.name],
      )}
      showAccount
    />
  );
};

const OperationCancelSubscriptionWithConnectorMemo = React.memo(
  OperationCancelSubscriptionWithConnector,
) as typeof OperationCancelSubscriptionWithConnector;

export default OperationCancelSubscriptionWithConnectorMemo;
