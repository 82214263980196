import { css } from '@emotion/css';
import { Alert, Space } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import { useCompanyPermissionGranted } from '@/features/company/hooks';
import { useCompanySettingsActions } from '@/features/company-settings/hooks';
import type {
  AssetsFormEditableProps,
  AssetsInputRow} from '@/features/dictionary/blockchain/components';
import {
  BlockchainAPILabel,
 AssetLabel, AssetsForm } from '@/features/dictionary/blockchain/components';
import { useListAssets, useSelectedNetwork } from '@/features/dictionary/blockchain/hooks';
import { useMerchantGasWallets } from '@/features/gas-wallets/hooks';
import { useUser } from '@/features/user/hooks';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useNotification, useStateMountSafe } from '@/hooks';
import { combine } from '@/infrastructure/model';
import { onlyUnique, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';
import { notEmpty } from '@/infrastructure/utils/ts';
import { ProfileLink } from '@/pages/settings/profile/components';

import type { CompanyActiveAssetsEditFormProps } from './types';

const notificationOptions = {
  error: () => (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_COMPANY_ACTIVE_ASSETS_EDIT_FORM_MESSAGE_ERROR} />
  ),
  success: () => (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_COMPANY_ACTIVE_ASSETS_EDIT_FORM_MESSAGE_SUCCESS} />
  ),
};

const CompanyActiveAssetsEditForm: React.FC<CompanyActiveAssetsEditFormProps> = ({ onSubmit, silent, ...props }) => {
  const { 'data-test': dataTest, selected } = props;

  const { network } = useSelectedNetwork();
  const assetsState = useListAssets(network);
  const [selectedAssets, setSelectedAssets] = useStateMountSafe(selected);
  const walletsState = useMerchantGasWallets();

  const newBlockchains = useMemo(
    () =>
      combine(walletsState.data, assetsState.data, (gasWallets, assets) => {
        const walletBlockchains = gasWallets.map(({ bt }) => bt);
        return assets
          .filter(({ code }) => selectedAssets.includes(code))
          .map(({ blockchain }) => blockchain)
          .filter(notEmpty)
          .filter(onlyUnique)
          .filter((bt) => !walletBlockchains.includes(bt));
      }),
    [assetsState.data, selectedAssets, walletsState.data],
  );
  const onAssetSelection = useCallback(
    (newAssets: AssetsInputRow[]) =>
      setSelectedAssets(newAssets.filter(({ isActive }) => isActive).map(({ code }) => code)),
    [setSelectedAssets],
  );

  const { data: userState } = useUser();
  const disableSelection: NonNullable<AssetsFormEditableProps['disableSelection']> = useCallback(
    (asset) => {
      const apiType = asset.apiType;
      return (
        !!userState.data
        && !!apiType
        && !userState.data.addresses.find(({ addressType }) => addressType.includes(apiType)) && {
          message: (
            <FormattedMessageWithMarkup
              id={I18nFeatureCompanySettings.COMPONENTS_COMPANY_ACTIVE_ASSETS_EDIT_FORM_DISABLES_NO_USER_ADDRESS}
              values={{
                api: <BlockchainAPILabel api={apiType} copyable={false} />,
                asset: <AssetLabel value={asset} mode="short" />,
                link: (text: React.ReactNode) => <ProfileLink mode="text" title={text} />,
              }}
            />
          ),
        }
      );
    },
    [userState.data],
  );

  const permission = useCompanyPermissionGranted('gasWalletsManagement');

  const { updateActiveAssets: updateAssetsAction } = useCompanySettingsActions();
  const { withNotification } = useNotification();

  const doSubmit = useMemo(
    () =>
      withSuppressError(
        withOnSuccess(withNotification(updateAssetsAction.act, !silent ? notificationOptions : {}), onSubmit),
      ),
    [withNotification, silent, onSubmit, updateAssetsAction],
  );

  return (
    <Space
      direction="vertical"
      className={css`
        display: flex;
      `}
    >
      {!!newBlockchains.data?.length && permission && (
        <Alert
          data-test={dataTest && `${dataTest}-blockchains`}
          type="info"
          className={css`
            width: 100%;
          `}
          message={
            <FormattedMessageWithMarkup
              id={I18nFeatureCompanySettings.COMPONENTS_COMPANY_ACTIVE_ASSETS_EDIT_FORM_WALLETS_ALERT_MESSAGE}
              values={{ blockchains: <b>{newBlockchains.data.join(', ')}</b> }}
            />
          }
        />
      )}
      <AssetsForm
        {...props}
        readonly={false}
        onChange={onAssetSelection}
        onSubmit={doSubmit}
        description={
          <FormattedMessageWithMarkup
            id={I18nFeatureCompanySettings.COMPONENTS_COMPANY_ACTIVE_ASSETS_EDIT_FORM_DESCRIPTION}
          />
        }
        disableSelection={disableSelection}
      />
    </Space>
  );
};

const CompanyActiveAssetsEditFormMemo = React.memo(CompanyActiveAssetsEditForm);

export default CompanyActiveAssetsEditFormMemo;
