/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { CollectTaskAddressAPIModel } from './CollectTaskAddressAPIModel';
import {
    CollectTaskAddressAPIModelFromJSON,
    CollectTaskAddressAPIModelFromJSONTyped,
    CollectTaskAddressAPIModelToJSON,
} from './CollectTaskAddressAPIModel';
import type { CollectTaskStatusAPIModel } from './CollectTaskStatusAPIModel';
import {
    CollectTaskStatusAPIModelFromJSON,
    CollectTaskStatusAPIModelFromJSONTyped,
    CollectTaskStatusAPIModelToJSON,
} from './CollectTaskStatusAPIModel';

/**
 * 
 * @export
 * @interface CollectTaskAPIModel
 */
export interface CollectTaskAPIModel {
    /**
     * 
     * @type {string}
     * @memberof CollectTaskAPIModel
     */
    id: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof CollectTaskAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof CollectTaskAPIModel
     */
    asset: string;
    /**
     * 
     * @type {Date}
     * @memberof CollectTaskAPIModel
     */
    processAt: Date;
    /**
     * 
     * @type {CollectTaskStatusAPIModel}
     * @memberof CollectTaskAPIModel
     */
    status: CollectTaskStatusAPIModel;
    /**
     * 
     * @type {Array<CollectTaskAddressAPIModel>}
     * @memberof CollectTaskAPIModel
     */
    addresses?: Array<CollectTaskAddressAPIModel>;
    /**
     * 
     * @type {string}
     * @memberof CollectTaskAPIModel
     */
    collectedAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectTaskAPIModel
     */
    gwtId?: string;
}

/**
 * Check if a given object implements the CollectTaskAPIModel interface.
 */
export function instanceOfCollectTaskAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('asset' in value)) return false;
    if (!('processAt' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function CollectTaskAPIModelFromJSON(json: any): CollectTaskAPIModel {
    return CollectTaskAPIModelFromJSONTyped(json, false);
}

export function CollectTaskAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectTaskAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'asset': json['asset'],
        'processAt': (new Date(json['processAt'])),
        'status': CollectTaskStatusAPIModelFromJSON(json['status']),
        'addresses': json['addresses'] == null ? undefined : ((json['addresses'] as Array<any>).map(CollectTaskAddressAPIModelFromJSON)),
        'collectedAmount': json['collectedAmount'] == null ? undefined : json['collectedAmount'],
        'gwtId': json['gwtId'] == null ? undefined : json['gwtId'],
    };
}

export function CollectTaskAPIModelToJSON(value?: CollectTaskAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'asset': value['asset'],
        'processAt': ((value['processAt']).toISOString()),
        'status': CollectTaskStatusAPIModelToJSON(value['status']),
        'addresses': value['addresses'] == null ? undefined : ((value['addresses'] as Array<any>).map(CollectTaskAddressAPIModelToJSON)),
        'collectedAmount': value['collectedAmount'],
        'gwtId': value['gwtId'],
    };
}

