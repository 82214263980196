import React from 'react';

import { FormattedMessageWithMarkup, KYBMailTo } from '@/components';
import { useActiveCompany } from '@/features/company/hooks';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';

import type { KYBRequiredBannerDescriptionMediumProps } from './types';

const KYBRequiredBannerDescriptionMedium: React.FC<KYBRequiredBannerDescriptionMediumProps> = () => {
  const { data } = useActiveCompany();
  return data.data ? (
    <FormattedMessageWithMarkup
      id={I18nFeatureCompany.COMPONENTS_KYB_REQUIRED_BANNER_MEDIUM_DESCRIPTION}
      values={{ email: <KYBMailTo companyId={data.data.id} /> }}
    />
  ) : (
    <FormattedMessageWithMarkup id={I18nFeatureCompany.COMPONENTS_KYB_REQUIRED_BANNER_SHORT_DESCRIPTION} />
  );
};

const KYBRequiredBannerDescriptionMediumMemo = React.memo(KYBRequiredBannerDescriptionMedium);

export default KYBRequiredBannerDescriptionMediumMemo;
