/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { DateTimeRangeAPIModel } from './DateTimeRangeAPIModel';
import {
    DateTimeRangeAPIModelFromJSON,
    DateTimeRangeAPIModelFromJSONTyped,
    DateTimeRangeAPIModelToJSON,
} from './DateTimeRangeAPIModel';
import type { ReportStatusAPIModel } from './ReportStatusAPIModel';
import {
    ReportStatusAPIModelFromJSON,
    ReportStatusAPIModelFromJSONTyped,
    ReportStatusAPIModelToJSON,
} from './ReportStatusAPIModel';

/**
 * 
 * @export
 * @interface MerchantReportPredicatesAPIModel
 */
export interface MerchantReportPredicatesAPIModel {
    /**
     * 
     * @type {Array<ReportStatusAPIModel>}
     * @memberof MerchantReportPredicatesAPIModel
     */
    statuses?: Array<ReportStatusAPIModel>;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof MerchantReportPredicatesAPIModel
     */
    createdRange?: DateTimeRangeAPIModel;
    /**
     * 
     * @type {Array<string>}
     * @memberof MerchantReportPredicatesAPIModel
     */
    templates?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MerchantReportPredicatesAPIModel
     */
    ids?: Array<string>;
}

/**
 * Check if a given object implements the MerchantReportPredicatesAPIModel interface.
 */
export function instanceOfMerchantReportPredicatesAPIModel(value: object): boolean {
    return true;
}

export function MerchantReportPredicatesAPIModelFromJSON(json: any): MerchantReportPredicatesAPIModel {
    return MerchantReportPredicatesAPIModelFromJSONTyped(json, false);
}

export function MerchantReportPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantReportPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'statuses': json['statuses'] == null ? undefined : ((json['statuses'] as Array<any>).map(ReportStatusAPIModelFromJSON)),
        'createdRange': json['createdRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['createdRange']),
        'templates': json['templates'] == null ? undefined : json['templates'],
        'ids': json['ids'] == null ? undefined : json['ids'],
    };
}

export function MerchantReportPredicatesAPIModelToJSON(value?: MerchantReportPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'statuses': value['statuses'] == null ? undefined : ((value['statuses'] as Array<any>).map(ReportStatusAPIModelToJSON)),
        'createdRange': DateTimeRangeAPIModelToJSON(value['createdRange']),
        'templates': value['templates'],
        'ids': value['ids'],
    };
}

