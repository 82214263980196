import React, { useCallback } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import type { Web3EVMWalletOperationClientAction } from '@/features/web3/components';
import { Web3EVMWalletOperation } from '@/features/web3/components';
import { withWeb3EVMConnector } from '@/features/web3/hocs';
import { I18nHocs } from '@/generated/i18n/i18n';
import type { Cancellable } from '@/infrastructure/utils/ui';

import type { OperationConfirmOwnershipProps } from './types';

const OperationConfirmOwnership: React.FC<OperationConfirmOwnershipProps> = ({
  connectorId,
  onSubmit,
  requiredAccount,
  disabled,
  'data-test': dataTest,
}) => {
  const doConfirmOwnership = useCallback<Web3EVMWalletOperationClientAction>(
    async ({ account, client }, cancellation?: Cancellable) => {
      if (account) await onSubmit(client, cancellation);
    },
    [onSubmit],
  );

  return (
    <Web3EVMWalletOperation
      data-test={dataTest}
      connectorId={connectorId}
      requiredAccount={{
        value: requiredAccount,
        title: (
          <FormattedMessageWithMarkup
            id={I18nHocs.WALLET_OWNERSHIP_ACCOUNT_TOOLTIP}
            values={{ address: <b>{requiredAccount}</b> }}
          />
        ),
      }}
      disabled={disabled}
      mainAction={{
        withClient: true,
        onAction: doConfirmOwnership,
        runOnConnect: true,
        title: <FormattedMessage id={I18nHocs.WALLET_OWNERSHIP_CREATE_SUBMIT_TITLE} />,
        'data-test': dataTest && `${dataTest}-submit`,
      }}
      cancellation={{ title: <FormattedMessage id={I18nHocs.WALLET_OWNERSHIP_CREATE_CANCEL_TITLE} /> }}
    />
  );
};

const OperationConfirmOwnershipHOCed = withWeb3EVMConnector(OperationConfirmOwnership);

const OperationConfirmOwnershipMemo = React.memo(
  OperationConfirmOwnershipHOCed,
) as typeof OperationConfirmOwnershipHOCed;

export default OperationConfirmOwnershipMemo;
