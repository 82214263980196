/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainAPITypeAPIModel } from './BlockchainAPITypeAPIModel';
import {
    BlockchainAPITypeAPIModelFromJSON,
    BlockchainAPITypeAPIModelFromJSONTyped,
    BlockchainAPITypeAPIModelToJSON,
} from './BlockchainAPITypeAPIModel';

/**
 * 
 * @export
 * @interface Web3AddressAuthRequestAPIModel
 */
export interface Web3AddressAuthRequestAPIModel {
    /**
     * 
     * @type {string}
     * @memberof Web3AddressAuthRequestAPIModel
     */
    address: string;
    /**
     * 
     * @type {BlockchainAPITypeAPIModel}
     * @memberof Web3AddressAuthRequestAPIModel
     */
    addressType: BlockchainAPITypeAPIModel;
}

/**
 * Check if a given object implements the Web3AddressAuthRequestAPIModel interface.
 */
export function instanceOfWeb3AddressAuthRequestAPIModel(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('addressType' in value)) return false;
    return true;
}

export function Web3AddressAuthRequestAPIModelFromJSON(json: any): Web3AddressAuthRequestAPIModel {
    return Web3AddressAuthRequestAPIModelFromJSONTyped(json, false);
}

export function Web3AddressAuthRequestAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Web3AddressAuthRequestAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'addressType': BlockchainAPITypeAPIModelFromJSON(json['addressType']),
    };
}

export function Web3AddressAuthRequestAPIModelToJSON(value?: Web3AddressAuthRequestAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'addressType': BlockchainAPITypeAPIModelToJSON(value['addressType']),
    };
}

