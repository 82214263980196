import type {
  AddressAssetHistoryBalanceAPIModel,
  AssetFullMetaAPIModel,
  BlockchainAPITypeAPIModel,
  BlockchainMetaAPIModel,
  BlockchainNetworkTypeAPIModel,
  BlockchainTypeAPIModel,
  BTCBlockchainInfoAPIModel,
  TronBlockchainInfoAPIModel,
  Web3BlockchainInfoAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { InitState, LoadingStateWithDirty } from '@/infrastructure/model';
import type { FullState } from '@/infrastructure/model/full/types';
import type { ListSortBy } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';

import type BigNumber from 'bignumber.js';

export const NAMESPACE = 'blockchain';

export type Asset = AssetFullMetaAPIModel;

export enum AssetSortByColumn {
  name = 'name',
}

export interface AssetWithNetwork extends Asset {
  net?: BlockchainNetworkTypeAPIModel;
  apiType?: BlockchainAPITypeAPIModel;
}

export type AssetFilterPredicate = object;

export type AssetsListState = FullState<Asset, AssetFilterPredicate, AssetSortByColumn>;

export type AssetsSortBy = ListSortBy<AssetSortByColumn>;

export interface BlockchainLinks {
  api?: string;
  apiAuth?: boolean;
  app?: string;
}

export type BlockchainInfo = BlockchainMetaAPIModel;

export enum BlockchainInfoSortByColumn {
  blockchain = 'blockchain',
}

export type BlockchainInfoFilterPredicate = object;

export type BlockchainInfoListState = FullState<
  BlockchainInfo,
  BlockchainInfoFilterPredicate,
  BlockchainInfoSortByColumn
>;

export type BlockchainInfoSortBy = ListSortBy<BlockchainInfoSortByColumn>;

export interface AssetAddress {
  assetId: string;
  address: string;
}

export interface AssetAddressHistoryKey extends AssetAddress {
  blockNum: string;
}

export interface BlockchainAddress {
  bt: BlockchainTypeAPIModel;
  address: string;
}

export interface FetchBlockchainAddressDataPayload extends BlockchainAddress {
  force?: boolean;
}

export interface FetchAssetAddressDataPayload extends AssetAddress {
  force?: boolean;
}

export interface FetchAssetAddressHistoryBalancePayload extends AssetAddressHistoryKey {
  force?: boolean;
}

export interface FetchAllowancePayload extends AllowanceId {
  force?: boolean;
}

export interface BalanceData {
  updatedAt: Date;
  amount: BigNumber;
}

export interface AllowanceId {
  assetId: string;
  owner: string;
  spender: string;
}

export interface AssetHistoryBalanceData extends Omit<AddressAssetHistoryBalanceAPIModel, 'balance'> {
  balance: BigNumber;
}

export interface Web3BlockchainSystemInfo extends Web3BlockchainInfoAPIModel {
  apiType: BlockchainAPITypeAPIModel.EVM;
}

export interface BTCBlockchainSystemInfo extends BTCBlockchainInfoAPIModel {
  apiType: BlockchainAPITypeAPIModel.BTC;
}

export interface TronBlockchainSystemInfo extends TronBlockchainInfoAPIModel {
  apiType: BlockchainAPITypeAPIModel.Tron;
}

export interface SolanaBlockchainSystemInfo {
  apiType: BlockchainAPITypeAPIModel.Solana;
}

export type BlockchainSystemInfo = {
  bt: BlockchainTypeAPIModel;
  forwarder: 'supported' | 'not-supported' | 'required';
  links: BlockchainLinks;
  isEnabled: boolean;
} & (BTCBlockchainSystemInfo | Web3BlockchainSystemInfo | TronBlockchainSystemInfo | SolanaBlockchainSystemInfo);

export type BlockchainSystemInfoState = LoadingStateWithDirty<
  Partial<Record<BlockchainTypeAPIModel, BlockchainSystemInfo>>
>;

export interface FetchBlockchainAssetPayload {
  asset: string;
  api?: BlockchainAPITypeAPIModel | BlockchainAPITypeAPIModel[];
  force?: boolean;
}

export type FetchBlockchainAPIPayload = {
  api?: BlockchainAPITypeAPIModel | BlockchainAPITypeAPIModel[];
  force?: boolean;
} & ({ bt: BlockchainTypeAPIModel; asset?: undefined } | { bt?: undefined; asset: string });

export const blockchainDefaultSortBy: BlockchainInfoSortBy = { [BlockchainInfoSortByColumn.blockchain]: 'DESC' };
export const assetsDefaultSortBy: AssetsSortBy = { [AssetSortByColumn.name]: 'DESC' };

export interface BlockchainState {
  selected: InitState<BlockchainNetworkTypeAPIModel>;

  assets: AssetsListState;
  allowance: SingleState<bigint>;
  blockchains: BlockchainInfoListState;
  tokenBalance: SingleState<BalanceData>; // key - AssetAddress
  tokenHistoryBalance: SingleState<AssetHistoryBalanceData>; // key - AssetAddressHistoryKey
  nativeBalance: SingleState<BalanceData>; // key - BlockchainAddress
  bcSystemInfo: BlockchainSystemInfoState;
  contractExistence: SingleState<boolean>; // key - BlockchainAddress
}
