import { css } from '@emotion/css';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { withWeb3SolanaWallet } from '@/features/web3/hocs';
import { useWeb3SolanaWallet } from '@/features/web3/hooks';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import { noop, withLogError, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';
import { cancellable } from '@/infrastructure/utils/ui';

import Web3WalletInProgress from '../Web3WalletInProgress';
import Web3WalletOperationAccount from '../Web3WalletOperationAccount';
import Web3WalletOperationButtons from '../Web3WalletOperationButtons';

import { Web3SolanaWalletConnect, Web3SolanaWalletDisconnect } from './components';

import type { Web3SolanaWalletConnectProps } from './components';
import type { Web3SolanaWalletOperationProps } from './types';
import type { UiWalletAccount } from '@wallet-standard/ui';

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
};

const Web3SolanaWalletOperation = <Result = unknown,>({
  'data-test': dataTest,
  mainAction,
  onSuccess,
  onlyLocal,
  cancellation: supportCancellation,
  showAccount,
  disabled,
  disabledMessage,
  requiredChain,
  requiredAccount: baseRequiredAccount,
  inProgress,
  inProgressMode,
  withConnect,
}: Web3SolanaWalletOperationProps<Result>) => {
  const requiredAccountValue =
    baseRequiredAccount && 'title' in baseRequiredAccount ? baseRequiredAccount.value : baseRequiredAccount;

  const { id, wallet, account, disconnectable, isConnecting } = useWeb3SolanaWallet();

  const [onCancel, setOnCancel] = useStateMountSafe<(() => void) | undefined>();

  const doAct = useCallback(
    async (actionAccount?: UiWalletAccount) => {
      const cancellation =
        // eslint-disable-next-line no-nested-ternary
        (supportCancellation
          ? typeof supportCancellation === 'function'
            ? supportCancellation()
            : typeof supportCancellation === 'object'
              && (supportCancellation.create?.() ?? cancellable((cancel) => setOnCancel(() => cancel)))
          : undefined) || undefined;

      if (!mainAction.withAccount) {
        return withOnSuccess(mainAction.onAction, onSuccess ?? noop)(actionAccount, cancellation).finally(() =>
          setOnCancel(undefined),
        );
      } else if (actionAccount)
        return withOnSuccess(mainAction.onAction, onSuccess ?? noop)(actionAccount, cancellation).finally(() =>
          setOnCancel(undefined),
        );
    },
    [mainAction.onAction, mainAction.withAccount, onSuccess, setOnCancel, supportCancellation],
  );

  const onAction = useMemo(() => withLogError(async () => doAct(wallet.accounts[0])), [doAct, wallet.accounts]);

  const onConnect = useMemo(
    () =>
      withSuppressError(async (newAccount?: UiWalletAccount) => {
        if (mainAction.runOnConnect) await doAct(newAccount);
      }),
    [doAct, mainAction.runOnConnect],
  );

  if (!account) {
    const connectProgressMode: Web3SolanaWalletConnectProps['inProgressMode'] =
      inProgressMode === 'view' || typeof inProgressMode === 'object' ? 'view' : inProgressMode;
    return (
      <Web3SolanaWalletConnect
        data-test={dataTest && `${dataTest}-connect`}
        disabled={disabled}
        onlyLocal={onlyLocal}
        wallet={wallet}
        onConnect={onConnect}
        requiredAccount={requiredAccountValue}
        withConnect={withConnect}
        inProgressMode={connectProgressMode}
        cancellation={supportCancellation}
      />
    );
  }

  if (requiredChain && account.chains.includes(`solana:${requiredChain.id}`)) {
    return (
      <Web3SolanaWalletOperation
        data-test={dataTest}
        mainAction={mainAction}
        disabled
        showAccount={showAccount}
        disabledMessage={
          <FormattedMessage
            id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_SWITCH_NETWORK_MANUAL_TITLE}
            values={{ network: requiredChain.network }}
          />
        }
        inProgressMode={inProgressMode}
      />
    );
  }

  if (requiredAccountValue && account.address !== requiredAccountValue) {
    return (
      <Web3SolanaWalletOperation
        data-test={dataTest}
        mainAction={mainAction}
        disabled
        showAccount={showAccount}
        disabledMessage={
          (baseRequiredAccount && typeof baseRequiredAccount === 'object' && baseRequiredAccount.title) || (
            <FormattedMessage
              id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_SWITCH_ACCOUNT_MANUAL_TITLE}
              values={{ account: requiredAccountValue }}
            />
          )
        }
        inProgressMode={inProgressMode}
      />
    );
  }

  if (inProgress && (inProgressMode === 'view' || typeof inProgressMode === 'object')) {
    const Description =
      typeof inProgressMode === 'object' && inProgressMode.Description ? (
        <inProgressMode.Description provider={id} account={account.address} />
      ) : (
        <FormattedMessage
          id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_DESCRIPTION}
          values={{ provider: wallet.name }}
        />
      );
    const cancelTitle = typeof inProgressMode === 'object' && inProgressMode.cancel;
    return (
      <Web3WalletInProgress
        data-test={dataTest}
        wallet={{ id, name: wallet.name }}
        cancel={onCancel && { onAction: onCancel, title: cancelTitle }}
        message={Description}
      />
    );
  }

  return (
    <div className={styles.container}>
      <Web3WalletOperationButtons
        data-test={dataTest}
        walletId={id}
        cancellationTitle={
          typeof supportCancellation === 'object' && 'title' in supportCancellation && supportCancellation.title
        }
        disconnectable={disconnectable}
        disabled={disabled}
        disabledMessage={disabledMessage}
        inProgress={inProgress || isConnecting}
        title={mainAction.title}
        onAction={onAction}
        onCancel={onCancel}
        Disconnect={Web3SolanaWalletDisconnect}
      />
      {showAccount && (
        <Web3WalletOperationAccount
          data-test={dataTest && `${dataTest}-account`}
          account={account.address}
          tooltip={typeof showAccount === 'object' && 'tooltip' in showAccount && showAccount.tooltip}
        />
      )}
    </div>
  );
};

const Web3SolanaWalletOperationWithConnector = withWeb3SolanaWallet(Web3SolanaWalletOperation);

const Web3SolanaWalletOperationMemo = React.memo(
  Web3SolanaWalletOperationWithConnector,
) as typeof Web3SolanaWalletOperationWithConnector;

export default Web3SolanaWalletOperationMemo;
