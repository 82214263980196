import { Card, Space, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAddress } from 'viem';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import type { OperationConfirmOwnershipProps } from '@/features/merchant-wallets/components';
import { OperationConfirmOwnership } from '@/features/merchant-wallets/components';
import { useMerchantWalletsActions } from '@/features/merchant-wallets/hooks';
import { useUserAddress } from '@/features/user/hooks';
import { withWeb3EVMContext } from '@/features/web3/hocs';
import { useWeb3EVMConnectors } from '@/features/web3/hooks';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nHocs } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useNotification, useSharedStyles } from '@/hooks';
import { withOnSuccess } from '@/infrastructure/utils/functions';
import { isCancelled } from '@/infrastructure/utils/ui';

import type { MerchantWalletOwnershipCardProps } from './types';
import type { CardProps } from 'antd/es/card/Card';

const { Text } = Typography;

const notificationOptions = {
  success: () => ({
    message: <FormattedMessage id={I18nHocs.WALLET_OWNERSHIP_MESSAGE_SUCCESS} />,
  }),
  error: (e: unknown) =>
    !isCancelled(e)
      ? { message: <FormattedMessage id={I18nHocs.WALLET_OWNERSHIP_MESSAGE_GENERIC_ERROR} /> }
      : undefined,
};

const cardStyles: CardProps['styles'] = { header: { textAlign: 'center' } };

const MerchantWalletOwnershipCard: React.FC<MerchantWalletOwnershipCardProps> = ({ 'data-test': dataTest }) => {
  const { modalCard } = useSharedStyles();
  const navigate = useNavigate();
  const { withNotification } = useNotification();

  const { confirmOwnership } = useMerchantWalletsActions();
  const { submitting, withSubmitting } = useErrorSafeSubmitting();
  const doConfirm = useMemo<OperationConfirmOwnershipProps['onSubmit']>(
    () =>
      withSubmitting(
        withOnSuccess(
          withNotification(
            async (client, cancellation) => confirmOwnership.act(client, { cancellation }),
            notificationOptions,
          ),
          // FIXME: dirty hack: there are no way to cleanup wallet web-socket connections so let's reload the page...
          () => navigate(0),
        ),
      ),
    [navigate, confirmOwnership, withNotification, withSubmitting],
  );

  const { orderedConnectors } = useWeb3EVMConnectors();
  const address = useUserAddress(BlockchainAPITypeAPIModel.EVM).data.data?.value;

  return address ? (
    <Card
      data-test={dataTest && `${dataTest}-card`}
      title={<FormattedMessage id={I18nHocs.WALLET_OWNERSHIP_TITLE} />}
      className={modalCard}
      styles={cardStyles}
    >
      <Space direction="vertical" style={{ display: 'flex', flex: 1 }} size="large">
        <Text data-test={dataTest && `${dataTest}-description`}>
          <FormattedMessageWithMarkup id={I18nHocs.WALLET_OWNERSHIP_DESCRIPTION} values={{ address }} />
        </Text>
        <Space direction="vertical" style={{ display: 'flex', flex: 1 }} size="middle">
          {orderedConnectors.map((connector) => (
            <OperationConfirmOwnership
              key={connector.id}
              data-test={dataTest && `${dataTest}-provider-${connector.id}`}
              connectorId={connector.id}
              requiredAccount={getAddress(address)}
              disabled={submitting}
              onSubmit={doConfirm}
            />
          ))}
        </Space>
      </Space>
    </Card>
  ) : null;
};

const MerchantWalletOwnershipCardContexted = withWeb3EVMContext(MerchantWalletOwnershipCard, React.Fragment);

const MerchantWalletOwnershipCardMemo = React.memo(
  MerchantWalletOwnershipCardContexted,
) as typeof MerchantWalletOwnershipCardContexted;

export default MerchantWalletOwnershipCardMemo;
