/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  MerchantReportAPIModel,
  MerchantWalletRunningBalanceAPIModel,
  MerchantWalletRunningBalanceFilterAPIModel,
  MerchantWalletRunningBalancePredicatesAPIModel,
  SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    MerchantReportAPIModelFromJSON,
    MerchantReportAPIModelToJSON,
    MerchantWalletRunningBalanceAPIModelFromJSON,
    MerchantWalletRunningBalanceAPIModelToJSON,
    MerchantWalletRunningBalanceFilterAPIModelFromJSON,
    MerchantWalletRunningBalanceFilterAPIModelToJSON,
    MerchantWalletRunningBalancePredicatesAPIModelFromJSON,
    MerchantWalletRunningBalancePredicatesAPIModelToJSON,
    SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModelFromJSON,
    SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModelToJSON,
} from '../models/index';

export interface ExportMerchantWalletRunningBalancesRequest {
    merchantWalletRunningBalancePredicatesAPIModel: MerchantWalletRunningBalancePredicatesAPIModel;
}

export interface GetMerchantWalletRunningBalanceRequest {
    txId: string;
}

export interface SearchMerchantWalletRunningBalancesRequest {
    merchantWalletRunningBalanceFilterAPIModel: MerchantWalletRunningBalanceFilterAPIModel;
    withTotal?: boolean;
}

/**
 * MerchantWalletRunningBalanceApi - interface
 * 
 * @export
 * @interface MerchantWalletRunningBalanceApiInterface
 */
export interface MerchantWalletRunningBalanceApiInterface {
    /**
     * Exports merchant wallets running balances
     * @param {MerchantWalletRunningBalancePredicatesAPIModel} merchantWalletRunningBalancePredicatesAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantWalletRunningBalanceApiInterface
     */
    exportMerchantWalletRunningBalancesRaw(requestParameters: ExportMerchantWalletRunningBalancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>>;

    /**
     * Exports merchant wallets running balances
     */
    exportMerchantWalletRunningBalances(merchantWalletRunningBalancePredicatesAPIModel: MerchantWalletRunningBalancePredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel>;

    /**
     * Returns merchant wallet running balance by id
     * @param {string} txId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantWalletRunningBalanceApiInterface
     */
    getMerchantWalletRunningBalanceRaw(requestParameters: GetMerchantWalletRunningBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletRunningBalanceAPIModel>>;

    /**
     * Returns merchant wallet running balance by id
     */
    getMerchantWalletRunningBalance(txId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletRunningBalanceAPIModel>;

    /**
     * Returns merchant wallet running balance according to filter
     * @param {MerchantWalletRunningBalanceFilterAPIModel} merchantWalletRunningBalanceFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantWalletRunningBalanceApiInterface
     */
    searchMerchantWalletRunningBalancesRaw(requestParameters: SearchMerchantWalletRunningBalancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModel>>;

    /**
     * Returns merchant wallet running balance according to filter
     */
    searchMerchantWalletRunningBalances(merchantWalletRunningBalanceFilterAPIModel: MerchantWalletRunningBalanceFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModel>;

}

/**
 * 
 */
export class MerchantWalletRunningBalanceApi extends runtime.BaseAPI implements MerchantWalletRunningBalanceApiInterface {

    /**
     * Exports merchant wallets running balances
     */
    async exportMerchantWalletRunningBalancesRaw(requestParameters: ExportMerchantWalletRunningBalancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>> {
        if (requestParameters['merchantWalletRunningBalancePredicatesAPIModel'] == null) {
            throw new runtime.RequiredError(
                'merchantWalletRunningBalancePredicatesAPIModel',
                'Required parameter "merchantWalletRunningBalancePredicatesAPIModel" was null or undefined when calling exportMerchantWalletRunningBalances().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-wallet-balance/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MerchantWalletRunningBalancePredicatesAPIModelToJSON(requestParameters['merchantWalletRunningBalancePredicatesAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantReportAPIModelFromJSON(jsonValue));
    }

    /**
     * Exports merchant wallets running balances
     */
    async exportMerchantWalletRunningBalances(merchantWalletRunningBalancePredicatesAPIModel: MerchantWalletRunningBalancePredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel> {
        const response = await this.exportMerchantWalletRunningBalancesRaw({ merchantWalletRunningBalancePredicatesAPIModel: merchantWalletRunningBalancePredicatesAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Returns merchant wallet running balance by id
     */
    async getMerchantWalletRunningBalanceRaw(requestParameters: GetMerchantWalletRunningBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletRunningBalanceAPIModel>> {
        if (requestParameters['txId'] == null) {
            throw new runtime.RequiredError(
                'txId',
                'Required parameter "txId" was null or undefined when calling getMerchantWalletRunningBalance().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-wallet-balance/{txId}`.replace(`{${"txId"}}`, encodeURIComponent(String(requestParameters['txId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantWalletRunningBalanceAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns merchant wallet running balance by id
     */
    async getMerchantWalletRunningBalance(txId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletRunningBalanceAPIModel> {
        const response = await this.getMerchantWalletRunningBalanceRaw({ txId: txId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns merchant wallet running balance according to filter
     */
    async searchMerchantWalletRunningBalancesRaw(requestParameters: SearchMerchantWalletRunningBalancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModel>> {
        if (requestParameters['merchantWalletRunningBalanceFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'merchantWalletRunningBalanceFilterAPIModel',
                'Required parameter "merchantWalletRunningBalanceFilterAPIModel" was null or undefined when calling searchMerchantWalletRunningBalances().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-wallet-balance/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MerchantWalletRunningBalanceFilterAPIModelToJSON(requestParameters['merchantWalletRunningBalanceFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns merchant wallet running balance according to filter
     */
    async searchMerchantWalletRunningBalances(merchantWalletRunningBalanceFilterAPIModel: MerchantWalletRunningBalanceFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModel> {
        const response = await this.searchMerchantWalletRunningBalancesRaw({ merchantWalletRunningBalanceFilterAPIModel: merchantWalletRunningBalanceFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

}
