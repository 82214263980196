/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { WithdrawalPeriodAPIModel } from './WithdrawalPeriodAPIModel';
import {
    WithdrawalPeriodAPIModelFromJSON,
    WithdrawalPeriodAPIModelFromJSONTyped,
    WithdrawalPeriodAPIModelToJSON,
} from './WithdrawalPeriodAPIModel';

/**
 * 
 * @export
 * @interface GetWithdrawalScheduleResponseAPIModel
 */
export interface GetWithdrawalScheduleResponseAPIModel {
    /**
     * 
     * @type {WithdrawalPeriodAPIModel}
     * @memberof GetWithdrawalScheduleResponseAPIModel
     */
    period: WithdrawalPeriodAPIModel;
    /**
     * 
     * @type {number}
     * @memberof GetWithdrawalScheduleResponseAPIModel
     */
    unifiedMinAmount: number;
}

/**
 * Check if a given object implements the GetWithdrawalScheduleResponseAPIModel interface.
 */
export function instanceOfGetWithdrawalScheduleResponseAPIModel(value: object): boolean {
    if (!('period' in value)) return false;
    if (!('unifiedMinAmount' in value)) return false;
    return true;
}

export function GetWithdrawalScheduleResponseAPIModelFromJSON(json: any): GetWithdrawalScheduleResponseAPIModel {
    return GetWithdrawalScheduleResponseAPIModelFromJSONTyped(json, false);
}

export function GetWithdrawalScheduleResponseAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetWithdrawalScheduleResponseAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'period': WithdrawalPeriodAPIModelFromJSON(json['period']),
        'unifiedMinAmount': json['unifiedMinAmount'],
    };
}

export function GetWithdrawalScheduleResponseAPIModelToJSON(value?: GetWithdrawalScheduleResponseAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'period': WithdrawalPeriodAPIModelToJSON(value['period']),
        'unifiedMinAmount': value['unifiedMinAmount'],
    };
}

