import { useCallback } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { createPayout } from '@/features/payouts/actions';
import type { NewPayout } from '@/features/payouts/types';
import { useUserAddress } from '@/features/user/hooks';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import type { UsePayoutsActions } from './types';

export default function usePayoutsActions(): UsePayoutsActions {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data: addressState } = useUserAddress(BlockchainAPITypeAPIModel.EVM);

  const isCreating = useActionPending(createPayout);
  const createAction: UsePayoutsActions['create']['act'] = useCallback(
    (data: NewPayout) => withExtractDataDispatch(createPayout)(data),
    [withExtractDataDispatch],
  );
  // eslint-disable-next-line no-nested-ternary
  const unavailabilityReason: UsePayoutsActions['create']['unavailabilityReason'] = !addressState.data?.value
    ? !addressState.data
      ? 'no-data'
      : 'no-user-address'
    : undefined;
  const createHook: UsePayoutsActions['create'] = {
    act: createAction,
    inAction: isCreating,
    available: true,
    unavailabilityReason,
  };

  return { create: createHook };
}
