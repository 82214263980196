import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { extractGasHistoryId } from '@/features/gas-history/utils';
import { createNormalizedListReducers } from '@/infrastructure/model/list/reducers';
import { defaultListState } from '@/infrastructure/model/list/types';

import {
  markGasHistoryDirty,
  storeMultipleGasHistory,
  storeGasHistory,
  storeGasHistoryListData,
  storeGasHistoryListParameters,
  markGasHistoryListDirty,
} from './actions';
import { defaultGasHistorySortBy } from './types';

import type { GasHistoryState } from './types';
import type { Draft } from 'immer';

const initialState: GasHistoryState = {
  entities: {},
  list: defaultListState({}, defaultGasHistorySortBy),
};

const {
  storeMultipleGasHistoryReducer,
  storeGasHistoryReducer,
  markGasHistoryDirtyReducer,
  storeGasHistoryListParametersReducer,
  markGasHistoryListDirtyReducer,
  storeGasHistoryListDataReducer,
} = createNormalizedListReducers(
  'GasHistory' as const,
  (state: Draft<GasHistoryState>) => state.list,
  (state, list) => ({ ...state, list }),
  initialState.list,
  (state) => state.entities,
  (state, entities) => ({ ...state, entities }),
  extractGasHistoryId,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(storeGasHistoryListData, storeGasHistoryListDataReducer)
    .addCase(storeGasHistoryListParameters, storeGasHistoryListParametersReducer)
    .addCase(markGasHistoryListDirty, markGasHistoryListDirtyReducer)

    .addCase(markGasHistoryDirty, markGasHistoryDirtyReducer)
    .addCase(storeGasHistory, storeGasHistoryReducer)
    .addCase(storeMultipleGasHistory, storeMultipleGasHistoryReducer)

    .addCase(notifyNetworkUpdated, () => initialState)
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address || previous?.activeCompanyId !== current?.activeCompanyId
        ? initialState
        : state,
    );
});

export default reducer;
