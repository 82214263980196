import { Form, Select, Space } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useWeb3EVMConnectors } from '@/features/web3/hooks';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';

import Web3ProviderIcon from '../Web3ProviderIcon';

import type { Web3EVMConnectorSelectItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const editableRules = [
  {
    required: true,
    message: <FormattedMessage id={I18nFeatureWeb3.COMPONENTS_CONNECTOR_SELECT_ITEM_REQUIRED} />,
  },
];

const Web3EVMConnectorSelectItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  required,
  readonly,
  ItemProps = {},
}: Web3EVMConnectorSelectItemProps<Values>) => {
  const { connectors } = useWeb3EVMConnectors();
  const options = useMemo(
    () =>
      connectors
        .filter(({ available }) => !!available)
        .map(({ connector: { id, name: label } }) => ({
          value: id,
          label: (
            <Space data-test={dataTest && `${dataTest}-item-${id}`}>
              <Web3ProviderIcon value={id} />
              <span>{label}</span>
            </Space>
          ),
        })),
    [connectors, dataTest],
  );

  return (
    <Form.Item<Values>
      label={<FormattedMessage id={I18nFeatureWeb3.COMPONENTS_CONNECTOR_SELECT_ITEM_LABEL} />}
      rules={!readonly && required ? editableRules : undefined}
      required={!readonly && required}
      {...ItemProps}
      name={name}
    >
      <Select disabled={readonly} data-test={dataTest} defaultActiveFirstOption options={options} />
    </Form.Item>
  );
};

const Web3EVMConnectorSelectItemMemo = React.memo(Web3EVMConnectorSelectItem) as typeof Web3EVMConnectorSelectItem;

export default Web3EVMConnectorSelectItemMemo;
