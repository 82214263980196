import { useMemo } from 'react';

import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import { mapStoredState } from '@/infrastructure/model';

export default function useWeb3AuthEnabled() {
  const { data } = useFeatureToggle();
  return useMemo(
    () =>
      mapStoredState(
        data,
        (featureFlags) =>
          (!featureFlags.disableEmailAuth || !featureFlags.disableGoogleAuth) && !featureFlags.disableWeb3Auth,
      ),
    [data],
  );
}
