import { useEffect } from 'react';

import { useCookiesAgreement } from '@/features/global/hooks';
import { useStateMountSafe } from '@/hooks';
import { withSuppressPromise } from '@/infrastructure/utils/functions';

const waitForYM = (repeats: number) =>
  new Promise((resolve) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (repeats <= 0 || window.ym) {
      resolve('');
    } else {
      setTimeout(async () => {
        await waitForYM(repeats - 1);
        resolve('');
      }, 100);
    }
  });

let scriptCreated = false;
let scriptLoaded = false;

function useYandexMetrikaInit() {
  const { accepted, strict, initialized } = useCookiesAgreement();
  const ymIsEnabled = window.env.YM_ENABLED && !!window.env.YM_ID && initialized && (accepted || !strict);
  const [loaded, setLoaded] = useStateMountSafe(scriptLoaded);
  useEffect(() => {
    if (ymIsEnabled && !scriptCreated) {
      scriptCreated = true;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `(function(m, e, t, r, i, k, a) {
          m[i] = m[i] || function() {
            (m[i].a = m[i].a || []).push(arguments);
          };
          m[i].l = 1 * new Date();
          k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);
        })
        (window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');
        
        ym(${window.env.YM_ID!}, 'init', {
          clickmap: false,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: ${window.env.YM_WEB_VISOR},
          trackHash: true,
        });`;
      document.head.append(script);

      withSuppressPromise(
        async () => {
          await waitForYM(10);
          scriptLoaded = true;
          setLoaded(true);
        },
        (e) => console.warn(e),
      )();
    }
  }, [loaded, setLoaded, ymIsEnabled]);
  return { loaded, ymIsEnabled };
}

export default useYandexMetrikaInit;
