import { css } from '@emotion/css';
import { Tooltip, Typography } from 'antd';
import React from 'react';
import Truncate from 'react-truncate-inside';

import { FormattedMessage } from '@/components';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';
import { useDimensions } from '@/hooks';

import type { Web3WalletOperationAccountProps } from './types';

const { Text } = Typography;

const styles = {
  account: css`
    width: 100%;
    white-space: nowrap;
    text-align: center;

    > div {
      padding-left: 5px;
      width: calc(100% - 80px);
      display: inline-block;
      text-align: left;
    }
  `,
};

const Web3WalletOperationAccount: React.FC<Web3WalletOperationAccountProps> = ({
  'data-test': dataTest,
  tooltip,
  account,
}) => {
  const [ref, dimension] = useDimensions();
  return (
    <Tooltip title={tooltip ?? <FormattedMessage id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_ACCOUNT_TOOLTIP} />}>
      <Text type="secondary" className={styles.account}>
        <FormattedMessage id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_ACCOUNT_TITLE} tagName="span" />
        <div data-test={dataTest} ref={ref}>
          {dimension && <Truncate text={account} offset={8} width={dimension.offsetWidth} />}
        </div>
      </Text>
    </Tooltip>
  );
};

const Web3WalletOperationAccountMemo = React.memo(Web3WalletOperationAccount) as typeof Web3WalletOperationAccount;

export default Web3WalletOperationAccountMemo;
