import React, { useContext } from 'react';

import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import type { Web3Auth } from '@/infrastructure/security/web3-auth/types';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';

const emptyComponent = () => null;

const withWeb3AuthState =
  (expected: (web3Auth: Web3Auth) => boolean, EmptyComponent: React.ComponentType = emptyComponent) =>
  <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
    namedHOC(
      Component,
      'WithWeb3AuthState',
    )((props) => {
      const { web3Auth } = useContext(Web3AuthContext);
      return web3Auth && expected(web3Auth) ? <Component {...props} /> : <EmptyComponent />;
    });

export default withWeb3AuthState;
