/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SolanaUserAddressConfirmationAPIModel
 */
export interface SolanaUserAddressConfirmationAPIModel {
    /**
     * 
     * @type {string}
     * @memberof SolanaUserAddressConfirmationAPIModel
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof SolanaUserAddressConfirmationAPIModel
     */
    signature: string;
    /**
     * 
     * @type {string}
     * @memberof SolanaUserAddressConfirmationAPIModel
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof SolanaUserAddressConfirmationAPIModel
     */
    partner?: string;
}

/**
 * Check if a given object implements the SolanaUserAddressConfirmationAPIModel interface.
 */
export function instanceOfSolanaUserAddressConfirmationAPIModel(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('signature' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function SolanaUserAddressConfirmationAPIModelFromJSON(json: any): SolanaUserAddressConfirmationAPIModel {
    return SolanaUserAddressConfirmationAPIModelFromJSONTyped(json, false);
}

export function SolanaUserAddressConfirmationAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SolanaUserAddressConfirmationAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'signature': json['signature'],
        'message': json['message'],
        'partner': json['partner'] == null ? undefined : json['partner'],
    };
}

export function SolanaUserAddressConfirmationAPIModelToJSON(value?: SolanaUserAddressConfirmationAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'signature': value['signature'],
        'message': value['message'],
        'partner': value['partner'],
    };
}

