/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { PushContractDeployStatusAPIModel } from './PushContractDeployStatusAPIModel';
import {
    PushContractDeployStatusAPIModelFromJSON,
    PushContractDeployStatusAPIModelFromJSONTyped,
    PushContractDeployStatusAPIModelToJSON,
} from './PushContractDeployStatusAPIModel';

/**
 * 
 * @export
 * @interface RechargeAddressAPIModel
 */
export interface RechargeAddressAPIModel {
    /**
     * 
     * @type {string}
     * @memberof RechargeAddressAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RechargeAddressAPIModel
     */
    address: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof RechargeAddressAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof RechargeAddressAPIModel
     */
    asset: string;
    /**
     * 
     * @type {string}
     * @memberof RechargeAddressAPIModel
     */
    cid: string;
    /**
     * 
     * @type {PushContractDeployStatusAPIModel}
     * @memberof RechargeAddressAPIModel
     */
    deployStatus: PushContractDeployStatusAPIModel;
    /**
     * 
     * @type {Date}
     * @memberof RechargeAddressAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof RechargeAddressAPIModel
     */
    balance: string;
    /**
     * 
     * @type {string}
     * @memberof RechargeAddressAPIModel
     */
    gwtId?: string;
}

/**
 * Check if a given object implements the RechargeAddressAPIModel interface.
 */
export function instanceOfRechargeAddressAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('address' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('asset' in value)) return false;
    if (!('cid' in value)) return false;
    if (!('deployStatus' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('balance' in value)) return false;
    return true;
}

export function RechargeAddressAPIModelFromJSON(json: any): RechargeAddressAPIModel {
    return RechargeAddressAPIModelFromJSONTyped(json, false);
}

export function RechargeAddressAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RechargeAddressAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'address': json['address'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'asset': json['asset'],
        'cid': json['cid'],
        'deployStatus': PushContractDeployStatusAPIModelFromJSON(json['deployStatus']),
        'createdAt': (new Date(json['createdAt'])),
        'balance': json['balance'],
        'gwtId': json['gwtId'] == null ? undefined : json['gwtId'],
    };
}

export function RechargeAddressAPIModelToJSON(value?: RechargeAddressAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'address': value['address'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'asset': value['asset'],
        'cid': value['cid'],
        'deployStatus': PushContractDeployStatusAPIModelToJSON(value['deployStatus']),
        'createdAt': ((value['createdAt']).toISOString()),
        'balance': value['balance'],
        'gwtId': value['gwtId'],
    };
}

