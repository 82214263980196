import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { DataFetchError, FormattedMessage, PageCard, PageContainer, PageLoading } from '@/components';
import { EditPayoutForm } from '@/features/payouts/components';
import { usePayoutsActions } from '@/features/payouts/hooks';
import { I18nPagePayouts } from '@/generated/i18n/i18n';
import {
  useDefaultNotification,
  useFirstRenderEffect,
  useFormPageAction,
  useNotification,
  useStateMountSafe,
} from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import { goalReached, YMGoals } from '@/infrastructure/ym';
import { payoutsLink } from '@/pages/payouts/routes';

import { PayoutAddBreadcrumb } from './components';
import { useInitialValues } from './hooks';

import type React from 'react';

const dataTest: string | undefined = 'payout-add';

const AddPayoutPage: React.FC = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const initialValues = useInitialValues(params.get('copy-of') ?? undefined);
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const { create: createAction } = usePayoutsActions();
  const { redirectOnSubmit } = useFormPageAction();
  const doRedirect = useMemo<Func>(() => redirectOnSubmit(payoutsLink()), [redirectOnSubmit]);
  const [onSubmit, setOnSubmit] = useStateMountSafe<Func | undefined>();
  const updateOnSubmit = useCallback((func?: Func) => setOnSubmit(() => func), [setOnSubmit]);

  useFirstRenderEffect(() => {
    goalReached(YMGoals.PAYOUT_CREATE_INIT);
  });

  const doCreate = useMemo(
    () =>
      withOnSuccess(
        withDefaultError(
          withSuccess(createAction.act, () => ({
            message: <FormattedMessage id={I18nPagePayouts.PAYOUT_ADD_MESSAGES_SUCCESS_TITLE} />,
            description: <FormattedMessage id={I18nPagePayouts.PAYOUT_ADD_MESSAGES_SUCCESS_DESCRIPTION} />,
          })),
          () => <FormattedMessage id={I18nPagePayouts.PAYOUT_ADD_MESSAGES_ERROR} />,
        ),
        () => {
          goalReached(YMGoals.PAYOUT_CREATED);
          doRedirect();
        },
      ),
    [withDefaultError, withSuccess, doRedirect, createAction],
  );
  const submit = useMemo(() => ({ onClick: onSubmit ?? noop, disabled: !onSubmit }), [onSubmit]);
  return (
    <PageContainer
      data-test={dataTest}
      breadcrumb={<PayoutAddBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
      submit={submit}
      cancel={doRedirect}
    >
      <PageCard cardSize="big">
        {
          /* eslint-disable-next-line no-nested-ternary */
          !initialValues.data.data ? (
            !initialValues.loading && !initialValues.data.isDirty ? (
              <DataFetchError refresh={initialValues.forceRefresh} back message={initialValues.data.error} />
            ) : (
              <PageLoading type="payout-add" />
            )
          ) : (
            <EditPayoutForm
              data-test={dataTest && `${dataTest}-form`}
              onSubmit={doCreate}
              onReset={doRedirect}
              submitCallback={updateOnSubmit}
              initialValues={initialValues.data.data}
              isNew
            />
          )
        }
      </PageCard>
    </PageContainer>
  );
};

export default AddPayoutPage;
