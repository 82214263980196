import React from 'react';

import { useUserAddress } from '@/features/user/hooks';
import type { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';

const emptyComponent = () => null;

const withUserAddressAvailability =
  (addressType: BlockchainAPITypeAPIModel, expected = true, EmptyComponent: React.ComponentType = emptyComponent) =>
  <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
    namedHOC(
      Component,
      'WithUserAddressAvailability',
    )((props) => {
      const available = useUserAddress(addressType);
      return !!available.data.data?.value === expected ? <Component {...props} /> : <EmptyComponent />;
    });

export default withUserAddressAvailability;
