import { Space } from 'antd';
import React from 'react';

import { OperationDisconnectWeb3AuthWallet, OperationSecureWeb3AuthPrivateKey } from '@/features/auth/components';
import { withWeb3AuthState } from '@/features/auth/hocs';

import type { Web3AuthActionsProps } from './types';

const Web3AuthActions = ({ 'data-test': dataTest }: Web3AuthActionsProps) => (
  <Space>
    <OperationSecureWeb3AuthPrivateKey data-test={dataTest && `${dataTest}-secureKeyOp`} mode="icon" />
    <OperationDisconnectWeb3AuthWallet data-test={dataTest && `${dataTest}-disconnectOp`} mode="icon" />
  </Space>
);

const Web3AuthActionsGuarded = withWeb3AuthState((web3Auth) => web3Auth.isAuthorized())(Web3AuthActions);

const Web3AuthActionsMemo = React.memo(Web3AuthActionsGuarded) as typeof Web3AuthActions;

export default Web3AuthActionsMemo;
