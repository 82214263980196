import { useContext, useEffect, useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { PageLoading } from '@/components';
import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { useAuthActions } from '@/features/auth/hooks';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { useUser } from '@/features/user/hooks';
import { useSingleRun } from '@/hooks';
import { withSuppressPromise } from '@/infrastructure/utils/functions';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import { isSameAddress } from '@/infrastructure/utils/web3/address';
import MessageLayout from '@/layouts/MessageLayout';

import type React from 'react';

const selectAuthToken = makeSelectAuthToken();

// Checks:
// - the user is logged-in by web3auth and created JWT corresponds to this user;
// - the web3auth authorized for the JWT logged-in user registered by email.
const withWeb3AuthConsistencyGuard = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithWeb3AuthConsistencyGuard',
  )((props) => {
    const { logout } = useAuthActions();
    const { withSingleRun, inProgress } = useSingleRun(true);

    const { web3Auth, initialized } = useContext(Web3AuthContext);
    const jwtState = useAppSelector(selectAuthToken);
    const userState = useUser();

    const isJWTActual = !jwtState.isDirty;
    const isAddressConsistent = useMemo(
      () =>
        !isJWTActual
        || !jwtState.data?.info.address
        || !web3Auth?.state?.address
        || isSameAddress(jwtState.data.info.address, web3Auth.state.address),
      [isJWTActual, jwtState.data?.info.address, web3Auth?.state?.address],
    );

    const isUserActual = !userState.loading && !userState.data.isDirty;

    useEffect(() => {
      if (!isAddressConsistent && !inProgress && initialized && isUserActual) {
        if (logout.available && !logout.inAction) {
          withSuppressPromise(withSingleRun(logout.act))(true);
        }
      }
    }, [
      inProgress,
      initialized,
      isAddressConsistent,
      isUserActual,
      logout.act,
      logout.available,
      logout.inAction,
      withSingleRun,
    ]);

    if (!isAddressConsistent && inProgress) {
      return (
        <MessageLayout>
          <PageLoading type="WithWeb3AuthConsistencyGuard" />
        </MessageLayout>
      );
    }
    return <Component {...props} />;
  });

export default withWeb3AuthConsistencyGuard;
