import { css } from '@emotion/css';
import { theme, Tooltip } from 'antd';
import React from 'react';

import KYBRequiredBanner from '../KYBRequiredBanner';

import type { KYBRequiredTooltipProps } from './types';

const { useToken } = theme;
const KYBRequiredTooltip: React.FC<KYBRequiredTooltipProps> = ({
  'data-test': dataTest,
  style,
  className,
  mode = 'full',
  children,
}) => {
  const token = useToken();
  return (
    <Tooltip
      title={
        <KYBRequiredBanner
          data-test={dataTest}
          mode={mode === 'full' ? 'full' : 'medium'}
          hideable={false}
          style={style}
          className={className}
        />
      }
      classNames={{
        root: css`
          --antd-arrow-background-color: ${token.token.colorInfoBorder} !important;
          border-radius: 8px !important;
          max-width: ${mode === 'full' ? '500' : '350'}px;
        `,
        body: css`
          padding: 0 !important;
          border-radius: 8px !important;
        `,
      }}
      showArrow={false}
      placement="bottom"
      autoAdjustOverflow
    >
      <>{children}</>
    </Tooltip>
  );
};

const KYBRequiredTooltipMemo = React.memo(KYBRequiredTooltip);

export default KYBRequiredTooltipMemo;
