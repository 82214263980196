/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PagePushTransactionSortByAPIModel } from './PagePushTransactionSortByAPIModel';
import {
    PagePushTransactionSortByAPIModelFromJSON,
    PagePushTransactionSortByAPIModelFromJSONTyped,
    PagePushTransactionSortByAPIModelToJSON,
} from './PagePushTransactionSortByAPIModel';
import type { RechargeTransactionPredicatesAPIModel } from './RechargeTransactionPredicatesAPIModel';
import {
    RechargeTransactionPredicatesAPIModelFromJSON,
    RechargeTransactionPredicatesAPIModelFromJSONTyped,
    RechargeTransactionPredicatesAPIModelToJSON,
} from './RechargeTransactionPredicatesAPIModel';

/**
 * 
 * @export
 * @interface RechargeTransactionFilterAPIModel
 */
export interface RechargeTransactionFilterAPIModel {
    /**
     * 
     * @type {RechargeTransactionPredicatesAPIModel}
     * @memberof RechargeTransactionFilterAPIModel
     */
    predicates: RechargeTransactionPredicatesAPIModel;
    /**
     * 
     * @type {PagePushTransactionSortByAPIModel}
     * @memberof RechargeTransactionFilterAPIModel
     */
    page: PagePushTransactionSortByAPIModel;
}

/**
 * Check if a given object implements the RechargeTransactionFilterAPIModel interface.
 */
export function instanceOfRechargeTransactionFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function RechargeTransactionFilterAPIModelFromJSON(json: any): RechargeTransactionFilterAPIModel {
    return RechargeTransactionFilterAPIModelFromJSONTyped(json, false);
}

export function RechargeTransactionFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RechargeTransactionFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': RechargeTransactionPredicatesAPIModelFromJSON(json['predicates']),
        'page': PagePushTransactionSortByAPIModelFromJSON(json['page']),
    };
}

export function RechargeTransactionFilterAPIModelToJSON(value?: RechargeTransactionFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': RechargeTransactionPredicatesAPIModelToJSON(value['predicates']),
        'page': PagePushTransactionSortByAPIModelToJSON(value['page']),
    };
}

