import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Input, Space, Tooltip } from 'antd';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage } from '@/components';
import { kytLevelDetails, OperationUpdateKYTLevel } from '@/features/company-settings/components';
import type { KYTToleranceLevelAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureCompanySettings, I18nPageSettings } from '@/generated/i18n/i18n';

import type { CompanyKYTLevelItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const CompanyKYTLevelItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  style,
  className,
}: CompanyKYTLevelItemProps<Values>) => {
  const { formatMessage } = useIntl();
  return (
    <Form.Item<Values>
      name={name}
      label={
        <Space>
          <FormattedMessage
            id={I18nPageSettings.COMPLIANCE_COMPONENTS_GENERAL_CARD_KYT_LEVEL_ITEM_LABEL}
            tagName="span"
          />
          <Tooltip title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_KYT_DESCRIPTION_TITLE} />}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      }
      getValueProps={useCallback(
        (kytLevel?: KYTToleranceLevelAPIModel) => ({
          value: kytLevel ? formatMessage({ id: kytLevelDetails[kytLevel] }) : undefined,
        }),
        [formatMessage],
      )}
    >
      <Input
        style={style}
        className={className}
        disabled
        data-test={dataTest}
        suffix={<OperationUpdateKYTLevel data-test={dataTest && `${dataTest}-updateOp`} mode="icon" />}
      />
    </Form.Item>
  );
};

const CompanyKYTLevelItemMemo = React.memo(CompanyKYTLevelItem) as typeof CompanyKYTLevelItem;

export default CompanyKYTLevelItemMemo;
