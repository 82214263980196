/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { CollectableAddressBalancePredicatesAPIModel } from './CollectableAddressBalancePredicatesAPIModel';
import {
    CollectableAddressBalancePredicatesAPIModelFromJSON,
    CollectableAddressBalancePredicatesAPIModelFromJSONTyped,
    CollectableAddressBalancePredicatesAPIModelToJSON,
} from './CollectableAddressBalancePredicatesAPIModel';
import type { PageCollectableAddressBalanceSortByAPIModel } from './PageCollectableAddressBalanceSortByAPIModel';
import {
    PageCollectableAddressBalanceSortByAPIModelFromJSON,
    PageCollectableAddressBalanceSortByAPIModelFromJSONTyped,
    PageCollectableAddressBalanceSortByAPIModelToJSON,
} from './PageCollectableAddressBalanceSortByAPIModel';

/**
 * 
 * @export
 * @interface CollectableAddressBalanceFilterAPIModel
 */
export interface CollectableAddressBalanceFilterAPIModel {
    /**
     * 
     * @type {CollectableAddressBalancePredicatesAPIModel}
     * @memberof CollectableAddressBalanceFilterAPIModel
     */
    predicates: CollectableAddressBalancePredicatesAPIModel;
    /**
     * 
     * @type {PageCollectableAddressBalanceSortByAPIModel}
     * @memberof CollectableAddressBalanceFilterAPIModel
     */
    page: PageCollectableAddressBalanceSortByAPIModel;
}

/**
 * Check if a given object implements the CollectableAddressBalanceFilterAPIModel interface.
 */
export function instanceOfCollectableAddressBalanceFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function CollectableAddressBalanceFilterAPIModelFromJSON(json: any): CollectableAddressBalanceFilterAPIModel {
    return CollectableAddressBalanceFilterAPIModelFromJSONTyped(json, false);
}

export function CollectableAddressBalanceFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectableAddressBalanceFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': CollectableAddressBalancePredicatesAPIModelFromJSON(json['predicates']),
        'page': PageCollectableAddressBalanceSortByAPIModelFromJSON(json['page']),
    };
}

export function CollectableAddressBalanceFilterAPIModelToJSON(value?: CollectableAddressBalanceFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': CollectableAddressBalancePredicatesAPIModelToJSON(value['predicates']),
        'page': PageCollectableAddressBalanceSortByAPIModelToJSON(value['page']),
    };
}

