import { StopOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { web3ButtonProps } from '@/features/web3/components/utils';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';

import Web3ProviderIcon from '../Web3ProviderIcon';

import type { Web3WalletOperationButtonsProps } from './types';

const styles = {
  buttonsContainer: css`
    display: flex;
    width: 100%;

    > span:nth-child(1) {
      display: flex !important;
      flex: 1;

      > button {
        display: flex !important;
      }
    }
  `,
};

const Web3WalletOperationButtons: React.FC<Web3WalletOperationButtonsProps> = ({
  'data-test': dataTest,
  walletId,
  title,
  disabled,
  disabledMessage,
  cancellationTitle,
  inProgress,
  onAction,
  onCancel,
  disconnectable,
  Disconnect,
}) => (
  <div className={styles.buttonsContainer}>
    <Operation
      ButtonProps={web3ButtonProps}
      title={title}
      style={useMemo(
        () => ({
          flex: 1,
          alignItems: 'center',
          marginLeft: 'auto',
          display: 'flex',
          justifyContent: 'flex-start',
          ...(disconnectable || onCancel ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {}),
        }),
        [disconnectable, onCancel],
      )}
      data-test={dataTest && `${dataTest}-act`}
      inProgress={inProgress}
      disabled={disabled}
      disabledMessage={disabledMessage}
      icon={
        <Web3ProviderIcon
          value={walletId}
          className={css`
            display: flex;
          `}
        />
      }
      onClick={onAction}
      mode="button"
    />
    {onCancel && (
      <Operation
        ButtonProps={web3ButtonProps}
        style={!disconnectable ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } : undefined}
        title={cancellationTitle || <FormattedMessage id={I18nFeatureWeb3.COMPONENTS_WALLET_OPERATION_CANCEL_TITLE} />}
        data-test={dataTest && `${dataTest}-cancel`}
        inProgress={inProgress}
        icon={<StopOutlined />}
        onClick={onCancel}
        mode="inline"
      />
    )}
    {disconnectable && Disconnect && <Disconnect data-test={dataTest && `${dataTest}-disconnect`} />}
  </div>
);

const Web3WalletOperationButtonsMemo = React.memo(Web3WalletOperationButtons) as typeof Web3WalletOperationButtons;

export default Web3WalletOperationButtonsMemo;
