import { css } from '@emotion/css';
import { Form, Space } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
} from '@/components';
import { BlockchainAPIItem } from '@/features/dictionary/blockchain/components';
import AddressItem from '@/features/dictionary/blockchain/components/AddressItem';
import { useSelectedNetwork } from '@/features/dictionary/blockchain/hooks';
import { parseAddress } from '@/features/dictionary/blockchain/utils';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop, someOrFail, withSuppressPromise } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';
import { isSameAddress } from '@/infrastructure/utils/web3/address';

import type { AddUserFormProps, AddUserFormValues } from './types';
import type { FormInstance } from 'antd/es/form';

const fields = [nameof<AddUserFormValues>('address')];

const footerMessages = {
  submit: <FormattedMessage id={I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_SUBMIT} tagName="span" />,
};

const apiMessages = {
  placeholder: <FormattedMessage id={I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_API_PLACEHOLDER} />,
};

const allowedAPI = [BlockchainAPITypeAPIModel.EVM, BlockchainAPITypeAPIModel.Solana];
const APIItemProps = { noStyle: true };

const AddUserForm: React.FC<AddUserFormProps> = ({
  'data-test': dataTest,
  onSubmit,
  registered,
  owner,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const { formatMessage } = useIntl();
  const { network } = useSelectedNetwork();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { form, withResetForm } = useForm<AddUserFormValues>();
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const doSubmit = useCallback(
    ({ addressType, address }: AddUserFormValues) =>
      withSubmitting(onSubmit)({ addressType: someOrFail(addressType), address: someOrFail(address) }),
    [onSubmit, withSubmitting],
  );
  const doReset = useMemo(() => withErrorHandling(withResetForm(onReset)), [onReset, withErrorHandling, withResetForm]);
  const errorMessage = useMemo(() => {
    if (!error) return undefined;
    return <FormattedMessage id={I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_ERROR_COMMON} />;
  }, [error]);

  const addressRules = useMemo(
    () => [
      {
        validator: async (_: unknown, value: string) => {
          const address = await parseAddress(value, allowedAPI);
          if (!address) return;
          if (registered.find((v) => isSameAddress(v, address.address, address.addressType)))
            throw new Error(
              formatMessage({
                id: I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_ADDRESS_ERROR_DUPLICATE,
              }),
            );
          if (owner.find((v) => isSameAddress(v, address.address, address.addressType)))
            throw new Error(
              formatMessage({ id: I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_ADDRESS_ERROR_OWNER }),
            );
        },
      },
    ],
    [formatMessage, owner, registered],
  );

  return (
    <Space
      direction="vertical"
      size="large"
      className={css`
        display: flex;
        flex: 1;
      `}
    >
      <FormattedMessage id={I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_DESCRIPTION} tagName="span" />
      <Form<AddUserFormValues>
        {...layout}
        autoComplete="off"
        form={form}
        size="middle"
        onFinish={doSubmit}
        onReset={doReset}
        preserve
      >
        {errorMessage && <ErrorFormMessage content={errorMessage} />}
        <AddressItem<AddUserFormValues>
          name="address"
          data-test={dataTest && `${dataTest}-address`}
          rules={addressRules}
          blockchainApis={allowedAPI}
          net={network}
        />
        <BlockchainAPIItem<AddUserFormValues>
          name="addressType"
          data-test={dataTest && `${dataTest}-type`}
          readonly
          messages={apiMessages}
          ItemProps={APIItemProps}
        />
        <Form.Item<AddUserFormValues>
          shouldUpdate={(prevValues, nextValues) => prevValues.address !== nextValues.address}
          hidden
        >
          {({ isFieldValidating, getFieldError, getFieldValue, setFieldValue }: FormInstance<AddUserFormValues>) => {
            withSuppressPromise(async () => {
              const addressType =
                !isFieldValidating('address') && !getFieldError('address').length
                  ? (await parseAddress(getFieldValue('address'), allowedAPI))?.addressType
                  : undefined;
              setFieldValue('addressType', addressType);
            })();
            return null;
          }}
        </Form.Item>
        <FormCompletenessItem<AddUserFormValues> requiredFields={fields} onChange={setFormComplete} />
        <FormFooter
          tailLayout={tailLayout}
          submitDisabled={!isFormComplete}
          submitting={submitting}
          messages={footerMessages}
          data-test={dataTest && `${dataTest}-submit`}
        />
      </Form>
    </Space>
  );
};

const AddUserFormMemo = React.memo(AddUserForm);

export default AddUserFormMemo;
