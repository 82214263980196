/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { DaySalesAPIModel } from './DaySalesAPIModel';
import {
    DaySalesAPIModelFromJSON,
    DaySalesAPIModelFromJSONTyped,
    DaySalesAPIModelToJSON,
} from './DaySalesAPIModel';

/**
 * 
 * @export
 * @interface InvoicesStatsSimpleAPIModel
 */
export interface InvoicesStatsSimpleAPIModel {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof InvoicesStatsSimpleAPIModel
     */
    todayInvoices: { [key: string]: number; };
    /**
     * 
     * @type {DaySalesAPIModel}
     * @memberof InvoicesStatsSimpleAPIModel
     */
    todaySales: DaySalesAPIModel;
}

/**
 * Check if a given object implements the InvoicesStatsSimpleAPIModel interface.
 */
export function instanceOfInvoicesStatsSimpleAPIModel(value: object): boolean {
    if (!('todayInvoices' in value)) return false;
    if (!('todaySales' in value)) return false;
    return true;
}

export function InvoicesStatsSimpleAPIModelFromJSON(json: any): InvoicesStatsSimpleAPIModel {
    return InvoicesStatsSimpleAPIModelFromJSONTyped(json, false);
}

export function InvoicesStatsSimpleAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoicesStatsSimpleAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'todayInvoices': json['todayInvoices'],
        'todaySales': DaySalesAPIModelFromJSON(json['todaySales']),
    };
}

export function InvoicesStatsSimpleAPIModelToJSON(value?: InvoicesStatsSimpleAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'todayInvoices': value['todayInvoices'],
        'todaySales': DaySalesAPIModelToJSON(value['todaySales']),
    };
}

