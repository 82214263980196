import { css } from '@emotion/css';
import { Space } from 'antd';
import React, { useMemo } from 'react';

import { withWeb3EVMContext } from '@/features/web3/hocs';
import { useWeb3EVMConnectors, useWeb3Singleton } from '@/features/web3/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { noop } from '@/infrastructure/utils/functions';

import EVMOperationContexted from '../EVMOperationContexted';

import type { EVMOperationsProps } from './types';

const evmPrefix = 'evm-';

const EVMOperations = <
  Success = unknown,
  Account = string,
  BT extends BlockchainTypeAPIModel | undefined = undefined,
  ExtraProps = undefined,
>({
  'data-test': dataTest,
  onSuccess,
  bt,
  requiredAccount,
  inProgress,
  inProgressMode,
  withInProgress: baseWithInProgress,
  cancellation,
  filter,
  Operation,
  extra,
}: EVMOperationsProps<Success, Account, BT, ExtraProps>): React.ReactNode => {
  const { orderedConnectors } = useWeb3EVMConnectors();
  const connectors = useMemo(
    () => (filter ? orderedConnectors.filter(filter) : orderedConnectors),
    [filter, orderedConnectors],
  );
  const { withReloadIfInitialized } = useWeb3Singleton();
  const doSuccess = useMemo(() => withReloadIfInitialized(onSuccess ?? noop), [onSuccess, withReloadIfInitialized]);
  const withInProgress = useMemo(
    () => (baseWithInProgress ? (selected: string) => baseWithInProgress(`${evmPrefix}-${selected}`) : undefined),
    [baseWithInProgress],
  );

  return (
    <Space
      direction="vertical"
      className={css`
        display: flex;
        flex: 1;
      `}
      size="middle"
    >
      {connectors.map(({ id }) => (
        <EVMOperationContexted<Success, Account, BT, ExtraProps>
          key={id}
          data-test={dataTest && `${dataTest}-${id}`}
          connectorId={id}
          requiredAccount={requiredAccount}
          onSuccess={doSuccess}
          inProgress={inProgress === `${evmPrefix}-${id}`}
          inProgressMode={inProgressMode}
          withInProgress={withInProgress}
          bt={bt}
          extra={extra}
          Operation={Operation}
          cancellation={cancellation}
        />
      ))}
    </Space>
  );
};

const EVMOperationsContexted = withWeb3EVMContext(EVMOperations, React.Fragment);

const EVMOperationsMemo = React.memo(EVMOperationsContexted) as typeof EVMOperations;

export default EVMOperationsMemo;
