import { Form } from 'antd';
import React, { useCallback } from 'react';

import { FormattedMessageWithMarkup } from '@/components';
import { BlockchainAPILabel } from '@/features/dictionary/blockchain/components';
import { useBlockchainSystemInfos } from '@/features/dictionary/blockchain/hooks';
import { useUser } from '@/features/user/hooks';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { ProfileLink } from '@/pages/settings/profile/components';

import { QRSupportInput } from './components';

import type { QRSupportInputProps } from './components';
import type { QRSupportItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const QRSupportItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  ...rest
}: QRSupportItemProps<Values>) => {
  const { data: userState } = useUser();
  const { data: systemInfos } = useBlockchainSystemInfos();
  const disableSelection: NonNullable<QRSupportInputProps['disableSelection']> = useCallback(
    ({ blockchain }) => {
      const info = systemInfos.data?.find(({ bt }) => bt === blockchain);
      const apiType = info?.apiType;
      return (
        !!userState.data
        && !!apiType
        && !userState.data.addresses.find(({ addressType }) => addressType.includes(apiType)) && {
          message: (
            <FormattedMessageWithMarkup
              id={I18nFeatureCompanySettings.COMPONENTS_UPDATE_QR_SUPPORT_FORM_DISABLES_NO_USER_ADDRESS}
              values={{
                bt: blockchain,
                api: <BlockchainAPILabel api={apiType} copyable={false} />,
                link: (text: React.ReactNode) => <ProfileLink mode="text" title={text} />,
              }}
            />
          ),
        }
      );
    },
    [systemInfos.data, userState.data],
  );
  return (
    <Form.Item<Values> data-test={dataTest && `${dataTest}-item`} name={name}>
      <QRSupportInput data-test={dataTest && `${dataTest}-input`} disableSelection={disableSelection} {...rest} />
    </Form.Item>
  );
};

const QRSupportItemMemo = React.memo(QRSupportItem) as typeof QRSupportItem;

export default QRSupportItemMemo;
