import { Space } from 'antd';
import { useCallback } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, PageCard } from '@/components';
import { OperationRefreshBrandingProfiles } from '@/features/branding/components';
import BrandingProfileView from '@/features/branding/components/BrandingProfileView';
import { useBrandingActiveProfile, useBrandingProfiles } from '@/features/branding/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import type { TestableProps } from '@/infrastructure/utils/react';
import BrandingProfileAddLink from '@/pages/settings/branding/profile-add/components/BrandingProfileAddLink';
import BrandingProfileEditLink from '@/pages/settings/branding/profile-edit/components/BrandingProfileEditLink';
import BrandingProfilesLink from '@/pages/settings/branding/profiles/components/BrandingProfilesLink';
import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';
import { settingsBrandingProfileAddLink, settingsBrandingProfileEditLink } from '@/pages/settings/routes';

import { BrandingProfilesBreadcrumb } from './components';

import type React from 'react';

type BrandingProfilesPageProps = TestableProps;

const BrandingProfilesPage: React.FC<BrandingProfilesPageProps> = ({ 'data-test': dataTest = 'profiles' }) => {
  const {
    data: { data: profiles, isDirty },
    loading,
  } = useBrandingProfiles();
  const {
    data: { data: active },
  } = useBrandingActiveProfile();
  const { openForm } = useFormPageAction();
  const doCreate = useCallback((copyOf?: string) => openForm(settingsBrandingProfileAddLink(copyOf)), [openForm]);
  const doEdit = useCallback((profileId: string) => openForm(settingsBrandingProfileEditLink(profileId)), [openForm]);
  return (
    <SettingsPageLayout
      data-test="container"
      selected={SettingsTabType.branding}
      breadcrumb={<BrandingProfilesBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
    >
      <PageCard
        data-test={dataTest && `${dataTest}-card`}
        extra={
          <Space>
            <BrandingProfileAddLink data-test={dataTest && `${dataTest}-addOp`} mode="button" />
            <OperationRefreshBrandingProfiles data-test={dataTest && `${dataTest}-refreshOp`} />
          </Space>
        }
        loading={loading || isDirty}
        cardSize="big"
      >
        {profiles?.length ? (
          <Space direction="vertical" size="large">
            {!active ? (
              <FormattedMessage
                id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_ACTIVE_PROFILE_CARD_NO_ACTIVE}
                values={{
                  op: (text: React.ReactNode) => (
                    <BrandingProfilesLink data-test={dataTest && `${dataTest}-viewOp`} mode="text" title={text} />
                  ),
                }}
              />
            ) : (
              <FormattedMessageWithMarkup
                id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_ACTIVE_PROFILE_CARD_ACTIVE}
                values={{
                  active: active.name,
                  update: (text: React.ReactNode) => (
                    <BrandingProfileEditLink
                      data-test={dataTest && `${dataTest}-updateOp`}
                      mode="link"
                      value={active.id}
                      title={text}
                    />
                  ),
                }}
              />
            )}
            <BrandingProfileView
              data-test={dataTest && `${dataTest}-container`}
              selected={profiles[0]?.id}
              onEdit={doEdit}
              onCreate={doCreate}
            />
          </Space>
        ) : (
          <FormattedMessageWithMarkup
            id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_ACTIVE_PROFILE_CARD_EMPTY}
            values={{
              op: (text: React.ReactNode) => (
                <BrandingProfileAddLink data-test={dataTest && `${dataTest}-createOp`} mode="link" title={text} />
              ),
            }}
          />
        )}
      </PageCard>
    </SettingsPageLayout>
  );
};

export default BrandingProfilesPage;
