/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { MerchantWalletTransferDirectionAPIModel } from './MerchantWalletTransferDirectionAPIModel';
import {
    MerchantWalletTransferDirectionAPIModelFromJSON,
    MerchantWalletTransferDirectionAPIModelFromJSONTyped,
    MerchantWalletTransferDirectionAPIModelToJSON,
} from './MerchantWalletTransferDirectionAPIModel';
import type { MerchantWalletTransferKindAPIModel } from './MerchantWalletTransferKindAPIModel';
import {
    MerchantWalletTransferKindAPIModelFromJSON,
    MerchantWalletTransferKindAPIModelFromJSONTyped,
    MerchantWalletTransferKindAPIModelToJSON,
} from './MerchantWalletTransferKindAPIModel';
import type { MerchantWalletTransferReconciliationStatusAPIModel } from './MerchantWalletTransferReconciliationStatusAPIModel';
import {
    MerchantWalletTransferReconciliationStatusAPIModelFromJSON,
    MerchantWalletTransferReconciliationStatusAPIModelFromJSONTyped,
    MerchantWalletTransferReconciliationStatusAPIModelToJSON,
} from './MerchantWalletTransferReconciliationStatusAPIModel';

/**
 * 
 * @export
 * @interface MerchantWalletTransferAPIModel
 */
export interface MerchantWalletTransferAPIModel {
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletTransferAPIModel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof MerchantWalletTransferAPIModel
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletTransferAPIModel
     */
    wallet: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletTransferAPIModel
     */
    sender: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletTransferAPIModel
     */
    destination: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof MerchantWalletTransferAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletTransferAPIModel
     */
    txHash: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletTransferAPIModel
     */
    blockNum: string;
    /**
     * 
     * @type {number}
     * @memberof MerchantWalletTransferAPIModel
     */
    positionInBlock?: number;
    /**
     * 
     * @type {Date}
     * @memberof MerchantWalletTransferAPIModel
     */
    broadcastAt?: Date;
    /**
     * 
     * @type {MerchantWalletTransferDirectionAPIModel}
     * @memberof MerchantWalletTransferAPIModel
     */
    direction: MerchantWalletTransferDirectionAPIModel;
    /**
     * 
     * @type {MerchantWalletTransferKindAPIModel}
     * @memberof MerchantWalletTransferAPIModel
     */
    kind: MerchantWalletTransferKindAPIModel;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletTransferAPIModel
     */
    amount: string;
    /**
     * 
     * @type {MerchantWalletTransferReconciliationStatusAPIModel}
     * @memberof MerchantWalletTransferAPIModel
     */
    reconciliationStatus: MerchantWalletTransferReconciliationStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletTransferAPIModel
     */
    withdrawalId?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletTransferAPIModel
     */
    entityId?: string;
}

/**
 * Check if a given object implements the MerchantWalletTransferAPIModel interface.
 */
export function instanceOfMerchantWalletTransferAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('wallet' in value)) return false;
    if (!('sender' in value)) return false;
    if (!('destination' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('txHash' in value)) return false;
    if (!('blockNum' in value)) return false;
    if (!('direction' in value)) return false;
    if (!('kind' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('reconciliationStatus' in value)) return false;
    return true;
}

export function MerchantWalletTransferAPIModelFromJSON(json: any): MerchantWalletTransferAPIModel {
    return MerchantWalletTransferAPIModelFromJSONTyped(json, false);
}

export function MerchantWalletTransferAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantWalletTransferAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'wallet': json['wallet'],
        'sender': json['sender'],
        'destination': json['destination'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'txHash': json['txHash'],
        'blockNum': json['blockNum'],
        'positionInBlock': json['positionInBlock'] == null ? undefined : json['positionInBlock'],
        'broadcastAt': json['broadcastAt'] == null ? undefined : (new Date(json['broadcastAt'])),
        'direction': MerchantWalletTransferDirectionAPIModelFromJSON(json['direction']),
        'kind': MerchantWalletTransferKindAPIModelFromJSON(json['kind']),
        'amount': json['amount'],
        'reconciliationStatus': MerchantWalletTransferReconciliationStatusAPIModelFromJSON(json['reconciliationStatus']),
        'withdrawalId': json['withdrawalId'] == null ? undefined : json['withdrawalId'],
        'entityId': json['entityId'] == null ? undefined : json['entityId'],
    };
}

export function MerchantWalletTransferAPIModelToJSON(value?: MerchantWalletTransferAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'wallet': value['wallet'],
        'sender': value['sender'],
        'destination': value['destination'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'txHash': value['txHash'],
        'blockNum': value['blockNum'],
        'positionInBlock': value['positionInBlock'],
        'broadcastAt': value['broadcastAt'] == null ? undefined : ((value['broadcastAt']).toISOString()),
        'direction': MerchantWalletTransferDirectionAPIModelToJSON(value['direction']),
        'kind': MerchantWalletTransferKindAPIModelToJSON(value['kind']),
        'amount': value['amount'],
        'reconciliationStatus': MerchantWalletTransferReconciliationStatusAPIModelToJSON(value['reconciliationStatus']),
        'withdrawalId': value['withdrawalId'],
        'entityId': value['entityId'],
    };
}

