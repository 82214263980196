import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import listenerMiddleware from '@/app/listenerMiddleware';
import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { createNotification } from '@/features/global/actions';
import { makeSelectGeneratingReports, makeSelectMultipleReport } from '@/features/reports/selectors';
import { extractReportId } from '@/features/reports/utils';
import { ReportStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureReports } from '@/generated/i18n/i18n';
import { defaultPage } from '@/infrastructure/model/api';
import { createNormalizedListReducers } from '@/infrastructure/model/list/reducers';
import { storedDirtyListDataTyped } from '@/infrastructure/model/list/utils';
import { onlyUnique } from '@/infrastructure/utils/functions';
import { notEmpty } from '@/infrastructure/utils/ts';
import { reportViewLink } from '@/pages/reports/routes';

import {
  markGeneratingReportsInitialized,
  markMultipleReportDirty,
  markReportDirty,
  markReportListDirty,
  storeGeneratingReportsRefreshableAfter,
  storeMultipleReport,
  storeRemoveReport,
  storeReport,
  storeReportListData,
  storeReportListParameters,
} from './actions';
import { defaultReportSortBy } from './types';

import type { ReportsState } from './types';
import type { Draft } from 'immer';

const initialState: ReportsState = {
  entities: {},
  list: {
    data: storedDirtyListDataTyped(),
    page: defaultPage,
    filter: {},
    sortBy: defaultReportSortBy,
    columnState: {},
  },
  generating: {
    initialized: false,
    refreshableAfter: new Date(),
  },
};

const {
  markReportListDirtyReducer,
  storeReportListDataReducer,
  storeReportListParametersReducer,
  markReportDirtyReducer,
  markMultipleReportDirtyReducer,
  storeReportReducer,
  storeRemoveReportReducer,
  storeMultipleReportReducer,
} = createNormalizedListReducers(
  'Report' as const,
  (state: Draft<ReportsState>) => state.list,
  (state, list) => ({ ...state, list }),
  initialState.list,
  (state) => state.entities,
  (state, entities) => ({ ...state, entities }),
  extractReportId,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markReportListDirty, markReportListDirtyReducer)
    .addCase(storeReportListData, storeReportListDataReducer)
    .addCase(storeReportListParameters, storeReportListParametersReducer)
    .addCase(markReportDirty, markReportDirtyReducer)
    .addCase(markMultipleReportDirty, markMultipleReportDirtyReducer)
    .addCase(storeReport, storeReportReducer)
    .addCase(storeRemoveReport, storeRemoveReportReducer)
    .addCase(storeMultipleReport, storeMultipleReportReducer)

    .addCase(storeGeneratingReportsRefreshableAfter, (state, { payload: refreshableAfter }) => ({
      ...state,
      generating: { ...state.generating, refreshableAfter },
    }))
    .addCase(markGeneratingReportsInitialized, (state, { payload: { refreshableAfter } }) => ({
      ...state,
      generating: { ...state.generating, refreshableAfter, initialized: true },
    }))

    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address || previous?.activeCompanyId !== current?.activeCompanyId
        ? initialState
        : state,
    );

  listenerMiddleware.startListening({
    matcher: isAnyOf(storeReport, storeReportListData, storeMultipleReport, storeRemoveReport),
    effect: (_, listenerApi) => {
      const werePending = makeSelectGeneratingReports()(listenerApi.getOriginalState()).data ?? [];
      const arePending = makeSelectGeneratingReports()(listenerApi.getState()).data ?? [];
      if (arePending.length || werePending.length) {
        const updated = makeSelectMultipleReport(
          [...werePending, ...arePending].map(extractReportId).filter(onlyUnique),
        )(listenerApi.getState());
        const succeeded = updated
          .filter(({ data }) => data.data?.status === ReportStatusAPIModel.Success)
          .map(({ data }) => data.data)
          .filter(notEmpty);
        const failed = updated
          .filter(({ data }) => data.data?.status === ReportStatusAPIModel.Error)
          .map(({ data }) => data.data)
          .filter(notEmpty);

        succeeded.forEach((report) =>
          listenerApi.dispatch(
            createNotification({
              severity: 'success',
              messageI18n: I18nFeatureReports.MESSAGES_GENERATING_FINISHED_SUCCESS,
              messageI18nValues: { ln: { type: 'link', to: reportViewLink(report.id) } },
            }),
          ),
        );

        failed.forEach((report) =>
          listenerApi.dispatch(
            createNotification({
              severity: 'error',
              messageI18n: I18nFeatureReports.MESSAGES_GENERATING_FINISHED_FAILURE,
              messageI18nValues: { ln: { type: 'link', to: reportViewLink(report.id) } },
            }),
          ),
        );
      }
    },
  });
});

export default reducer;
