import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getAddress, recoverMessageAddress } from 'viem';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  authEmail,
  authWeb3,
  fetchAuthParameters,
  logout,
  relogin,
  storeAuthStatus,
  tryInitFromJWT,
} from '@/features/auth/actions';
import TKeyContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { makeSelectAuthStatus, makeSelectAuthToken } from '@/features/auth/selectors';
import { AuthStatus } from '@/features/auth/types';
import { parseEVMSignature } from '@/features/dictionary/blockchain/utils';
import { useActionPending } from '@/features/global/hooks';
import type { ReCaptchaParams } from '@/features/recaptcha/types';
import type { SignMessageFn } from '@/features/web3/types';
import type { OpenIdProviderTypeAPIModel } from '@/generated/api/ncps-api';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useSubmitting } from '@/hooks';
import type { HookAction } from '@/infrastructure/model';
import type { JWTTokenInfo } from '@/infrastructure/security/jwt/types';
import { someOrFail, withOnError } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import type { Cancellable } from '@/infrastructure/utils/ui';
import { runCancellable } from '@/infrastructure/utils/ui';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import type { Address } from 'viem';

export interface UseAuthActionsType {
  authWeb3: HookAction<
    [
      {
        signMessage: SignMessageFn;
        address: string;
        addressType: BlockchainAPITypeAPIModel;
        withRecaptcha: <T extends unknown[] = [], R = unknown>(
          func: (v: ReCaptchaParams) => Func<T, R>,
        ) => (...args: T) => Promise<R>;
      },
      { cancellation?: Cancellable; onBeforeSignUp?: Func<[]> }?,
    ],
    JWTTokenInfo
  >;
  authEmail: HookAction<
    [
      {
        evm: { address: Address; signMessage?: SignMessageFn };
        solana?: { address: string; signMessage?: SignMessageFn };
        emailToken: string;
        emailProvider: OpenIdProviderTypeAPIModel;
      },
      { cancellation?: Cancellable; onBeforeSignUp?: Func<[]> }?,
    ],
    JWTTokenInfo
  >;
  relogin: HookAction<[number], JWTTokenInfo>;
  disconnectWeb3Auth: HookAction<[{ signMessage: SignMessageFn }, { cancellation?: Cancellable }?], boolean>;
  confirmWeb3AuthAddressOwnership: HookAction<
    [{ signMessage: SignMessageFn }, { cancellation?: Cancellable }?],
    boolean
  >;
  logout: HookAction<[boolean?]>;
  tryInitJWT: HookAction<[], void>;
}

const selectJWTState = makeSelectAuthToken();
const selectAuthStatus = makeSelectAuthStatus();

export default function useAuthActions(): UseAuthActionsType {
  const { formatMessage } = useIntl();
  const { withExtractDataDispatch, withDispatch, withVoidDispatch } = useAppDispatch();
  const jwtState = useAppSelector(selectJWTState);
  const authStatus = useAppSelector(selectAuthStatus);

  const authInProgress = authStatus === AuthStatus.NOT_INITIALIZED;

  const [authWeb3InProgress, withAuthWeb3] = useSubmitting(false);
  const authWeb3Action: UseAuthActionsType['authWeb3']['act'] = useMemo(
    () =>
      withOnError(
        withAuthWeb3(
          async ({ signMessage, address, addressType, withRecaptcha }, { cancellation, onBeforeSignUp } = {}) => {
            withDispatch(storeAuthStatus)({ newState: AuthStatus.PROCESSING });
            switch (addressType) {
              case BlockchainAPITypeAPIModel.Tron:
              case BlockchainAPITypeAPIModel.BTC:
                throw new Error('FeatureNotSupported');
              case BlockchainAPITypeAPIModel.EVM:
              case BlockchainAPITypeAPIModel.Solana:
                break;
            }
            const params = await withExtractDataDispatch(fetchAuthParameters)({ addressType, address });
            if (params.nonce === '0' && onBeforeSignUp) {
              await (cancellation ? runCancellable(onBeforeSignUp(), cancellation) : onBeforeSignUp());
            }

            const partnerAddress = params.brokerAddress;
            const message =
              // eslint-disable-next-line no-nested-ternary
              params.nonce === '0'
                ? partnerAddress
                  ? formatMessage(
                      { id: I18nFeatureAuth.HOOKS_ACTIONS_SIGNUP_MESSAGE_WITH_BROKER },
                      {
                        address:
                          addressType === BlockchainAPITypeAPIModel.EVM ? partnerAddress.toLowerCase() : partnerAddress,
                      },
                    )
                  : formatMessage(
                      { id: I18nFeatureAuth.HOOKS_ACTIONS_SIGNUP_MESSAGE_NO_BROKER },
                      { address: addressType === BlockchainAPITypeAPIModel.EVM ? address.toLowerCase() : address },
                    )
                : formatMessage({ id: I18nFeatureAuth.HOOKS_ACTIONS_LOGIN_MESSAGE }, { nonce: params.nonce });
            const signed = await (cancellation
              ? runCancellable(signMessage(message), cancellation)
              : signMessage(message));

            return withRecaptcha(
              (reCaptcha) => () =>
                withExtractDataDispatch(authWeb3)({
                  ...(addressType === BlockchainAPITypeAPIModel.EVM
                    ? {
                        evm: {
                          address: getAddress(address),
                          message: signed.message,
                          signature: parseEVMSignature(signed.signature),
                          partner: partnerAddress,
                        },
                      }
                    : {
                        solana: {
                          address,
                          message: signed.message,
                          signature: signed.signature,
                          partner: partnerAddress,
                        },
                      }),
                  reCaptcha,
                }),
            )();
          },
        ),
        () => withDispatch(storeAuthStatus)({ newState: AuthStatus.UNAUTHORIZED, expected: AuthStatus.PROCESSING }),
      ),
    [formatMessage, withDispatch, withExtractDataDispatch, withAuthWeb3],
  );
  const authWeb3Hook = useMemo(
    () => ({ act: authWeb3Action, available: !jwtState.data, inAction: authWeb3InProgress || authInProgress }),
    [authWeb3Action, jwtState.data, authWeb3InProgress, authInProgress],
  );

  const web3Auth = useContext(TKeyContext);

  const [authEmailInProgress, withAuthEmail] = useSubmitting(false);
  const authEmailAction: UseAuthActionsType['authEmail']['act'] = useMemo(
    () =>
      withOnError(
        withAuthEmail(async ({ evm, solana, emailProvider, emailToken }, { cancellation, onBeforeSignUp } = {}) => {
          withDispatch(storeAuthStatus)({ newState: AuthStatus.PROCESSING });

          const evmParams = evm.signMessage
            ? await withExtractDataDispatch(fetchAuthParameters)({
                addressType: BlockchainAPITypeAPIModel.EVM,
                address: evm.address,
              })
            : undefined;
          const solanaParams = solana
            ? await withExtractDataDispatch(fetchAuthParameters)({
                addressType: BlockchainAPITypeAPIModel.Solana,
                address: solana.address,
              })
            : undefined;
          if (
            (!evmParams || evmParams.nonce === '0')
            && (!solanaParams || solanaParams.nonce === '0')
            && onBeforeSignUp
          ) {
            await (cancellation ? runCancellable(onBeforeSignUp(), cancellation) : onBeforeSignUp());
          }
          const evmMessage =
            evmParams
            // eslint-disable-next-line no-nested-ternary
            && (evmParams.nonce === '0'
              ? evmParams.brokerAddress
                ? formatMessage(
                    { id: I18nFeatureAuth.HOOKS_ACTIONS_SIGNUP_MESSAGE_WITH_BROKER },
                    { address: evmParams.brokerAddress.toLowerCase() },
                  )
                : formatMessage(
                    { id: I18nFeatureAuth.HOOKS_ACTIONS_SIGNUP_MESSAGE_NO_BROKER },
                    { address: evm.address.toLowerCase() },
                  )
              : formatMessage({ id: I18nFeatureAuth.HOOKS_ACTIONS_LOGIN_MESSAGE }, { nonce: evmParams.nonce }));
          const solanaMessage =
            solanaParams
            // eslint-disable-next-line no-nested-ternary
            && (solanaParams.nonce === '0'
              ? solanaParams.brokerAddress
                ? formatMessage(
                    { id: I18nFeatureAuth.HOOKS_ACTIONS_SIGNUP_MESSAGE_WITH_BROKER },
                    { address: solanaParams.brokerAddress },
                  )
                : formatMessage(
                    { id: I18nFeatureAuth.HOOKS_ACTIONS_SIGNUP_MESSAGE_NO_BROKER },
                    { address: solana?.address },
                  )
              : formatMessage({ id: I18nFeatureAuth.HOOKS_ACTIONS_LOGIN_MESSAGE }, { nonce: solanaParams.nonce }));
          const solanaSignature =
            solana?.signMessage && solanaMessage
              ? await (cancellation
                  ? runCancellable(solana.signMessage(solanaMessage), cancellation)
                  : solana.signMessage(solanaMessage))
              : undefined;
          const evmSignature =
            evm.signMessage && evmMessage
              ? await (cancellation
                  ? runCancellable(evm.signMessage(evmMessage), cancellation)
                  : evm.signMessage(evmMessage))
              : undefined;

          return withExtractDataDispatch(authEmail)({
            evm:
              evmSignature && evmMessage
                ? {
                    signature: parseEVMSignature(evmSignature.signature),
                    message: evmSignature.message,
                    address: evm.address,
                    partner: evmParams.brokerAddress,
                  }
                : undefined,
            solana:
              solana && solanaSignature && solanaMessage
                ? {
                    signature: solanaSignature.signature,
                    message: solanaSignature.message,
                    address: solana.address,
                    partner: solanaParams.brokerAddress,
                  }
                : undefined,
            emailToken,
            emailProvider,
          });
        }),
        () => withDispatch(storeAuthStatus)({ newState: AuthStatus.UNAUTHORIZED, expected: AuthStatus.PROCESSING }),
      ),
    [withAuthEmail, withDispatch, withExtractDataDispatch, formatMessage],
  );
  const authEmailHook = useMemo(
    () => ({ act: authEmailAction, available: !jwtState.data, inAction: authEmailInProgress || authInProgress }),
    [authEmailAction, authEmailInProgress, authInProgress, jwtState.data],
  );

  const reloginAction: UseAuthActionsType['relogin']['act'] = useMemo(
    () => withAuthWeb3((companyId: number) => withExtractDataDispatch(relogin)({ companyId })),
    [withExtractDataDispatch, withAuthWeb3],
  );
  const reloginHook = useMemo(
    () => ({ act: reloginAction, available: !!jwtState.data, inAction: authWeb3InProgress }),
    [jwtState.data, authWeb3InProgress, reloginAction],
  );

  const [loggingOut, withLoggingOut] = useSubmitting(false);
  const logoutAction: UseAuthActionsType['logout']['act'] = useMemo(
    () =>
      withLoggingOut(async (silent) => {
        if (web3Auth.web3Auth) {
          await web3Auth.web3Auth.logout();
        }
        goalReached(YMGoals.USER_LOGGED_OUT);
        await withVoidDispatch(logout)({ silent });
      }),
    [web3Auth.web3Auth, withLoggingOut, withVoidDispatch],
  );
  const logoutHook = useMemo(
    () => ({ act: logoutAction, available: !!jwtState.data, inAction: loggingOut }),
    [logoutAction, jwtState.data, loggingOut],
  );

  const [initializing, withInitializing] = useSubmitting(false);
  const initializingJWT = useActionPending(tryInitFromJWT);
  const initJWTAction = useMemo(
    () => withInitializing(withVoidDispatch(tryInitFromJWT)),
    [withInitializing, withVoidDispatch],
  );
  const tryInitJWT = useMemo(
    () => ({
      act: initJWTAction,
      available: authStatus === AuthStatus.NOT_INITIALIZED,
      inAction: initializingJWT || initializing,
    }),
    [authStatus, initJWTAction, initializing, initializingJWT],
  );

  const [disconnecting, withDisconnecting] = useSubmitting(false);
  const disconnectWeb3AuthAction: UseAuthActionsType['disconnectWeb3Auth']['act'] = useMemo(
    () =>
      withDisconnecting(async ({ signMessage }, { cancellation } = {}) => {
        const address = someOrFail(web3Auth.web3Auth?.state?.address, () => new Error('Web3AuthNotInitialized'));
        const validateAddress = async () => {
          const message = formatMessage({ id: I18nFeatureAuth.HOOKS_ACTIONS_WEB3AUTH_DISCONNECT_MESSAGE }, { address });
          const signed = await signMessage(message);
          const recovered = await recoverMessageAddress(signed);
          return recovered === address;
        };

        const validationResult = await (cancellation
          ? runCancellable(validateAddress(), cancellation)
          : validateAddress());
        if (validationResult) await logoutHook.act(true);
        return validationResult;
      }),
    [formatMessage, logoutHook, web3Auth.web3Auth, withDisconnecting],
  );
  const disconnectWeb3Auth = useMemo(
    () => ({
      act: disconnectWeb3AuthAction,
      available: web3Auth.initialized && !!web3Auth.web3Auth?.isAuthorized(),
      inAction: disconnecting,
    }),
    [disconnectWeb3AuthAction, web3Auth.initialized, web3Auth.web3Auth, disconnecting],
  );

  const [confirming, withConfirming] = useSubmitting(false);
  const confirmWeb3AuthAddressOwnershipAction: UseAuthActionsType['confirmWeb3AuthAddressOwnership']['act'] = useMemo(
    () =>
      withConfirming(async ({ signMessage }, { cancellation } = {}) => {
        const address = someOrFail(web3Auth.web3Auth?.state?.address, () => new Error('Web3AuthNotInitialized'));
        const validateAddress = async () => {
          const message = formatMessage({ id: I18nFeatureAuth.HOOKS_ACTIONS_WEB3AUTH_CONFIRM_MESSAGE }, { address });
          const signed = await signMessage(message);
          const recovered = await recoverMessageAddress(signed);
          return recovered === address;
        };

        return cancellation ? runCancellable(validateAddress(), cancellation) : validateAddress();
      }),
    [formatMessage, web3Auth.web3Auth?.state?.address, withConfirming],
  );
  const confirmWeb3AuthAddressOwnership = useMemo(
    () => ({
      act: confirmWeb3AuthAddressOwnershipAction,
      available: web3Auth.initialized && !!web3Auth.web3Auth?.isAuthorized(),
      inAction: confirming,
    }),
    [confirmWeb3AuthAddressOwnershipAction, confirming, web3Auth.initialized, web3Auth.web3Auth],
  );

  return {
    authWeb3: authWeb3Hook,
    authEmail: authEmailHook,
    relogin: reloginHook,
    logout: logoutHook,
    tryInitJWT,
    disconnectWeb3Auth,
    confirmWeb3AuthAddressOwnership,
  };
}
