import { Alert } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { Web3WalletOperationButtons } from '@/features/web3/components';
import Web3WalletInProgress from '@/features/web3/components/Web3WalletInProgress';
import Web3WalletLink from '@/features/web3/components/Web3WalletLink';
import type { Web3WalletType } from '@/features/web3/components/Web3WalletLink/types';
import { walletTypes } from '@/features/web3/components/Web3WalletLink/types';
import { withWeb3EVMConnector } from '@/features/web3/hocs';
import { useWeb3EVMConnector } from '@/features/web3/hooks';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';
import { useDefaultNotification, useStateMountSafe, useSubmitting } from '@/hooks';
import { emptyWith, noop, withFinally, withLogError } from '@/infrastructure/utils/functions';
import { cancellable, runCancellable } from '@/infrastructure/utils/ui';

import type { Web3EVMWalletConnectProps } from './types';

const Web3EVMWalletConnect: React.FC<Web3EVMWalletConnectProps> = ({
  'data-test': dataTest,
  onlyLocal,
  disabled,
  onConnect = noop,
  cancellation: supportCancellation,
  requiredAccount,
  withConnect,
  inProgressMode = 'button',
}) => {
  // const { token } = theme.useToken();
  // const styles = useMemo(
  //   () => ({
  //     container: css`
  //       width: 100%;
  //       display: flex;
  //     `,
  //     operation: css`
  //       display: flex;
  //       flex: 1;
  //       align-items: center;
  //       margin-left: auto;
  //       width: 100%;
  //     `,
  //     cancellable: css`
  //       border-top-right-radius: 0 !important;
  //       border-bottom-right-radius: 0 !important;
  //     `,
  //     cancel: css`
  //       border-top-left-radius: 0 !important;
  //       border-bottom-left-radius: 0 !important;
  //
  //       > button {
  //         border-top-left-radius: 0 !important;
  //         border-bottom-left-radius: 0 !important;
  //       }
  //     `,
  //     icon: css`
  //       display: flex;
  //     `,
  //     description: css`
  //       text-align: center;
  //       padding-top: ${token.paddingLG};
  //     `,
  //   }),
  //   [token.paddingLG],
  // );

  const {
    connector,
    refreshAccount,
    account,
    isLocal,
    isReady,
    isConnecting: baseIsConnecting,
  } = useWeb3EVMConnector();
  const [onCancel, setOnCancel] = useStateMountSafe<(() => void) | undefined>();
  const { withDefaultError } = useDefaultNotification();
  const [localIsConnecting, withConnecting] = useSubmitting(false);
  const isConnecting = localIsConnecting || baseIsConnecting;

  const doConnect = useMemo(
    () =>
      withLogError(
        withDefaultError(
          (withConnect ?? emptyWith)(
            withConnecting(
              withFinally(
                async () => {
                  const cancellation =
                    // eslint-disable-next-line no-nested-ternary
                    (supportCancellation
                      ? typeof supportCancellation === 'function'
                        ? supportCancellation()
                        : typeof supportCancellation === 'object'
                          && (supportCancellation.create?.() ?? cancellable((cancel) => setOnCancel(() => cancel)))
                      : undefined) || undefined;
                  await (cancellation ? runCancellable(connector.connect(), cancellation) : connector.connect());

                  const newAccount = await refreshAccount();
                  if (!requiredAccount || requiredAccount === newAccount) await onConnect();
                },
                () => setOnCancel(undefined),
              ),
            ),
          ),
        ),
      ),
    [
      withDefaultError,
      withConnect,
      withConnecting,
      supportCancellation,
      connector,
      refreshAccount,
      requiredAccount,
      onConnect,
      setOnCancel,
    ],
  );

  if (account) return null;

  if (!isConnecting && (!isReady || (onlyLocal && !isLocal))) {
    return (
      <Alert
        data-test={dataTest && `${dataTest}-notSupported`}
        showIcon
        message={
          <FormattedMessage
            id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_NOT_SUPPORTED}
            values={{
              provider: walletTypes.find((v) => v === connector.id) ? (
                <Web3WalletLink type={connector.id as Web3WalletType} />
              ) : (
                <b>{connector.name}</b>
              ),
            }}
            tagName="span"
          />
        }
      />
    );
  }

  if (isConnecting && inProgressMode === 'view') {
    return (
      <Web3WalletInProgress
        data-test={dataTest}
        wallet={connector}
        message={
          <FormattedMessage
            id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_DESCRIPTION}
            values={{ provider: <b>{connector.name}</b> }}
          />
        }
      />
    );
  }

  return (
    // <div className={styles.container}>
    //   <Operation
    //     ButtonProps={{ size: 'large' }}
    //     title={
    //       <FormattedMessage
    //         id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_CONNECT_TITLE}
    //         values={{ provider: connector.name }}
    //       />
    //     }
    //     className={cx(styles.operation, onCancel && styles.cancellable)}
    //     data-test={dataTest && `${dataTest}-submit`}
    //     inProgress={isConnecting}
    //     disabled={disabled}
    //     icon={<Web3ProviderIcon value={connector.id} className={styles.icon} />}
    //     onClick={doConnect}
    //     mode="button"
    //   />
    //   {onCancel && (
    //     <Operation
    //       ButtonProps={{ size: 'large' }}
    //       className={styles.cancel}
    //       title={<FormattedMessage id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_CANCEL_CONNECT_TITLE} />}
    //       data-test={dataTest && `${dataTest}-cancel`}
    //       inProgress={isConnecting}
    //       icon={<StopOutlined />}
    //       onClick={onCancel}
    //       mode="inline"
    //     />
    //   )}
    // </div>
    <Web3WalletOperationButtons
      data-test={dataTest}
      walletId={connector.id}
      cancellationTitle={
        typeof supportCancellation === 'object' && 'title' in supportCancellation && supportCancellation.title
      }
      disabled={disabled}
      inProgress={isConnecting}
      title={
        <FormattedMessage
          id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_CONNECT_TITLE}
          values={{ provider: connector.name }}
        />
      }
      onAction={doConnect}
      onCancel={onCancel}
    />
  );
};

const Web3EVMWalletConnectWithConnector = withWeb3EVMConnector<Web3EVMWalletConnectProps>(Web3EVMWalletConnect);

const Web3EVMWalletConnectMemo = React.memo(
  Web3EVMWalletConnectWithConnector,
) as typeof Web3EVMWalletConnectWithConnector;

export default Web3EVMWalletConnectMemo;
