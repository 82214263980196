import { useContext } from 'react';

import { PageLoading } from '@/components';
import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import { MessageLayout } from '@/layouts';

import type React from 'react';

const withWeb3AuthContextInitGuard = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithWeb3AuthInitGuard',
  )((props) => {
    const ctx = useContext(Web3AuthContext);
    if (!ctx.initialized) {
      return (
        <MessageLayout>
          <PageLoading type="withWeb3AuthContextInitGuard" />
        </MessageLayout>
      );
    }

    return <Component {...props} />;
  });

export default withWeb3AuthContextInitGuard;
