import React from 'react';

import { FormattedMessageWithMarkup, StatusViewElement } from '@/components';
import { KYTToleranceLevelAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';

import type { KYTToleranceLevelViewProps } from './types';

export const kytLevelLabels: Record<KYTToleranceLevelAPIModel, I18nMessages> = {
  [KYTToleranceLevelAPIModel.VeryLow]: I18nFeatureCompanySettings.COMPONENTS_KYT_TOLERANCE_LEVELS_VERY_LOW_LABEL,
  [KYTToleranceLevelAPIModel.Low]: I18nFeatureCompanySettings.COMPONENTS_KYT_TOLERANCE_LEVELS_LOW_LABEL,
  [KYTToleranceLevelAPIModel.Moderate]: I18nFeatureCompanySettings.COMPONENTS_KYT_TOLERANCE_LEVELS_MODERATE_LABEL,
  [KYTToleranceLevelAPIModel.High]: I18nFeatureCompanySettings.COMPONENTS_KYT_TOLERANCE_LEVELS_HIGH_LABEL,
  [KYTToleranceLevelAPIModel.VeryHigh]: I18nFeatureCompanySettings.COMPONENTS_KYT_TOLERANCE_LEVELS_VERY_HIGH_LABEL,
};

export const kytLevelDetails: Record<KYTToleranceLevelAPIModel, I18nMessages> = {
  [KYTToleranceLevelAPIModel.VeryLow]: I18nFeatureCompanySettings.COMPONENTS_KYT_TOLERANCE_LEVELS_VERY_LOW_DETAILS,
  [KYTToleranceLevelAPIModel.Low]: I18nFeatureCompanySettings.COMPONENTS_KYT_TOLERANCE_LEVELS_LOW_DETAILS,
  [KYTToleranceLevelAPIModel.Moderate]: I18nFeatureCompanySettings.COMPONENTS_KYT_TOLERANCE_LEVELS_MODERATE_DETAILS,
  [KYTToleranceLevelAPIModel.High]: I18nFeatureCompanySettings.COMPONENTS_KYT_TOLERANCE_LEVELS_HIGH_DETAILS,
  [KYTToleranceLevelAPIModel.VeryHigh]: I18nFeatureCompanySettings.COMPONENTS_KYT_TOLERANCE_LEVELS_VERY_HIGH_DETAILS,
};

const descriptions: Record<KYTToleranceLevelAPIModel, I18nMessages> = {
  [KYTToleranceLevelAPIModel.VeryLow]: I18nFeatureCompanySettings.COMPONENTS_KYT_TOLERANCE_LEVELS_VERY_LOW_DESCRIPTION,
  [KYTToleranceLevelAPIModel.Low]: I18nFeatureCompanySettings.COMPONENTS_KYT_TOLERANCE_LEVELS_LOW_DESCRIPTION,
  [KYTToleranceLevelAPIModel.Moderate]: I18nFeatureCompanySettings.COMPONENTS_KYT_TOLERANCE_LEVELS_MODERATE_DESCRIPTION,
  [KYTToleranceLevelAPIModel.High]: I18nFeatureCompanySettings.COMPONENTS_KYT_TOLERANCE_LEVELS_HIGH_DESCRIPTION,
  [KYTToleranceLevelAPIModel.VeryHigh]:
    I18nFeatureCompanySettings.COMPONENTS_KYT_TOLERANCE_LEVELS_VERY_HIGH_DESCRIPTION,
};

const icons: Record<KYTToleranceLevelAPIModel, React.ReactNode> = {
  [KYTToleranceLevelAPIModel.VeryLow]: <>🔴</>,
  [KYTToleranceLevelAPIModel.Low]: <>🟠</>,
  [KYTToleranceLevelAPIModel.Moderate]: <>🟡</>,
  [KYTToleranceLevelAPIModel.High]: <>🟢</>,
  [KYTToleranceLevelAPIModel.VeryHigh]: <>🔵</>,
};

const KYTToleranceLevelAPIModelView: React.FC<KYTToleranceLevelViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
  withDetails,
}) => {
  if (!value) return null;
  if (mode === 'description') return <FormattedMessageWithMarkup id={descriptions[value]} />;
  return (
    <StatusViewElement
      data-test={dataTest}
      i18n={(withDetails ? kytLevelDetails : kytLevelLabels)[value]}
      icon={icons[value]}
      mode={mode}
    />
  );
};

const KYTToleranceLevelAPIModelViewMemo = React.memo(KYTToleranceLevelAPIModelView);

export default KYTToleranceLevelAPIModelViewMemo;
