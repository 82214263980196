import { useMemo } from 'react';
import { walletActions } from 'viem';

import { useAppDispatch } from '@/app/hooks';
import { useBlockchainActions, useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import { useActionPending } from '@/features/global/hooks';
import { hintSubscriptionStatusCharge } from '@/features/subscriptions/actions';
import type { Subscription } from '@/features/subscriptions/types';
import {
  cancelSubscriptionOnBC,
  pauseSubscriptionOnBC,
  unpauseSubscriptionOnBC,
} from '@/features/subscriptions/web3-api';
import { useWeb3EVMConnector } from '@/features/web3/hooks';
import { SubscriptionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { useSubmitting } from '@/hooks';
import type { HookAction } from '@/infrastructure/model';
import { assertNotNil } from '@/infrastructure/utils/functions';
import { withCancelledByUser } from '@/infrastructure/utils/ui';

import useSubscription from './useSubscription';

type UpdateStatusUnavailabilityReason = 'no-data' | 'invalid-status' | 'no-blockchain-data' | 'invalid-chain-id';

export interface UseSubscriptionWeb3Actions {
  pause: HookAction<[], Subscription, UpdateStatusUnavailabilityReason>;
  unpause: HookAction<[], Subscription, UpdateStatusUnavailabilityReason>;
  cancel: HookAction<[], Subscription, UpdateStatusUnavailabilityReason>;
}

export default function useSubscriptionWeb3Actions(subscriptionId: string): UseSubscriptionWeb3Actions {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data: subscription } = useSubscription(subscriptionId);
  const bcSystemState = useBlockchainSystemInfo(subscription.data?.blockchain);
  const { client, chainId } = useWeb3EVMConnector();
  const bcChainId =
    bcSystemState.data.data && 'chainId' in bcSystemState.data.data ? bcSystemState.data.data.chainId : undefined;
  const subscriptionAddress = subscription.data?.contractAddress;
  const { isContractDeployed } = useBlockchainActions(subscription.data?.blockchain);

  const walletStateUnavailability = useMemo<
    Exclude<UpdateStatusUnavailabilityReason, 'invalid-status'> | undefined
  >(() => {
    if (!subscription.data) return 'no-data';
    if (!bcChainId) return 'no-blockchain-data';
    if (bcChainId !== chainId) return 'invalid-chain-id';
    return undefined;
  }, [bcChainId, chainId, subscription.data]);

  const [pausing, withPausing] = useSubmitting(false);
  const pauseUnavailabilityReason = useMemo<UpdateStatusUnavailabilityReason | undefined>(() => {
    if (walletStateUnavailability) return walletStateUnavailability;
    if (!subscription.data?.actions.pausable) return 'invalid-status';
    return undefined;
  }, [subscription.data?.actions.pausable, walletStateUnavailability]);

  const pending = useActionPending(hintSubscriptionStatusCharge, subscriptionId);
  const pauseAction: UseSubscriptionWeb3Actions['pause']['act'] = useMemo(
    () =>
      withPausing(async () => {
        assertNotNil(subscriptionAddress, () => new Error(pauseUnavailabilityReason ?? 'no-data'));
        assertNotNil(client, () => new Error(pauseUnavailabilityReason ?? 'no-data'));
        const walletClient = client.extend(walletActions);
        const receipt = await withCancelledByUser(pauseSubscriptionOnBC)(walletClient, subscriptionAddress);
        return withExtractDataDispatch(hintSubscriptionStatusCharge)({
          id: subscriptionId,
          hash: receipt.transactionHash,
          newStatus: SubscriptionStatusAPIModel.Paused,
        });
      }),
    [withPausing, subscriptionAddress, client, withExtractDataDispatch, subscriptionId, pauseUnavailabilityReason],
  );
  const pause = {
    act: pauseAction,
    available: !pauseUnavailabilityReason,
    unavailabilityReason: pauseUnavailabilityReason,
    inAction: pausing || pending,
  };

  const [unpausing, withUnpausing] = useSubmitting(false);
  const unpauseUnavailabilityReason = useMemo<UpdateStatusUnavailabilityReason | undefined>(() => {
    if (walletStateUnavailability) return walletStateUnavailability;
    if (!subscription.data?.actions.unpausable) return 'invalid-status';
    return undefined;
  }, [subscription.data?.actions.unpausable, walletStateUnavailability]);
  const unpauseAction: UseSubscriptionWeb3Actions['unpause']['act'] = useMemo(
    () =>
      withUnpausing(async () => {
        assertNotNil(subscriptionAddress, () => new Error(unpauseUnavailabilityReason ?? 'no-data'));
        assertNotNil(client, () => new Error(unpauseUnavailabilityReason ?? 'no-data'));
        const walletClient = client.extend(walletActions);
        const receipt = await withCancelledByUser(unpauseSubscriptionOnBC)(walletClient, subscriptionAddress);
        return withExtractDataDispatch(hintSubscriptionStatusCharge)({
          id: subscriptionId,
          hash: receipt.transactionHash,
          newStatus: SubscriptionStatusAPIModel.Active,
        });
      }),
    [withUnpausing, subscriptionAddress, client, withExtractDataDispatch, subscriptionId, unpauseUnavailabilityReason],
  );
  const unpause = {
    act: unpauseAction,
    available: !unpauseUnavailabilityReason,
    unavailabilityReason: unpauseUnavailabilityReason,
    inAction: unpausing || pending,
  };

  const [canceling, withCanceling] = useSubmitting(false);
  const cancelUnavailabilityReason = useMemo<UpdateStatusUnavailabilityReason | undefined>(() => {
    if (walletStateUnavailability) return walletStateUnavailability;
    if (!subscription.data?.actions.cancelable) return 'invalid-status';
    return undefined;
  }, [subscription.data?.actions.cancelable, walletStateUnavailability]);

  const cancelAction: UseSubscriptionWeb3Actions['cancel']['act'] = useMemo(
    () =>
      withCanceling(async () => {
        assertNotNil(subscriptionAddress, () => new Error(cancelUnavailabilityReason ?? 'no-data'));
        assertNotNil(client, () => new Error(cancelUnavailabilityReason ?? 'no-data'));
        const walletClient = client.extend(walletActions);
        const receipt = await withCancelledByUser(async () => {
          const isDeployed = await isContractDeployed.act(subscriptionAddress);
          return isDeployed ? cancelSubscriptionOnBC(walletClient, subscriptionAddress) : undefined;
        })();

        return withExtractDataDispatch(hintSubscriptionStatusCharge)({
          id: subscriptionId,
          hash: receipt?.transactionHash,
          newStatus: SubscriptionStatusAPIModel.Cancelled,
        });
      }),
    [
      withCanceling,
      subscriptionAddress,
      client,
      withExtractDataDispatch,
      subscriptionId,
      cancelUnavailabilityReason,
      isContractDeployed,
    ],
  );

  const cancel = {
    act: cancelAction,
    available: isContractDeployed.available && !cancelUnavailabilityReason,
    unavailabilityReason: cancelUnavailabilityReason,
    inAction: canceling || pending,
  };

  return { pause, unpause, cancel };
}
