import { useMemo } from 'react';

import type { ReCaptchaParams } from '@/features/recaptcha/types';
import { createSignMessage } from '@/features/web3/solana-utils';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { useSubmitting } from '@/hooks';
import type { HookAction } from '@/infrastructure/model';
import type { JWTTokenInfo } from '@/infrastructure/security/jwt/types';
import type { Func } from '@/infrastructure/utils/ts';

import useAuthActions from '../useAuthActions';

import type { UseAuthActionsType } from '../useAuthActions';
import type { UiWalletAccount } from '@wallet-standard/ui';

export interface UseAuthWeb3SolanaActions {
  auth: HookAction<
    [
      {
        account: UiWalletAccount;
        withRecaptcha: <T extends unknown[] = [], R = unknown>(
          func: (v: ReCaptchaParams) => Func<T, R>,
        ) => (...args: T) => Promise<R>;
      },
      Parameters<UseAuthActionsType['authWeb3']['act']>[1],
    ],
    JWTTokenInfo
  >;
  confirmWeb3AuthAddressOwnership: HookAction<
    [UiWalletAccount, Parameters<UseAuthActionsType['confirmWeb3AuthAddressOwnership']['act']>[1]],
    Awaited<ReturnType<UseAuthActionsType['confirmWeb3AuthAddressOwnership']['act']>>
  >;
}

export default function useAuthWeb3EVMActions(): UseAuthWeb3SolanaActions {
  const { authWeb3: baseAuth, confirmWeb3AuthAddressOwnership: baseConfirmWeb3AuthAddressOwnership } = useAuthActions();
  const doAuth = baseAuth.act;
  const [authInProgress, withAuth] = useSubmitting(false);
  const loginAction: UseAuthWeb3SolanaActions['auth']['act'] = useMemo(
    () =>
      withAuth(async ({ account, withRecaptcha }, options) =>
        doAuth(
          {
            address: account.address,
            addressType: BlockchainAPITypeAPIModel.Solana,
            signMessage: createSignMessage(account),
            withRecaptcha,
          },
          options,
        ),
      ),
    [withAuth, doAuth],
  );
  const login = useMemo(
    () => ({
      act: loginAction,
      available: baseAuth.available,
      inAction: authInProgress || baseAuth.inAction,
    }),
    [loginAction, baseAuth.available, baseAuth.inAction, authInProgress],
  );

  const doConfirmWeb3AuthAddressOwnership = baseConfirmWeb3AuthAddressOwnership.act;
  const [confirming, withConfirming] = useSubmitting(false);
  const confirmWeb3AuthAddressOwnershipAction: UseAuthWeb3SolanaActions['confirmWeb3AuthAddressOwnership']['act'] =
    useMemo(
      () =>
        withConfirming(async (account, options) =>
          doConfirmWeb3AuthAddressOwnership({ signMessage: createSignMessage(account) }, options),
        ),
      [withConfirming, doConfirmWeb3AuthAddressOwnership],
    );
  const confirmWeb3AuthAddressOwnership = useMemo(
    () => ({
      act: confirmWeb3AuthAddressOwnershipAction,
      available: baseConfirmWeb3AuthAddressOwnership.available,
      inAction: confirming,
    }),
    [confirmWeb3AuthAddressOwnershipAction, baseConfirmWeb3AuthAddressOwnership.available, confirming],
  );

  return {
    auth: login,
    confirmWeb3AuthAddressOwnership: confirmWeb3AuthAddressOwnership,
  };
}
