/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';

/**
 * 
 * @export
 * @interface CompanyKeyRequestAPIModel
 */
export interface CompanyKeyRequestAPIModel {
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof CompanyKeyRequestAPIModel
     */
    network: BlockchainNetworkTypeAPIModel;
}

/**
 * Check if a given object implements the CompanyKeyRequestAPIModel interface.
 */
export function instanceOfCompanyKeyRequestAPIModel(value: object): boolean {
    if (!('network' in value)) return false;
    return true;
}

export function CompanyKeyRequestAPIModelFromJSON(json: any): CompanyKeyRequestAPIModel {
    return CompanyKeyRequestAPIModelFromJSONTyped(json, false);
}

export function CompanyKeyRequestAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyKeyRequestAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'network': BlockchainNetworkTypeAPIModelFromJSON(json['network']),
    };
}

export function CompanyKeyRequestAPIModelToJSON(value?: CompanyKeyRequestAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'network': BlockchainNetworkTypeAPIModelToJSON(value['network']),
    };
}

