import { Space } from 'antd';
import { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import type { UpdateQRSupportFormData } from '@/features/company-settings/components';
import { OperationRefreshCompanySettings } from '@/features/company-settings/components';
import { useCompanyActiveAssets, useCompanySettings } from '@/features/company-settings/hooks';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { useBlockchainSystemInfos, useSelectedNetwork } from '@/features/dictionary/blockchain/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import { combine, type LoadingStateWithDirty } from '@/infrastructure/model';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';
import UpdateQRSupportLink from '@/pages/settings/company/qr-support-edit/components/UpdateQRSupportLink';

import type React from 'react';

const withQRSupportDataLoading = <
  Original extends { data: UpdateQRSupportFormData } & TestableProps,
  Wrapper extends Omit<Original, 'data'>,
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithQRSupportDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const { network } = useSelectedNetwork();
    const { data: blockchainState } = useBlockchainSystemInfos(network);
    const { data: activeAssetsState } = useCompanyActiveAssets();
    const { data: settingsState, loading, forceRefresh } = useCompanySettings();

    const data = useMemo<LoadingStateWithDirty<UpdateQRSupportFormData>>(
      () =>
        combine(
          activeAssetsState,
          combine(blockchainState, settingsState, (blockchains, settings) =>
            settings.qrSupport
              .filter(({ isEnabled }) => isEnabled)
              .filter(({ blockchain }) => {
                const bt = blockchains.find((bc) => bc.bt === blockchain);
                return bt && bt.forwarder !== 'not-supported';
              }),
          ),
          (assets, selected) => ({ assets, selected }),
        ),
      [activeAssetsState, blockchainState, settingsState],
    );

    return withCardDataLoading<UpdateQRSupportFormData, Original>({
      data,
      loading,
      forceRefresh,
      'data-test': dataTest,
      hideBack: true,
      title: (
        <Space>
          <FormattedMessage id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_QR_SUPPORT_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
        </Space>
      ),
      CardProps: {
        extra: (
          <Space>
            <UpdateQRSupportLink data-test={dataTest && `${dataTest}-opUpdate`} mode="button" />
            <OperationRefreshCompanySettings data-test={dataTest && `${dataTest}-opRefresh`} mode="inline" />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withQRSupportDataLoading;
