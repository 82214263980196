import React, { useCallback } from 'react';

import QRSupportList from '@/features/company-settings/components/QRSupportList';
import type { QRSupportListOperationsProps } from '@/features/company-settings/components/QRSupportList/types';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import QRSupportSelectOperation from '../QRSupportSelectOperation';

import type { QRSupportInputProps } from './types';

const QRSupportInput: React.FC<QRSupportInputProps> = ({
  'data-test': dataTest,
  onChange,
  disableSelection,
  value = [],
  assets = [],
}) => {
  const QRSupportSelectAction = useCallback(
    (props: QRSupportListOperationsProps) => {
      const disabled = disableSelection?.(props.settings);
      return (
        <QRSupportSelectOperation
          {...props}
          onSelect={(bt: BlockchainTypeAPIModel, checked: boolean) =>
            onChange?.(value.map((val) => (val.blockchain === bt ? { ...val, isEnabled: checked } : val)))
          }
          disabled={!!disabled}
          disabledMessage={
            !!disabled && typeof disabled === 'object' && 'message' in disabled ? disabled.message : undefined
          }
        />
      );
    },
    [disableSelection, onChange, value],
  );
  return <QRSupportList data-test={dataTest} assets={assets} settings={value} Actions={QRSupportSelectAction} />;
};

const QRSupportInputMemo = React.memo(QRSupportInput);

export default QRSupportInputMemo;
