import { isAddress as isSolanaAddress } from '@solana/addresses';
import { isAddress as isEVMAddress, parseSignature } from 'viem';

import {
  BlockchainAPITypeAPIModel,
  BlockchainNetworkTypeAPIModel,
  BlockchainTypeAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { isAddressValid as isTronAddress } from '@/infrastructure/utils/tron/address';
import { notEmpty } from '@/infrastructure/utils/ts';

import type {
  AllowanceId,
  Asset,
  AssetAddress,
  AssetAddressHistoryKey,
  BlockchainAddress,
  BlockchainInfo,
} from './types';
import type { Hex} from 'viem';

export const explorers: Record<BlockchainTypeAPIModel, string> = {
  [BlockchainTypeAPIModel.TronMainNet]: 'https://tronscan.org/#',
  [BlockchainTypeAPIModel.TronNile]: 'https://nile.tronscan.org/#',
  [BlockchainTypeAPIModel.TronShasta]: 'https://shasta.tronscan.org/#',
  [BlockchainTypeAPIModel.ArbitrumMainNet]: 'https://arbiscan.io',
  [BlockchainTypeAPIModel.ArbitrumTestNet]: 'https://goerli-rollup-explorer.arbitrum.io',
  [BlockchainTypeAPIModel.ArbitrumSepolia]: 'https://sepolia.arbiscan.io',
  [BlockchainTypeAPIModel.BinanceMainNet]: 'https://bscscan.com',
  [BlockchainTypeAPIModel.BinanceTestNet]: 'https://testnet.bscscan.com',
  [BlockchainTypeAPIModel.EthereumMainNet]: 'https://etherscan.io',
  [BlockchainTypeAPIModel.EthereumGoerli]: 'https://goerli.etherscan.io',
  [BlockchainTypeAPIModel.EthereumRopsten]: 'https://ropsten.etherscan.io',
  [BlockchainTypeAPIModel.EthereumSepolia]: 'https://sepolia.etherscan.io',
  [BlockchainTypeAPIModel.PolygonMainNet]: 'https://polygonscan.com',
  [BlockchainTypeAPIModel.PolygonAmoy]: 'https://amoy.polygonscan.com',
  [BlockchainTypeAPIModel.PolygonMumbaiNet]: 'https://mumbai.polygonscan.com',
  [BlockchainTypeAPIModel.BitcoinMainNet]: 'https://blockstream.info',
  [BlockchainTypeAPIModel.BitcoinTestNet]: 'https://blockstream.info/testnet',
  [BlockchainTypeAPIModel.SolanaMainNet]: 'https://solscan.io',
  [BlockchainTypeAPIModel.SolanaTestNet]: 'https://solscan.io',
  [BlockchainTypeAPIModel.SolanaDevNet]: 'https://solscan.io',
};

export const createBlockchainAddressKey = ({ address, bt }: BlockchainAddress) => `${bt}_${address}`;
export const createAssetAddressKey = ({ address, assetId }: AssetAddress) => `${assetId}_${address}`;
export const createAssetAddressHistoryKey = ({ address, assetId, blockNum }: AssetAddressHistoryKey) =>
  `${assetId}_${address}_${blockNum}`;
export const createAllowanceKey = ({ spender, owner, assetId }: AllowanceId) => `${assetId}_${owner}_${spender}`;

export const extractBlockchainType = ({ blockchain }: BlockchainInfo) => blockchain;
export const extractAssetId = ({ code }: Asset) => code;

export const parseAddress = async (
  address: string,
  allowed: BlockchainAPITypeAPIModel | BlockchainAPITypeAPIModel[],
  allowedNetworks: BlockchainNetworkTypeAPIModel | BlockchainNetworkTypeAPIModel[] = [
    BlockchainNetworkTypeAPIModel.MainNet,
    BlockchainNetworkTypeAPIModel.TestNet,
  ],
) => {
  const networks = Array.isArray(allowedNetworks) ? allowedNetworks : [allowedNetworks];
  const allowedApis = Array.isArray(allowed) ? allowed : [allowed];
  if (allowedApis.includes(BlockchainAPITypeAPIModel.Tron) && isTronAddress(address))
    return { address, addressType: BlockchainAPITypeAPIModel.Tron, net: networks };
  if (allowedApis.includes(BlockchainAPITypeAPIModel.Solana) && isSolanaAddress(address))
    return { address, addressType: BlockchainAPITypeAPIModel.Solana, net: networks };
  if (allowedApis.includes(BlockchainAPITypeAPIModel.EVM) && isEVMAddress(address))
    return { address, addressType: BlockchainAPITypeAPIModel.EVM, net: networks };
  if (allowedApis.includes(BlockchainAPITypeAPIModel.BTC)) {
    const bitcoin = await import('bitcoinjs-lib');
    const validNetworks = networks
      .map((network) => {
        try {
          bitcoin.address.toOutputScript(
            address,
            network === BlockchainNetworkTypeAPIModel.MainNet ? bitcoin.networks.bitcoin : bitcoin.networks.testnet,
          );
          return network;
        } catch {
          return undefined;
        }
      })
      .filter(notEmpty);
    return validNetworks.length
      ? { address, addressType: BlockchainAPITypeAPIModel.BTC, net: validNetworks }
      : undefined;
  }
  return undefined;
};

export const parseEVMSignature = (signature: Hex) => {
  const components = parseSignature(signature);
  return { ...components, v: Number(components.v) };
};
