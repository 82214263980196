/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubscriptionBriefStatsAPIModel
 */
export interface SubscriptionBriefStatsAPIModel {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionBriefStatsAPIModel
     */
    active: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionBriefStatsAPIModel
     */
    mrr: string;
}

/**
 * Check if a given object implements the SubscriptionBriefStatsAPIModel interface.
 */
export function instanceOfSubscriptionBriefStatsAPIModel(value: object): boolean {
    if (!('active' in value)) return false;
    if (!('mrr' in value)) return false;
    return true;
}

export function SubscriptionBriefStatsAPIModelFromJSON(json: any): SubscriptionBriefStatsAPIModel {
    return SubscriptionBriefStatsAPIModelFromJSONTyped(json, false);
}

export function SubscriptionBriefStatsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionBriefStatsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'active': json['active'],
        'mrr': json['mrr'],
    };
}

export function SubscriptionBriefStatsAPIModelToJSON(value?: SubscriptionBriefStatsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'active': value['active'],
        'mrr': value['mrr'],
    };
}

