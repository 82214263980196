/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentAuxAmountAPIModel
 */
export interface PaymentAuxAmountAPIModel {
    /**
     * 
     * @type {string}
     * @memberof PaymentAuxAmountAPIModel
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentAuxAmountAPIModel
     */
    forwarderAddress?: string;
}

/**
 * Check if a given object implements the PaymentAuxAmountAPIModel interface.
 */
export function instanceOfPaymentAuxAmountAPIModel(value: object): boolean {
    if (!('amount' in value)) return false;
    return true;
}

export function PaymentAuxAmountAPIModelFromJSON(json: any): PaymentAuxAmountAPIModel {
    return PaymentAuxAmountAPIModelFromJSONTyped(json, false);
}

export function PaymentAuxAmountAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentAuxAmountAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'forwarderAddress': json['forwarderAddress'] == null ? undefined : json['forwarderAddress'],
    };
}

export function PaymentAuxAmountAPIModelToJSON(value?: PaymentAuxAmountAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': value['amount'],
        'forwarderAddress': value['forwarderAddress'],
    };
}

