import { css } from '@emotion/css';
import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import type { PageLoadingIconProps } from './types';

const PageLoadingIcon: React.FC<PageLoadingIconProps> = ({ 'data-test': dataTest, className, style }) => {
  const [id] = useMemo(() => uuid().replace(/-/g, ''), []);
  return (
    <div data-test={dataTest} className={className} style={style}>
      <style>
        {`
        @keyframes innerFlip {
          0 % { transform: rotateY(0deg); }
          50% { transform: rotateY(180deg); }
          100% { transform: rotateY(360deg); }
      }`}
      </style>
      <div
        className={css`
          width: 48px;
          height: 48px;
          z-index: 1000;
        `}
      >
        <svg viewBox="0 0 135 134" fill="none" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id={id} x1="0.227539" y1="0" x2="134.002" y2="132.152" gradientUnits="userSpaceOnUse">
              <stop stopColor="#F97575" />
              <stop offset="1" stopColor="#E94F4F" />
            </linearGradient>
          </defs>
          <rect x="0.227539" width="134.114" height="133.199" rx="66.5995" fill={`url(#${id})`} />
          <g style={{ animation: '4s 0s infinite normal none running innerFlip', transformOrigin: 'center' }}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M78.0884 100.618L78.0916 101.068L56.6495 101.199C56.6462 100.736 56.6423 100.261 56.6382 99.7761C56.558 90.1804 56.4438 76.5057 60.7481 64.0344C65.5406 50.1487 75.9331 37.376 96.5128 32L102.341 51.0842C89.8984 54.3346 84.1911 61.3266 81.1753 70.0646C77.9361 79.4498 78.0143 90.322 78.0884 100.618Z"
              fill="white"
            />
            <path
              opacity="0.8"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M64.726 47.8793C58.7368 40.8803 50.1578 35.1613 38.0561 32L32.2275 51.0842C44.6705 54.3346 50.3778 61.3266 53.3936 70.0646C53.7129 70.9898 54 71.9295 54.2581 72.8817C54.8823 69.5249 55.7273 66.146 56.8687 62.8388C58.6682 57.625 61.2211 52.5378 64.726 47.8793Z"
              fill="white"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

const PageLoadingIconMemo = React.memo(PageLoadingIcon);

export default PageLoadingIconMemo;
