import * as ed from '@noble/ed25519';
import { sha512 } from '@noble/hashes/sha512';
import { base58 } from '@scure/base';

ed.etc.sha512Sync = (msg) => sha512(msg);

export const validateSolanaSignature = (address: string, signature: string, message: string) => {
  if (!ed.verify(signature.replace('0x', ''), Buffer.from(message), base58.decode(address))) {
    throw new Error('Signature is invalid');
  }
};
