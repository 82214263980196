/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PageSettlementIntentTransactionSortByAPIModel } from './PageSettlementIntentTransactionSortByAPIModel';
import {
    PageSettlementIntentTransactionSortByAPIModelFromJSON,
    PageSettlementIntentTransactionSortByAPIModelFromJSONTyped,
    PageSettlementIntentTransactionSortByAPIModelToJSON,
} from './PageSettlementIntentTransactionSortByAPIModel';
import type { SettlementIntentTransactionPredicatesAPIModel } from './SettlementIntentTransactionPredicatesAPIModel';
import {
    SettlementIntentTransactionPredicatesAPIModelFromJSON,
    SettlementIntentTransactionPredicatesAPIModelFromJSONTyped,
    SettlementIntentTransactionPredicatesAPIModelToJSON,
} from './SettlementIntentTransactionPredicatesAPIModel';

/**
 * 
 * @export
 * @interface SettlementIntentTransactionFilterAPIModel
 */
export interface SettlementIntentTransactionFilterAPIModel {
    /**
     * 
     * @type {SettlementIntentTransactionPredicatesAPIModel}
     * @memberof SettlementIntentTransactionFilterAPIModel
     */
    predicates: SettlementIntentTransactionPredicatesAPIModel;
    /**
     * 
     * @type {PageSettlementIntentTransactionSortByAPIModel}
     * @memberof SettlementIntentTransactionFilterAPIModel
     */
    page: PageSettlementIntentTransactionSortByAPIModel;
}

/**
 * Check if a given object implements the SettlementIntentTransactionFilterAPIModel interface.
 */
export function instanceOfSettlementIntentTransactionFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function SettlementIntentTransactionFilterAPIModelFromJSON(json: any): SettlementIntentTransactionFilterAPIModel {
    return SettlementIntentTransactionFilterAPIModelFromJSONTyped(json, false);
}

export function SettlementIntentTransactionFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementIntentTransactionFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': SettlementIntentTransactionPredicatesAPIModelFromJSON(json['predicates']),
        'page': PageSettlementIntentTransactionSortByAPIModelFromJSON(json['page']),
    };
}

export function SettlementIntentTransactionFilterAPIModelToJSON(value?: SettlementIntentTransactionFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': SettlementIntentTransactionPredicatesAPIModelToJSON(value['predicates']),
        'page': PageSettlementIntentTransactionSortByAPIModelToJSON(value['page']),
    };
}

