import Icon, { LinkOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import CoinbaseIcon from '@/assets/icons/providers/coinbase.svg?react';
import MetamaskIcon from '@/assets/icons/providers/metamask.svg?react';
import PhantomIcon from '@/assets/icons/providers/phantom.svg?react';
import SolflareIcon from '@/assets/icons/providers/solflare.svg?react';
import TrustIcon from '@/assets/icons/providers/trust.svg?react';
import WalletConnectIcon from '@/assets/icons/providers/walletconnect.svg?react';
import Web3AuthIcon from '@/assets/icons/providers/web3-auth.svg?react';
import { Web3AuthConnectorId } from '@/features/web3/hocs/withWeb3Support/hocs/withEVMConnectorsSupport/web3auth-connector-id';

import type { Web3ProviderIconProps } from './types';
import type { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const mapping: Partial<Record<string, typeof CoinbaseIcon>> = {
  metamask: MetamaskIcon,
  coinbase: CoinbaseIcon,
  walletconnect: WalletConnectIcon,
  phantom: PhantomIcon,
  trust: TrustIcon,
  solflare: SolflareIcon,
  [Web3AuthConnectorId.toLowerCase()]: Web3AuthIcon,
};

const Web3ProviderIcon: React.FC<Web3ProviderIconProps> = ({ 'data-test': dataTest, value, className, style }) => {
  const icon = useMemo(() => {
    const ico = Object.entries(mapping)
      .filter(([type]) => value.toLowerCase().includes(type))
      .map(([, mappingIcon]) => mappingIcon)[0];
    // typecast from antd official docs
    return ico ?? (LinkOutlined as React.ForwardRefExoticComponent<CustomIconComponentProps>);
  }, [value]);
  return <Icon component={icon} className={className} style={style} data-test={dataTest} />;
};

const Web3ProviderIconMemo = React.memo(Web3ProviderIcon);

export default Web3ProviderIconMemo;
