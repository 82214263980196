import { createAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { createAppAsyncThunk } from '@/app/actions';
import type { ReCaptchaParams } from '@/features/recaptcha/types';
import { storedDataError, storedDataLoaded } from '@/infrastructure/model';
import { withAPICall } from '@/infrastructure/model/api';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import { requestSendEmailCode, requestVerifyEmailCode } from './api';
import { makeSelectEmailCodeState } from './selectors';
import { NAMESPACE } from './types';

import type { EmailCodeState, EmailConfirmationState } from './types';

export const storeEmailCodeState = createAction<{
  email: string;
  data: EmailCodeState;
}>(`${NAMESPACE}/storeEmailCodeState`);

export const storeEmailConfirmationState = createAction<{
  email: string;
  data?: EmailConfirmationState;
}>(`${NAMESPACE}/storeEmailConfirmationState`);

export const requestEmailCode = createAppAsyncThunk(
  `${NAMESPACE}/requestEmailCode`,
  async ({ email, ...recaptcha }: { email: string } & ReCaptchaParams, { dispatch, signal }) => {
    const result = await withAPICall(requestSendEmailCode, 'Unable to send email code')(email, recaptcha, { signal });
    dispatch(
      storeEmailCodeState({
        email,
        data: {
          data: result.data && {
            sentAt: dayjs().toDate(),
            retryableAfter: dayjs().add(30, 's').toDate(),
            expiresAt: dayjs().add(3, 'minutes').toDate(),
          },
          error: result.error,
        },
      }),
    );
    goalReached(YMGoals.EMAIL_CONFIRM_REQUESTED);
    return result;
  },
);

export const verifyEmailCode = createAppAsyncThunk(
  `${NAMESPACE}/verifyEmailCode`,
  async (
    { email, code, ...recaptcha }: { email: string; code: string } & ReCaptchaParams,
    { dispatch, getState, signal },
  ) => {
    const result = await withAPICall(requestVerifyEmailCode, 'Unable to verify email code')(
      { email, code },
      recaptcha,
      { signal },
    );
    if (result.data) {
      const data = {
        email,
        code,
        auth0Token: result.data.token,
        expiresAt: dayjs().add(10, 'minutes').toDate(),
      };
      goalReached(YMGoals.EMAIL_CONFIRM_SUCCEEDED);
      dispatch(storeEmailConfirmationState({ email, data }));
      return storedDataLoaded(data);
    }
    goalReached(YMGoals.EMAIL_CONFIRM_FAILED);
    dispatch(
      storeEmailCodeState({
        email,
        data: { data: makeSelectEmailCodeState(email)(getState())?.data, error: result.error },
      }),
    );
    return storedDataError<EmailConfirmationState>(result.error!);
  },
);
