import React, { useMemo } from 'react';

import { withWeb3SolanaWallet } from '@/features/web3/hocs';
import { useWeb3SolanaWallet } from '@/features/web3/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import type { SolanaOperationContextedProps } from './types';

const SolanaOperationContexted = <
  Success = unknown,
  Account = string,
  BT extends BlockchainTypeAPIModel | undefined = undefined,
  ExtraProps = undefined,
>({
  Operation,
  withInProgress: baseWithInProgress,
  cancellation: baseCancellation,
  walletId,
  ...props
}: SolanaOperationContextedProps<Success, Account, BT, ExtraProps>) => {
  const withInProgress = useMemo(() => baseWithInProgress?.(walletId), [baseWithInProgress, walletId]);
  const cancellation = useMemo(() => baseCancellation?.(walletId), [baseCancellation, walletId]);
  const { wallet } = useWeb3SolanaWallet();

  return <Operation {...props} withInProgress={withInProgress} cancellation={cancellation} wallet={wallet} />;
};

const SolanaOperationContextedHOC = withWeb3SolanaWallet(SolanaOperationContexted);

const SolanaOperationContextedMemo = React.memo(SolanaOperationContextedHOC) as typeof SolanaOperationContexted;

export default SolanaOperationContextedMemo;
