import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { useCompanyPermissions } from '@/features/company/hooks';
import type { MerchantPermissions } from '@/features/company/types';
import SettingsIcon from '@/features/company-settings/components/SettingsIcon';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import type { FeatureFlags } from '@/features/feature-toggle/types';
import { I18nMenu } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';
import type { CustomizableRoute } from '@/layouts/types';
import { AppPageRoutes, appRoute } from '@/pages/routes';
import { SettingsTabType } from '@/pages/settings/components';
import SettingsTabIcon from '@/pages/settings/components/SettingsTabIcon';
import {
  BrandingPageRoutes,
  CompanyPageRoutes,
  settingsBrandingRoute,
  settingsBrandingSummaryLink,
  settingsCompanyRoute,
  settingsCompanySummaryLink,
  settingsComplianceLink,
  settingsIntegrationLink,
  settingsPermissionsLink,
  settingsProfileLink,
} from '@/pages/settings/routes';

import type { IntlFormattersArrow } from 'react-intl';

const menuCreator = (
  selected: boolean,
  formatMessage: IntlFormattersArrow['formatMessage'],
  featureFlags: FeatureFlags | undefined,
  permissions: MerchantPermissions | undefined,
): CustomizableRoute[] => [
  {
    name: formatMessage({ id: I18nMenu.SETTINGS_MAIN }),
    key: appRoute(AppPageRoutes.SETTINGS),
    icon: <SettingsIcon />,
    path: settingsCompanySummaryLink(),
    hideChildrenInMenu: !selected,

    children: [
      {
        name: formatMessage({ id: I18nMenu.SETTINGS_COMPANY_SUMMARY }),
        icon: <SettingsTabIcon value={SettingsTabType.company} />,
        path: settingsCompanySummaryLink(),
        hideChildrenInMenu: true,

        children: enumValues(CompanyPageRoutes)
          .filter((route) => route !== CompanyPageRoutes.SUMMARY)
          .map((route) => settingsCompanyRoute(route))
          .map((path) => ({ path })),
      },
      {
        name: formatMessage({ id: I18nMenu.SETTINGS_COMPANY_COMPLIANCE }),
        icon: <SettingsTabIcon value={SettingsTabType.compliance} />,
        path: settingsComplianceLink(),
        hideChildrenInMenu: true,
      },
      ...(permissions?.integrationManagement
        ? [
            {
              name: formatMessage({ id: I18nMenu.SETTINGS_COMPANY_INTEGRATION }),
              icon: <SettingsTabIcon value={SettingsTabType.integration} />,
              path: settingsIntegrationLink(),
              hideChildrenInMenu: true,
            },
          ]
        : []),
      ...((permissions?.brandingConfiguration || permissions?.termsOfServiceSet) && featureFlags?.enableBranding
        ? [
            {
              name: formatMessage({ id: I18nMenu.SETTINGS_COMPANY_BRANDING }),
              icon: <SettingsTabIcon value={SettingsTabType.branding} />,
              path: settingsBrandingSummaryLink(),
              hideChildrenInMenu: true,

              children: enumValues(BrandingPageRoutes)
                .filter((route) => route !== BrandingPageRoutes.SUMMARY)
                .map((route) => settingsBrandingRoute(route))
                .map((path) => ({ path })),
            },
          ]
        : []),
      {
        name: formatMessage({ id: I18nMenu.SETTINGS_COMPANY_PROFILE }),
        icon: <SettingsTabIcon value={SettingsTabType.profile} />,
        path: settingsProfileLink(),
        hideChildrenInMenu: true,
      },
      ...(permissions?.usersManagement
        ? [
            {
              name: formatMessage({ id: I18nMenu.SETTINGS_COMPANY_PERMISSIONS }),
              icon: <SettingsTabIcon value={SettingsTabType.permissions} />,
              path: settingsPermissionsLink(),
              hideChildrenInMenu: true,
            },
          ]
        : []),
    ],
  },
];

export default function useMenuData(): CustomizableRoute[] {
  const {
    data: { data: featureFlags },
  } = useFeatureToggle();
  const {
    data: { data: permissions },
  } = useCompanyPermissions();
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const selected = pathname.startsWith(appRoute(AppPageRoutes.SETTINGS));
  return useMemo(
    () => menuCreator(selected, formatMessage, featureFlags, permissions),
    [featureFlags, formatMessage, permissions, selected],
  );
}
