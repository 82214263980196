import React, { useMemo } from 'react';
import { getAddress } from 'viem';

import { FormattedMessage } from '@/components';
import { useUserAddress } from '@/features/user/hooks';
import { Web3WalletModalContainer } from '@/features/web3/components';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';

import OperationPauseSubscriptionWithConnector from '../OperationPauseSubscriptionWithConnector';

import type { PauseSubscriptionModalProps } from './types';

const PauseSubscriptionModal: React.FC<PauseSubscriptionModalProps> = ({
  'data-test': dataTest,
  onSuccess,
  onCancel,
  subscription,
}) => {
  const userAddress = useUserAddress(subscription.blockchain);
  const requiredAccount = userAddress.data.data?.value ? getAddress(userAddress.data.data.value) : undefined;

  return (
    <Web3WalletModalContainer
      data-test={dataTest}
      onSuccess={onSuccess}
      onClose={onCancel}
      title={<FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_PAUSE_SUBSCRIPTION_DIALOG_TITLE} />}
      description={
        <FormattedMessage
          id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_PAUSE_SUBSCRIPTION_DIALOG_DESCRIPTION}
          tagName="span"
        />
      }
      bt={subscription.blockchain}
      api={undefined}
      evm={useMemo(
        () => ({
          requiredAccount,
          Operation: OperationPauseSubscriptionWithConnector,
          extra: subscription,
        }),
        [requiredAccount, subscription],
      )}
    />
  );
};

const PauseSubscriptionModalMemo = React.memo(PauseSubscriptionModal) as typeof PauseSubscriptionModal;

export default PauseSubscriptionModalMemo;
