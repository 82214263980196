import { Typography } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { WebhooksList } from '@/features/company-settings/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';

import { withWebhooksDataLoading } from './hocs';

import type { WebhooksCardProps } from './types';

const { Paragraph } = Typography;

const WebhooksCard: React.FC<WebhooksCardProps> = ({ data, 'data-test': dataTest }) => (
  <>
    <Paragraph data-test={dataTest && `${dataTest}-description`}>
      <FormattedMessage id={I18nPageSettings.INTEGRATION_COMPONENTS_WEBHOOKS_CARD_DESCRIPTION} />
    </Paragraph>
    <WebhooksList data-test={dataTest && `${dataTest}-list`} data={data} />
  </>
);

const WebhooksCardLoaded = withWebhooksDataLoading(WebhooksCard);

const WebhooksCardMemo = React.memo(WebhooksCardLoaded) as typeof WebhooksCardLoaded;

export default WebhooksCardMemo;
