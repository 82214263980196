import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import {
  markSubscriptionChargeDirty,
  storeMultipleSubscriptionCharge,
  storeSubscriptionCharge,
} from '@/features/subscription-charges/actions';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';

import type { SubscriptionChargesState } from './types';
import type { Draft } from 'immer';

const initialState: SubscriptionChargesState = {
  entities: {},
};

const { storeMultipleSubscriptionChargeReducer, storeSubscriptionChargeReducer, markSubscriptionChargeDirtyReducer } =
  createSingleReducers(
    'SubscriptionCharge',
    (state: Draft<SubscriptionChargesState>) => state.entities as SubscriptionChargesState['entities'],
    (global, entities) => ({ ...global, entities }),
  );

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markSubscriptionChargeDirty, markSubscriptionChargeDirtyReducer)
    .addCase(storeSubscriptionCharge, storeSubscriptionChargeReducer)
    .addCase(storeMultipleSubscriptionCharge, storeMultipleSubscriptionChargeReducer)

    .addCase(notifyNetworkUpdated, () => initialState)
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address || previous?.activeCompanyId !== current?.activeCompanyId
        ? initialState
        : state,
    );
});

export default reducer;
