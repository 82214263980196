import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import listenerMiddleware from '@/app/listenerMiddleware';
import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { createNotification } from '@/features/global/actions';
import { makeSelectDeployingMerchantWallets, makeSelectMerchantWallets } from '@/features/merchant-wallets/selectors';
import { extractWalletId, merchantWalletIdToStoreKey } from '@/features/merchant-wallets/utils';
import { storeUser } from '@/features/user/actions';
import { makeSelectUserAddress } from '@/features/user/selectors';
import { BlockchainAPITypeAPIModel, MerchantWalletStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';
import { storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';
import { suppressPromise } from '@/infrastructure/utils/functions';
import { auditWalletViewLink } from '@/pages/audit/routes';

import {
  markMerchantWalletDeployTransactionsDirty,
  markMerchantWalletOwnershipDirty,
  markMerchantWalletsDependentDataDirty,
  markMerchantWalletsDirty,
  markMultipleMerchantWalletDeployTransactionsDirty,
  storeDeployingMerchantWalletsRefreshableAfter,
  storeMerchantWalletDeployTransactions,
  storeMerchantWalletOwnership,
  storeMerchantWallets,
} from './actions';
import { type MerchantWalletsState } from './types';

import type { Draft } from 'immer';

const initialState: MerchantWalletsState = {
  ownership: storedDirtyData,
  wallets: storedDirtyData,
  deploy: { transactions: {}, refreshableAfter: new Date() },
};

const {
  storeMerchantWalletDeployTransactionsReducer,
  markMerchantWalletDeployTransactionsDirtyReducer,
  markMultipleMerchantWalletDeployTransactionsDirtyReducer,
} = createSingleReducers(
  'MerchantWalletDeployTransactions',
  (state: Draft<MerchantWalletsState>) => state.deploy.transactions,
  (global, transactions) => ({ ...global, deploy: { ...global.deploy, transactions } }),
  merchantWalletIdToStoreKey,
);

const { storeMerchantWalletOwnershipReducer, markMerchantWalletOwnershipDirtyReducer } = createLoadingDataReducers(
  'MerchantWalletOwnership',
  (state: Draft<MerchantWalletsState>) => state.ownership,
  (global, ownership) => ({ ...global, ownership }),
);

const { storeMerchantWalletsReducer, markMerchantWalletsDirtyReducer } = createLoadingDataReducers(
  'MerchantWallets',
  (state: Draft<MerchantWalletsState>) => state.wallets,
  (state, wallets) => ({ ...state, wallets }),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markMerchantWalletDeployTransactionsDirty, markMerchantWalletDeployTransactionsDirtyReducer)
    .addCase(storeMerchantWalletDeployTransactions, storeMerchantWalletDeployTransactionsReducer)
    .addCase(
      markMultipleMerchantWalletDeployTransactionsDirty,
      markMultipleMerchantWalletDeployTransactionsDirtyReducer,
    )

    .addCase(markMerchantWalletOwnershipDirty, markMerchantWalletOwnershipDirtyReducer)
    .addCase(storeMerchantWalletOwnership, storeMerchantWalletOwnershipReducer)

    .addCase(markMerchantWalletsDirty, markMerchantWalletsDirtyReducer)
    .addCase(storeMerchantWallets, storeMerchantWalletsReducer)

    .addCase(storeDeployingMerchantWalletsRefreshableAfter, (state, { payload: refreshableAfter }) => ({
      ...state,
      deploy: { ...state.deploy, refreshableAfter },
    }))

    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address || previous?.activeCompanyId !== current?.activeCompanyId
        ? initialState
        : state,
    );

  listenerMiddleware.startListening({
    matcher: isAnyOf(storeMerchantWallets),
    effect: (_, listenerApi) => {
      const wereDeploying =
        makeSelectDeployingMerchantWallets()(listenerApi.getOriginalState())
          .data?.map(extractWalletId)
          .map(merchantWalletIdToStoreKey) ?? [];
      if (wereDeploying.length) {
        const updated =
          makeSelectMerchantWallets()(listenerApi.getState()).data?.filter((v) =>
            wereDeploying.includes(merchantWalletIdToStoreKey(extractWalletId(v))),
          ) ?? [];
        const succeeded = updated.filter(({ status }) => MerchantWalletStatusAPIModel.Deployed === status);
        const failed = updated.filter(({ status }) =>
          [MerchantWalletStatusAPIModel.DeployFailed, MerchantWalletStatusAPIModel.Invalid].includes(status),
        );

        succeeded.forEach((wallet) =>
          listenerApi.dispatch(
            createNotification({
              severity: 'success',
              messageI18n: I18nFeatureMerchantWallets.MESSAGES_DEPLOYING_FINISHED_SUCCESS_MESSAGE,
              descriptionI18n: I18nFeatureMerchantWallets.MESSAGES_DEPLOYING_FINISHED_SUCCESS_DESCRIPTION,
              descriptionI18nValues: {
                ln: { type: 'link', to: auditWalletViewLink(wallet.blockchain, wallet.address) },
                bt: wallet.blockchain,
              },
            }),
          ),
        );

        failed.forEach((wallet) =>
          listenerApi.dispatch(
            createNotification({
              severity: 'success',
              messageI18n: I18nFeatureMerchantWallets.MESSAGES_DEPLOYING_FINISHED_FAILURE,
              messageI18nValues: { ln: { type: 'link', to: auditWalletViewLink(wallet.blockchain, wallet.address) } },
            }),
          ),
        );

        if (succeeded.length || failed.length) {
          const walletIds = [...succeeded, ...failed].map(extractWalletId);
          suppressPromise(listenerApi.dispatch(markMerchantWalletsDependentDataDirty(walletIds)));
        }
      }
    },
  });

  listenerMiddleware.startListening({
    matcher: isAnyOf(storeUser),
    effect: (_, listenerApi) => {
      const oldAddress = makeSelectUserAddress(BlockchainAPITypeAPIModel.EVM)(listenerApi.getOriginalState());
      const newAddress = makeSelectUserAddress(BlockchainAPITypeAPIModel.EVM)(listenerApi.getState());
      if (oldAddress.data?.value !== newAddress.data?.value) {
        listenerApi.dispatch(markMerchantWalletOwnershipDirty());
        listenerApi.dispatch(markMerchantWalletsDirty());
      }
    },
  });
});

export default reducer;
