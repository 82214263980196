import PageLoading from '@/components/PageLoading';
import { namedHOC } from '@/infrastructure/utils/react';
import { useYandexMetrikaInit } from '@/infrastructure/ym/hooks';
import MessageLayout from '@/layouts/MessageLayout';

import type React from 'react';
import type { PropsWithChildren } from 'react';

const withYandexMetrika = <P extends PropsWithChildren>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithYandexMetrika',
  )((props) => {
    const { loaded, ymIsEnabled } = useYandexMetrikaInit();

    return !ymIsEnabled || loaded ? (
      <Component {...props} />
    ) : (
      <MessageLayout>
        <PageLoading type="withYandexMetrika" />
      </MessageLayout>
    );
  });

export default withYandexMetrika;
