import type { EmptyObject } from '@/infrastructure/utils/ts';

import { withWeb3AuthContextInitGuard, withWeb3AuthConsistencyGuard } from './hocs';

import type React from 'react';

const withWeb3AuthLifecycle = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  withWeb3AuthContextInitGuard(withWeb3AuthConsistencyGuard(Component));

export default withWeb3AuthLifecycle;
