import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import { useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import useSubscriptionWeb3Actions from '@/features/subscriptions/hooks/useSubscriptionWeb3Actions';
import { Web3EVMWalletOperation } from '@/features/web3/components';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nCommon, I18nFeatureSubscriptions } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { mapStoredState } from '@/infrastructure/model';
import { emptyWith, noop, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';
import { isCancelled } from '@/infrastructure/utils/ui';
import SubscriptionLink from '@/pages/subscriptions/subscription-view/components/SubscriptionLink';

import type { OperationUnpauseSubscriptionWithConnectorProps } from './types';
import type { ReactNode } from 'react';

const OperationUnpauseSubscriptionWithConnector: React.FC<OperationUnpauseSubscriptionWithConnectorProps> = ({
  'data-test': dataTest,
  connector,
  extra: subscription,
  requiredAccount,
  onSuccess,
  withInProgress,
}) => {
  const { unpause: unpauseAction } = useSubscriptionWeb3Actions(subscription.id);
  const blockchainState = useBlockchainSystemInfo(subscription.blockchain);
  const blockchain = useMemo(
    () =>
      mapStoredState(blockchainState.data, (state) => (state.apiType === BlockchainAPITypeAPIModel.EVM ? state : null)),
    [blockchainState.data],
  );
  const { withNotification } = useNotification();
  const options = useMemo(
    () => ({
      error: (error?: unknown) =>
        !isCancelled(error)
          ? {
              message: (
                <FormattedMessage
                  id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_FAILED_MESSAGE}
                />
              ),
              description: (
                <FormattedMessageWithMarkup
                  id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_FAILED_DESCRIPTION}
                  values={{ account: requiredAccount }}
                />
              ),
            }
          : undefined,
      success: () => ({
        message: (
          <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_SUCCESS_MESSAGE} />
        ),
        description: (
          <FormattedMessage
            id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_SUCCESS_DESCRIPTION}
            values={{ ln: (text: ReactNode) => <SubscriptionLink value={subscription.id} title={text} mode="text" /> }}
          />
        ),
      }),
    }),
    [requiredAccount, subscription.id],
  );
  const unpause = useMemo(
    () =>
      withSuppressError(
        withNotification(withOnSuccess((withInProgress ?? emptyWith)(unpauseAction.act), onSuccess ?? noop), options),
      ),
    [withNotification, withInProgress, unpauseAction.act, onSuccess, options],
  );
  const disabledMessage = useMemo(() => {
    switch (unpauseAction.unavailabilityReason) {
      case 'invalid-status':
        return (
          <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_INVALID_STATUS} />
        );
      case 'no-data':
      case 'no-blockchain-data':
        return <FormattedMessage id={I18nCommon.MESSAGES_LOADING} />;
      case 'invalid-chain-id':
        return (
          <FormattedMessage
            id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_INVALID_CHAIN_ID}
            values={{ network: subscription.blockchain, chainId: blockchain.data?.chainId }}
          />
        );
      case undefined:
        return undefined;
    }
  }, [blockchain.data?.chainId, unpauseAction.unavailabilityReason, subscription.blockchain]);

  return (
    <Web3EVMWalletOperation
      data-test={dataTest}
      connector={connector}
      disabled={!unpauseAction.available}
      disabledMessage={disabledMessage}
      requiredChain={useMemo(
        () =>
          blockchain.data?.chainId ? { id: blockchain.data.chainId, network: subscription.blockchain } : undefined,
        [blockchain.data?.chainId, subscription.blockchain],
      )}
      requiredAccount={requiredAccount}
      inProgress={unpauseAction.inAction}
      mainAction={useMemo(
        () => ({
          runOnConnect: true,
          onAction: unpause,
          title: (
            <FormattedMessage
              id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_CONNECTOR_TITLE}
              values={{ connector: connector.name }}
            />
          ),
        }),
        [connector.name, unpause],
      )}
      showAccount
    />
  );
};

const OperationUnpauseSubscriptionWithConnectorMemo = React.memo(
  OperationUnpauseSubscriptionWithConnector,
) as typeof OperationUnpauseSubscriptionWithConnector;

export default OperationUnpauseSubscriptionWithConnectorMemo;
