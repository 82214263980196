import { Form } from 'antd';
import React, { useEffect, useMemo } from 'react';

import type { KYTToleranceLevelAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { useForm, useSharedStyles } from '@/hooks';

import { CompanyKYBStateItem, CompanyKYTLevelItem } from './components';
import { withGeneralSettingsDataLoading } from './hocs';

import type { GeneralSettingsCardProps } from './types';

interface GeneralSettingsFormValues {
  isKYBFinished: boolean;
  kytLevel: KYTToleranceLevelAPIModel;
}

const GeneralSettingsCard: React.FC<GeneralSettingsCardProps> = ({
  data: { company, settings },
  'data-test': dataTest,
}) => {
  const { form } = useForm<GeneralSettingsFormValues>();
  useEffect(() => {
    setTimeout(() => form.resetFields(), 0);
    // to refresh form values on settings or company update
  }, [form, settings, company]);
  const initialValues = useMemo<GeneralSettingsFormValues>(
    () => ({ isKYBFinished: company.isKYBFinished, kytLevel: settings.kytLevel }),
    [company.isKYBFinished, settings.kytLevel],
  );
  const { disabledTextInput } = useSharedStyles();
  return (
    <Form<GeneralSettingsFormValues>
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      initialValues={initialValues}
    >
      <CompanyKYBStateItem<GeneralSettingsFormValues>
        name="isKYBFinished"
        data-test={dataTest && `${dataTest}-kyb`}
        className={disabledTextInput}
        companyId={company.id}
      />
      <CompanyKYTLevelItem<GeneralSettingsFormValues>
        name="kytLevel"
        data-test={dataTest && `${dataTest}-kyt`}
        className={disabledTextInput}
      />
    </Form>
  );
};

const GeneralSettingsCardLoaded = withGeneralSettingsDataLoading(GeneralSettingsCard);

const GeneralSettingsCardMemo = React.memo(GeneralSettingsCardLoaded) as typeof GeneralSettingsCardLoaded;

export default GeneralSettingsCardMemo;
