import { useCallback, useMemo } from 'react';
import { walletActions } from 'viem';

import { useAppDispatch } from '@/app/hooks';
import { useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import { useActionPending } from '@/features/global/hooks';
import { refreshMerchantWallet } from '@/features/merchant-wallets/actions';
import { requestDeployMerchantAddress } from '@/features/merchant-wallets/web3-api';
import { useUserAddress } from '@/features/user/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { useSubmitting } from '@/hooks';
import { type HookAction, mapStoredState } from '@/infrastructure/model';
import { assertNotNil, someOrFail } from '@/infrastructure/utils/functions';
import { withCancelledByUser } from '@/infrastructure/utils/ui';

import useIsActiveMerchantWalletDeployed from '../useIsActiveMerchantWalletDeployed';
import useMerchantWalletOwnership from '../useMerchantWalletOwnership';

import type { Account, Chain, Client, Transport } from 'viem';

export type RefreshUnavailabilityReason = 'loading' | 'already-deployed';

export type DeployUnavailabilityReason =
  | 'loading'
  | 'already-deployed'
  | 'no-blockchain-data'
  | 'no-user-address'
  | 'no-wallet-signature'
  | 'invalid-chain-id'
  | 'unsupported-chain';

export interface UseMerchantWalletActionsType {
  refresh: HookAction<[], void, RefreshUnavailabilityReason>;
  deploy: HookAction<[Client<Transport, Chain, Account>], void, DeployUnavailabilityReason>;
}

export default function useMerchantWalletActions(
  bt: BlockchainTypeAPIModel | undefined,
  address: string | undefined,
  chainId?: number,
): UseMerchantWalletActionsType {
  const { withExtractDataDispatch } = useAppDispatch();
  const ownershipState = useMerchantWalletOwnership();
  const walletDeployState = useIsActiveMerchantWalletDeployed(bt);
  const addressState = useUserAddress(BlockchainAPITypeAPIModel.EVM);
  const bcSystemState = useBlockchainSystemInfo(bt);
  const bcState = useMemo(
    () =>
      mapStoredState(bcSystemState.data, (state) => (state.apiType === BlockchainAPITypeAPIModel.EVM ? state : null)),
    [bcSystemState.data],
  );
  const merchantWalletFactoryAddress = bcState.data?.merchantWalletFactoryAddress;
  const bcChainId = bcState.data?.chainId;

  const refreshUnavailabilityReason: UseMerchantWalletActionsType['refresh']['unavailabilityReason'] = useMemo(() => {
    if (walletDeployState.loading) return 'loading';
    if (walletDeployState.data) return 'already-deployed';
    return undefined;
  }, [walletDeployState.data, walletDeployState.loading]);

  const refreshing = useActionPending(refreshMerchantWallet);
  const refreshAction: UseMerchantWalletActionsType['refresh']['act'] = useCallback(
    () => withExtractDataDispatch(refreshMerchantWallet)({ bt: someOrFail(bt), address }),
    [withExtractDataDispatch, bt, address],
  );
  const refresh = {
    act: refreshAction,
    inAction: refreshing,
    unavailabilityReason: refreshUnavailabilityReason,
    available: !refreshUnavailabilityReason,
  };

  const deployUnavailabilityReason: UseMerchantWalletActionsType['deploy']['unavailabilityReason'] = useMemo(() => {
    if (
      bcSystemState.data.isDirty
      || (bcSystemState.loading && !bcSystemState.data.data)
      || ownershipState.data.isDirty
      || (ownershipState.loading && !ownershipState.data.data)
      || addressState.data.isDirty
      || (addressState.loading && !addressState.data.data)
      || walletDeployState.loading
    )
      return 'loading';
    if (!bcState.isDirty && !bcSystemState.loading && !!bcSystemState.data.data && !bcState.data?.apiType)
      return 'unsupported-chain';
    if (!merchantWalletFactoryAddress || !bcChainId) return 'no-blockchain-data';
    if (!ownershipState.data.data) return 'no-wallet-signature';
    if (!addressState.data.data?.value) return 'no-user-address';
    if (walletDeployState.data) return 'already-deployed';
    if (chainId && bcChainId && bcChainId !== chainId) return 'invalid-chain-id';
    return undefined;
  }, [
    bcSystemState.data.isDirty,
    bcSystemState.data.data,
    bcSystemState.loading,
    ownershipState.data.isDirty,
    ownershipState.data.data,
    ownershipState.loading,
    addressState.data.isDirty,
    addressState.data.data,
    addressState.loading,
    walletDeployState.loading,
    walletDeployState.data,
    bcState.isDirty,
    bcState.data?.apiType,
    merchantWalletFactoryAddress,
    bcChainId,
    chainId,
  ]);

  const [deploying, withDeploying] = useSubmitting(false);
  const deployAction: UseMerchantWalletActionsType['deploy']['act'] = useMemo(
    () =>
      withDeploying(async (client) => {
        if (deployUnavailabilityReason) throw new Error(deployUnavailabilityReason);
        const signature = someOrFail(ownershipState.data.data);
        const userAddress = someOrFail(addressState.data.data?.value);
        assertNotNil(merchantWalletFactoryAddress);
        assertNotNil(bt);
        const walletClient = client.extend(walletActions);
        const deployedAddress = await withCancelledByUser(requestDeployMerchantAddress)(
          walletClient,
          merchantWalletFactoryAddress,
          userAddress,
          signature,
        );
        assertNotNil(deployedAddress, () => new Error('no address'));

        await withExtractDataDispatch(refreshMerchantWallet)({ bt, address: deployedAddress });
      }),
    [
      withDeploying,
      deployUnavailabilityReason,
      ownershipState.data.data,
      addressState.data.data,
      merchantWalletFactoryAddress,
      bt,
      withExtractDataDispatch,
    ],
  );
  const deploy = {
    act: deployAction,
    inAction: deploying,
    unavailabilityReason: deployUnavailabilityReason,
    available: !deployUnavailabilityReason,
  };

  return { deploy, refresh };
}
