import React from 'react';

import type { SolanaWalletMeta } from '@/features/web3/types';

import type { UiWalletAccount } from '@wallet-standard/ui';

export type Web3SolanaWalletContextType = Omit<SolanaWalletMeta, 'wallet'> &
  Required<Pick<SolanaWalletMeta, 'wallet'>> & {
    isLocal: boolean;
    account?: UiWalletAccount;
    isConnecting?: boolean;
    isReady: boolean;
  };

const Web3SolanaWalletContext = React.createContext<Web3SolanaWalletContextType | undefined>(undefined);

export default Web3SolanaWalletContext;
