/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { ForwarderDelegateStatusAPIModel } from './ForwarderDelegateStatusAPIModel';
import {
    ForwarderDelegateStatusAPIModelFromJSON,
    ForwarderDelegateStatusAPIModelFromJSONTyped,
    ForwarderDelegateStatusAPIModelToJSON,
} from './ForwarderDelegateStatusAPIModel';

/**
 * 
 * @export
 * @interface ForwarderDelegateAPIModel
 */
export interface ForwarderDelegateAPIModel {
    /**
     * 
     * @type {string}
     * @memberof ForwarderDelegateAPIModel
     */
    id: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof ForwarderDelegateAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {ForwarderDelegateStatusAPIModel}
     * @memberof ForwarderDelegateAPIModel
     */
    status: ForwarderDelegateStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof ForwarderDelegateAPIModel
     */
    merchantWallet: string;
    /**
     * 
     * @type {string}
     * @memberof ForwarderDelegateAPIModel
     */
    forwarderFactory: string;
    /**
     * 
     * @type {string}
     * @memberof ForwarderDelegateAPIModel
     */
    address?: string;
    /**
     * 
     * @type {Date}
     * @memberof ForwarderDelegateAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ForwarderDelegateAPIModel
     */
    updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof ForwarderDelegateAPIModel
     */
    deployAttempts: number;
    /**
     * 
     * @type {Date}
     * @memberof ForwarderDelegateAPIModel
     */
    nextDeployAttemptAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ForwarderDelegateAPIModel
     */
    deployFailureReason?: string;
}

/**
 * Check if a given object implements the ForwarderDelegateAPIModel interface.
 */
export function instanceOfForwarderDelegateAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('status' in value)) return false;
    if (!('merchantWallet' in value)) return false;
    if (!('forwarderFactory' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    if (!('deployAttempts' in value)) return false;
    return true;
}

export function ForwarderDelegateAPIModelFromJSON(json: any): ForwarderDelegateAPIModel {
    return ForwarderDelegateAPIModelFromJSONTyped(json, false);
}

export function ForwarderDelegateAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForwarderDelegateAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'status': ForwarderDelegateStatusAPIModelFromJSON(json['status']),
        'merchantWallet': json['merchantWallet'],
        'forwarderFactory': json['forwarderFactory'],
        'address': json['address'] == null ? undefined : json['address'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'deployAttempts': json['deployAttempts'],
        'nextDeployAttemptAt': json['nextDeployAttemptAt'] == null ? undefined : (new Date(json['nextDeployAttemptAt'])),
        'deployFailureReason': json['deployFailureReason'] == null ? undefined : json['deployFailureReason'],
    };
}

export function ForwarderDelegateAPIModelToJSON(value?: ForwarderDelegateAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'status': ForwarderDelegateStatusAPIModelToJSON(value['status']),
        'merchantWallet': value['merchantWallet'],
        'forwarderFactory': value['forwarderFactory'],
        'address': value['address'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
        'deployAttempts': value['deployAttempts'],
        'nextDeployAttemptAt': value['nextDeployAttemptAt'] == null ? undefined : ((value['nextDeployAttemptAt']).toISOString()),
        'deployFailureReason': value['deployFailureReason'],
    };
}

