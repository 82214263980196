/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PageSettlementIntentSortByAPIModel } from './PageSettlementIntentSortByAPIModel';
import {
    PageSettlementIntentSortByAPIModelFromJSON,
    PageSettlementIntentSortByAPIModelFromJSONTyped,
    PageSettlementIntentSortByAPIModelToJSON,
} from './PageSettlementIntentSortByAPIModel';
import type { SettlementIntentPredicatesAPIModel } from './SettlementIntentPredicatesAPIModel';
import {
    SettlementIntentPredicatesAPIModelFromJSON,
    SettlementIntentPredicatesAPIModelFromJSONTyped,
    SettlementIntentPredicatesAPIModelToJSON,
} from './SettlementIntentPredicatesAPIModel';

/**
 * 
 * @export
 * @interface SettlementIntentFilterAPIModel
 */
export interface SettlementIntentFilterAPIModel {
    /**
     * 
     * @type {SettlementIntentPredicatesAPIModel}
     * @memberof SettlementIntentFilterAPIModel
     */
    predicates: SettlementIntentPredicatesAPIModel;
    /**
     * 
     * @type {PageSettlementIntentSortByAPIModel}
     * @memberof SettlementIntentFilterAPIModel
     */
    page: PageSettlementIntentSortByAPIModel;
}

/**
 * Check if a given object implements the SettlementIntentFilterAPIModel interface.
 */
export function instanceOfSettlementIntentFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function SettlementIntentFilterAPIModelFromJSON(json: any): SettlementIntentFilterAPIModel {
    return SettlementIntentFilterAPIModelFromJSONTyped(json, false);
}

export function SettlementIntentFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementIntentFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': SettlementIntentPredicatesAPIModelFromJSON(json['predicates']),
        'page': PageSettlementIntentSortByAPIModelFromJSON(json['page']),
    };
}

export function SettlementIntentFilterAPIModelToJSON(value?: SettlementIntentFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': SettlementIntentPredicatesAPIModelToJSON(value['predicates']),
        'page': PageSettlementIntentSortByAPIModelToJSON(value['page']),
    };
}

