import { css } from '@emotion/css';
import React, { useContext } from 'react';

import { PageLoading } from '@/components';
import Web3SolanaInitContext from '@/features/web3/hocs/withWeb3Support/hocs/withSolanaWalletsSupport/context';
import { useFirstRenderEffect } from '@/hooks';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import { SolanaWalletErrorBoundary } from './components';

const Web3ContextLoading = () => (
  <PageLoading
    type="withWeb3SolanaContext"
    className={css`
      height: auto;
    `}
  />
);

const withWeb3SolanaContext = <T extends EmptyObject>(
  Component: React.ComponentType<T>,
  LoadingComponent: React.ComponentType = Web3ContextLoading,
): React.FC<T> =>
  namedHOC(
    Component,
    'WithWeb3SolanaContext',
  )((props) => {
    const ctx = useContext(Web3SolanaInitContext);
    useFirstRenderEffect(ctx.initialize);
    return ctx.initialized ? (
      <SolanaWalletErrorBoundary>
        <Component {...props} />
      </SolanaWalletErrorBoundary>
    ) : (
      <LoadingComponent />
    );
  });

export default withWeb3SolanaContext;
