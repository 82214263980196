import toString from 'lodash-es/toString';
import { useMemo } from 'react';

import { getAppStore } from '@/app/hocs/withStore';
import type { CommonLoadingState, LoadingStateWithDirty } from '@/infrastructure/model';
import { withExtractData } from '@/infrastructure/model';

import type { AppAsyncThunk, AppStore } from '../store';
import type { ActionCreatorWithPayload } from '@reduxjs/toolkit';

export type UseAppDispatch = () => AppStore['dispatch'] & {
  dispatch: AppStore['dispatch'];
  withExtractDataDispatch: <Returned, ThunkArg>(
    action:
      | AppAsyncThunk<CommonLoadingState<Returned>, ThunkArg>
      | AppAsyncThunk<LoadingStateWithDirty<Returned>, ThunkArg>,
  ) => (args: ThunkArg) => Promise<Returned>;
  withVoidDispatch: <ThunkArg, Returned>(
    action: AppAsyncThunk<Returned, ThunkArg extends undefined ? undefined : ThunkArg>,
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  ) => ThunkArg extends void ? () => Promise<void> : (args: ThunkArg) => Promise<void>;
  withDispatch: <Payload>(
    creator: ActionCreatorWithPayload<Payload>,
  ) => (args: Payload extends never ? never : Payload) => void;
};

const useAppDispatch: UseAppDispatch = () => {
  const { dispatch } = getAppStore();

  return useMemo(() => {
    const result = ((...args: Parameters<typeof dispatch>) => dispatch(...args)) as ReturnType<UseAppDispatch>;
    result.dispatch = dispatch;
    result.withExtractDataDispatch =
      <Returned, ThunkArg>(
        action:
          | AppAsyncThunk<CommonLoadingState<Returned>, ThunkArg>
          | AppAsyncThunk<LoadingStateWithDirty<Returned>, ThunkArg>,
      ) =>
      (args: ThunkArg) =>
        // FIXME: don't know how to proof the AppAsyncThunk arg is the correct one
        withExtractData(() => dispatch(action(args as never)).unwrap())();
    result.withVoidDispatch = (<Returned, ThunkArg>(
        action: AppAsyncThunk<Returned, ThunkArg extends undefined ? undefined : ThunkArg>,
      ) =>
      async (args: ThunkArg) => {
        // FIXME: don't know how to proof the AppAsyncThunk arg is the correct one
        const data: Returned | undefined = await dispatch(action(args as never)).unwrap();
        if (data && typeof data === 'object' && 'error' in data) throw new Error(toString(data.error));
      }) as never;
    result.withDispatch =
      <Payload>(creator: ActionCreatorWithPayload<Payload>) =>
      (args: Payload extends never ? never : Payload) => {
        dispatch(creator(args as Payload));
      };
    return result;
  }, [dispatch]);
};

export default useAppDispatch;
