/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { DateTimeRangeAPIModel } from './DateTimeRangeAPIModel';
import {
    DateTimeRangeAPIModelFromJSON,
    DateTimeRangeAPIModelFromJSONTyped,
    DateTimeRangeAPIModelToJSON,
} from './DateTimeRangeAPIModel';
import type { WithdrawalReconciliationStatusAPIModel } from './WithdrawalReconciliationStatusAPIModel';
import {
    WithdrawalReconciliationStatusAPIModelFromJSON,
    WithdrawalReconciliationStatusAPIModelFromJSONTyped,
    WithdrawalReconciliationStatusAPIModelToJSON,
} from './WithdrawalReconciliationStatusAPIModel';

/**
 * 
 * @export
 * @interface WithdrawalReconciliationPredicatesAPIModel
 */
export interface WithdrawalReconciliationPredicatesAPIModel {
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof WithdrawalReconciliationPredicatesAPIModel
     */
    btEq?: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalReconciliationPredicatesAPIModel
     */
    partnerAddressEq?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof WithdrawalReconciliationPredicatesAPIModel
     */
    companyIdIn?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WithdrawalReconciliationPredicatesAPIModel
     */
    companyHidIn?: Array<string>;
    /**
     * 
     * @type {Array<WithdrawalReconciliationStatusAPIModel>}
     * @memberof WithdrawalReconciliationPredicatesAPIModel
     */
    statusIn?: Array<WithdrawalReconciliationStatusAPIModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WithdrawalReconciliationPredicatesAPIModel
     */
    idIn?: Array<string>;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof WithdrawalReconciliationPredicatesAPIModel
     */
    withdrawnAtRange?: DateTimeRangeAPIModel;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof WithdrawalReconciliationPredicatesAPIModel
     */
    nextAttemptAtRange?: DateTimeRangeAPIModel;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof WithdrawalReconciliationPredicatesAPIModel
     */
    networkEq?: BlockchainNetworkTypeAPIModel;
}

/**
 * Check if a given object implements the WithdrawalReconciliationPredicatesAPIModel interface.
 */
export function instanceOfWithdrawalReconciliationPredicatesAPIModel(value: object): boolean {
    return true;
}

export function WithdrawalReconciliationPredicatesAPIModelFromJSON(json: any): WithdrawalReconciliationPredicatesAPIModel {
    return WithdrawalReconciliationPredicatesAPIModelFromJSONTyped(json, false);
}

export function WithdrawalReconciliationPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalReconciliationPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'btEq': json['btEq'] == null ? undefined : BlockchainTypeAPIModelFromJSON(json['btEq']),
        'partnerAddressEq': json['partnerAddressEq'] == null ? undefined : json['partnerAddressEq'],
        'companyIdIn': json['companyIdIn'] == null ? undefined : json['companyIdIn'],
        'companyHidIn': json['companyHidIn'] == null ? undefined : json['companyHidIn'],
        'statusIn': json['statusIn'] == null ? undefined : ((json['statusIn'] as Array<any>).map(WithdrawalReconciliationStatusAPIModelFromJSON)),
        'idIn': json['idIn'] == null ? undefined : json['idIn'],
        'withdrawnAtRange': json['withdrawnAtRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['withdrawnAtRange']),
        'nextAttemptAtRange': json['nextAttemptAtRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['nextAttemptAtRange']),
        'networkEq': json['networkEq'] == null ? undefined : BlockchainNetworkTypeAPIModelFromJSON(json['networkEq']),
    };
}

export function WithdrawalReconciliationPredicatesAPIModelToJSON(value?: WithdrawalReconciliationPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'btEq': BlockchainTypeAPIModelToJSON(value['btEq']),
        'partnerAddressEq': value['partnerAddressEq'],
        'companyIdIn': value['companyIdIn'],
        'companyHidIn': value['companyHidIn'],
        'statusIn': value['statusIn'] == null ? undefined : ((value['statusIn'] as Array<any>).map(WithdrawalReconciliationStatusAPIModelToJSON)),
        'idIn': value['idIn'],
        'withdrawnAtRange': DateTimeRangeAPIModelToJSON(value['withdrawnAtRange']),
        'nextAttemptAtRange': DateTimeRangeAPIModelToJSON(value['nextAttemptAtRange']),
        'networkEq': BlockchainNetworkTypeAPIModelToJSON(value['networkEq']),
    };
}

