import { noop } from '@/infrastructure/utils/functions';

export enum YMGoals {
  USER_LOGGED_IN = 'USER_LOGGED_IN',
  USER_TOS_READ = 'USER_TOS_READ',
  USER_LOGGED_OUT = 'USER_LOGGED_OUT',

  USER_ADD_ADDRESS_SHOWN = 'USER_ADD_ADDRESS_SHOWN',
  USER_ADD_SOLANA_ADDRESS_FINISHED = 'USER_ADD_SOLANA_ADDRESS_FINISHED',
  USER_ADD_EVM_ADDRESS_FINISHED = 'USER_ADD_EVM_ADDRESS_FINISHED',

  WALLET_MM_INSTALLED = 'WALLET_MM_INSTALLED',

  AUTH_WALLET_SOLANA_INIT = 'AUTH_WALLET_SOLANA_INIT',
  AUTH_WALLET_EVM_INIT = 'AUTH_WALLET_EVM_INIT',
  AUTH_EMAIL_INIT = 'AUTH_EMAIL_INIT',
  AUTH_GOOGLE_INIT = 'AUTH_GOOGLE_INIT',
  AUTH_WALLET_SUCCEEDED = 'AUTH_WALLET_SUCCEEDED',
  AUTH_WEB3AUTH_SUCCEEDED = 'AUTH_WEB3AUTH_SUCCEEDED',
  AUTH_BY_JWT_TRY = 'AUTH_BY_JWT_TRY',
  AUTH_INCONSISTENT_LOGOUT = 'AUTH_INCONSISTENT_LOGOUT',

  EMAIL_CONFIRM_REQUESTED = 'EMAIL_CONFIRM_REQUESTED',
  EMAIL_CONFIRM_FAILED = 'EMAIL_CONFIRM_FAILED',
  EMAIL_CONFIRM_SUCCEEDED = 'EMAIL_CONFIRM_SUCCEEDED',

  ONBOARD_STEP1_FINISHED = 'ONBOARD_STEP1_FINISHED',
  ONBOARD_STEP2_FINISHED = 'ONBOARD_STEP2_FINISHED',

  CAPTCHA_SHOWN = 'CAPTCHA_SHOWN',
  CAPTCHA_INVISIBLE_FAILED = 'CAPTCHA_INVISIBLE_FAILED',
  CAPTCHA_VISIBLE_FAILED = 'CAPTCHA_VISIBLE_FAILED',
  CAPTCHA_INVISIBLE_SUCCEEDED = 'CAPTCHA_INVISIBLE_SUCCEEDED',
  CAPTCHA_VISIBLE_SUCCEEDED = 'CAPTCHA_VISIBLE_SUCCEEDED',

  BLOCKCHAIN_NETWORK_UPDATED = 'BLOCKCHAIN_NETWORK_UPDATED',

  MERCHANT_WALLET_MANUAL_DEPLOY_SHOWN = 'MERCHANT_WALLET_MANUAL_DEPLOY_SHOWN',
  MERCHANT_WALLET_MANUAL_DEPLOY_FINISHED = 'MERCHANT_WALLET_MANUAL_DEPLOY_FINISHED',

  PAYMENT_CREATE_INIT = 'PAYMENT_CREATE_INIT',
  PAYMENT_CREATED = 'PAYMENT_CREATED',

  DONATION_CREATE_INIT = 'DONATION_CREATE_INIT',
  DONATION_CREATED = 'DONATION_CREATED',
  DONATION_UPDATE_INIT = 'DONATION_UPDATE_INIT',
  DONATION_UPDATED = 'DONATION_UPDATED',
  DONATION_ACTIVATED = 'DONATION_ACTIVATED',
  DONATION_DEACTIVATED = 'DONATION_DEACTIVATED',

  RECHARGE_CREATE_INIT = 'RECHARGE_CREATE_INIT',
  RECHARGE_CREATED = 'RECHARGE_CREATED',

  REPORT_SCHEDULE_CREATE_INIT = 'REPORT_SCHEDULE_CREATE_INIT',
  REPORT_SCHEDULE_CREATED = 'REPORT_SCHEDULE_CREATED',
  REPORT_SCHEDULE_UPDATE_INIT = 'REPORT_SCHEDULE_UPDATE_INIT',
  REPORT_SCHEDULE_UPDATED = 'REPORT_SCHEDULE_UPDATED',

  GAS_WALLET_REFUND_TRIGGERED = 'GAS_WALLET_REFUND_TRIGGERED',

  COMPANY_USER_ADDED = 'COMPANY_USER_ADDED',
  COMPANY_USER_REMOVED = 'COMPANY_USER_REMOVED',

  COMPANY_NAME_UPDATE_INIT = 'COMPANY_NAME_UPDATE_INIT',
  COMPANY_NAME_UPDATED = 'COMPANY_NAME_UPDATED',

  COMPANY_WEBSITE_UPDATE_INIT = 'COMPANY_WEBSITE_UPDATE_INIT',
  COMPANY_WEBSITE_UPDATED = 'COMPANY_WEBSITE_UPDATED',

  COMPANY_KYT_LEVEL_UPDATE_INIT = 'COMPANY_KYT_LEVEL_UPDATE_INIT',
  COMPANY_KYT_LEVEL_UPDATED = 'COMPANY_KYT_LEVEL_UPDATED',

  COMPANY_ASSET_UPDATE_INIT = 'COMPANY_ASSET_UPDATE_INIT',
  COMPANY_ASSET_UPDATED = 'COMPANY_ASSET_UPDATED',

  COMPANY_QR_UPDATE_INIT = 'COMPANY_QR_UPDATE_INIT',
  COMPANY_QR_UPDATED = 'COMPANY_QR_UPDATED',

  API_KEY_GENERATED = 'API_KEY_GENERATED',
  API_WEBHOOK_UPDATED = 'API_WEBHOOK_UPDATED',

  BRANDING_PROFILE_CREATED = 'BRANDING_PROFILE_CREATED',
  BRANDING_PROFILE_UPDATED = 'BRANDING_PROFILE_UPDATED',
  BRANDING_PROFILE_ACTIVATED = 'BRANDING_PROFILE_ACTIVATED',
  BRANDING_PROFILE_DEACTIVATED = 'BRANDING_PROFILE_DEACTIVATED',

  TOS_UPDATED = 'TOS_UPDATED',
  TOS_CLEARED = 'TOS_CLEARED',

  SUBSCRIPTION_PLAN_CREATE_INIT = 'SUBSCRIPTION_PLAN_CREATE_INIT',
  SUBSCRIPTION_PLAN_CREATED = 'SUBSCRIPTION_PLAN_CREATED',
  SUBSCRIPTION_PLAN_UPDATE_INIT = 'SUBSCRIPTION_PLAN_UPDATE_INIT',
  SUBSCRIPTION_PLAN_UPDATED = 'SUBSCRIPTION_PLAN_UPDATED',

  COLLECT_SCHEDULE_INIT = 'COLLECT_SCHEDULE_INIT',
  COLLECT_SCHEDULE_UPDATED = 'COLLECT_SCHEDULE_UPDATED',
  COLLECT_SCHEDULE_CLEARED = 'COLLECT_SCHEDULE_CLEARED',

  COLLECT_NOW_TRIGGERED = 'COLLECT_NOW_TRIGGERED',

  SETTLEMENT_SCHEDULE_INIT = 'SETTLEMENT_SCHEDULE_INIT',
  SETTLEMENT_SCHEDULE_UPDATED = 'SETTLEMENT_SCHEDULE_UPDATED',
  SETTLEMENT_SCHEDULE_CLEARED = 'SETTLEMENT_SCHEDULE_CLEARED',

  SETTLEMENT_NOW_TRIGGERED = 'SETTLEMENT_NOW_TRIGGERED',

  PAYOUT_CREATE_INIT = 'PAYOUT_CREATE_INIT',
  PAYOUT_CREATED = 'PAYOUT_CREATED',
  PAYOUT_UPDATE_INIT = 'PAYOUT_UPDATE_INIT',
  PAYOUT_UPDATED = 'PAYOUT_UPDATED',
  PAYOUT_STARTED = 'PAYOUT_STARTED',
}

const ymId = window.env.YM_ENABLED && window.env.YM_ID ? window.env.YM_ID : undefined;

const goalReachedFunc = (id: number) => (goalName: YMGoals) => {
  try {
    ym(id, 'reachGoal', goalName);
  } catch (e) {
    console.warn(goalName, e);
  }
};

const urlHitFunc = (id: number) => (url: string) => {
  try {
    ym(id, 'hit', url);
  } catch (e) {
    console.warn(url, e);
  }
};

const initUserFunc = (id: number) => (address?: string) => {
  if (address) {
    try {
      ym(id, 'setUserID', address);
    } catch (e) {
      console.warn('user init failed', e);
    }
  }
};

const updateUserParams =
  (id: number) => (params: { address: string; email?: string; company: { email?: string; id: number } }) => {
    try {
      ym(id, 'userParams', params);
    } catch (e) {
      console.warn('user init failed', e);
    }
  };

export const goalReached = ymId ? goalReachedFunc(ymId) : noop;
export const urlHit = ymId ? urlHitFunc(ymId) : noop;

export const ymUpdateUserParams = ymId ? updateUserParams(ymId) : noop;
export const ymInitUser = ymId ? initUserFunc(ymId) : noop;
