/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';

/**
 * 
 * @export
 * @interface CompanyNotificationSettingsAPIModel
 */
export interface CompanyNotificationSettingsAPIModel {
    /**
     * 
     * @type {string}
     * @memberof CompanyNotificationSettingsAPIModel
     */
    url: string;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof CompanyNotificationSettingsAPIModel
     */
    network: BlockchainNetworkTypeAPIModel;
}

/**
 * Check if a given object implements the CompanyNotificationSettingsAPIModel interface.
 */
export function instanceOfCompanyNotificationSettingsAPIModel(value: object): boolean {
    if (!('url' in value)) return false;
    if (!('network' in value)) return false;
    return true;
}

export function CompanyNotificationSettingsAPIModelFromJSON(json: any): CompanyNotificationSettingsAPIModel {
    return CompanyNotificationSettingsAPIModelFromJSONTyped(json, false);
}

export function CompanyNotificationSettingsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyNotificationSettingsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'],
        'network': BlockchainNetworkTypeAPIModelFromJSON(json['network']),
    };
}

export function CompanyNotificationSettingsAPIModelToJSON(value?: CompanyNotificationSettingsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'url': value['url'],
        'network': BlockchainNetworkTypeAPIModelToJSON(value['network']),
    };
}

