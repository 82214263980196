import { useCallback, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useActiveCompany } from '@/features/company/hooks';
import type { Company } from '@/features/company/types';
import { OperationRefreshCompanySettings } from '@/features/company-settings/components';
import { useCompanySettings } from '@/features/company-settings/hooks';
import type { CompanySettings } from '@/features/company-settings/types';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import { combine } from '@/infrastructure/model';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withGeneralSettingsDataLoading = <
  Original extends {
    data: { settings: Pick<CompanySettings, 'payments' | 'statisticsAsset'>; company: Company };
  } & TestableProps,
  Wrapper extends Omit<Original, 'data'>,
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithGeneralSettingsDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const settingsState = useCompanySettings();
    const companyState = useActiveCompany();
    const loading = settingsState.loading || companyState.loading;
    const forceRefresh = useCallback(
      async () => Promise.all([settingsState.forceRefresh(), companyState.forceRefresh()]),
      [companyState, settingsState],
    );
    const data = useMemo(
      () => combine(settingsState.data, companyState.data, (settings, company) => ({ settings, company })),
      [companyState.data, settingsState.data],
    );
    return withCardDataLoading<
      { settings: Pick<CompanySettings, 'payments' | 'statisticsAsset'>; company: Company },
      Original
    >({
      data,
      loading,
      forceRefresh,
      'data-test': dataTest,
      hideBack: true,
      title: <FormattedMessage id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_TITLE} />,
      CardProps: {
        extra: <OperationRefreshCompanySettings data-test={dataTest && `${dataTest}-opRefresh`} mode="inline" />,
      },
    })(Component)(props);
  });

export default withGeneralSettingsDataLoading;
