import { Form, Input } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage } from '@/components';
import { parseAddress } from '@/features/dictionary/blockchain/utils';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';

import type { AddressItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const AddressItem = <Values extends Store = Store>({
  'data-test': dataTest,
  required,
  name,
  blockchainApis,
  net,
  messages,
  rules = [],
  InputProps = {},
  ItemProps = {},
}: AddressItemProps<Values>) => {
  const { formatMessage: i18n } = useIntl();
  const messagesOrDefault = useMemo(
    () => ({
      label: messages?.label ?? <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ADDRESS_ITEM_LABEL} />,
      required: messages?.required ?? (
        <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ADDRESS_ITEM_ERROR_REQUIRED} />
      ),
      placeholder: messages?.placeholder || i18n({ id: I18nFeatureDictionary.COMPONENTS_ADDRESS_ITEM_PLACEHOLDER }),
    }),
    [i18n, messages?.label, messages?.placeholder, messages?.required],
  );
  const validateAddress = useCallback(
    async (_: unknown, addressField: string) => {
      if (required && !addressField) return;
      const address = await parseAddress(addressField, blockchainApis, net);
      if (!address) throw new Error(i18n({ id: I18nFeatureDictionary.COMPONENTS_ADDRESS_ITEM_ERROR_ADDRESS_INVALID }));
    },
    [blockchainApis, i18n, net, required],
  );

  return (
    <Form.Item<Values>
      required={required}
      label={messagesOrDefault.label}
      name={name}
      rules={useMemo(
        () => [
          { validator: validateAddress },
          ...rules,
          ...(required ? [{ required: true, message: messagesOrDefault.required }] : []),
        ],
        [messagesOrDefault.required, required, rules, validateAddress],
      )}
      {...ItemProps}
    >
      <Input
        data-test={dataTest}
        placeholder={messagesOrDefault.placeholder}
        className="ym-hide-content"
        {...InputProps}
      />
    </Form.Item>
  );
};

const AddressItemMemo = React.memo(AddressItem) as typeof AddressItem;

export default AddressItemMemo;
