/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionStatusAPIModel {
    Pending = 'Pending',
    Sent = 'Sent',
    Succeeded = 'Succeeded',
    Failed = 'Failed',
    Abandoned = 'Abandoned'
}


export function TransactionStatusAPIModelFromJSON(json: any): TransactionStatusAPIModel {
    return TransactionStatusAPIModelFromJSONTyped(json, false);
}

export function TransactionStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionStatusAPIModel {
    return json as TransactionStatusAPIModel;
}

export function TransactionStatusAPIModelToJSON(value?: TransactionStatusAPIModel | null): any {
    return value as any;
}

