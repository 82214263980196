import { css } from '@emotion/css';
import { Space } from 'antd';
import React, { useMemo } from 'react';

import { withWeb3SolanaContext } from '@/features/web3/hocs';
import { useWeb3SolanaWallets } from '@/features/web3/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import SolanaOperationContexted from '../SolanaOperationContexted';

import type { SolanaOperationsProps } from './types';

const solanaPrefix = 'solana-';

const SolanaOperations = <
  Success = unknown,
  Account = string,
  BT extends BlockchainTypeAPIModel | undefined = undefined,
  ExtraProps = undefined,
>({
  'data-test': dataTest,
  onSuccess,
  bt,
  requiredAccount,
  inProgress,
  inProgressMode,
  withInProgress: baseWithInProgress,
  cancellation,
  filter,
  Operation,
  extra,
}: SolanaOperationsProps<Success, Account, BT, ExtraProps>): React.ReactNode => {
  const { orderedWallets } = useWeb3SolanaWallets();
  const wallets = useMemo(() => (filter ? orderedWallets.filter(filter) : orderedWallets), [filter, orderedWallets]);
  const withInProgress = useMemo(
    () => (baseWithInProgress ? (selected: string) => baseWithInProgress(`${solanaPrefix}-${selected}`) : undefined),
    [baseWithInProgress],
  );

  return (
    <Space
      direction="vertical"
      className={css`
        display: flex;
        flex: 1;
      `}
      size="middle"
    >
      {wallets.map(({ id }) => (
        <SolanaOperationContexted<Success, Account, BT, ExtraProps>
          key={id}
          data-test={dataTest && `${dataTest}-${id}`}
          walletId={id}
          requiredAccount={requiredAccount}
          onSuccess={onSuccess}
          inProgress={inProgress === `${solanaPrefix}-${id}`}
          inProgressMode={inProgressMode}
          withInProgress={withInProgress}
          bt={bt}
          extra={extra}
          Operation={Operation}
          cancellation={cancellation}
        />
      ))}
    </Space>
  );
};

const SolanaOperationsHOC = withWeb3SolanaContext(SolanaOperations, React.Fragment);

const SolanaOperationsMemo = React.memo(SolanaOperationsHOC) as typeof SolanaOperations;

export default SolanaOperationsMemo;
