/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  PaymentTransactionSearchResultAPIModel,
  PaymentTransactionsFilterAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    PaymentTransactionSearchResultAPIModelFromJSON,
    PaymentTransactionSearchResultAPIModelToJSON,
    PaymentTransactionsFilterAPIModelFromJSON,
    PaymentTransactionsFilterAPIModelToJSON,
} from '../models/index';

export interface GetPaymentTransactionsRequest {
    paymentTransactionsFilterAPIModel: PaymentTransactionsFilterAPIModel;
}

/**
 * PaymentTransactionApi - interface
 * 
 * @export
 * @interface PaymentTransactionApiInterface
 */
export interface PaymentTransactionApiInterface {
    /**
     * Returns payment transactions by a filter
     * @param {PaymentTransactionsFilterAPIModel} paymentTransactionsFilterAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentTransactionApiInterface
     */
    getPaymentTransactionsRaw(requestParameters: GetPaymentTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentTransactionSearchResultAPIModel>>;

    /**
     * Returns payment transactions by a filter
     */
    getPaymentTransactions(paymentTransactionsFilterAPIModel: PaymentTransactionsFilterAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentTransactionSearchResultAPIModel>;

}

/**
 * 
 */
export class PaymentTransactionApi extends runtime.BaseAPI implements PaymentTransactionApiInterface {

    /**
     * Returns payment transactions by a filter
     */
    async getPaymentTransactionsRaw(requestParameters: GetPaymentTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentTransactionSearchResultAPIModel>> {
        if (requestParameters['paymentTransactionsFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'paymentTransactionsFilterAPIModel',
                'Required parameter "paymentTransactionsFilterAPIModel" was null or undefined when calling getPaymentTransactions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payment-transactions/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentTransactionsFilterAPIModelToJSON(requestParameters['paymentTransactionsFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentTransactionSearchResultAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns payment transactions by a filter
     */
    async getPaymentTransactions(paymentTransactionsFilterAPIModel: PaymentTransactionsFilterAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentTransactionSearchResultAPIModel> {
        const response = await this.getPaymentTransactionsRaw({ paymentTransactionsFilterAPIModel: paymentTransactionsFilterAPIModel }, initOverrides);
        return await response.value();
    }

}
