/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlanChurnRateRecordAPIModel
 */
export interface PlanChurnRateRecordAPIModel {
    /**
     * 
     * @type {number}
     * @memberof PlanChurnRateRecordAPIModel
     */
    percentage: number;
    /**
     * 
     * @type {number}
     * @memberof PlanChurnRateRecordAPIModel
     */
    chargesCount: number;
    /**
     * 
     * @type {number}
     * @memberof PlanChurnRateRecordAPIModel
     */
    daysToTheCharge: number;
}

/**
 * Check if a given object implements the PlanChurnRateRecordAPIModel interface.
 */
export function instanceOfPlanChurnRateRecordAPIModel(value: object): boolean {
    if (!('percentage' in value)) return false;
    if (!('chargesCount' in value)) return false;
    if (!('daysToTheCharge' in value)) return false;
    return true;
}

export function PlanChurnRateRecordAPIModelFromJSON(json: any): PlanChurnRateRecordAPIModel {
    return PlanChurnRateRecordAPIModelFromJSONTyped(json, false);
}

export function PlanChurnRateRecordAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanChurnRateRecordAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'percentage': json['percentage'],
        'chargesCount': json['chargesCount'],
        'daysToTheCharge': json['daysToTheCharge'],
    };
}

export function PlanChurnRateRecordAPIModelToJSON(value?: PlanChurnRateRecordAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'percentage': value['percentage'],
        'chargesCount': value['chargesCount'],
        'daysToTheCharge': value['daysToTheCharge'],
    };
}

