import { Space, Tooltip } from 'antd';
import React from 'react';

import FormattedMessageWithMarkup from '../FormattedMessageWithMarkup';

import type { StatusViewElementProps } from './types';

const StatusViewElement: React.FC<StatusViewElementProps> = ({
  'data-test': dataTest,
  style,
  className,
  mode = 'full',
  icon,
  i18n,
  i18nValues,
}) => (
  <div data-test={dataTest} style={{ display: 'inline-flex', ...style }} className={className}>
    {/* FIXME: div is a workaround for unsupported data-* https://github.com/ant-design/ant-design/issues/8561 */}
    <Space>
      {mode === 'full' && icon}
      {mode === 'icon' && (
        <Tooltip title={<FormattedMessageWithMarkup id={i18n} values={i18nValues} />}>{icon}</Tooltip>
      )}
      {(mode === 'full' || mode === 'text') && <FormattedMessageWithMarkup id={i18n} values={i18nValues} />}
    </Space>
  </div>
);

const StatusViewElementMemo = React.memo(StatusViewElement) as typeof StatusViewElement;

export default StatusViewElementMemo;
