import Icon from '@ant-design/icons';
import React from 'react';

import BaseGoogleIcon from '@/assets/icons/google.svg?react';
import { FormattedMessage, InProgressView } from '@/components';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import type { StyledProps } from '@/infrastructure/utils/react';

import Web3AuthInProgress from '../Web3AuthInProgress';

import type { GoogleInProgressProps } from './types';

const GoogleIcon: React.FC<StyledProps> = (props) => <Icon component={BaseGoogleIcon} {...props} />;

const GoogleInProgress: React.FC<GoogleInProgressProps> = ({ 'data-test': dataTest, cancel, authorizing }) =>
  authorizing ? (
    <Web3AuthInProgress data-test={dataTest} />
  ) : (
    <InProgressView
      data-test={dataTest}
      cancel={cancel && { onAction: cancel }}
      Icon={GoogleIcon}
      message={<FormattedMessage id={I18nFeatureAuth.COMPONENTS_GOOGLE_IN_PROGRESS_MESSAGE} />}
    />
  );

const GoogleInProgressMemo = React.memo(GoogleInProgress);

export default GoogleInProgressMemo;
