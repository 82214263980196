import { QuestionCircleOutlined } from '@ant-design/icons';
import { cx } from '@emotion/css';
import { Form, Input, Space, Tooltip } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { withFeatureToggleGuard } from '@/features/feature-toggle/hocs';
import { I18nPageSettings } from '@/generated/i18n/i18n';

import type { ProfileTronAddressItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const ProfileTronAddressItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  style,
  className,
}: ProfileTronAddressItemProps<Values>) => (
  <Form.Item<Values>
    name={name}
    label={
      <Space>
        <FormattedMessage id={I18nPageSettings.PROFILE_COMPONENTS_PROFILE_CARD_TRON_ADDRESS_ITEM_LABEL} />
        <Tooltip
          title={<FormattedMessage id={I18nPageSettings.PROFILE_COMPONENTS_PROFILE_CARD_TRON_ADDRESS_ITEM_HELP} />}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </Space>
    }
  >
    <Input
      data-test={dataTest && `${dataTest}-value`}
      disabled
      className={cx('ym-hide-content', className)}
      style={style}
    />
  </Form.Item>
);

const ProfileTronAddressItemHOC = withFeatureToggleGuard('disableTron', false)(ProfileTronAddressItem);
const ProfileTronAddressItemMemo = React.memo(ProfileTronAddressItemHOC) as typeof ProfileTronAddressItem;

export default ProfileTronAddressItemMemo;
