/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { TransactionStatusAPIModel } from './TransactionStatusAPIModel';
import {
    TransactionStatusAPIModelFromJSON,
    TransactionStatusAPIModelFromJSONTyped,
    TransactionStatusAPIModelToJSON,
} from './TransactionStatusAPIModel';

/**
 * 
 * @export
 * @interface SettlementIntentTransactionDetailsAPIModel
 */
export interface SettlementIntentTransactionDetailsAPIModel {
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionDetailsAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionDetailsAPIModel
     */
    intentId: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionDetailsAPIModel
     */
    txId: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionDetailsAPIModel
     */
    wallet?: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof SettlementIntentTransactionDetailsAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {TransactionStatusAPIModel}
     * @memberof SettlementIntentTransactionDetailsAPIModel
     */
    status: TransactionStatusAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SettlementIntentTransactionDetailsAPIModel
     */
    failCount: number;
    /**
     * 
     * @type {Date}
     * @memberof SettlementIntentTransactionDetailsAPIModel
     */
    sentAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionDetailsAPIModel
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionDetailsAPIModel
     */
    blockNum?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionDetailsAPIModel
     */
    errorReason?: string;
}

/**
 * Check if a given object implements the SettlementIntentTransactionDetailsAPIModel interface.
 */
export function instanceOfSettlementIntentTransactionDetailsAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('intentId' in value)) return false;
    if (!('txId' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('status' in value)) return false;
    if (!('failCount' in value)) return false;
    return true;
}

export function SettlementIntentTransactionDetailsAPIModelFromJSON(json: any): SettlementIntentTransactionDetailsAPIModel {
    return SettlementIntentTransactionDetailsAPIModelFromJSONTyped(json, false);
}

export function SettlementIntentTransactionDetailsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementIntentTransactionDetailsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'intentId': json['intentId'],
        'txId': json['txId'],
        'wallet': json['wallet'] == null ? undefined : json['wallet'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'status': TransactionStatusAPIModelFromJSON(json['status']),
        'failCount': json['failCount'],
        'sentAt': json['sentAt'] == null ? undefined : (new Date(json['sentAt'])),
        'hash': json['hash'] == null ? undefined : json['hash'],
        'blockNum': json['blockNum'] == null ? undefined : json['blockNum'],
        'errorReason': json['errorReason'] == null ? undefined : json['errorReason'],
    };
}

export function SettlementIntentTransactionDetailsAPIModelToJSON(value?: SettlementIntentTransactionDetailsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'intentId': value['intentId'],
        'txId': value['txId'],
        'wallet': value['wallet'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'status': TransactionStatusAPIModelToJSON(value['status']),
        'failCount': value['failCount'],
        'sentAt': value['sentAt'] == null ? undefined : ((value['sentAt']).toISOString()),
        'hash': value['hash'],
        'blockNum': value['blockNum'],
        'errorReason': value['errorReason'],
    };
}

