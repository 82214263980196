import { useContext, useEffect } from 'react';

import { useAppSelector } from '@/app/hooks';
import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { makeSelectAuthStatus } from '@/features/auth/selectors';
import { AuthStatus } from '@/features/auth/types';
import { suppressPromise } from '@/infrastructure/utils/functions';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import type React from 'react';

const selectAuthStatus = makeSelectAuthStatus();

const withWeb3AuthJWTInit = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithWeb3AuthJWTInit',
  )((props) => {
    const web3Auth = useContext(Web3AuthContext);
    const authStatus = useAppSelector(selectAuthStatus);
    const isJWTUnauthorized = authStatus === AuthStatus.UNAUTHORIZED;
    const isWeb3AuthAuthorized = web3Auth.initialized && web3Auth.web3Auth?.isAuthorized();

    useEffect(() => {
      if (isWeb3AuthAuthorized && isJWTUnauthorized && web3Auth.web3Auth) {
        goalReached(YMGoals.AUTH_INCONSISTENT_LOGOUT);
        suppressPromise(web3Auth.web3Auth.logout());
      }
    }, [isJWTUnauthorized, isWeb3AuthAuthorized, web3Auth.web3Auth]);

    return <Component {...props} />;
  });

export default withWeb3AuthJWTInit;
