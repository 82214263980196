import React, { useCallback, useMemo } from 'react';

import { Web3WalletEVMLogin } from '@/features/auth/components';
import type { Web3WalletEVMLoginOperationProps } from '@/features/auth/components';
import { useAuthWeb3EVMActions } from '@/features/auth/hooks';
import { Web3WalletContainer } from '@/features/web3/components';
import type { EVMOperationConfig } from '@/features/web3/components';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { asAFunc } from '@/infrastructure/utils/ts';

import type { EVMWalletsProps } from './types';

const EVMWallets: React.FC<EVMWalletsProps> = ({
  'data-test': dataTest,
  activeProvider,
  withLogIn,
  withSelectProvider,
  onPrepareCancel,
}) => {
  const { auth: authAction } = useAuthWeb3EVMActions();
  const doWeb3Login: Web3WalletEVMLoginOperationProps['login'] = useCallback(
    ({ client }, cancellation) =>
      asAFunc(
        withSelectProvider(
          withLogIn(
            (onBeforeSignUp, withRecaptcha) => () =>
              authAction.act({ client, withRecaptcha }, { onBeforeSignUp, cancellation }),
          ),
        ),
      )(),
    [authAction, withLogIn, withSelectProvider],
  );

  const evm: EVMOperationConfig<unknown, string, undefined, Web3WalletEVMLoginOperationProps> = useMemo(
    () => ({
      Operation: Web3WalletEVMLogin,
      extra: { login: doWeb3Login },
      filter: ({ id }) => !activeProvider || id === activeProvider,
    }),
    [activeProvider, doWeb3Login],
  );

  const withInProgress = useCallback(() => withSelectProvider, [withSelectProvider]);

  return (
    <Web3WalletContainer
      data-test={dataTest}
      description={null}
      api={BlockchainAPITypeAPIModel.EVM}
      withInProgress={withInProgress}
      evm={evm}
      bt={undefined}
      inProgressMode={activeProvider ? ('view' as const) : ('button' as const)}
      cancellation={onPrepareCancel}
    />
  );
};

const EVMWalletsMemo: typeof EVMWallets = React.memo(EVMWallets);

export default EVMWalletsMemo;
