import type { UseAuthWeb3SolanaActions } from '@/features/auth/hooks';
import type { TestableProps } from '@/infrastructure/utils/react';
import type { Func } from '@/infrastructure/utils/ts';

export enum WalletAuthType {
  eth = 'eth',
  sol = 'sol',
}

export interface WalletsBlockProps extends TestableProps {
  withLogIn: <V extends unknown[] = unknown[], R = unknown>(
    func: (
      onBeforeSignUp: Func<[]>,
      withRecaptcha: Parameters<UseAuthWeb3SolanaActions['auth']['act']>[0]['withRecaptcha'],
    ) => Func<V, R>,
  ) => Func<V>;
  onSelectProvider: (selected?: { id: string; onCancel?: () => void }) => void;
  activeProvider?: string;
}
