import { useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { removeMerchantToS, saveMerchantToS } from '@/features/branding/actions';
import { useActionPending } from '@/features/global/hooks';
import type { CommonLoadingState, HookAction } from '@/infrastructure/model';

import useToS from './useToS';

export type ToSUnavailabilityReason = 'no-data';

export interface UseToSActions {
  define: HookAction<[string], string>;
  remove: HookAction<[], unknown, ToSUnavailabilityReason>;
}

export default function useToSActions(): UseToSActions {
  const { withExtractDataDispatch, withVoidDispatch } = useAppDispatch();
  const { data } = useToS();

  const definePending = useActionPending(saveMerchantToS);
  const defineAction: UseToSActions['define']['act'] = useMemo(
    () => withExtractDataDispatch(saveMerchantToS),
    [withExtractDataDispatch],
  );
  const define: UseToSActions['define'] = useMemo(
    () => ({ act: defineAction, available: true, inAction: definePending }),
    [defineAction, definePending],
  );

  const removePending = useActionPending(removeMerchantToS);
  const removeAction: UseToSActions['remove']['act'] = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    () => withVoidDispatch<void, CommonLoadingState<void>>(removeMerchantToS),
    [withVoidDispatch],
  );
  const remove: UseToSActions['remove'] = useMemo(
    () => ({
      act: removeAction,
      available: !!data.data,
      unavailabilityReason: !data.data ? 'no-data' : undefined,
      inAction: removePending,
    }),
    [removeAction, data.data, removePending],
  );
  return { define, remove };
}
