/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum SubscriptionChargeStatusAPIModel {
    Pending = 'Pending',
    Succeeded = 'Succeeded',
    Retrying = 'Retrying',
    Failed = 'Failed',
    Cancelled = 'Cancelled'
}


export function SubscriptionChargeStatusAPIModelFromJSON(json: any): SubscriptionChargeStatusAPIModel {
    return SubscriptionChargeStatusAPIModelFromJSONTyped(json, false);
}

export function SubscriptionChargeStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionChargeStatusAPIModel {
    return json as SubscriptionChargeStatusAPIModel;
}

export function SubscriptionChargeStatusAPIModelToJSON(value?: SubscriptionChargeStatusAPIModel | null): any {
    return value as any;
}

