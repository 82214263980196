import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

export const isSameAddress = (
  a: string | undefined,
  b: string | undefined,
  api: BlockchainAPITypeAPIModel = BlockchainAPITypeAPIModel.EVM,
) => {
  if (a === b) return true;
  if (api === BlockchainAPITypeAPIModel.EVM) return a?.toLowerCase() === b?.toLowerCase();
  return false;
};
