import { cx } from '@emotion/css';
import { Form, Input } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import Web3AuthActions from '@/pages/settings/profile/components/ProfileCard/components/Web3AuthActions';

import type { ProfileEmailItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const ProfileEmailItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  style,
  className,
}: ProfileEmailItemProps<Values>) => {
  const email = Form.useWatch<string | undefined>(name);

  if (!email) return <Form.Item<Values> name={name} hidden noStyle />;
  return (
    <Form.Item<Values>
      label={<FormattedMessage id={I18nPageSettings.PROFILE_COMPONENTS_PROFILE_CARD_LOGIN_EMAIL_ITEM_LABEL} />}
      name={name}
    >
      <Input
        data-test={dataTest && `${dataTest}-email`}
        disabled
        className={cx('ym-hide-content', className)}
        style={style}
        suffix={<Web3AuthActions data-test={dataTest && `${dataTest}-actions`} />}
      />
    </Form.Item>
  );
};

const ProfileEmailItemMemo = React.memo(ProfileEmailItem) as typeof ProfileEmailItem;

export default ProfileEmailItemMemo;
