import { createReducer } from '@reduxjs/toolkit';

import listenerMiddleware from '@/app/listenerMiddleware';
import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { makeSelectRefundingGasWallets } from '@/features/gas-wallets/selectors';
import { extractGasWalletId } from '@/features/gas-wallets/utils';
import { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { storedDirtyData } from '@/infrastructure/model';
import { createNormalizedPartialDataReducers } from '@/infrastructure/model/partial/reducers';

import {
  initRefundListener,
  markGasWalletBatchDirty,
  markGasWalletDirty,
  storeGasWallet,
  storeGasWalletBatch,
} from './actions';

import type { GasWalletsState } from './types';
import type { Draft } from 'immer';

const initialState: GasWalletsState = {
  entities: {},
  wallets: storedDirtyData,
};

const { storeGasWalletReducer, storeGasWalletBatchReducer, markGasWalletBatchDirtyReducer, markGasWalletDirtyReducer } =
  createNormalizedPartialDataReducers(
    'GasWallet',
    (state: Draft<GasWalletsState>) => state.wallets,
    (global, wallets) => ({ ...global, wallets }),
    // this cast is workaround caused by BigNumber in the store
    (state: Draft<GasWalletsState>) => state.entities as GasWalletsState['entities'],
    (global, entities) => ({ ...global, entities }),
    extractGasWalletId,
  );

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markGasWalletDirty, markGasWalletDirtyReducer)
    .addCase(storeGasWallet, storeGasWalletReducer)
    .addCase(markGasWalletBatchDirty, markGasWalletBatchDirtyReducer)
    .addCase(storeGasWalletBatch, storeGasWalletBatchReducer)

    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address || previous?.activeCompanyId !== current?.activeCompanyId
        ? initialState
        : state,
    );

  listenerMiddleware.startListening({
    actionCreator: initRefundListener,
    effect: async (_, listenerApi) => {
      listenerApi.unsubscribe();
      try {
        for (;;) {
          await listenerApi.delay(20_000);
          const refunding = makeSelectRefundingGasWallets()(listenerApi.getState());
          if (!refunding.length) {
            return;
          }
          refunding.forEach((bt) => listenerApi.dispatch(markGasWalletDirty(BlockchainTypeAPIModel[bt])));
        }
      } finally {
        listenerApi.subscribe();
      }
    },
  });
});

export default reducer;
