import React, { useCallback, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { BlockchainLabelList } from '@/features/dictionary/blockchain/components';
import OperationRefreshUser from '@/features/user/components/OperationRefreshUser';
import { useUserActions, useUserAddress } from '@/features/user/hooks';
import type { User } from '@/features/user/types';
import { Web3EVMWalletOperation, type Web3EVMWalletOperationClientAction } from '@/features/web3/components';
import { createSignMessage } from '@/features/web3/evm-utils';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureUser } from '@/generated/i18n/i18n';
import type { NotificationOptions } from '@/hooks';
import { useNotification } from '@/hooks';
import { emptyWith, noop, someOrFail, withOnSuccess } from '@/infrastructure/utils/functions';
import { isCancelled } from '@/infrastructure/utils/ui';

import type { OperationAddUserEVMAddressWithConnectorProps } from './types';

const options: NotificationOptions<unknown[], User> = {
  error: (e) =>
    !isCancelled(e)
      ? {
          message: (
            <FormattedMessage
              id={I18nFeatureUser.COMPONENTS_OPERATION_ADD_USER_ADDRESS_CONNECTOR_FAILED_COMMON_MESSAGE}
            />
          ),
          description:
            !!e && typeof e === 'object' && 'message' in e && e.message === 'UserAddressDuplicate' ? (
              <FormattedMessage id={I18nFeatureUser.COMPONENTS_OPERATION_ADD_USER_ADDRESS_CONNECTOR_FAILED_DUPLICATE} />
            ) : undefined,
        }
      : undefined,
  success: ({ addresses }) => ({
    message: <FormattedMessage id={I18nFeatureUser.COMPONENTS_OPERATION_ADD_USER_ADDRESS_CONNECTOR_SUCCESS_MESSAGE} />,
    description: (
      <FormattedMessage
        id={I18nFeatureUser.COMPONENTS_OPERATION_ADD_USER_ADDRESS_CONNECTOR_SUCCESS_DESCRIPTION}
        values={{
          account: addresses.find(({ addressType }) => addressType === BlockchainAPITypeAPIModel.EVM)?.address,
          blockchains: <BlockchainLabelList api={BlockchainAPITypeAPIModel.EVM} />,
        }}
      />
    ),
  }),
};

const OperationAddUserEVMAddressWithConnector: React.FC<OperationAddUserEVMAddressWithConnectorProps> = ({
  'data-test': dataTest,
  connector,
  onSuccess,
  withInProgress,
}) => {
  const { data: address, loading } = useUserAddress(BlockchainAPITypeAPIModel.EVM);
  const { addAddress: addAddressAction } = useUserActions();
  const { withNotification } = useNotification();

  const doAdd = useCallback<Web3EVMWalletOperationClientAction>(
    ({ client, account }) =>
      withNotification(withOnSuccess((withInProgress ?? emptyWith)(addAddressAction.act), onSuccess ?? noop), options)(
        {
          signMessage: createSignMessage(client),
          address: someOrFail(account),
          addressType: BlockchainAPITypeAPIModel.EVM,
        },
        {},
      ),
    [withNotification, withInProgress, addAddressAction.act, onSuccess],
  );
  const disabledMessage = useMemo(() => {
    if (loading || address.isDirty) {
      return (
        <FormattedMessage
          id={I18nFeatureUser.COMPONENTS_OPERATION_ADD_USER_ADDRESS_CONNECTOR_ERROR_NO_BLOCKCHAIN_DATA}
          values={{
            op: (text: React.ReactNode) => (
              <OperationRefreshUser data-test={dataTest && `${dataTest}-opRefresh`} mode="link" title={text} />
            ),
          }}
        />
      );
    }
    switch (addAddressAction.unavailabilityReason) {
      case 'no-data':
        return (
          <FormattedMessage
            id={I18nFeatureUser.COMPONENTS_OPERATION_ADD_USER_ADDRESS_CONNECTOR_ERROR_NO_BLOCKCHAIN_DATA}
          />
        );
      case undefined:
        return undefined;
    }
  }, [dataTest, addAddressAction.unavailabilityReason, loading, address.isDirty]);

  return (
    <Web3EVMWalletOperation
      data-test={dataTest}
      connector={connector}
      disabled={!addAddressAction.available}
      disabledMessage={disabledMessage}
      inProgress={addAddressAction.inAction}
      showAccount
      mainAction={useMemo(
        () => ({
          withClient: true,
          onAction: doAdd,
          title: (
            <FormattedMessage
              id={I18nFeatureUser.COMPONENTS_OPERATION_ADD_USER_ADDRESS_CONNECTOR_TITLE}
              values={{ connector: connector.name }}
            />
          ),
        }),
        [connector.name, doAdd],
      )}
    />
  );
};

const OperationAddUserEVMAddressWithConnectorMemo = React.memo(
  OperationAddUserEVMAddressWithConnector,
) as typeof OperationAddUserEVMAddressWithConnector;

export default OperationAddUserEVMAddressWithConnectorMemo;
