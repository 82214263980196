import React from 'react';

import { FormattedMessage } from '@/components';
import { WithdrawalPeriodAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import type { SettlementPeriodLabelProps } from './types';

const i18n: Record<WithdrawalPeriodAPIModel, I18nMessages> = {
  [WithdrawalPeriodAPIModel.NotSet]: I18nFeatureSettlements.COMPONENTS_SETTLEMENT_PERIOD_LABEL_NOT_SET,
  [WithdrawalPeriodAPIModel.StartOfDay]: I18nFeatureSettlements.COMPONENTS_SETTLEMENT_PERIOD_LABEL_START_OF_DAY,
  [WithdrawalPeriodAPIModel.FirstDayOfMonth]:
    I18nFeatureSettlements.COMPONENTS_SETTLEMENT_PERIOD_LABEL_FIRST_DAY_OF_MONTH,
  [WithdrawalPeriodAPIModel.FirstDayOfWeek]:
    I18nFeatureSettlements.COMPONENTS_SETTLEMENT_PERIOD_LABEL_FIRST_DAY_OF_WEEK,
};

const SettlementPeriodLabel: React.FC<SettlementPeriodLabelProps> = ({ value }) => (
  <FormattedMessage id={i18n[value]} />
);

const SettlementPeriodLabelMemo = React.memo(SettlementPeriodLabel);

export default SettlementPeriodLabelMemo;
