import React from 'react';

import { withBlockchainAPIType } from '@/features/dictionary/blockchain/hocs';
import { useUserAddress } from '@/features/user/hooks';

import { UserAddressNotAddedBanner } from './components';

import type { UserAddressIssueBannerProps } from './types';

const UserAddressIssueBanner: React.FC<UserAddressIssueBannerProps> = (props) => {
  const address = useUserAddress(props.api).data.data?.value;
  return !address ? <UserAddressNotAddedBanner {...props} /> : null;
};

const UserAddressIssueBannerHOCed = withBlockchainAPIType<UserAddressIssueBannerProps>(UserAddressIssueBanner);

const UserAddressIssueBannerMemo = React.memo(UserAddressIssueBannerHOCed) as typeof UserAddressIssueBannerHOCed;

export default UserAddressIssueBannerMemo;
