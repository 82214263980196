/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MerchantWalletRunningBalanceAPIModel } from './MerchantWalletRunningBalanceAPIModel';
import {
    MerchantWalletRunningBalanceAPIModelFromJSON,
    MerchantWalletRunningBalanceAPIModelFromJSONTyped,
    MerchantWalletRunningBalanceAPIModelToJSON,
} from './MerchantWalletRunningBalanceAPIModel';
import type { PageMerchantWalletRunningBalanceSortByAPIModel } from './PageMerchantWalletRunningBalanceSortByAPIModel';
import {
    PageMerchantWalletRunningBalanceSortByAPIModelFromJSON,
    PageMerchantWalletRunningBalanceSortByAPIModelFromJSONTyped,
    PageMerchantWalletRunningBalanceSortByAPIModelToJSON,
} from './PageMerchantWalletRunningBalanceSortByAPIModel';

/**
 * 
 * @export
 * @interface SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModel
 */
export interface SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModel {
    /**
     * 
     * @type {Array<MerchantWalletRunningBalanceAPIModel>}
     * @memberof SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModel
     */
    list?: Array<MerchantWalletRunningBalanceAPIModel>;
    /**
     * 
     * @type {PageMerchantWalletRunningBalanceSortByAPIModel}
     * @memberof SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModel
     */
    page: PageMerchantWalletRunningBalanceSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModel interface.
 */
export function instanceOfSliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModelFromJSON(json: any): SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModel {
    return SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModelFromJSONTyped(json, false);
}

export function SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(MerchantWalletRunningBalanceAPIModelFromJSON)),
        'page': PageMerchantWalletRunningBalanceSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModelToJSON(value?: SliceMerchantWalletRunningBalanceMerchantWalletRunningBalanceSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(MerchantWalletRunningBalanceAPIModelToJSON)),
        'page': PageMerchantWalletRunningBalanceSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

