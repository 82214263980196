import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';

import {
  markMerchantToSDirty,
  markBrandingProfileDirty,
  storeMerchantToS,
  storeBrandingProfile,
  storeRemoveBrandingProfile,
  storeBranding,
  markBrandingDirty,
  storeMerchantDomains,
  markMerchantDomainsDirty,
} from './actions';

import type { BrandingState } from './types';
import type { Draft } from 'immer';

const initialState: BrandingState = {
  branding: storedDirtyData,
  profiles: {},
  tos: storedDirtyData,
  domains: storedDirtyData,
};

const { markBrandingProfileDirtyReducer, storeBrandingProfileReducer, storeRemoveBrandingProfileReducer } =
  createSingleReducers(
    'BrandingProfile' as const,
    (state: Draft<BrandingState>) => state.profiles,
    (state, profiles) => ({ ...state, profiles }),
  );

const { storeMerchantBrandingReducer, markMerchantBrandingDirtyReducer } = createLoadingDataReducers(
  'MerchantBranding' as const,
  (state: Draft<BrandingState>) => state.branding,
  (state, branding) => ({ ...state, branding }),
);

const { storeMerchantToSReducer, markMerchantToSDirtyReducer } = createLoadingDataReducers<
  BrandingState,
  string | undefined,
  'MerchantToS'
>(
  'MerchantToS' as const,
  (state) => state.tos,
  (state, tos) => ({ ...state, tos }),
);

const { storeMerchantDomainsReducer, markMerchantDomainsDirtyReducer } = createLoadingDataReducers(
  'MerchantDomains' as const,
  (state: Draft<BrandingState>) => state.domains,
  (state, domains) => ({ ...state, domains }),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markBrandingProfileDirty, markBrandingProfileDirtyReducer)
    .addCase(storeBrandingProfile, storeBrandingProfileReducer)
    .addCase(storeRemoveBrandingProfile, storeRemoveBrandingProfileReducer)

    .addCase(markBrandingDirty, markMerchantBrandingDirtyReducer)
    .addCase(storeBranding, storeMerchantBrandingReducer)

    .addCase(markMerchantToSDirty, markMerchantToSDirtyReducer)
    .addCase(storeMerchantToS, storeMerchantToSReducer)

    .addCase(markMerchantDomainsDirty, markMerchantDomainsDirtyReducer)
    .addCase(storeMerchantDomains, storeMerchantDomainsReducer)

    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address || previous?.activeCompanyId !== current?.activeCompanyId
        ? initialState
        : state,
    );
});

export default reducer;
