import { useEffect, useMemo, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { useWeb3AuthEnabled } from '@/features/auth/hooks';
import { useSingleRun, useStateMountSafe } from '@/hooks';
import { withSuppressPromise } from '@/infrastructure/utils/functions';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import Web3AuthContext, { defaultWeb3AuthContext } from './context';

import type { Web3AuthContextType } from './context';
import type { PropsWithChildren } from 'react';
import type React from 'react';

const withWeb3AuthContext = <P extends PropsWithChildren<EmptyObject>>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithWeb3AuthContext',
  )((props) => {
    const [context, setContext] = useStateMountSafe<{ state?: string } & Web3AuthContextType>(defaultWeb3AuthContext);
    const { withSingleRun, inProgress } = useSingleRun();
    const tried = useRef(false);
    const enabled = useWeb3AuthEnabled();
    const initialize = useMemo(
      () =>
        withSuppressPromise(
          withSingleRun(async () => {
            if (tried.current) {
              return;
            }
            if (!enabled.data) {
              setContext({ initialized: true });
              return;
            }
            try {
              const onUpdate = () => setContext((prev) => ({ ...prev, state: uuid() }));
              const { default: web3Auth } = await import('@/infrastructure/security/web3-auth/web3-auth');
              const created = await web3Auth.create({ onUpdate });
              setContext({ initialized: true, web3Auth: created });
            } catch (err) {
              console.warn(err);
              setContext({ initialized: true });
            } finally {
              tried.current = true;
            }
          }),
        ),
      [withSingleRun, enabled.data, tried, setContext],
    );
    useEffect(() => {
      if (!inProgress && !tried.current && !enabled.isDirty) {
        initialize();
      }
    }, [enabled.isDirty, inProgress, initialize, tried]);
    return (
      <Web3AuthContext.Provider value={context}>
        <Component {...props} />
      </Web3AuthContext.Provider>
    );
  });

export default withWeb3AuthContext;
