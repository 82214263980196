import React, { useCallback } from 'react';

import { BooleanSwitch, FormattedMessage } from '@/components';
import { useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';

import type { QRSupportSelectOperationProps } from './types';

const QRSupportSelectOperation: React.FC<QRSupportSelectOperationProps> = ({
  'data-test': dataTest,
  settings,
  assets,
  onSelect,
  disabled,
  disabledMessage,
}) => {
  const doSelect = useCallback(
    (checked: boolean) => onSelect(settings.blockchain, checked),
    [onSelect, settings.blockchain],
  );
  const { data: systemInfo } = useBlockchainSystemInfo(settings.blockchain);
  const isRequired = systemInfo.data?.forwarder === 'required' && !!assets.length && settings.isEnabled;
  return (
    <BooleanSwitch
      data-test={dataTest}
      value={settings.isEnabled}
      onChange={doSelect}
      disabled={disabled || isRequired}
      disabledMessage={
        (disabled && disabledMessage)
        || (isRequired && (
          <FormattedMessage
            id={I18nFeatureCompanySettings.COMPONENTS_UPDATE_QR_SUPPORT_FORM_INPUT_REQUIRED_NETWORK_TOOLTIP}
            tagName="span"
          />
        ))
      }
    />
  );
};

const QRSupportSelectOperationMemo = React.memo(QRSupportSelectOperation);

export default QRSupportSelectOperationMemo;
