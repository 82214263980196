import type { LinkMode } from '@/components/Link/types';
import type { TestableProps } from '@/infrastructure/utils/react';

import type { ReactNode } from 'react';

export type Web3WalletType = 'metaMaskSDK' | 'coinbaseWallet' | 'trust' | 'phantom' | 'solflare';

export const walletTypes: Web3WalletType[] = ['metaMaskSDK', 'coinbaseWallet', 'phantom', 'trust', 'solflare'] as const;

export interface Web3WalletLinkProps extends TestableProps {
  mode?: LinkMode;
  title?: ReactNode;
  type: Web3WalletType;
}
