/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { CollectTaskStatusAPIModel } from './CollectTaskStatusAPIModel';
import {
    CollectTaskStatusAPIModelFromJSON,
    CollectTaskStatusAPIModelFromJSONTyped,
    CollectTaskStatusAPIModelToJSON,
} from './CollectTaskStatusAPIModel';
import type { CollectableAddressLinkAPIModel } from './CollectableAddressLinkAPIModel';
import {
    CollectableAddressLinkAPIModelFromJSON,
    CollectableAddressLinkAPIModelFromJSONTyped,
    CollectableAddressLinkAPIModelToJSON,
} from './CollectableAddressLinkAPIModel';
import type { CollectableKindAPIModel } from './CollectableKindAPIModel';
import {
    CollectableKindAPIModelFromJSON,
    CollectableKindAPIModelFromJSONTyped,
    CollectableKindAPIModelToJSON,
} from './CollectableKindAPIModel';
import type { DateTimeRangeAPIModel } from './DateTimeRangeAPIModel';
import {
    DateTimeRangeAPIModelFromJSON,
    DateTimeRangeAPIModelFromJSONTyped,
    DateTimeRangeAPIModelToJSON,
} from './DateTimeRangeAPIModel';

/**
 * 
 * @export
 * @interface CollectTaskPredicatesAPIModel
 */
export interface CollectTaskPredicatesAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof CollectTaskPredicatesAPIModel
     */
    idIn?: Array<string>;
    /**
     * 
     * @type {Array<CollectableKindAPIModel>}
     * @memberof CollectTaskPredicatesAPIModel
     */
    kindIn?: Array<CollectableKindAPIModel>;
    /**
     * 
     * @type {Array<BlockchainTypeAPIModel>}
     * @memberof CollectTaskPredicatesAPIModel
     */
    btIn?: Array<BlockchainTypeAPIModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CollectTaskPredicatesAPIModel
     */
    assetIn?: Array<string>;
    /**
     * 
     * @type {Array<CollectTaskStatusAPIModel>}
     * @memberof CollectTaskPredicatesAPIModel
     */
    statusIn?: Array<CollectTaskStatusAPIModel>;
    /**
     * 
     * @type {string}
     * @memberof CollectTaskPredicatesAPIModel
     */
    hashLike?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectTaskPredicatesAPIModel
     */
    addressLike?: string;
    /**
     * 
     * @type {Array<CollectableAddressLinkAPIModel>}
     * @memberof CollectTaskPredicatesAPIModel
     */
    addressIn?: Array<CollectableAddressLinkAPIModel>;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof CollectTaskPredicatesAPIModel
     */
    processAtRange?: DateTimeRangeAPIModel;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof CollectTaskPredicatesAPIModel
     */
    networkEq?: BlockchainNetworkTypeAPIModel;
}

/**
 * Check if a given object implements the CollectTaskPredicatesAPIModel interface.
 */
export function instanceOfCollectTaskPredicatesAPIModel(value: object): boolean {
    return true;
}

export function CollectTaskPredicatesAPIModelFromJSON(json: any): CollectTaskPredicatesAPIModel {
    return CollectTaskPredicatesAPIModelFromJSONTyped(json, false);
}

export function CollectTaskPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectTaskPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'idIn': json['idIn'] == null ? undefined : json['idIn'],
        'kindIn': json['kindIn'] == null ? undefined : ((json['kindIn'] as Array<any>).map(CollectableKindAPIModelFromJSON)),
        'btIn': json['btIn'] == null ? undefined : ((json['btIn'] as Array<any>).map(BlockchainTypeAPIModelFromJSON)),
        'assetIn': json['assetIn'] == null ? undefined : json['assetIn'],
        'statusIn': json['statusIn'] == null ? undefined : ((json['statusIn'] as Array<any>).map(CollectTaskStatusAPIModelFromJSON)),
        'hashLike': json['hashLike'] == null ? undefined : json['hashLike'],
        'addressLike': json['addressLike'] == null ? undefined : json['addressLike'],
        'addressIn': json['addressIn'] == null ? undefined : ((json['addressIn'] as Array<any>).map(CollectableAddressLinkAPIModelFromJSON)),
        'processAtRange': json['processAtRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['processAtRange']),
        'networkEq': json['networkEq'] == null ? undefined : BlockchainNetworkTypeAPIModelFromJSON(json['networkEq']),
    };
}

export function CollectTaskPredicatesAPIModelToJSON(value?: CollectTaskPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idIn': value['idIn'],
        'kindIn': value['kindIn'] == null ? undefined : ((value['kindIn'] as Array<any>).map(CollectableKindAPIModelToJSON)),
        'btIn': value['btIn'] == null ? undefined : ((value['btIn'] as Array<any>).map(BlockchainTypeAPIModelToJSON)),
        'assetIn': value['assetIn'],
        'statusIn': value['statusIn'] == null ? undefined : ((value['statusIn'] as Array<any>).map(CollectTaskStatusAPIModelToJSON)),
        'hashLike': value['hashLike'],
        'addressLike': value['addressLike'],
        'addressIn': value['addressIn'] == null ? undefined : ((value['addressIn'] as Array<any>).map(CollectableAddressLinkAPIModelToJSON)),
        'processAtRange': DateTimeRangeAPIModelToJSON(value['processAtRange']),
        'networkEq': BlockchainNetworkTypeAPIModelToJSON(value['networkEq']),
    };
}

