import React, { useCallback, useMemo } from 'react';

import { Web3WalletSolanaLogin } from '@/features/auth/components';
import type { Web3WalletSolanaLoginOperationProps } from '@/features/auth/components';
import { useAuthWeb3SolanaActions } from '@/features/auth/hooks';
import { Web3WalletContainer } from '@/features/web3/components';
import type { SolanaOperationConfig } from '@/features/web3/components';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { asAFunc } from '@/infrastructure/utils/ts';

import type { SolanaWalletsProps } from './types';

const SolanaWallets: React.FC<SolanaWalletsProps> = ({
  'data-test': dataTest,
  activeProvider,
  withLogIn,
  withSelectProvider,
  onPrepareCancel,
}) => {
  const { auth: authAction } = useAuthWeb3SolanaActions();
  const doWeb3Login: Web3WalletSolanaLoginOperationProps['login'] = useCallback(
    ({ account }, cancellation) =>
      asAFunc(
        withSelectProvider(
          withLogIn(
            (onBeforeSignUp, withRecaptcha) => () =>
              authAction.act({ account, withRecaptcha }, { onBeforeSignUp, cancellation }),
          ),
        ),
      )(),
    [authAction, withLogIn, withSelectProvider],
  );

  const solana: SolanaOperationConfig<unknown, string, undefined, Web3WalletSolanaLoginOperationProps> = useMemo(
    () => ({
      Operation: Web3WalletSolanaLogin,
      extra: { login: doWeb3Login },
      filter: ({ id }) => !activeProvider || id === activeProvider,
    }),
    [activeProvider, doWeb3Login],
  );

  const withInProgress = useCallback(() => withSelectProvider, [withSelectProvider]);

  return (
    <Web3WalletContainer
      data-test={dataTest}
      description={null}
      api={BlockchainAPITypeAPIModel.Solana}
      withInProgress={withInProgress}
      solana={solana}
      bt={undefined}
      inProgressMode={activeProvider ? ('view' as const) : ('button' as const)}
      cancellation={onPrepareCancel}
    />
  );
};

const SolanaWalletsMemo: typeof SolanaWallets = React.memo(SolanaWallets);

export default SolanaWalletsMemo;
