/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { NCpsRoleAPIModel } from './NCpsRoleAPIModel';
import {
    NCpsRoleAPIModelFromJSON,
    NCpsRoleAPIModelFromJSONTyped,
    NCpsRoleAPIModelToJSON,
} from './NCpsRoleAPIModel';
import type { UserAddressAPIModel } from './UserAddressAPIModel';
import {
    UserAddressAPIModelFromJSON,
    UserAddressAPIModelFromJSONTyped,
    UserAddressAPIModelToJSON,
} from './UserAddressAPIModel';

/**
 * 
 * @export
 * @interface UserAPIModel
 */
export interface UserAPIModel {
    /**
     * 
     * @type {string}
     * @memberof UserAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserAPIModel
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<UserAddressAPIModel>}
     * @memberof UserAPIModel
     */
    addresses?: Array<UserAddressAPIModel>;
    /**
     * 
     * @type {number}
     * @memberof UserAPIModel
     */
    nonce: number;
    /**
     * 
     * @type {Array<NCpsRoleAPIModel>}
     * @memberof UserAPIModel
     */
    roles?: Array<NCpsRoleAPIModel>;
    /**
     * 
     * @type {boolean}
     * @memberof UserAPIModel
     */
    isEmailAuth: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAPIModel
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAPIModel
     */
    invitedBy?: string;
}

/**
 * Check if a given object implements the UserAPIModel interface.
 */
export function instanceOfUserAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('nonce' in value)) return false;
    if (!('isEmailAuth' in value)) return false;
    if (!('isActive' in value)) return false;
    return true;
}

export function UserAPIModelFromJSON(json: any): UserAPIModel {
    return UserAPIModelFromJSONTyped(json, false);
}

export function UserAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'] == null ? undefined : json['email'],
        'createdAt': (new Date(json['createdAt'])),
        'addresses': json['addresses'] == null ? undefined : ((json['addresses'] as Array<any>).map(UserAddressAPIModelFromJSON)),
        'nonce': json['nonce'],
        'roles': json['roles'] == null ? undefined : ((json['roles'] as Array<any>).map(NCpsRoleAPIModelFromJSON)),
        'isEmailAuth': json['isEmailAuth'],
        'isActive': json['isActive'],
        'invitedBy': json['invitedBy'] == null ? undefined : json['invitedBy'],
    };
}

export function UserAPIModelToJSON(value?: UserAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email': value['email'],
        'createdAt': ((value['createdAt']).toISOString()),
        'addresses': value['addresses'] == null ? undefined : ((value['addresses'] as Array<any>).map(UserAddressAPIModelToJSON)),
        'nonce': value['nonce'],
        'roles': value['roles'] == null ? undefined : ((value['roles'] as Array<any>).map(NCpsRoleAPIModelToJSON)),
        'isEmailAuth': value['isEmailAuth'],
        'isActive': value['isActive'],
        'invitedBy': value['invitedBy'],
    };
}

