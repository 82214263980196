import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import SettingsBreadcrumb from '@/pages/settings/components/SettingsBreadcrumb';

import SettingsComplianceLink from '../SettingsComplianceLink';

import type { SettingsComplianceBreadcrumbProps } from './types';

const SettingsComplianceBreadcrumb: React.FC<SettingsComplianceBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <SettingsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettings.COMPLIANCE_COMPONENTS_BREADCRUMB_TITLE} />
        ) : (
          <SettingsComplianceLink
            mode="text"
            title={<FormattedMessage id={I18nPageSettings.COMPLIANCE_COMPONENTS_BREADCRUMB_TITLE} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const SettingsComplianceBreadcrumbMemo = React.memo(SettingsComplianceBreadcrumb);

export default SettingsComplianceBreadcrumbMemo;
