import { List, Radio } from 'antd';
import React, { useCallback } from 'react';

import KYTToleranceLevelView from '@/features/company-settings/components/KYTToleranceLevelView';
import { KYTToleranceLevelAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { enumByKey, enumValues } from '@/infrastructure/utils/ts';

import type { KYTLevelSelectionInputProps } from './types';
import type { RadioProps } from 'antd';

const values = enumValues(KYTToleranceLevelAPIModel);

const KYTLevelSelectionInput: React.FC<KYTLevelSelectionInputProps> = ({ 'data-test': dataTest, value, onChange }) => {
  const doChange = useCallback<NonNullable<RadioProps['onChange']>>(
    (e) => {
      // this cast is safe because options are limited to KYTToleranceLevelAPIModel, but unfortunately onChange event is not typed
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      onChange?.(enumByKey(KYTToleranceLevelAPIModel, e.target.value));
    },
    [onChange],
  );
  return (
    <List<KYTToleranceLevelAPIModel>
      size="small"
      itemLayout="horizontal"
      loadMore={false}
      dataSource={values}
      renderItem={useCallback(
        (item: KYTToleranceLevelAPIModel) => (
          <List.Item key={item}>
            <List.Item.Meta
              data-test={dataTest && `${dataTest}-item-${item}`}
              avatar={<Radio checked={item === value} onChange={doChange} value={item} />}
              title={<KYTToleranceLevelView value={item} withDetails mode="full" />}
              description={<KYTToleranceLevelView value={item} withDetails mode="description" />}
            />
          </List.Item>
        ),
        [dataTest, doChange, value],
      )}
    />
  );
};

const KYTLevelSelectionInputMemo = React.memo(KYTLevelSelectionInput) as typeof KYTLevelSelectionInput;

export default KYTLevelSelectionInputMemo;
