/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3921
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignatureComponentsAPIModel
 */
export interface SignatureComponentsAPIModel {
    /**
     * 
     * @type {number}
     * @memberof SignatureComponentsAPIModel
     */
    v: number;
    /**
     * 
     * @type {string}
     * @memberof SignatureComponentsAPIModel
     */
    r: string;
    /**
     * 
     * @type {string}
     * @memberof SignatureComponentsAPIModel
     */
    s: string;
}

/**
 * Check if a given object implements the SignatureComponentsAPIModel interface.
 */
export function instanceOfSignatureComponentsAPIModel(value: object): boolean {
    if (!('v' in value)) return false;
    if (!('r' in value)) return false;
    if (!('s' in value)) return false;
    return true;
}

export function SignatureComponentsAPIModelFromJSON(json: any): SignatureComponentsAPIModel {
    return SignatureComponentsAPIModelFromJSONTyped(json, false);
}

export function SignatureComponentsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignatureComponentsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'v': json['v'],
        'r': json['r'],
        's': json['s'],
    };
}

export function SignatureComponentsAPIModelToJSON(value?: SignatureComponentsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'v': value['v'],
        'r': value['r'],
        's': value['s'],
    };
}

