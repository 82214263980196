import { Form } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import type { PayoutDestinationRow } from '@/features/payouts/components/EditPayoutForm/components/PayoutDestinationsTableInput/types';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';

import PayoutDestinationsTableInput from '../PayoutDestinationsTableInput';

import type { PayoutDestinationsItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const rules = [
  {
    validator: async (_: unknown, value?: PayoutDestinationRow[]) =>
      !value?.length ? Promise.reject(new Error()) : Promise.resolve(),
    message: (
      <FormattedMessage
        id={I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_ERRORS_REQUIRED}
      />
    ),
  },
];

const PayoutDestinationsItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  assetItemName,
  readonly,
  required,
  onStateChange,
  ItemProps = {},
}: PayoutDestinationsItemProps<Values>) => {
  const asset = Form.useWatch<string | undefined>([assetItemName]);
  return (
    <Form.Item<Values>
      rules={required ? rules : undefined}
      {...ItemProps}
      name={name}
      labelCol={{ span: 0 }}
      wrapperCol={{ span: 24 }}
    >
      {asset ? (
        <PayoutDestinationsTableInput
          data-test={dataTest}
          asset={asset}
          disabled={readonly}
          onStateChange={onStateChange}
        />
      ) : null}
    </Form.Item>
  );
};

const PayoutDestinationsItemMemo = React.memo(PayoutDestinationsItem) as typeof PayoutDestinationsItem;

export default PayoutDestinationsItemMemo;
